import React, { useContext, useEffect, useState } from "react";
import "./GuestPostRequest.css";
import { FaCommentDots, FaEye } from "react-icons/fa";
import { GlobalContext } from "../Context/GlobalState";
import { IoSend } from "react-icons/io5";
import { ImCross } from "react-icons/im";
import { BASE_URL_API_TRANXT_UAT_API } from "../Utils/Constant";
import { Button, Col, Row } from "react-bootstrap";
import swal from "sweetalert";
import { RejectModal, ViewdocsModal } from "./Modal";
const Guestpostrequest = () => {
  const [showModal, setShowModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [selectedDocs, setSelectedDocs] = useState([]);
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [message, setMessage] = useState("");
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [filterDate, setFilterDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    if (query.trim() === "") {
      setFilteredData(data); 
    } else {
      const filtered = data.filter(
        (row) =>
          row.webisie_name.toLowerCase().includes(query) ||
          row.category_name.toLowerCase().includes(query) ||
          row.subcategory_name.toLowerCase().includes(query)
      );
      setFilteredData(filtered);
    }
  };

  const fetchData = async (date) => {
    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          userid: "0",
          date: date || filterDate,
        }),
      };

      const response = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetAllGpRequests`,
        requestOptions
      );

      const data = await response.json();

      if (data.status === "0" && data.data && data.data.length > 0) {
        setData(data.data);
        setFilteredData(data.data);
      } else {
        console.error(
          "No data found or failed to fetch:",
          data.message || "Unknown error"
        );
        setData([]);
        setFilteredData([]);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setTransactionLoaderState(false);
    }
  };


  const handleRowClick = (row) => {
    setSelectedRow(row);
    setSidebarVisible(true);
  };

  const closeSidebar = () => {
    setSidebarVisible(false);
  };

  const handleSendMessage = async () => {
    if (!message.trim()) return;

    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.user_id,
        requestid: selectedRow.id,
        remark: message,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      console.log("sending data to the server");

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GpConversation`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        setSelectedRow((prev) => ({
          ...prev,
          response: [
            ...(prev.response || []),
            {
              userid: user.user_id,
              remark: message,
              in_date: new Date().toISOString(),
            },
          ],
        }));
        setMessage("");
        fetchData();
      } else {
      }
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const handleClearMessage = () => {
    setMessage("");
  };

  const handleStatusChange = async (id, status, row) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        id: id,
        status: status,
        userid: user.user_id,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      console.log("sending status change request to the server");
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GpApprove`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        swal({
          title: "Success",
          text: data.result[0].message,
          icon: "success",
          buttons: true,
        }).then(() => {
          if (status === "R") {
            setSelectedRow({ ...row, id });
            setShowRejectModal(true);
          }
        });
        fetchData();
      } else {
        swal("Error", "Something went wrong. Please try again.", "error");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleViewDocs = async (requestId) => {
    try {
      setTransactionLoaderState(true);
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ requestId }),
      };

      const response = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetGpDocs`,
        requestOptions
      );
      const data = await response.json();

      if (data.status === "0" && data.data) {
        setSelectedDocs(data.data);
      } else {
        console.error(
          "Failed to fetch documents:",
          data.msg || "Unknown error"
        );
      }
      setShowModal(true);
      setTransactionLoaderState(false);
    } catch (error) {
      console.error("Error fetching documents:", error);
      setTransactionLoaderState(false);
    }
  };
  const handleFilterDateChange = (e) => {
    setFilterDate(e.target.value);
    fetchData(e.target.value);
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="guestpost-container">
      <Row className="p-0 mb-2 mt-2">
        <Col className="d-flex align-items-center">
          <h5>Guest Post Request </h5>
        </Col>
        <Col className="d-flex justify-content-end align-items-center">
        <div>
        <input
        type="text"
        placeholder="Search..."
        value={searchQuery}
        onChange={handleSearch}
        className="form-control my-3"
      />

        </div>
          <div className="ml-2 mr-3">
            <input
              type="date"
              id="filterDate"
              className="form-control"
              value={filterDate}
              onChange={handleFilterDateChange}
            />
          </div>
        </Col>
      </Row>
      <div className="table-responsive guestpost-container p-0">
        <table className="guestpost-table">
          <thead>
            <tr>
              <th
                style={{
                  background: "#B3C8CF",
                  color: "#1C325B",
                  fontSize: ".8rem",
                }}
              >
                Website{" "}
              </th>
              <th
                style={{
                  background: "#B3C8CF",
                  color: "#1C325B",
                  fontSize: ".8rem",
                }}
              >
                DA / PA
              </th>
              <th
                style={{
                  background: "#B3C8CF",
                  color: "#1C325B",
                  fontSize: ".8rem",
                }}
              >
                {" "}
                Price
              </th>
              <th
                style={{
                  background: "#B3C8CF",
                  color: "#1C325B",
                  fontSize: ".8rem",
                }}
              >
                Bid Price
              </th>
              <th
                style={{
                  background: "#B3C8CF",
                  color: "#1C325B",
                  fontSize: ".8rem",
                }}
              >
                Do
                <br /> Follow
              </th>
              <th
                style={{
                  background: "#B3C8CF",
                  color: "#1C325B",
                  fontSize: ".8rem",
                }}
              >
                Category/
                <br /> Subcategory
              </th>
              <th
                style={{
                  background: "#B3C8CF",
                  color: "#1C325B",
                  fontSize: ".8rem",
                }}
              >
                Articles <br />
                Count
              </th>
              <th
                style={{
                  background: "#B3C8CF",
                  color: "#1C325B",
                  fontSize: ".8rem",
                }}
              >
                Status
              </th>
              <th
                style={{
                  background: "#B3C8CF",
                  color: "#1C325B",
                  fontSize: ".8rem",
                }}
              >
                In Date /<br /> Updated
              </th>
              <th
                style={{
                  background: "#B3C8CF",
                  color: "#1C325B",
                  fontSize: ".8rem",
                }}
              >
                Action
              </th>
              <th>View Docs</th>
              {/* <th
              style={{
                background: "#B3C8CF",
                color: "#1C325B",
                fontSize: ".8rem",
              }}
            >
              Msg.
            </th> */}
            </tr>
          </thead>
          <tbody>
            {filteredData.length > 0 ? (
              filteredData.map((row) => (
                <tr key={row.id}>
                  <td style={{ fontSize: ".9rem" }}>{row.webisie_name}</td>
                  <td style={{ fontSize: ".9rem" }}>
                    {row.da} / {row.pa}
                  </td>
                  <td style={{ fontSize: ".9rem" }}>{row.define_price}</td>
                  <td style={{ fontSize: ".9rem" }}>{row.bid_price}</td>
                  <td style={{ fontSize: ".9rem" }}>{row.do_follow}</td>
                  <td style={{ fontSize: ".9rem" }}>
                    {row.category_name}/ <br />
                    {row.subcategory_name}
                  </td>
                  <td style={{ fontSize: ".9rem" }}>{row.articles_count}</td>
                  <td style={{ fontSize: ".9rem" }}>
                    {row.status === "A" && (
                      <span className="badge bg-success text-light">
                        Approved
                      </span>
                    )}
                    {row.status === "P" && (
                      <span className="badge bg-warning text-light">
                        Pending
                      </span>
                    )}
                    {row.status === "R" && (
                      <span className="badge bg-danger text-light">
                        Rejected
                      </span>
                    )}
                  </td>
                  <td style={{ fontSize: ".9rem" }}>
                    {new Date(row.in_date).toLocaleDateString()}/ <br />
                    {row.update
                      ? new Date(row.update).toLocaleDateString()
                      : "-"}
                  </td>
                  <td>
                    <Button
                      size="sm"
                      className="btn btn-success me-2"
                      onClick={() => handleStatusChange(row.id, "A")}
                      style={{ marginRight: ".3rem" }}
                    >
                      Approve
                    </Button>
                    <Button
                      size="sm"
                      className="btn btn-danger"
                      onClick={() => handleStatusChange(row.id, "R", row)}
                    >
                      Reject
                    </Button>
                  </td>
                  <td className="text-center">
                    <FaEye
                      onClick={() => handleViewDocs(row.id)}
                      style={{ cursor: "pointer", fontSize: "1.2rem" }}
                      title="View Documents"
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="11" className="text-center">
                  No Data Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <RejectModal
        show={showRejectModal}
        onHide={() => setShowRejectModal(false)}
        requestId={selectedRow ? selectedRow.id : null}
        articlesCount={selectedRow ? selectedRow.articles_count : null}
      />

      <ViewdocsModal
        show={showModal}
        onHide={() => {
          setShowModal(false);
          setSelectedDocs([]);
        }}
        docs={selectedDocs}
      />
      {sidebarVisible && selectedRow && (
        <div className="guestpost-sidebar">
          <button className="guestpost-close-btn" onClick={closeSidebar}>
            ✖
          </button>
          <h5>Messages for {selectedRow.webisie_name}</h5>
          <div className="guestpost-messages">
            {selectedRow.response ? (
              selectedRow.response
                .sort((a, b) => new Date(a.in_date) - new Date(b.in_date))
                .map((message, index) => (
                  <div
                    key={index}
                    className={
                      message.userid === user.user_id
                        ? "message-row user-message"
                        : "message-row other-message"
                    }
                  >
                    <p>{message.remark}</p>
                    <span className="message-timestamp">
                      {new Date(message.in_date).toLocaleString()}
                    </span>
                  </div>
                ))
            ) : (
              <p>No messages available</p>
            )}
          </div>

          <div className="message-input-container">
            <input
              type="text"
              className="message-input"
              placeholder="Type your message..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <IoSend onClick={handleSendMessage} />
            <ImCross onClick={handleClearMessage} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Guestpostrequest;
