import React, { useContext, useEffect, useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import swal from "sweetalert";
import { BASE_URL_API_TRANXT_UAT_API } from "../Utils/Constant";
import Select from "react-select";
import { GlobalContext } from "../Context/GlobalState";
import {
  FaCheckCircle,
  FaFolderOpen,
  FaGlobe,
  FaLink,
  FaSmile,
} from "react-icons/fa";
import "react-quill/dist/quill.snow.css";
import { Redirect } from "react-router-dom";
const Newregister = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [redirect, setRedirect] = useState(false);
  const [formData, setFormData] = useState({
    sitename: "",
    site_url: "",
    site_category: "",
    comp_urls: ["", "", "", "", ""],
    full_name: "",
    mobile: "",
    email: "",
    website: "",
    company_name: "",
    password: "",
    da: "",
    pa: "",
  });
  const [errors, setErrors] = useState({});
  const [appreciationMessage, setAppreciationMessage] = useState(
    "Welcome! We're excited to have you—let's get started with filling out the form!"
  );
  const { user } = useContext(GlobalContext);
  const appreciationIcons = [
    <FaCheckCircle />,
    <FaGlobe />,
    <FaFolderOpen />,
    <FaLink />,
    <FaSmile />,
  ];
  const appreciationMessages = [
    "Welcome! We're excited to have you—let's get started with filling out the form!",
    "Great! Let's start with the Site Name.",
    "Nice job! Now, please provide the Site URL.",
    "Awesome! Select the category that best fits your site.",
    "You're doing well! Now, please enter the competitive URLs.",
    "Almost there! Just one more step to complete.",
  ];
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name.startsWith("comp_url")) {
      const index = parseInt(name.split("_")[2]);
      const newCompUrls = [...formData.comp_urls];
      newCompUrls[index] = value;
      setFormData({
        ...formData,
        comp_urls: newCompUrls,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }

    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: null,
      });
    }
  };
  const handleSelectChange = (selectedOption) => {
    setFormData({
      ...formData,
      site_category: selectedOption,
    });

    if (errors.site_category) {
      setErrors({
        ...errors,
        site_category: null,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (currentStep === 4) {
      try {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
          userid: "0",
          site_url: formData.site_url,
          site_category: formData.site_category?.label,
          comp_url1: formData.comp_urls[0],
          comp_url2: formData.comp_urls[1],
          comp_url3: formData.comp_urls[2],
          comp_url4: formData.comp_urls[3],
          comp_url5: formData.comp_urls[4],
          keywords: "string",
          project_name: formData.sitename,
          type: "1",
          full_name: formData.full_name,
          mobile: formData.mobile,
          email: formData.email,
          website: formData.website,
          company_name: formData.company_name,
          password: formData.password,
          da: formData.da,
          pa: formData.pa,

        });

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        console.log("sending data to the server");
        const res = await fetch(
          `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/AddYourUrl`,
          requestOptions
        );
        const data = await res.json();

        if (data.status === "1") {
          swal("Error", data.msg, "error");
        } else if (data.status === "0") {
          const message = data.result[0].message;
          swal("Success", message, "success");
          setFormData({
            site_url: "",
            site_category: "",
            comp_urls: ["", "", "", "", ""],
            sitename: "",
          });
        }

        console.log("Form submission completed.");
      } catch (error) {
        console.log("Error:", error);
      }
    }
  };
  const [categories, setCategories] = useState([]);
  const fetchCategories = async () => {
    try {
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetBlogCategories`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({}),
        }
      );
      const data = await res.json();
      if (data.status === "0") {
        setCategories(
          data.data.map((category) => ({
            label: category.category_name,
            value: category.id,
          }))
        );
      } else {
        console.error("Failed to fetch categories:", data.msg);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const nextStep = (e) => {
    e.preventDefault();
    setCurrentStep((prevStep) => {
      const newStep = Math.min(prevStep + 1, 4);
      setAppreciationMessage(appreciationMessages[newStep]);
      return newStep;
    });
  };

  const prevStep = () => {
    setCurrentStep((prevStep) => {
      const newStep = Math.max(prevStep - 1, 0);
      setAppreciationMessage(appreciationMessages[newStep]);
      return newStep;
    });
  };

  if (redirect) {
    return <Redirect to="/user-pages/login-2" />;
  }
  const renderStepContent = () => {
    switch (currentStep) {
      case 0:
        return (
          <>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formName" className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="FULL NAME"
                  name="full_name"
                  value={formData.full_name}
                  onChange={handleChange}
                  className="custom-placeholder"
                />
              </Form.Group>
              <Form.Group controlId="formMobile" className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="MOBILE"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                  className="custom-placeholder"
                />
              </Form.Group>
              <Form.Group controlId="formEmail" className="mb-3">
                <Form.Control
                  type="email"
                  placeholder="EMAIL"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="custom-placeholder"
                />
              </Form.Group>
              <Form.Group controlId="formWebsite" className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="WEBSITE"
                  name="website"
                  value={formData.website}
                  onChange={handleChange}
                  className="custom-placeholder"
                />
              </Form.Group>
              <Form.Group controlId="formCompanyName" className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="COMPANY NAME"
                  name="company_name"
                  value={formData.company_name}
                  onChange={handleChange}
                  className="custom-placeholder"
                />
              </Form.Group>
              <Form.Group controlId="formPassword" className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="CREATE PASSWORD"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  className="custom-placeholder"
                />
              </Form.Group>
              <Row>
                <Form.Group controlId="formDA" className="col mb-3">
                  <Form.Control
                    type="number"
                    placeholder="DA"
                    name="da"
                    value={formData.da}
                    onChange={handleChange}
                    className="custom-placeholder"
                  />
                </Form.Group>
                <Form.Group controlId="formPA" className="col mb-3">
                  <Form.Control
                    type="number"
                    placeholder="PA"
                    name="pa"
                    value={formData.pa}
                    onChange={handleChange}
                    className="custom-placeholder"
                  />
                </Form.Group>
              </Row>

              {/* <Button
                style={{ background: "#5089EB", borderRadius: "1.2rem" }}
                type="submit"
                className="w-100"
              >
                Next
              </Button> */}
            </Form>
          </>
        );

      case 1:
        return (
          <>
            <Form.Group controlId="formSiteName">
              <Form.Label>Site Name</Form.Label>
              <Form.Control
                type="text"
                name="sitename"
                value={formData.sitename}
                onChange={handleChange}
                placeholder="Enter site name"
                isInvalid={!!errors.sitename}
              />
            </Form.Group>
          </>
        );

      case 2:
        return (
          <>
            <Form.Group controlId="formSiteUrl">
              <Form.Label>Site URL</Form.Label>
              <Form.Control
                type="text"
                name="site_url"
                value={formData.site_url}
                onChange={handleChange}
                placeholder="Enter site URL"
                isInvalid={!!errors.site_url}
              />
            </Form.Group>
          </>
        );
      case 3:
        return (
          <>
            <Form.Group controlId="formSiteCategory">
              <Form.Label>Site Category</Form.Label>
              <Select
                value={formData.site_category}
                onChange={handleSelectChange}
                options={categories}
                placeholder="Select site category"
                isInvalid={!!errors.site_category}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    backgroundColor: "white",
                  }),
                  menu: (provided) => ({
                    ...provided,
                    backgroundColor: "white",
                  }),
                }}
              />
            </Form.Group>
          </>
        );
      case 4:
        return (
          <>
            <Form.Label>Competitive URLs</Form.Label>
            <Row>
              {formData.comp_urls.map((url, index) => (
                <Col key={index} md={6} className="mb-3">
                  <Form.Group controlId={`formCompUrl${index + 1}`}>
                    <Form.Control
                      type="text"
                      name={`comp_url_${index}`}
                      value={url}
                      onChange={handleChange}
                      placeholder={`Enter Competitive URL ${index + 1}`}
                      isInvalid={!!errors[`comp_url${index + 1}`]}
                    />
                  </Form.Group>
                </Col>
              ))}
            </Row>
          </>
        );

      default:
        return null;
    }
  };
  return (
    <div
      className="d-flex align-items-center justify-content-center auth auth-img-bg"
      style={{ height: "100vh", background: "#F4F6FF" }}
    >
      <div className="row flex-grow justify-content-center">
        <div className="col-lg-7 d-flex align-items-center justify-content-center">
          <div
            style={{
              background: "#FBFBFB",
              border: "1px solid #E4E0E1",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}
            className="auth-form-transparent text-left p-3"
          >
            <div className="d-flex justify-content-center mt-2 mb-3">
              <img
                src={require("../../assets/images/seoimages/logo.svg")}
                alt="GoSeo"
                style={{ width: "8rem" }}
              />
            </div>
            <h4 className="text-center text-primary font-weight-bold mb-3">
              Welcome to GoSeo!
            </h4>
            {appreciationMessage && (
              <div className="alert alert-success d-flex align-items-center">
                <span className="me-2">{appreciationIcons[currentStep]}</span>
                {appreciationMessage}
              </div>
            )}
            <Form onSubmit={handleSubmit}>
              {renderStepContent()}
              <Row className="m-0 p-0">
                <Col className="d-flex justify-content-between">
                  {currentStep > 0 && (
                    <Button variant="secondary" onClick={prevStep}>
                      Back
                    </Button>
                  )}

                  {currentStep === 4 ? (
                    <Button variant="primary" type="submit">
                      Submit
                    </Button>
                  ) : (
                    <Button variant="primary" onClick={nextStep}>
                      Next
                    </Button>
                  )}
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newregister;
