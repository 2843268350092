import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Nav, Tooltip, OverlayTrigger } from "react-bootstrap";
import { GiNewspaper } from "react-icons/gi";
import { Authorsitemaptable, Blogsitemaptable, Sitemaptable } from "./Table";
import { RiPagesLine } from "react-icons/ri";
import { FaUserPen } from "react-icons/fa6";
import { GlobalContext } from "../Context/GlobalState";
import { BASE_URL_API_TRANXT_UAT_API } from "../Utils/Constant";

const Sitemap = () => {
  const { setTransactionLoaderState,siteid,siteurl,Productname } = useContext(GlobalContext);
  const [stimapdata, setstimapdata] = useState([]);
  const [key, setKey] = useState("first");
  const [selectedLocation, setSelectedLocation] = useState(""); // state for the selected location

  const Getsitemapdata = async () => {
    setTransactionLoaderState(true);
    const trimmedUrl = siteurl.trim();

    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ webid: siteid, url: trimmedUrl }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/extract`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        setstimapdata(data.sitemaps);
        const pageLocation = data.sitemaps.find((sitemap) =>
          sitemap.location.includes("sitemap_pages.xml")
        )?.location;
        if (pageLocation) {
          setSelectedLocation(pageLocation);
          setKey("first");
        }
      } else {
        setstimapdata([]);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    Getsitemapdata();
  }, [siteid]);

  // Function to handle the Nav.Link click and set the selected location
  const handleNavClick = (location) => {
    setSelectedLocation(location);
  };

  return (
    <Row>
      <Col
        md={1}
        className="sitemap-tabs-container m-0 p-0"
        style={{
          borderRight: "1px solid grey",
          background: "#fff",
          height: "90vh",
        }}
      >
        <Nav
          variant="pills"
          className="flex-column"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          style={{ border: "none" }}
        >
          {/* Page Tab */}
          <Nav.Item>
            <Nav.Link
              eventKey="first"
              onClick={() => {
                const pageLocation = stimapdata.find((sitemap) =>
                  sitemap.location.includes("sitemap_pages.xml")
                )?.location;
                if (pageLocation) {
                  handleNavClick(pageLocation);
                }
              }} // Dynamically pass location for Page
            >
              <h6 style={{ color: "#441752" }}>
                {" "}
                Pages
                <br /> Sitemap
              </h6>
            </Nav.Link>
          </Nav.Item>

          {/* Author Tab */}
          <Nav.Item>
            <Nav.Link
              eventKey="second"
              onClick={() => {
                const authorLocation = stimapdata.find((sitemap) =>
                  sitemap.location.includes("sitemap_author.xml")
                )?.location;
                if (authorLocation) {
                  handleNavClick(authorLocation);
                }
              }} // Dynamically pass location for Author
            >
                           <h6 style={{ color: "#441752" }}>
                {" "}
                Author
                <br /> Sitemap
              </h6>
            </Nav.Link>
          </Nav.Item>

          {/* Blog Tab */}
          <Nav.Item>
            <Nav.Link
              eventKey="third"
              onClick={() => {
                const blogLocation = stimapdata.find((sitemap) =>
                  sitemap.location.includes("sitemap_blogs.xml")
                )?.location;
                if (blogLocation) {
                  handleNavClick(blogLocation);
                }
              }}
            >
                           <h6 style={{ color: "#441752" }}>
                {" "}
                Blog
                <br /> Sitemap
              </h6>
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </Col>

      <Col sm={11} className="sitemap-content-container">
        {key === "first" && (
          <Sitemaptable sitemaps={stimapdata} location={selectedLocation} />
        )}
        {key === "second" && <Authorsitemaptable location={selectedLocation} />}
        {key === "third" && <Blogsitemaptable location={selectedLocation} />}
      </Col>
    </Row>
  );
};

export default Sitemap;
