import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import {
  Button,
  Card,
  Col,
  Form,
  Image,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import ReactJson from "react-json-view";
import { GlobalContext } from "../Context/GlobalState";
import { BASE_URL_API_TRANXT_UAT_API } from "../Utils/Constant";
import swal from "sweetalert";
import { FaPlus, FaTrash } from "react-icons/fa";
import projectImage from "../../assets/images/seoimages/selectproject.png";
import visitorDashboardImage from "../../assets/images/seoimages/visitor_dashboard.png";
import auditImage1 from "../../assets/images/seoimages/Audit1.png";
import auditImage2 from "../../assets/images/seoimages/Audit2.png";
import campaignImage1 from "../../assets/images/seoimages/Campaign1.png";
import campaignImage2 from "../../assets/images/seoimages/Campaign2.png";
import seoDataImage from "../../assets/images/seoimages/Workspace.png";
import socialMediaImage from "../../assets/images/seoimages/Social.png";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
export const ResubmitModal = ({ show, onHide, rowData, fetchData }) => {
  console.log("rowData inside modal:", rowData);
  console.log(rowData?.total_bid);
  const { user } = useContext(GlobalContext);
  const [remark, setRemark] = useState("");
  const [price, setPrice] = useState("");

  const handleSubmit = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        userid: user?.user_id,
        requestid: rowData?.id,
        remark: remark,
        amount: price,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      console.log("Sending data to the server...");

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GpConversation`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        swal(
          "Success",
          data.result[0]?.message || "Operation successful",
          "success"
        );
        setRemark("");
        setPrice("");
        onHide();
        fetchData();
      } else {
        swal("Error", data.msg || "Something went wrong", "error");
      }
    } catch (error) {
      console.error("Error sending message:", error);
      swal("Error", "Error occurred while processing your request.", "error");
    }
  };
  useEffect(() => {
    if (show && rowData?.total_bid) {
      setPrice(rowData.total_bid);
    }
  }, [show, rowData]);
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Re-Bid</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Are you sure you want to re-bid for{" "}
          <strong style={{ color: "black" }}>
            {rowData?.webisie_name || "N/A"}
          </strong>
          ?
        </p>
        <Form>
          <Form.Group controlId="priceField" className="mb-3">
            <Form.Label>Price for {rowData?.articles_count} Article</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter price"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="remarkField" className="mb-3">
            <Form.Label>Remark</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your remark"
              value={remark}
              onChange={(e) => setRemark(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const RejectModal = ({ show, onHide, requestId, articlesCount }) => {
  console.log("rowData inside modal:", requestId);
  console.log("articlesCount inside modal:", articlesCount);

  const { user } = useContext(GlobalContext);
  const [remark, setRemark] = useState("");
  const [price, setPrice] = useState("");

  const handleSubmit = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        userid: user?.user_id,
        requestid: requestId,
        remark: remark,
        amount: price,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      console.log("Sending data to the server...");

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GpConversation`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        swal(
          "Success",
          data.result[0]?.message || "Operation successful",
          "success"
        );
        setRemark("");
        setPrice("");
        onHide();
      } else {
        swal("Error", data.msg || "Something went wrong", "error");
      }
    } catch (error) {
      console.error("Error sending message:", error);
      swal("Error", "Error occurred while processing your request.", "error");
    }
  };
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Remark</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="priceField" className="mb-3">
            <Form.Label>Price for {articlesCount} Article</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter price"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="remarkField" className="mb-3">
            <Form.Label>Remark</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your remark"
              value={remark}
              onChange={(e) => setRemark(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const Projectmodal = ({
  modalIsOpen,
  handleClose,
  urlOptions,
  selectedURL,
  handleSelectChange,
}) => {
  return (
    <>
      <div>
        <Modal show={modalIsOpen} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Select Project URL</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <span style={{ color: "#0F67B1" }}>Select Project :</span>
              <Select
                options={urlOptions}
                className="basic-single"
                classNamePrefix="select"
                placeholder="Select URL"
                value={selectedURL}
                onChange={handleSelectChange}
                menuPlacement="auto"
                menuPosition="fixed"
                styles={{
                  control: (provided) => ({
                    ...provided,
                    backgroundColor: "#fff",
                    borderColor: "#ccc",
                  }),
                  menu: (provided) => ({
                    ...provided,
                    backgroundColor: "#fff",
                    zIndex: 1050,
                  }),
                }}
              />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};
export const Jsonmodal = ({
  showModal,
  handleCloseModal,
  loadingModal,
  selectedURL,
  jsonData,
  validateJson,
  handleSubmit,
}) => {
  const preventCopyPaste = (event) => {
    event.preventDefault();
  };
  return (
    <>
      <div>
        <Modal show={showModal} onHide={handleCloseModal} className="jsonmodal">
          <Modal.Header closeButton>
            <Modal.Title>
              {selectedURL ? `JSON for ${selectedURL.label}` : "JSON Data"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              style={{ height: "70vh", overflow: "auto", userSelect: "none" }}
              onCopy={preventCopyPaste}
              onPaste={preventCopyPaste}
            >
              {loadingModal ? (
                <div className="d-flex justify-content-center align-items-center">
                  <Spinner animation="border" />
                </div>
              ) : (
                <ReactJson src={jsonData} />
              )}
            </div>
            <Button
              className="mt-4 mr-2"
              variant="info"
              size="sm"
              onClick={validateJson}
            >
              Validate JOSN
            </Button>

            <Button
              className="mt-4"
              variant="info"
              size="sm"
              onClick={handleSubmit}
            >
              Final Submit
            </Button>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export const AddbacklinksSiteModal = ({
  show,
  handleClose,
  GetBackLinksCredentials,
}) => {
  const { user,siteid } = useContext(GlobalContext);
  const [formData, setFormData] = useState({
    websiteurl: "",
    email: "",
    password: "",
    username: "",
    domain_authority: "",
    page_authority: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const requestData = {
      webisteurl: formData.websiteurl,
      email: formData.email,
      password: formData.password,
      username: formData.username,
      domain_authoriy: formData.domain_authority,
      page_authoriy: formData.page_authority,
      userId: user.user_id,
      webid:siteid
    };

    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      };

      console.log("Sending data to the server");
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/InsertBacklinkCredentials`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        swal("Success", data.result[0].message, "success");
        handleClose();
        GetBackLinksCredentials();
      } else {
        swal("Error", data.msg, "error");
        GetBackLinksCredentials();
      }

      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add New Site</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="row m-0 p-0">
            <Form.Group className="col" controlId="websiteurl">
              <Form.Label>Website URL</Form.Label>
              <Form.Control
                type="url"
                name="websiteurl"
                value={formData.websiteurl}
                placeholder="Enter website URL"
                onChange={handleInputChange}
              />
            </Form.Group>
          </div>

          <div className="row m-0 p-0">
            <Form.Group className="col" controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                placeholder="Enter contact email"
                onChange={handleInputChange}
              />
            </Form.Group>
          </div>

          <div className="row m-0 p-0">
            <Form.Group className="col" controlId="username">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                name="username"
                value={formData.username}
                placeholder="Enter login username"
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group className="col" controlId="password">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="text"
                name="password"
                value={formData.password}
                placeholder="Enter login password"
                onChange={handleInputChange}
              />
            </Form.Group>
          </div>

          <div className="row m-0 p-0">
            <Form.Group className="col" controlId="domain_authority">
              <Form.Label>Domain Authority</Form.Label>
              <Form.Control
                type="number"
                name="domain_authority"
                value={formData.domain_authority}
                placeholder="Enter domain authority"
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group className="col" controlId="page_authority">
              <Form.Label>Page Authority</Form.Label>
              <Form.Control
                type="number"
                name="page_authority"
                value={formData.page_authority}
                placeholder="Enter page authority"
                onChange={handleInputChange}
              />
            </Form.Group>
          </div>
        </Form>

        <div className="row m-0 p-0 mx-3">
          <Button size="sm" variant="primary" onClick={handleSubmit}>
            Save
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export const EditSiteModal = ({
  showModal,
  handleModalClose,
  handleFormSubmit,
  formData,
  setFormData,
}) => {
  useEffect(() => {
    if (!showModal) {
      setFormData({
        weburl: "",
        email: "",
        password: "",
        username: "",
        da: "",
        pa: "",
      });
    }
  }, [showModal, setFormData]);

  return (
    <Modal show={showModal} onHide={handleModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Site Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleFormSubmit}>
          <Row className="p-0 m-0">
            {" "}
            <Form.Group className="col p-1" controlId="weburl">
              <Form.Label>Web URL</Form.Label>
              <Form.Control
                type="text"
                value={formData.weburl}
                onChange={(e) =>
                  setFormData({ ...formData, weburl: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="col p-1" controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
              />
            </Form.Group>
          </Row>
          <Row className="p-0 m-0">
            <Form.Group className="col p-1" controlId="password">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="text"
                value={formData.password}
                onChange={(e) =>
                  setFormData({ ...formData, password: e.target.value })
                }
              />
            </Form.Group>

            <Form.Group className="col p-1" controlId="username">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                value={formData.username}
                onChange={(e) =>
                  setFormData({ ...formData, username: e.target.value })
                }
              />
            </Form.Group>
          </Row>
          <Row className="p-0 m-0">
            <Form.Group className="col p-1" controlId="da">
              <Form.Label>Domain Authority</Form.Label>
              <Form.Control
                type="text"
                value={formData.da}
                onChange={(e) =>
                  setFormData({ ...formData, da: e.target.value })
                }
              />
            </Form.Group>

            <Form.Group className="col p-1" controlId="pa">
              <Form.Label>Page Authority</Form.Label>
              <Form.Control
                type="text"
                value={formData.pa}
                onChange={(e) =>
                  setFormData({ ...formData, pa: e.target.value })
                }
              />
            </Form.Group>
          </Row>
          <Button variant="primary" type="submit">
            Save Changes
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export const AddbacklinkModal = ({
  show,
  handleClose,
  GetBackLinksUrl,
  siteId,
}) => {
  const [backlinks, setBacklinks] = useState([
    { backlink_url: "", indexType: "" },
  ]);
  const { user, setTransactionLoaderState } = useContext(GlobalContext);

  const handleInputChange = (index, e) => {
    const { name, value } = e.target;
    const newBacklinks = [...backlinks];
    newBacklinks[index][name] = value;
    setBacklinks(newBacklinks);
  };

  const handleAddBacklink = () => {
    setBacklinks([...backlinks, { backlink_url: "", indexType: "" }]);
  };

  const handleRemoveBacklink = (index) => {
    const newBacklinks = backlinks.filter((_, idx) => idx !== index);
    setBacklinks(newBacklinks);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const requestData = {
      requests: backlinks.map((link) => ({
        fkid: siteId,
        backlink_url: link.backlink_url,
        is_indexed: link.indexType || "N",
        userid: user?.user_id,
      })),
    };

    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/InsertBacklinksUrl`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        swal("Success", data.msg, "success");
        handleClose();
        GetBackLinksUrl();
      } else {
        swal("Error", data.msg, "error");
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add New Backlinks</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          {backlinks.map((backlink, index) => (
            <div key={index} className="row m-0 p-0 align-items-center">
              <Form.Group className="col-5" controlId={`backlink_url_${index}`}>
                <Form.Label>Backlink URL</Form.Label>
                <Form.Control
                  type="url"
                  name="backlink_url"
                  value={backlink.backlink_url}
                  placeholder="Enter Backlink URL"
                  onChange={(e) => handleInputChange(index, e)}
                  required
                />
              </Form.Group>

              <Form.Group className="col-5" controlId={`indexType_${index}`}>
                <div>
                  <Form.Check
                    inline
                    label="Do follow index"
                    type="checkbox"
                    name="indexType"
                    checked={backlink.indexType === "index"}
                    onChange={(e) => {
                      const newIndexType = e.target.checked ? "index" : "";
                      const newBacklinks = [...backlinks];
                      newBacklinks[index].indexType = newIndexType;
                      setBacklinks(newBacklinks);
                    }}
                  />
                  <Form.Check
                    inline
                    label="No follow index"
                    type="checkbox"
                    name="indexType"
                    checked={backlink.indexType === "noindex"}
                    onChange={(e) => {
                      const newIndexType = e.target.checked ? "noindex" : "";
                      const newBacklinks = [...backlinks];
                      newBacklinks[index].indexType = newIndexType;
                      setBacklinks(newBacklinks);
                    }}
                  />
                </div>
              </Form.Group>

              <div className="col-2 d-flex align-items-center justify-content-center">
                {index === backlinks.length - 1 ? (
                  <Button
                    variant="success"
                    size="sm"
                    onClick={handleAddBacklink}
                  >
                    <FaPlus />
                  </Button>
                ) : (
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => handleRemoveBacklink(index)}
                  >
                    <FaTrash />
                  </Button>
                )}
              </div>
            </div>
          ))}

          <div className="row m-0 p-0 mt-3 mx-3">
            <Button size="sm" variant="primary" type="submit">
              Save
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export const EditBacklinkModal = ({
  show,
  handleClose,
  backlink,
  handleUpdate,
  GetBackLinksUrl,
}) => {
  const [formData, setFormData] = useState({
    backlink_url: "",
    indexType: "",
  });

  useEffect(() => {
    if (backlink) {
      console.log(backlink);
      const newIndexType =
        backlink.is_indexed === "Do follow index" ? "index" : "noindex";
      setFormData({
        backlink_url: backlink.backlink_url || "",
        indexType: newIndexType,
      });
      console.log("Updated indexType:", newIndexType);
    }
  }, [backlink]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const requestData = {
      id: backlink.id,
      fkid: backlink.fkid,
      url: formData.backlink_url,
      isindexed: formData.indexType,
    };

    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      };

      console.log("Sending data to the server");
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/EditBacklinkURL`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        swal(
          "Success",
          data.result[0].message || "Url Edited Successfully",
          "success"
        );

        handleClose();
        GetBackLinksUrl();
      } else {
        swal("Error", data.msg || "Failed to update backlink", "error");
      }
    } catch (error) {
      console.error("Error updating backlink:", error);
      swal("Error", "An error occurred while updating the backlink", "error");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Backlink</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="backlink_url">
            <Form.Label>Backlink URL</Form.Label>
            <Form.Control
              type="url"
              name="backlink_url"
              value={formData.backlink_url}
              onChange={handleInputChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="indexType">
            <Form.Label>Index Type</Form.Label>
            <div>
              <Form.Check
                inline
                label="Do follow index"
                type="radio"
                name="indexType"
                value="index"
                checked={formData.indexType === "index"}
                onChange={handleInputChange}
              />
              <Form.Check
                inline
                label="No follow index"
                type="radio"
                name="indexType"
                value="noindex"
                checked={formData.indexType === "noindex"}
                onChange={handleInputChange}
              />
            </div>
          </Form.Group>

          <Button variant="primary" type="submit">
            Save Changes
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export const AddTaskModal = ({ show, handleClose, handleSubmit }) => {
  const [formData, setFormData] = useState({
    url: "",
    social: "",
    date: "",
  });
  const [selectedUser, setSelectedUser] = useState(null);
  const { setTransactionLoaderState } = useContext(GlobalContext);
  const [users, setUsers] = useState([]);
  const socialOptions = [
    { value: "instagram", label: "Instagram" },
    { value: "facebook", label: "Facebook" },
    { value: "linkedin", label: "LinkedIn" },
    { value: "youtube", label: "YouTube" },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleUserChange = (selectedOption) => {
    setSelectedUser(selectedOption);
  };

  const handleSelectChange = (selectedOption) => {
    setFormData((prevData) => ({
      ...prevData,
      social: selectedOption.value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const updatedFormData = {
      ...formData,
      userid: selectedUser ? selectedUser.value : "",
    };
    handleSubmit(updatedFormData);
    handleClose();
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      backgroundColor: "white",
      color: "black",
      borderColor: "#ced4da",
      boxShadow: "none",
      "&:hover": {
        borderColor: "#86b7fe",
      },
    }),
    singleValue: (base) => ({
      ...base,
      color: "black",
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: "white",
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isFocused ? "#e9ecef" : "white",
      color: "black",
      "&:active": {
        backgroundColor: "#dee2e6",
      },
    }),
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add Task</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmit}>
          <Form.Group className="col" controlId="formSocial">
            <Form.Label>Social</Form.Label>
            <Select
              options={socialOptions}
              onChange={handleSelectChange}
              placeholder="Select Social"
              styles={customStyles}
            />
          </Form.Group>
          <Form.Group className="col" controlId="formUrl">
            <Form.Label>URL</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter URL"
              name="url"
              value={formData.url}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group className="col" controlId="formDate">
            <Form.Label>Date</Form.Label>
            <Form.Control
              type="date"
              name="date"
              value={formData.date}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Button className="mx-3" variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
export const Registermodal = ({ show, handleClose, GetGPAllDomains }) => {
  const [formData, setFormData] = useState({
    website_name: "",
    p_da: "",
    p_pa: "",
    p_define_price: "",
    p_email: "",
    p_phone: "",
    p_person_name: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        website_name: formData.website_name,
        p_da: formData.p_da,
        p_pa: formData.p_pa,
        p_define_price: formData.p_define_price,
        p_email: formData.p_email,
        p_phone: formData.p_phone,
        p_person_name: formData.p_person_name,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/RegisterYourDomain`,
        requestOptions
      );

      const data = await res.json();
      if (data.status === "1") {
        swal("Error", data.result, "error");
      } else {
        swal("Success", data.result[0].message, "success");

        setFormData({
          website_name: "",
          p_da: "",
          p_pa: "",
          p_define_price: "",
          p_email: "",
          p_phone: "",
          p_person_name: "",
        });
        GetGPAllDomains();
        handleClose();
      }
      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
    }
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Register Domain</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row className="m-0 p-0">
            {" "}
            <Form.Group className="col">
              <Form.Label>Website Name</Form.Label>
              <Form.Control
                type="text"
                name="website_name"
                value={formData.website_name}
                onChange={handleChange}
                placeholder="Enter website name"
              />
            </Form.Group>
          </Row>
          <Row className="m-0 p-0">
            <Form.Group className="col">
              <Form.Label>DA</Form.Label>
              <Form.Control
                type="text"
                name="p_da"
                value={formData.p_da}
                onChange={handleChange}
                placeholder="Enter DA"
              />
            </Form.Group>
            <Form.Group className="col">
              <Form.Label>PA</Form.Label>
              <Form.Control
                type="text"
                name="p_pa"
                value={formData.p_pa}
                onChange={handleChange}
                placeholder="Enter PA"
              />
            </Form.Group>
          </Row>
          <Row className="m-0 p-0">
            <Form.Group className="col">
              <Form.Label>Define Price</Form.Label>
              <Form.Control
                type="text"
                name="p_define_price"
                value={formData.p_define_price}
                onChange={handleChange}
                placeholder="Enter price"
              />
            </Form.Group>
            <Form.Group className="col">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="p_email"
                value={formData.p_email}
                onChange={handleChange}
                placeholder="Enter email"
              />
            </Form.Group>
          </Row>
          <Row className="m-0 p-0">
            <Form.Group className="col">
              <Form.Label>Person Name</Form.Label>
              <Form.Control
                type="text"
                name="p_person_name"
                value={formData.p_person_name}
                onChange={handleChange}
                placeholder="Enter person's name"
              />
            </Form.Group>
            <Form.Group className="col">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="text"
                name="p_phone"
                value={formData.p_phone}
                onChange={handleChange}
                placeholder="Enter phone number"
              />
            </Form.Group>
          </Row>
          <Row className="m-0 p-0">
            <Col>
              {" "}
              <Button variant="primary" size="sm" type="submit">
                Submit
              </Button>
            </Col>
          </Row>{" "}
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export const DocumentUploadModal = ({
  show,
  handleClose,
  articlesCount,
  rowData,
}) => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [files, setFiles] = useState(new Array(articlesCount).fill(null));
  const [fileUrls, setFileUrls] = useState([]);
  const handleFileChange = async (index, event) => {
    setTransactionLoaderState(true);
    const file = event.target.files[0];
    if (!file) return;
    const newFiles = [...files];
    newFiles[index] = file;
    setFiles(newFiles);

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await fetch(
        "https://goseo.in/gp_documents/upload_file.php",
        {
          method: "POST",
          body: formData,
        }
      );
      const data = await response.json();
      if (data.status === "success") {
        swal({
          title: "Success!",
          text: data.message,
          icon: "success",
          buttons: "OK",
        });
        setFileUrls((prevUrls) => [...prevUrls, data.file_url]);
      } else {
        swal({
          title: "Error!",
          text: data.message,
          icon: "error",
          buttons: "OK",
        });
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.error("Error uploading file:", error);
      setTransactionLoaderState(false);
      swal({
        title: "Error!",
        text: "There was an error uploading the file.",
        icon: "error",
        buttons: "OK",
      });
    }
  };

  const handleSubmit = async () => {
    setTransactionLoaderState(true);
    if (fileUrls.length === 0) {
      swal({
        title: "Error!",
        text: "No files uploaded.",
        icon: "error",
        buttons: "OK",
      });
      return;
    }

    try {
      const requestBody = {
        requests: fileUrls.map((fileUrl) => ({
          requestid: rowData?.id,
          userid: user?.user_id,
          url: fileUrl,
        })),
      };

      const response = await fetch("https://api.goseo.in/api/Seo/GpDocuments", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();
      if (data.status === "0" && data.result[0]?.result === "Y") {
        swal({
          title: "Success!",
          text: data.result[0]?.message || "Documents uploaded successfully.",
          icon: "success",
          buttons: "OK",
        });
        handleClose();
      } else {
        swal({
          title: "Error!",
          text: data.result[0]?.message || "Something went wrong.",
          icon: "error",
          buttons: "OK",
        });
      }
      setTransactionLoaderState(false);
    } catch (error) {}
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Upload Documents</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {Array.from({ length: articlesCount }).map((_, index) => (
            <Form.Group key={index} controlId={`fileUpload${index}`}>
              <Form.Label>Article #{index + 1}</Form.Label>
              <Form.Control
                type="file"
                onChange={(e) => handleFileChange(index, e)}
              />
            </Form.Group>
          ))}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Upload
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export const ViewdocsModal = ({ show, onHide, docs }) => {
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Documents</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {docs.length > 0 ? (
          <ul>
            {docs.map((doc, index) => (
              <li key={doc.id}>
                <a
                  href={doc.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >{`Document ${index + 1}`}</a>
              </li>
            ))}
          </ul>
        ) : (
          <p>No uploaded documents.</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export const WelcomeModal = ({ showModal, handleClose }) => {
  const [step, setStep] = useState(0);
  const { user, setTransactionLoaderState } = useContext(GlobalContext);

  const steps = [
    {
      title: "Step 1: Select Your Project",
      description: "From here, you can select your project.",
      image: [projectImage],
    },
    {
      title: "Step 2: Visitor Dashboard",
      description: "This is the visitor dashboard of your project.",
      image: [visitorDashboardImage],
    },
    {
      title: "Step 3: Audit Your Website Health",
      description: "Audit your website health.",
      image: [auditImage1, auditImage2],
    },
    {
      title: "Step 4: Run Your Campaigns",
      description: "Run your campaigns seamlessly from here.",
      image: [campaignImage1, campaignImage2],
    },
    {
      title: "Step 5: Manage Workspace or SEO Data",
      description: "Manage the SEO data of your website effectively.",
      image: [seoDataImage],
    },
    {
      title: "Step 6: Manage Your Social Media",
      description: "Handle your social media efficiently.",
      image: [socialMediaImage],
    },
  ];

  const handleNext = () => {
    if (step < steps.length - 1) setStep(step + 1);
  };

  const handlePrev = () => {
    if (step > 0) setStep(step - 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        mobile:   user.mobile,
       
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/UpdateFirstLogin`,
        requestOptions
      );

      const data = await res.json();
      if (data.status === "1") {
        swal("Error", data.result, "error");
      } else {
        // swal("Success", data.result[0].message, "success");

        handleClose();
      }
      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
    }
  };
  return (
    <Modal show={showModal} backdrop="static" centered>
      <Modal.Header>
        <Modal.Title>{steps[step]?.title || "Welcome"}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <p>{steps[step]?.description || "Welcome to the application!"}</p>
        {Array.isArray(steps[step]?.image) && steps[step]?.image.length ? (
          steps[step].image.length === 1 ? (
            <Image src={steps[step].image[0]} alt={steps[step]?.title} fluid />
          ) : (
            <Row>
              {steps[step].image.map((img, index) => (
                <Col key={index} xs={12} md={6}>
                  <Image
                    src={img}
                    alt={`${steps[step]?.title} ${index + 1}`}
                    fluid
                  />
                </Col>
              ))}
            </Row>
          )
        ) : (
          <p>No images available for this step.</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handlePrev} disabled={step === 0}>
          Previous
        </Button>
        {step < steps.length - 1 ? (
          <Button variant="primary" onClick={handleNext}>
            Next
          </Button>
        ) : (
          <Button variant="success" onClick={handleSubmit}>
            Finish
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export const InvoiceModal = ({ showModal, handleCloseModal, row }) => {
  const [imageClass, setImageClass] = useState("");
  const downloadInvoice = async () => {
    setImageClass("pt-5");
    console.log(imageClass); 
    const invoiceImage = document.querySelector("img[alt='Logo']");
    const originalWidth = invoiceImage.style.width;
    const originalHeight = invoiceImage.style.height;
    const originalStyle = invoiceImage.style.marginTop; 
    invoiceImage.style.width = "9rem";  
    invoiceImage.style.height = "auto"; 
    invoiceImage.style.marginTop = "1.5rem"; 
    const invoiceDiv = document.getElementById("invoice");
    const canvas = await html2canvas(invoiceDiv, {
      scale: 2,
      useCORS: true,
    });
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "mm", "a4");
    const pdfWidth = 210;
    const pdfHeight = 297;
    const canvasWidth = canvas.width;
    const canvasHeight = canvas.height;
    const scaleX = pdfWidth / canvasWidth;
    const scaleY = pdfHeight / canvasHeight;
    const scale = Math.min(scaleX, scaleY);
    const xOffset = (pdfWidth - canvasWidth * scale) / 2;
    const yOffset = (pdfHeight - canvasHeight * scale) / 2;
    pdf.addImage(imgData, "PNG", xOffset, yOffset, canvasWidth * scale, canvasHeight * scale);
    pdf.save("invoice.pdf");
    invoiceImage.style.width = originalWidth;
    invoiceImage.style.height = originalHeight;
    invoiceImage.style.marginTop = originalStyle; 
  };
  
  useEffect(() => {
    console.log('Image class updated:', imageClass);
  }, [imageClass]);
  return (
    <Modal
      show={showModal}
      onHide={handleCloseModal}
      className="invoicecustom-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Invoice Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div  id="invoice" style={{ background: "#2176FF", height: "137vh", width: "100%" }}>
          <div
            style={{
              background: "#000",
              width: "100%",
              height: "137vh",
              borderTopLeftRadius: "12rem",
              borderBottomRightRadius: "12rem",
            }}
          >
            <div
              style={{
                background: "#fff",
                width: "100%",
                height: "137vh",
                borderTopLeftRadius: "13rem",
                borderBottomRightRadius: "13rem",
              }}
            >
              <Row className="pt-5 px-5">
                <Col>
                  <img  className={imageClass}
                    src={require("../../assets/images/seoimages/logo.svg")}
                    alt="Logo"
                    style={{ width: "11rem", height: "4rem" }}
                  />
                </Col>
                <Col className="d-flex justify-content-end mt-3">
                  <div>
                    <h6 style={{ color: "#2176FF", textAlign: "end" }}>
                      <b>OFFICE ADDRESS</b>
                    </h6>
                    <p className="mb-0 " style={{ textAlign: "end" }}>
                      <b>Bumppy Media Pvt. Ltd. 
                      </b>
                    </p>
                    <p className="mb-0 " style={{ textAlign: "end" }}>
                      <b>B-218 Ithum Tower</b>
                    </p>
                    <p className="mb-0" style={{ textAlign: "end" }}>
                      <b>Noida, Uttar Pradesh, 201301</b>
                    </p>
                  </div>
                </Col>
              </Row>
              <Row className="px-5 pt-0">
                <Col>
                  <p className="mb-0" style={{ fontSize: "1.7rem" }}>
                    INVOICE
                  </p>
                  <p>
                    For:{" "}
                    <span style={{ fontSize: "1.2rem" }}>Article</span>
                  </p>
                </Col>
                <Col md={4}>
                  <h6 style={{ color: "#2176FF" }}>
                    <b>Total Due</b>
                  </h6>
                  <div style={{ background: "#2176FF" }}>
                    <p
                      style={{
                        color: "#fff",
                        fontSize: "2rem",
                        textAlign: "center",
                      }}
                    >
                      <b>$20</b>
                    </p>
                  </div>
                </Col>
              </Row>
              <Row className="px-5 pt-0">
                <Col>
                  <h6 style={{ color: "#2176FF" }}>
                    <b>Billed To</b>
                  </h6>
                  <p className="mb-0">Abhijeet, Anlook Solution</p>
                  <p className="mb-0">RC-85/B Antriksh Golf</p>
                  <p className="mb-0">Sector-81, Noida</p>
                  <p>9812843765</p>
                </Col>
                <Col className="mt-4 p-0">
                  <Row className="m-0 p-0">
                    <Col style={{ textAlign: "end", color: "#2176FF" }}>
                      <h6>INVOICE NO</h6>
                    </Col>
                    <Col md={4} style={{ textAlign: "end" }}>
                      <h6>87969796</h6>
                    </Col>
                  </Row>
                  <Row className="m-0 p-0">
                    <Col style={{ textAlign: "end", color: "#2176FF" }}>
                      <h6>ISSUE DATE</h6>
                    </Col>
                    <Col md={4} style={{ textAlign: "end" }}>
                     <h6> 09/11/2021</h6>
                    </Col>
                  </Row>
                  <Row className="m-0 p-0">
                    <Col style={{ textAlign: "end", color: "#2176FF" }}>
                      <h6>ACCOUNT NO</h6>
                    </Col>
                    <Col md={4} style={{ textAlign: "end" }}>
                      <h6>87969796</h6>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <div className="px-5">
                <table
                  className="table"
                  style={{ borderCollapse: "collapse", width: "100%" }}
                >
                  <thead>
                    <tr
                      style={{
                        borderBottom: "2px solid #dee2e6",
                        backgroundColor: "#2176FF",
                        color: "#FFFFFF",
                        textAlign: "center",
                      }}
                    >
                      <th colSpan="5">Invoice Summary</th>
                    </tr>

                    <tr style={{ borderBottom: "2px solid #dee2e6" }}>
                      <th>No</th>
                      <th>Items</th>
                      <th>Qty</th>
                      <th>Price</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr style={{ borderBottom: "1px solid #dee2e6" }}>
                      <td>1</td>
                      <td>
                        Article Publishing - Bumppy.com
                        https://www.bumppy.com/exploring-the-
                        best-airlines-for-kolkata-to-bangalore-flights-
                        a-comprehensive-comparison/
                      </td>
                      <td>2</td>
                      <td>$10</td>
                      <td>$20</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                className="px-5"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "2rem",
                  fontSize: "1rem",
                }}
              >
                <div>
                  <p className="mb-0">Subtotal:</p>
                  <p className="mb-0">Shipping:</p>
                  <p className="mb-0">
                    <b>TOTAL:</b>
                  </p>
                </div>
                <div style={{ textAlign: "right" }}>
                  <p className="mb-0">$0.00</p>
                  <p className="mb-0">$0.00</p>
                  <p className="mb-0">
                    <b>$0.00 USD</b>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
        onClick={downloadInvoice}
        style={{
          margin: "1rem",
          padding: "0.5rem 1rem",
          backgroundColor: "#2176FF",
          color: "#fff",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
        }}
      >
        Download Invoice
      </button>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
