import React, { createContext, useReducer } from "react";
import AppReducer from "./AppReducer";
const user = JSON.parse(sessionStorage.getItem("user"));
const SET_IS_VERIFIED = JSON.parse(sessionStorage.getItem("SET_IS_VERIFIED"));
const siteObj = JSON.parse(sessionStorage.getItem("SITE_OBJECT"));
const SET_id = sessionStorage.getItem("SET_id");
const SET_url = sessionStorage.getItem("SET_url");
const Product_name = sessionStorage.getItem("Product_name");
const initialState = {
  myAccountInfo: null,
  token: null,
  transactionLoader: false,
  user: user,
  isVerified: SET_IS_VERIFIED,
  siteid:SET_id,
  siteurl:SET_url,
  Productname:Product_name,
  siteObj:siteObj
};

export const GlobalContext = createContext(initialState);

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);
  function setProductname (Productname) {
    console.log("Called ......");
    dispatch({
      type: "Product_name",
      payload: Productname,
    });
  }
  function setsiteid (siteid) {
    console.log("Called ......");
    dispatch({
      type: "SET_id",
      payload: siteid,
    });
  }
  function setsiteObj (siteObj) {
    console.log("Called ......");
    dispatch({
      type: "SET_SITE_OBJECT",
      payload: siteObj,
    });
  }
  function setURL (siteurl) {
    console.log("Called ......");
    dispatch({
      type: "SET_url",
      payload: siteurl,
    });
  }
  function setIsVerified (isVerified) {

    dispatch({
      type: "SET_IS_VERIFIED",
      payload: isVerified,
    });
  }

  function setToken(token) {
    dispatch({
      type: "SET_TOKEN",
      payload: token,
    });
  }

  function setMyAccountInfo(info) {
    console.log(info);
    dispatch({
      type: "SET_MY_ACCOUNT_INFO",
      payload: info,
    });
  }

  function setTransactionLoaderState(loaderState) {
    dispatch({
      type: "SET_TRANSACTION_LOADER",
      payload: loaderState,
    });
  }
  function setUserInfo(info) {
    sessionStorage.setItem("user", JSON.stringify(info));
    dispatch({
      type: "SET_USER_INFO",
      payload: info,
    });
  }
  return (
    <GlobalContext.Provider
      value={{
        token: state.token,
        myAccountInfo: state.myAccountInfo,
        transactionLoader : state.transactionLoader,
        user: state.user, 
        isVerified: state.isVerified,
        siteid: state.siteid,
        siteurl: state.siteurl,
        Productname: state.Productname,
        siteObj: state.siteObj,
        setToken,
        setMyAccountInfo,
        setTransactionLoaderState,
        setUserInfo,
        setIsVerified,
        setsiteid,
        setURL,
        setProductname,
        setsiteObj
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
