import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../Context/GlobalState";

const Instagram = () => {
  const { user } = useContext(GlobalContext);
console.log(user.user_type);
  return (
  <>
  <h1>Instagram</h1>
  </>
  );
};

export default Instagram;
