import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../Context/GlobalState";
import { Dropdown, Modal } from "react-bootstrap";
import { Link, useHistory, Redirect } from "react-router-dom";
import { Trans } from "react-i18next";
import { Button } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import Swal from "sweetalert2";
import swal from "sweetalert";
import { Jsonmodal, Projectmodal } from "../GoSeo/Modal";
import { BASE_URL_API_TRANXT_UAT_API } from "../Utils/Constant";
const Navbar = () => {
  const {
    siteid,
    setsiteid,
    siteurl,
    setURL,
    siteObj,
    Productname,
    setProductname,
    setIsVerified,setTransactionLoaderState , setsiteObj
  } = useContext(GlobalContext);
  console.log(siteid);
  console.log(siteurl);
  console.log(Productname);
  const history = useHistory();
  function toggleOffcanvas() {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  }
  function logOut() {
    history.push("/user-pages/login-2");
  }
  const { user } = useContext(GlobalContext);
  const [selectedURL, setSelectedURL] = useState({
    label: siteObj?.label || "Select Url",
    value: siteObj?.value || "Select Url",
  });
  const [urlOptions, setUrlOptions] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  function toggleOffcanvas() {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  }

  function logOut() {
    history.push("/user-pages/login-2");
  }

  const handleSelectChange = (selectedOption) => {
    setsiteid(selectedOption.id);
    setURL(selectedOption.value);
    setProductname(selectedOption.project_name);
    console.log("Selected id:", selectedOption.id);
    console.log(setProductname);
    setsiteObj(selectedOption)
    setSelectedURL(selectedOption);
    sessionStorage.setItem("selectedURL", selectedOption.value);
    sessionStorage.setItem("selectedURLId", selectedOption.id);
    sessionStorage.setItem("selectedURLLabel", selectedOption.label);
    sessionStorage.setItem(
      "selectedURLProjectName",
      selectedOption.project_name
    );
    console.log("Selected Option:", selectedOption.value);
    setModalIsOpen(false);
    if (selectedOption.is_verified === "N") {
      setIsVerified(false);
    } else {
      setIsVerified(true);
    }
  };

  const Getsitemapurl = async () => {
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ userid: user.user_id }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GenerateUserAccessManagement`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        const options = data.result.map((item) => ({
          value: item.site_url,
          label: `${item.site_url} `,
          id: item.project_id,
          project_name: item.project_name,
          is_verified: item.is_verified,
        }));
        setUrlOptions(options);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };
  useEffect(() => {
    if (user) {
      const modalShown = sessionStorage.getItem(`modalShown-${user.id}`); 
      console.log("modalShown value:", modalShown);
  
      if (!modalShown) {
        console.log("Modal opened successfully 2.");
        setModalIsOpen(true);
        sessionStorage.setItem(`modalShown-${user.id}`, "true");
        console.log("Modal opened successfully 3.");
      }
    }
  }, [user]);
  
  

  useEffect(() => {
    Getsitemapurl();
  }, [user]);
  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: "14rem",
    }),
    control: (provided) => ({
      ...provided,
      boxShadow: "none",
      borderRadius: ".4rem",
      width: "13rem",
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: "0",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#f0f0f0" : "#fff",
      color: state.isSelected ? "#000" : "#000",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#999",
    }),
  };

  const [loadingModal, setLoadingModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [jsonData, setJsonData] = useState(null);
  const handleCloseModal = () => {
    setShowModal(false);
    setJsonData(null);
  };
  const validateJson = () => {
    if (jsonData) {
      try {
        JSON.parse(JSON.stringify(jsonData));
        Swal.fire({
          icon: "success",
          title: '<span style="color: black;">JSON is valid!</span>',
          html: '<span style="color: black;">The JSON data is correctly formatted.</span>',
          didOpen: () => {
            const titleElement = Swal.getTitle();
            const htmlContainer = Swal.getHtmlContainer();
            if (titleElement) titleElement.style.color = "black";
            if (htmlContainer) htmlContainer.style.color = "black";
          },
        });
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: '<span style="color: black;">Invalid JSON</span>',
          html: '<span style="color: black;">The JSON data is not properly formatted.</span>',
          didOpen: () => {
            const titleElement = Swal.getTitle();
            const htmlContainer = Swal.getHtmlContainer();
            if (titleElement) titleElement.style.color = "black";
            if (htmlContainer) htmlContainer.style.color = "black";
          },
        });
      }
    } else {
      Swal.fire({
        icon: "warning",
        title: '<span style="color: black;">No Data</span>',
        html: '<span style="color: black;">No JSON data available to validate.</span>',
        didOpen: () => {
          const titleElement = Swal.getTitle();
          const htmlContainer = Swal.getHtmlContainer();
          if (titleElement) titleElement.style.color = "black";
          if (htmlContainer) htmlContainer.style.color = "black";
        },
      });
    }
  };
  const handleSubmit = async () => {
    if (!selectedURL) {
      swal({
        icon: "warning",
        title: "No URL Selected",
        text: "Please select a URL before submitting.",
      });
      return;
    }

    const urlWithoutWWW = selectedURL.label.replace(
      /^https?:\/\/www\./,
      "https://"
    );
    const apiURL = `${urlWithoutWWW}update_manifest.php`;

    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ webid: selectedURL.id }),
      };

      const res = await fetch(apiURL, requestOptions);
      const data = await res.json();

      if (data.status === "0") {
        swal({
          icon: "success",
          title: "Success",
          text: "Manifest updated successfully!",
        });
      } else {
        swal({
          icon: "error",
          title: "Error",
          text: data.msg || "Something went wrong!",
        });
      }
    } catch (error) {
      console.log("Error:", error);
      swal({
        icon: "error",
        title: "Error",
        text: "An error occurred while updating the manifest.",
      });
    }
  };
  const handleShowModal = () => {
    setShowModal(true);
    Getseosetting();
  };
  const [seodata, setseodata] = useState([]);
  const Getseosetting = async () => {
    setTransactionLoaderState(true);
    try {
      if (!selectedURL) {
        console.warn("No URL selected");
        return;
      }

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ webid: siteid }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetSeoSettings`,
        requestOptions
      );
      const data = await res.json();

      setJsonData(data);
      if (data.status === "0") {
        setseodata(data.data);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };
  return (
    <>
      <GlobalContext.Consumer>
        {(state) =>
          state.user == null || state.user.user_type == "" ? (
            <Redirect to={`/user-pages/login-2`} />
          ) : (
            <nav
              style={{ background: "#fff" }}
              className="navbar p-0 fixed-top d-flex flex-row"
            >
              <ToastContainer
                autoClose={3000}
                hideProgressBar
                draggableDirection="y"
              />
              <div className="navbar-brand-wrapper d-flex d-lg-none align-items-center justify-content-center">
                {/* <Link className="navbar-brand brand-logo-mini" to="/"> */}
                <span
                  style={{ fontSize: "2rem", color: "#FFF", fontWeight: "600" }}
                >
                  Go
                </span>
                {/* </Link> */}
              </div>

              <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
                <button
                  className="navbar-toggler align-self-center"
                  type="button"
                  onClick={() =>
                    document.body.classList.toggle("sidebar-icon-only")
                  }
                >
                  <span
                    className="mdi mdi-menu"
                    style={{ color: "blue", fontSize: "1.2rem" }}
                  >
                    {" "}
                    &nbsp;&nbsp;&nbsp;
                    <img
                      src={require("../../assets/images/seoimages/logo.svg")}
                      alt="GoSeo"
                      style={{ width: "9vw" }}
                    />
                  </span>
                </button>

                <ul className="navbar-nav navbar-nav-right">
                  {state.myAccountInfo == null ? null : (
                    <li>
                      <span
                        style={{ fontWeight: "600" }}
                      >{`AEPS Bal : ₹ `}</span>
                      <span style={{ color: "green", fontWeight: "600" }}>
                        {" "}
                        {`${state.myAccountInfo.aepsBalance} `}
                      </span>
                      <span
                        style={{ fontWeight: "600" }}
                      >{`DMT Bal : ₹ `}</span>
                      <span style={{ color: "green", fontWeight: "600" }}>
                        {" "}
                        {`${state.myAccountInfo.dmtBalance} `}
                      </span>
                    </li>
                  )}
                  <div className="d-flex align-items-center justify-content-end p-0">
                    <span style={{ color: "#0F67B1" }}>
                      Select Project : &nbsp;
                    </span>
                    <Select
                      options={urlOptions}
                      className="basic-single"
                      classNamePrefix="select"
                      placeholder="Select URL"
                      value={selectedURL}
                      onChange={handleSelectChange}
                      styles={customStyles}
                    />
                    <Button size="sm" variant="success"  onClick={handleShowModal}>
                      Ready to Publish
                    </Button>
                  </div>
                  <Dropdown alignRight as="li" className="nav-item">
                    <Dropdown.Toggle
                      as="a"
                      className="nav-link cursor-pointer no-caret"
                    >
                      <div className="navbar-profile">
                        <img
                          className="img-xs rounded-circle"
                          // src={require("../../assets/images/faces/face7.jpg")}
                          src={user.profile_url}
                          alt="profile"
                        />
                        <p className="mb-0 d-none d-sm-block navbar-profile-name">
                          <Trans>
                            {state.myAccountInfo == null ? (
                              ""
                            ) : (
                              <span style={{ fontWeight: "600" }}>
                                {state.myAccountInfo.name}
                              </span>
                            )}
                          </Trans>
                        </p>
                        <i className="mdi mdi-menu-down d-none d-sm-block"></i>
                      </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      style={{ minWidth: "20rem" }}
                      className="navbar-dropdown preview-list navbar-profile-dropdown-menu"
                    >
                      <div style={{ margin: "10px", display: "flex" }}>
                        <img
                          style={{ maxHeight: "50px", maxWidth: "50px" }}
                          // src={userAvtar}
                          src={user.profile_url}
                        />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            marginLeft: "10px",
                          }}
                        >
                          <span
                            style={{
                              fontWeight: "600",
                              fontSize: "1rem",
                              padding: "10px",
                              paddingLeft: "0px",
                            }}
                          >
                            {state.myAccountInfo == null
                              ? ""
                              : state.myAccountInfo.name}
                          </span>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <span>
                              {state.myAccountInfo == null
                                ? ""
                                : state.myAccountInfo.vAccountNumber}
                            </span>
                            <button
                              onClick={() =>
                                toast.success("merchant id copied")
                              }
                              style={{
                                fontSize: "0.8rem",
                                outline: "0",
                                border: "1px solid #b2b2b3",
                                borderRadius: "4px",
                                paddingTop: "2px",
                                paddingBottom: "2px",
                              }}
                            >
                              {" "}
                              copy Merchant Id
                            </button>
                          </div>
                        </div>
                      </div>
                      <Dropdown.Divider />
                      <div
                        style={{
                          background: "#F5F5F5",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <span style={{ fontWeight: "600", padding: "10px" }}>
                          {" "}
                          Logged in as
                        </span>
                        <div
                          style={{
                            alignItems: "center",
                            display: "flex",
                            paddingLeft: "10px",
                          }}
                        >
                          <img
                            style={{ maxHeight: "25px", maxWidth: "25px" }}
                            // src={userAvtar}
                            src={user.profile_url}
                          />
                          <span style={{ paddingLeft: "10px" }}>
                            {" "}
                            {state.myAccountInfo == null
                              ? ""
                              : state.myAccountInfo.email}
                          </span>
                        </div>

                        <Button variant="primary m-2" onClick={logOut}>
                          Log out
                        </Button>
                      </div>
                      <Dropdown.Divider />
                    </Dropdown.Menu>
                  </Dropdown>
                </ul>
                <button
                  className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
                  type="button"
                  onClick={toggleOffcanvas}
                >
                  <span className="mdi mdi-format-line-spacing"></span>
                </button>
              </div>
            </nav>
          )
        }
      </GlobalContext.Consumer>
      {/* <WelcomeModal showModal={showModal} handleClose={handleClose} /> */}
      <Projectmodal
        modalIsOpen={modalIsOpen}
        handleClose={() => setModalIsOpen(false)}
        urlOptions={urlOptions}
        selectedURL={selectedURL}
        handleSelectChange={handleSelectChange}
      />
       <Jsonmodal
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        loadingModal={loadingModal}
        selectedURL={selectedURL}
        jsonData={jsonData}
        validateJson={validateJson}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

export default Navbar;
