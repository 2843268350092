import React from "react";

export default (state, action) => {
  switch (action.type) {
    case "SET_TOKEN":
      return {
        ...state,
        token: action.payload,
      };
    case "SET_MY_ACCOUNT_INFO":
      return {
        ...state,
        myAccountInfo: action.payload,
      };
    case "SET_TRANSACTION_LOADER":
      return { ...state, transactionLoader: action.payload };
    case "SET_USER_INFO":
      return {
        ...state,
        user: action.payload,
      };
    case "SET_IS_VERIFIED":
      sessionStorage.setItem("SET_IS_VERIFIED", action.payload);
      return { ...state, isVerified: action.payload };
    case "SET_id":
      sessionStorage.setItem("SET_id", action.payload);
      return { ...state, siteid: action.payload };
    case "SET_url":
      sessionStorage.setItem("SET_url", action.payload);
      return { ...state, siteurl: action.payload };
    case "Product_name":
      sessionStorage.setItem("Product_name", action.payload);
      return { ...state, Productname: action.payload };
    case "SET_SITE_OBJECT": 
      sessionStorage.setItem("SITE_OBJECT", JSON.stringify(action.payload));
      return { ...state, siteObj: action.payload };
    default:
      return state;
  }
};
