import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../Context/GlobalState";
import { BASE_URL_API_TRANXT_UAT_API } from "../Utils/Constant";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from "react-select";

const Structuredata = () => {
  const { setTransactionLoaderState } = useContext(GlobalContext);
  const [Schemadat, setSchemadat] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [filteredData, setFilteredData] = useState([]);

  const dropdownOptions = [
    { value: "", label: "Clear Filter" },
    ...Schemadat.map((item) => ({
      value: item.id,
      label: item.feature_name,
    })),
  ];

  const GetSchemaTypes = async () => {
    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({}),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetSchemaTypes`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        setSchemadat(data.data);
        setFilteredData(data.data); 
      } else {
        setSchemadat([]);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  const handleGetStartedClick = (id) => {
    sessionStorage.setItem("featureId", id);
  };

  useEffect(() => {
    GetSchemaTypes();
  }, []);

  useEffect(() => {
    if (selectedOption && selectedOption.value) {
      const filtered = Schemadat.filter(
        (item) => item.id === selectedOption.value
      );
      setFilteredData(filtered);
    } else if (selectedOption && selectedOption.value === "") {
      setFilteredData(Schemadat);
    } else {
      setFilteredData(Schemadat); 
    }
  }, [selectedOption, Schemadat]);

  return (
    <div className="m-2 pt-3">
      <h5>Structure Data</h5>
      <div className="mt-2 mb-3 col-3 p-0">
        <Select
          options={dropdownOptions}
          value={selectedOption}
          onChange={setSelectedOption}
          placeholder="Select Feature"
          styles={{
            control: (base) => ({
              ...base,
              backgroundColor: "white",
              color: "black",
              borderColor: "#ccc",
            }),
            menu: (base) => ({
              ...base,
              backgroundColor: "white",
            }),
            option: (base, state) => ({
              ...base,
              backgroundColor: state.isSelected ? "#f0f0f0" : "white",
              color: "black",
              "&:hover": {
                backgroundColor: "#e6e6e6",
              },
            }),
            singleValue: (base) => ({
              ...base,
              color: "black",
            }),
            placeholder: (base) => ({
              ...base,
              color: "black",
            }),
          }}
        />
      </div>
      <div className="table-container">
        <table className="schema-table">
          <colgroup>
            <col style={{ width: "60%" }} />
            <col style={{ width: "40%" }} />
          </colgroup>
          <thead>
            <tr>
              <th>Structure Data Feature</th>
              <th>Image</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.length > 0 ? (
              filteredData.map((item) => (
                <tr key={item.id}>
                  <td style={{ verticalAlign: "top" }}>
                    <div className="">
                      <div className="mb-1">
                        <b>{item.feature_name}</b>
                      </div>
                      {item.description}
                      <br />
                      <div className="mt-3">
                        <Link
                          to="/Go_seo/Schema_creation"
                          onClick={() => handleGetStartedClick(item.id)}
                        >
                          <Button size="sm" className="">
                            Get Started
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </td>

                  <td>
                    <img
                      src={item.image_url}
                      alt={item.feature_name}
                      className="schema-image"
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="2">No data available</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Structuredata;
