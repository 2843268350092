import React, { useState, useEffect, useContext } from "react";
import { GlobalContext } from "../Context/GlobalState";
import { BASE_URL_API_TRANXT_UAT_API } from "../Utils/Constant";
import { useHistory } from "react-router-dom";
import { FaEye, FaSpinner, FaUsers, FaVideo, FaYoutube } from "react-icons/fa";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { IoReloadOutline, IoSettings } from "react-icons/io5";
import Swal from "sweetalert2";
import swal from "sweetalert";
import { MdOpenInNew } from "react-icons/md";

const Youtube = () => {
  const [youtubeprofile, setyoutubeprofile] = useState([]);
  const {user, setTransactionLoaderState } = useContext(GlobalContext);
  const [isExpired, setIsExpired] = useState(false);
  const history = useHistory();
  const handleCardClick = (uploads_playlist_id, access_token) => {
    const storedData = sessionStorage.getItem("youtube_profile_data");

    if (storedData) {
      const youtubeProfileData = JSON.parse(storedData);
      sessionStorage.setItem("access_token", access_token);
      sessionStorage.setItem("upload_id", uploads_playlist_id);
      sessionStorage.setItem(
        "youtube_full_data",
        JSON.stringify(youtubeProfileData)
      );

      history.push("/Go_seo/Youtube_Videos");
    }
  };
  const handleConnectClick = () => {
    const userId = user?.user_id; 
    if (userId) {
      console.log("Connect button clicked with user_id:", userId);
      window.location.href = `https://goseo.in/youtube/authorize.php?userid=${userId}`;
    } else {
      console.error("User ID is not available.");
    }
  };
  

  const GetProfile = async () => {
    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          userid: "1",
        }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetAllYoutubeVideos`,
        requestOptions
      );

      const data = await res.json();
      console.log("Video fetch response:", data);
      if (data.status === "0") {
        sessionStorage.setItem(
          "youtube_profile_data",
          JSON.stringify(data.data)
        );
        setyoutubeprofile(data.data);
        if (data.data.some((profile) => profile.status === "expired")) {
          setIsExpired(true);
        }
      }
    } catch (error) {
      console.log("Error fetching videos:", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    GetProfile();
  }, []);
  const formatNumber = (number) => {
    if (number >= 1000 && number < 1000000) {
      return (number / 1000).toFixed(1) + "k";
    } else if (number >= 1000000) {
      return (number / 1000000).toFixed(1) + "M";
    }
    return number;
  };
  const refreshAccessToken = async () => {
    setTransactionLoaderState(true);
    try {
      const refreshToken = youtubeprofile.find(
        (profile) => profile.status === "expired"
      )?.refresh_token;

      if (!refreshToken) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Refresh token not found!",
          customClass: {
            popup: "swal-popup",
          },
          color: "#000",
        });
        return;
      }

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          userid: "1",
          refresh_token: refreshToken,
        }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/RefreshAccessToken`,
        requestOptions
      );
      const data = await res.json();

      console.log("Refresh token response:", data);

      if (data.status === "00") {
        swal("Success", data.msg, "success");
        await GetProfile();
      } else {
        swal("Error", data.msg, "error");
      }
    } catch (error) {
      console.log("Error refreshing access token:", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  return (
    <>
      <div style={{ height: "60vh" }}>
        <div
          className="d-flex align-items-center justify-content-between p-3"
          style={{ backgroundColor: "#d4edda" }}
        >
          <div className="d-flex align-items-center">
            <FaYoutube size={50} color="#FF0000" />
          </div>
          <Button
            size="sm"
            style={{
              padding: "10px 20px",
              backgroundColor: "#28a745",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            onClick={handleConnectClick}
          >
            Connect
          </Button>
        </div>
        <div
          className="d-flex align-items-center justify-content-center p-3"
          style={{ padding: "20px" }}
        >
          {youtubeprofile.map((profile, index) => {
            const isExpired = profile.status === "expired";

            return (
              <div
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "40px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "20px",
                    border: "1px solid #ddd",
                    borderRadius: "10px",
                    width: "300px",
                    boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                    opacity: isExpired ? 0.5 : 1,
                    pointerEvents: isExpired ? "none" : "auto",
                  }}
                >
                  <div
                    style={{
                      width: "150px",
                      height: "150px",
                      borderRadius: "50%",
                      overflow: "hidden",
                      marginBottom: "20px",
                    }}
                  >
                    <img
                      onClick={() =>
                        handleCardClick(
                          profile.uploads_playlist_id,
                          profile.access_token
                        )
                      }
                      src={profile.profile_url}
                      alt={profile.title}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                  <h4 style={{ margin: "0", textAlign: "center" }}>
                    {profile.title}
                  </h4>
                  <p
                    style={{
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      WebkitLineClamp: 3,
                      textAlign: "center",
                      color: "#888",
                      maxHeight: "4.5em",
                      lineHeight: "1.5em",
                    }}
                  >
                    {profile.description}
                  </p>

                  <div
                    className="col-6"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      padding: "10px 0",
                      borderTop: "1px solid #ddd",
                      borderBottom: "1px solid #ddd",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <FaEye style={{ color: "#888", fontSize: "20px" }} />
                      <span>{formatNumber(profile.view_count)}</span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <FaUsers style={{ color: "#888", fontSize: "20px" }} />
                      <span>{formatNumber(profile.subscriber_count)}</span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <FaVideo style={{ color: "#888", fontSize: "20px" }} />
                      <span>{formatNumber(profile.video_count)}</span>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: "10px",
                    }}
                  >
                    <a
                      href={!isExpired ? profile.url : undefined}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        textDecoration: "none",
                        color: isExpired ? "#888" : "#007BFF",
                        fontWeight: "bold",
                        pointerEvents: isExpired ? "none" : "auto",
                      }}
                    >
                      {profile.custom_url}
                    </a>
                    &nbsp;&nbsp;
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip id={`tooltip-top`}>Open</Tooltip>}
                    >
                      <a
                        href={!isExpired ? profile.url : undefined}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <MdOpenInNew
                          style={{ color: "green", fontSize: "20px" }}
                        />{" "}
                      </a>
                    </OverlayTrigger>
                    &nbsp;&nbsp;
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip id={`tooltip-top`}>Manage SEO</Tooltip>}
                    >
                      <IoSettings
                        onClick={() =>
                          handleCardClick(
                            profile.uploads_playlist_id,
                            profile.access_token
                          )
                        }
                        style={{ color: "#3C3D37", fontSize: "20px" }}
                      />
                    </OverlayTrigger>
                  </div>
                </div>
                {isExpired && (
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id={`tooltip-top`}>Reconnect</Tooltip>}
                  >
                    <IoReloadOutline
                      className="ml-5"
                      style={{
                        fontSize: "20px",
                        color: "#000",
                        cursor: "pointer",
                      }}
                      onClick={refreshAccessToken}
                    />
                  </OverlayTrigger>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Youtube;
