import React, { Component, useContext, useEffect, useState } from "react";
import { GlobalContext } from "../Context/GlobalState";
import { BASE_URL_API_TRANXT_UAT_API } from "../Utils/Constant";
import { Projectform } from "./Forms";
import { Projecttable } from "./Table";
import { Nav, Tab } from "react-bootstrap";

const Project = () => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [project, setproject] = useState([]);
  const Geprojectdata = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          userid: user.user_id,
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetRecentProjects`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setproject(
          data.data.map((item, index) => ({
            id: `${index + 1}`,
            site_url: item.site_url,
            comp_url1: item.comp_url1,
            comp_url2: item.comp_url2,
            comp_url3: item.comp_url3,
            comp_url4: item.comp_url4,
            comp_url5: item.comp_url5,
          }))
        );
      } else {
        setproject([]);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    Geprojectdata("");
  }, []);
  return (
    <div>
      <Tab.Container defaultActiveKey="Projectform">
        <div className="tab-buttons mt-3">
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link
                className="p-3"
                eventKey="Projectform"
                style={{ fontSize: "1rem", fontWeight: "500" }}
              >
                Add Website
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                className="p-3"
                eventKey="Myproject"
                style={{ fontSize: "1rem", fontWeight: "500" }}
              >
                My Website
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
        <Tab.Content>
          <Tab.Pane eventKey="Projectform">
            <Projectform />
          </Tab.Pane>
          <Tab.Pane eventKey="Myproject">
            <Projecttable project={project} Geprojectdata={Geprojectdata} />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};

export default Project;
