import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { GlobalContext } from "../Context/GlobalState";
import { BASE_URL_API_TRANXT_UAT_API } from "../Utils/Constant";
import { FaEye } from "react-icons/fa";
import { FcComments } from "react-icons/fc";
import { FcLike } from "react-icons/fc";
export const Reportingtable = ({ data }) => {
  return (
    <>
      <div className="row p-0">
        <div className="col-12">
          <div
            className="table-responsive"
            style={{ borderRadius: ".4em", border: "1px solid lightgrey" }}
          >
            <table className="table table-bordered m-0 p-0">
              <thead>
                <tr style={{ borderBottom: "1px solid lightgrey" }}>
                  <th
                    className="text-center"
                    style={{
                      color: "#0074D9",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    ID
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#0074D9",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    URL
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#0074D9",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Platform
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#0074D9",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Date
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.length > 0 ? (
                  data.map((item, index) => (
                    <tr
                      style={{ borderBottom: "1px solid lightgrey" }}
                      key={index}
                    >
                      <td
                        className="text-center align-middle"
                        style={{ border: "none" }}
                      >
                        {index + 1}
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{ border: "none" }}
                      >
                        <a
                          href={item.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {item.url}
                        </a>
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{ border: "none" }}
                      >
                        {item.social}
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{ border: "none" }}
                      >
                        {item.date}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="10" className="text-center">
                      No data found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export const LinkedIntable = ({ data }) => {
  return (
    <>
      <div className="row p-0">
        <div className="col-12">
          <div
            className="table-responsive"
            style={{ borderRadius: ".4em", border: "1px solid lightgrey" }}
          >
            <table className="table table-bordered m-0 p-0">
              <thead>
                <tr style={{ borderBottom: "1px solid lightgrey" }}>
                  <th
                    className="text-center"
                    style={{
                      color: "#0074D9",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    ID
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#0074D9",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    URL
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#0074D9",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Platform
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#0074D9",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Date
                  </th>
                </tr>
              </thead>
              {/* <tbody>
                {data.length > 0 ? (
                  data.map((item, index) => (
                    <tr
                      style={{ borderBottom: "1px solid lightgrey" }}
                      key={index}
                    >
                      <td
                        className="text-center align-middle"
                        style={{ border: "none" }}
                      >
                        {index + 1}
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{ border: "none" }}
                      >
                        <a
                          href=  {item.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                           {item.url}
                        </a>
                      
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{ border: "none" }}
                      >
                        {item.social}
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{ border: "none" }}
                      >
                        {item.date}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="10" className="text-center">
                      No data found
                    </td>
                  </tr>
                )}
              </tbody> */}
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
export const Instagramtable = ({ data }) => {
  return (
    <>
      <div className="row p-0">
        <div className="col-12">
          <div
            className="table-responsive"
            style={{ borderRadius: ".4em", border: "1px solid lightgrey" }}
          >
            <table className="table table-bordered m-0 p-0">
              <thead>
                <tr style={{ borderBottom: "1px solid lightgrey" }}>
                  <th
                    className="text-center"
                    style={{
                      color: "#0074D9",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    ID
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#0074D9",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    URL
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#0074D9",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Platform
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#0074D9",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Date
                  </th>
                </tr>
              </thead>
              {/* <tbody>
                {data.length > 0 ? (
                  data.map((item, index) => (
                    <tr
                      style={{ borderBottom: "1px solid lightgrey" }}
                      key={index}
                    >
                      <td
                        className="text-center align-middle"
                        style={{ border: "none" }}
                      >
                        {index + 1}
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{ border: "none" }}
                      >
                        <a
                          href=  {item.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                           {item.url}
                        </a>
                      
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{ border: "none" }}
                      >
                        {item.social}
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{ border: "none" }}
                      >
                        {item.date}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="10" className="text-center">
                      No data found
                    </td>
                  </tr>
                )}
              </tbody> */}
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
export const Facebooktable = ({ data }) => {
  return (
    <>
      <div className="row p-0">
        <div className="col-12">
          <div
            className="table-responsive"
            style={{ borderRadius: ".4em", border: "1px solid lightgrey" }}
          >
            <table className="table table-bordered m-0 p-0">
              <thead>
                <tr style={{ borderBottom: "1px solid lightgrey" }}>
                  <th
                    className="text-center"
                    style={{
                      color: "#0074D9",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    ID
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#0074D9",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    URL
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#0074D9",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Platform
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#0074D9",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Date
                  </th>
                </tr>
              </thead>
              {/* <tbody>
                {data.length > 0 ? (
                  data.map((item, index) => (
                    <tr
                      style={{ borderBottom: "1px solid lightgrey" }}
                      key={index}
                    >
                      <td
                        className="text-center align-middle"
                        style={{ border: "none" }}
                      >
                        {index + 1}
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{ border: "none" }}
                      >
                        <a
                          href=  {item.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                           {item.url}
                        </a>
                      
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{ border: "none" }}
                      >
                        {item.social}
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{ border: "none" }}
                      >
                        {item.date}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="10" className="text-center">
                      No data found
                    </td>
                  </tr>
                )}
              </tbody> */}
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
export const Youtubetable = () => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [videos, setVideos] = useState([]);
  const [videoDetailsMap, setVideoDetailsMap] = useState({});
  const [selectedVideoDetails, setSelectedVideoDetails] = useState(null);
  const productname = sessionStorage.getItem("selectedURLProjectName");
  const [channelDetails, setChannelDetails] = useState(null);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const handleFromDateChange = (e) => setFromDate(e.target.value);
  const handleToDateChange = (e) => setToDate(e.target.value);
  const getCurrentFormattedDate = () => {
    const currentDate = new Date();
    return `${currentDate.getFullYear()}-${String(
      currentDate.getMonth() + 1
    ).padStart(2, "0")}-${String(currentDate.getDate()).padStart(2, "0")}`;
  };
  const fetchVideos = async () => {
    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          channel_title: productname,
          from_date: fromDate || getCurrentFormattedDate(),
          to_date: toDate || getCurrentFormattedDate(),
        }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetYoutubeDataDashbord`,
        requestOptions
      );

      const data = await res.json();
      console.log("Video fetch response:", data);
      if (data.status === "0" && data.data.length > 0) {
        const channelData = data.data[0];
        setChannelDetails(channelData);
        setVideos(channelData.response);
        console.log("Channel and videos set:", channelData);
      } else {
        setChannelDetails(null);
        setVideos([]);
        console.error("Error fetching videos:", data.msg);
      }
    } catch (error) {
      console.error("Error fetching videos:", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };
  const handleGetDetails = async (videoId) => {
    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          key: "AIzaSyDSMivxiXj653PQxHdzoJQSEYfsXobltjs",
          video_id: videoId,
        }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetYoutubeVideoDetails`,
        requestOptions
      );

      const data = await res.json();
      console.log("Video details fetched:", data);
      if (data.status === "00") {
        const videoDetails = data.data.items[0];
        setVideoDetailsMap((prev) => ({
          ...prev,
          [videoId]: {
            viewCount: videoDetails.statistics.viewCount,
            likeCount: videoDetails.statistics.likeCount,
            commentCount: videoDetails.statistics.commentCount,
          },
        }));
      } else {
        console.error("Failed to fetch video details:", data.msg);
      }
    } catch (error) {
      console.error("Error fetching video details:", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  const handleSearch = () => {
    fetchVideos();
  };
  function decodeUnicodeString(input) {
    return input.replace(/\\u[\dA-F]{4}/gi, (match) =>
      String.fromCharCode(parseInt(match.replace("\\u", ""), 16))
    );
  }

  return (
    <>
      <div
        style={{
          height: "75vh",
          overflowY: "auto",
          overflowX: "hidden",
          msOverflowStyle: "none",
          scrollbarWidth: "none",
        }}
        className="hide-scrollbar"
      >
        <Row className=" p-0">
          <Col md={3}>
            <Form.Group controlId="fromDate">
              <Form.Label>From Date</Form.Label>
              <Form.Control
                type="date"
                value={fromDate}
                onChange={handleFromDateChange}
              />
            </Form.Group>
          </Col>

          <Col md={3}>
            <Form.Group controlId="toDate">
              <Form.Label>To Date</Form.Label>
              <Form.Control
                type="date"
                value={toDate}
                onChange={handleToDateChange}
              />
            </Form.Group>
          </Col>

          <Col md={3} className="d-flex align-items-center mt-2">
            <div>
              {" "}
              <Button
                style={{
                  backgroundColor: "#2ca01c",
                  border: "none",
                }}
                onClick={handleSearch}
              >
                Search
              </Button>
            </div>
          </Col>
        </Row>
        <hr className="m-1" />
        <div>
          {channelDetails && (
            <div
              style={{
                padding: "10px",
                backgroundColor: "#f9f9f9",
                borderRadius: "8px",
              }}
            >
              <Row>
                <Col md={2}>
                  <img
                    src={channelDetails.thumbnail_high_url}
                    alt={channelDetails.channel_tittle}
                    style={{ width: "20vh", borderRadius: "8px" }}
                  />
                </Col>
                <Col md={10}>
                  <Row className="p-0 m-0">
                    <Col className="p-0 m-0">
                      <h4 style={{ color: "#333" }}>
                        {channelDetails.channel_tittle}
                      </h4>
                    </Col>
                    <Col className="p-0 m-0 d-flex justify-content-end">
                      <p style={{ color: "#666" }}>
                        Last Updated On: {channelDetails.updated_at}
                      </p>
                    </Col>
                  </Row>
                  <p style={{ color: "#666" }}>{channelDetails.channel_desc}</p>
                  <div>
                    <strong>Subscribers:</strong>{" "}
                    {channelDetails.subscriber_count}
                  </div>
                  <div>
                    <strong>Total Views:</strong> {channelDetails.view_count}
                  </div>
                  <div>
                    <strong>Total Videos:</strong> {channelDetails.video_count}
                  </div>
                  <div>
                    <strong>Custom URL:</strong>{" "}
                    <a
                      href={`https://youtube.com/${channelDetails.custom_url}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {channelDetails.custom_url}
                    </a>
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </div>
        <div>
          {videos.length === 0 ? (
            <div
              style={{ padding: "20px", textAlign: "center", color: "#666" }}
            >
              <h5>No data found</h5>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "",
              }}
            >
              {videos.map((video) => (
                <div
                  key={video.id}
                  className="video-card"
                  style={{
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                    padding: "10px",
                    margin: "10px",
                    width: "calc(25% - 20px)",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    boxSizing: "border-box",
                    cursor: "pointer",
                  }}
                >
                  <h6 style={{ color: "#333", fontSize: "16px" }}>
                    {decodeUnicodeString(video.title)}
                  </h6>
                  <p style={{ color: "#666", fontSize: "12px" }}>
                    {new Date(video.published_at).toLocaleString()}
                  </p>
                  <img
                    src={video.thumbnail_url}
                    alt={video.title}
                    style={{
                      width: "100%",
                      height: "auto",
                      borderRadius: "4px",
                    }}
                  />

                  {videoDetailsMap[video.video_id] ? (
                    <Row>
                      <Col>
                        <FaEye /> {videoDetailsMap[video.video_id].viewCount}
                      </Col>
                      <Col style={{ marginLeft: "15px" }}>
                        <FcLike /> {videoDetailsMap[video.video_id].likeCount}
                      </Col>
                      <Col style={{ marginLeft: "15px" }}>
                        <FcComments />{" "}
                        {videoDetailsMap[video.video_id].commentCount}
                      </Col>
                    </Row>
                  ) : (
                    <button
                      className="get-details-btn"
                      onClick={() => handleGetDetails(video.video_id)}
                    >
                      Get Details
                    </button>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
