import React, { useContext } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import App from "./app/App";
import "./i18n";
import * as serviceWorker from "./serviceWorker";
import "./index.css";
import "./custom.scss";
import { GlobalProvider, GlobalContext } from "./app/Context/GlobalState"; 
import Documentation from "./app/documentaion/Documentation";
import TransactionLoader from "./app/Utils/TransactionLoader";
import ForgetPassword222 from "./app/user-pages/ForgotPassword";
import Newregister from "./app/user-pages/Newregister";

const Root = () => {
  const context = useContext(GlobalContext);  

  return (
    <TransactionLoader text="Please Wait..." isActive={context.transactionLoader}>
      <Switch>
        <Route path="/docs" component={Documentation} />
        <Route path="/Register" component={Newregister} />
        <Route path="/forget" component={ForgetPassword222} />
        <Route path="/" component={App} />
      </Switch>
    </TransactionLoader>
  );
};

ReactDOM.render(
  <BrowserRouter>
    <GlobalProvider>
      <Root />
    </GlobalProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

serviceWorker.unregister();
