import React, { useEffect, useState } from "react";
import { BASE_URL_API_TRANXT_UAT_API } from "../Utils/Constant";
import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
const Playlistvideos = () => {
  const [playlistId, setPlaylistId] = useState(null);
  const [videos, setVideos] = useState([]);
  const [playlistTitle, setPlaylistTitle] = useState("");
  const [transactionLoaderState, setTransactionLoaderState] = useState(false);
  const [nextPageToken, setNextPageToken] = useState("");
  const history = useHistory();
  useEffect(() => {
    const storedPlaylistId = sessionStorage.getItem("playlistId");
    const storedPlaylistTitle = sessionStorage.getItem("playlistTitle");
    if (storedPlaylistId) {
      setPlaylistId(storedPlaylistId);
      fetchVideos(storedPlaylistId);
      setPlaylistTitle(storedPlaylistTitle);
    }
  }, []);
  const handleCardClick = (videoId) => {
    sessionStorage.setItem("selectedVideoId", videoId);
    history.push("/Go_seo/Playlist_Seo");
  };
  const fetchVideos = async (playlistId, pagetoken = "") => {
    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          playlist: playlistId,
          pagetoken: pagetoken,
        }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetYoutubePlaylistItems`,
        requestOptions
      );
      const data = await res.json();
      console.log("Video fetch response:", data);
      if (data.status === "00" && data.data && data.data.items) {
        setVideos((prevVideos) => [...prevVideos, ...data.data.items]);
        setNextPageToken(data.data.nextPageToken);
        // if (data.data.items.length > 0) {
        //   setChannelTitle(data.data.items[0].snippet.channelTitle);
        // }
        console.log("Videos set:", data.data.items);
      } else {
        console.log("No videos found or error status:", data.msg);
      }
    } catch (error) {
      console.log("Error fetching videos:", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  const handleNextPage = () => {
    if (nextPageToken) {
      fetchVideos(playlistId, nextPageToken);
    }
  };

  return (
    <div className="m-3">
      <Row className="pb-0">
        <Col>
          <h4>{playlistTitle}</h4>
        </Col>
        <Col className="d-flex justify-content-end">
          {" "}
          <Link to="/Go_seo/Youtube_Videos">
            <Button variant="info" size="sm">
              Back
            </Button>
          </Link>
        </Col>
      </Row>
      <div
        className="video-list p-2 pt-0"
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "flex-start",
        }}
      >
        {transactionLoaderState ? (
          <p>Loading videos...</p>
        ) : videos.length > 0 ? (
          videos.map((video) => (
            <div
              key={video.id}
              className="video-card "
              style={{
                border: "1px solid #ddd",
                borderRadius: "8px",
                padding: "10px",
                margin: "10px",
                width: "calc(25% - 20px)",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                boxSizing: "border-box",
                cursor: "pointer",
              }}
              onClick={() => handleCardClick(video.snippet.resourceId.videoId)}
            >
              <img
                src={
                  video.snippet.thumbnails?.medium?.url || "fallback-image-url"
                } 
                alt={video.snippet.title}
                style={{ width: "100%" }}
              />

              <h6
                className="mt-2"
                style={{
                  fontWeight: "bold",
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  margin: "0",
                }}
              >
                {video.snippet.title}
              </h6>
              <p>
                Published on:{" "}
                {new Date(video.snippet.publishedAt).toLocaleDateString()}
              </p>
            </div>
          ))
        ) : (
          <p>No videos available.</p>
        )}
      </div>

      <div className="m-5" style={{ textAlign: "center" }}>
        <Button
          size="sm"
          variant="success"
          onClick={handleNextPage}
          disabled={!nextPageToken}
          style={{ cursor: "pointer" }}
        >
          View More
        </Button>
      </div>
    </div>
  );
};

export default Playlistvideos;
