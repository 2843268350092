import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { Button, Card, Form, Modal, Row, Spinner } from "react-bootstrap";
import ReactJson from "react-json-view";
import { GlobalContext } from "../Context/GlobalState";
import { BASE_URL_API_TRANXT_UAT_API } from "../Utils/Constant";
import swal from "sweetalert";
import { FaPlus, FaTrash } from "react-icons/fa";

export const AddTaskModal = ({ show, handleClose, handleSubmit }) => {
  const [formData, setFormData] = useState({
    url: "",
    social: "",
    date: "",
  });
  const [selectedUser, setSelectedUser] = useState(null);
  const { setTransactionLoaderState } = useContext(GlobalContext);
  const [users, setUsers] = useState([]);
  const socialOptions = [
    { value: "instagram", label: "Instagram" },
    { value: "facebook", label: "Facebook" },
    { value: "linkedin", label: "LinkedIn" },
    { value: "youtube", label: "YouTube" },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleUserChange = (selectedOption) => {
    setSelectedUser(selectedOption);
  };

  const handleSelectChange = (selectedOption) => {
    setFormData((prevData) => ({
      ...prevData,
      social: selectedOption.value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const updatedFormData = {
      ...formData,
      userid: selectedUser ? selectedUser.value : "", 
    };
    handleSubmit(updatedFormData);
    handleClose();
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      backgroundColor: "white",
      color: "black",
      borderColor: "#ced4da",
      boxShadow: "none",
      "&:hover": {
        borderColor: "#86b7fe",
      },
    }),
    singleValue: (base) => ({
      ...base,
      color: "black",
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: "white",
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isFocused ? "#e9ecef" : "white",
      color: "black",
      "&:active": {
        backgroundColor: "#dee2e6",
      },
    }),
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add Task</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmit}>
          <Form.Group className="col" controlId="formSocial">
            <Form.Label>Social</Form.Label>
            <Select
              options={socialOptions}
              onChange={handleSelectChange}
              placeholder="Select Social"
              styles={customStyles}
            />
          </Form.Group>
          <Form.Group className="col" controlId="formUrl">
            <Form.Label>URL</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter URL"
              name="url"
              value={formData.url}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group className="col" controlId="formDate">
            <Form.Label>Date</Form.Label>
            <Form.Control
              type="date"
              name="date"
              value={formData.date}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Button className="mx-3" variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};