import React, { useContext, useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Card,
  Form,
  Modal,
  OverlayTrigger,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import { CiEdit, CiReceipt } from "react-icons/ci";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { GlobalContext } from "../Context/GlobalState";
import { BASE_URL_API_TRANXT_UAT_API } from "../Utils/Constant";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import { MdEdit, MdVerified } from "react-icons/md";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import { FaArrowRight, FaCrown, FaEdit, FaEye } from "react-icons/fa";
import { EditBacklinkModal, EditSiteModal } from "./Modal";
import Sitemapsetting from "./Sitemapsetting";
import { Basicsetting, BlogSeoSettingForm, Keyword, Ogsetting } from "./Forms";
export const Sitemaptable = ({ location }) => {
  const whiteStyle = { background: "#FFF" };
  const greyStyle = { background: "#e7eaf6" };
  const rowStyle = (row, index) => {
    return index % 2 === 0 ? greyStyle : whiteStyle;
  };

  const { setTransactionLoaderState,siteid,siteurl,Productname } = useContext(GlobalContext);
  const [stimapdata, setstimapdata] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
 
  const Getsitemapdata = async () => {
    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ company_id: siteid, url: location }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetAllsitemaps`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        setstimapdata(
          data.data.map((item, index) => ({
            id: `${index + 1}`,
            url: item.page_url,
            priority: item.priority,
            frequency: item.frequency,
            lastModified: item.last_modified,
            status: "Open",
            action: "",
          }))
        );
      } else if (data.status === "1") {
        swal({
          title: "Data Fetch Confirmation",
          text: "Do you want to fetch Sitemap?",
          icon: "info",
          buttons: ["Cancel", "Fetch"],
          dangerMode: false,
        }).then((fetchConfirmed) => {
          if (fetchConfirmed) {
            Fetchsitemapdata(siteurl, siteid);
          } else {
            swal("Info", "Data fetch cancelled.", "info");
          }
        });
      } else {
        setstimapdata([]);
        swal("Error", data.msg, "error");
      }
    } catch (error) {
      console.log("error", error);
      swal("Error", "Failed to fetch data. Please try again later.", "error");
    } finally {
      setTransactionLoaderState(false);
    }
  };

  const Fetchsitemapdata = async () => {
    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ company_id: siteid, url: location }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/FetchAllExtractSitemaps`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        setstimapdata(data.data || []);
        Getsitemapdata(); // Ensure data is an array
      } else {
        setstimapdata([]);
        swal("Error", data.msg, "error");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  const columns = [
    {
      dataField: "id",
      text: "Order #",
      sort: true,
    },
    {
      dataField: "url",
      text: "URL",
      sort: true,
    },
    {
      dataField: "priority",
      text: "Priority",
      sort: true,
    },
    {
      dataField: "frequency",
      text: "Frequency",
      sort: true,
    },
    {
      dataField: "lastModified",
      text: "Last Modified",
      sort: true,
    },
    {
      dataField: "action",
      text: "Action",
      sort: false,
      formatter: () => {
        return (
          <div>
            <button
              style={{
                background: "#FFF",
                color: "#007cb9",
                border: "1px #007cb9 solid",
              }}
              className="btn"
            >
              <Link to="/Go_seo/Addsitemap">
                <MdEdit /> Edit
              </Link>
            </button>
          </div>
        );
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  useEffect(() => {
    if (location && siteid) {
      Getsitemapdata();
    }
  }, [location, siteid]);

  const filteredData = stimapdata.filter((item) => {
    const query = searchQuery.toLowerCase();
    return (
      (item.url && item.url.toLowerCase().includes(query)) ||
      (item.priority && item.priority.toLowerCase().includes(query)) ||
      (item.frequency && item.frequency.toLowerCase().includes(query)) ||
      (item.lastModified && item.lastModified.toLowerCase().includes(query))
    );
  });

  return (
    <div>
      <div className="row p-0">
        <div className="col-12">
          <ToolkitProvider
            keyField="id"
            bootstrap4
            data={filteredData} 
            columns={columns}
            search
          >
            {({ baseProps }) => (
              <div>
                <div className="row mt-2 mb-2">
                  <h6  style={{ color: "#441752" }} className="col-4 text">
                    Sitemap of <u>{location}</u>
                  </h6>
                  <div className="col d-flex justify-content-end">
                    <Form.Control
                      type="text"
                      placeholder="Search..."
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      style={{ maxWidth: "300px", marginRight: "10px" }}
                    />
                    <Link to="/Go_seo/Addsitemap">
                      <Button className="ml-2" size="sm">
                        Add Sitemap
                      </Button>
                    </Link>
                    <Link>
                      {" "}
                      <Button
                        className="ml-2"
                        size="sm"
                        onClick={Fetchsitemapdata}
                      >
                        Fetch Sitemap
                      </Button>{" "}
                    </Link>
                  </div>
                </div>

                <BootstrapTable
                  rowStyle={rowStyle}
                  defaultSorted={defaultSorted}
                  pagination={paginationFactory()}
                  {...baseProps}
                  wrapperClasses="table-responsive"
                />
                {filteredData.length === 0 && (
                  <div className="text-center mt-4">No data found</div>
                )}
              </div>
            )}
          </ToolkitProvider>
        </div>
      </div>
    </div>
  );
};
export const Authorsitemaptable = ({ location }) => {
  const whiteStyle = { background: "#FFF" };
  const greyStyle = { background: "#e7eaf6" };
  const rowStyle = (row, index) => {
    return index % 2 === 0 ? greyStyle : whiteStyle;
  };

  const { setTransactionLoaderState,siteurl,siteid } = useContext(GlobalContext);
  const [stimapdata, setstimapdata] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const Getsitemapdata = async () => {
    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ company_id: siteid, url: location }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetAllsitemaps`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        setstimapdata(
          data.data.map((item, index) => ({
            id: `${index + 1}`,
            url: item.page_url,
            priority: item.priority,
            frequency: item.frequency,
            lastModified: item.last_modified,
            status: "Open",
            action: "",
          }))
        );
      } else if (data.status === "1") {
        swal({
          title: "Data Fetch Confirmation",
          text: "Do you want to fetch Sitemap?",
          icon: "info",
          buttons: ["Cancel", "Fetch"],
          dangerMode: false,
        }).then((fetchConfirmed) => {
          if (fetchConfirmed) {
            Fetchsitemapdata(siteid);
          } else {
            swal("Info", "Data fetch cancelled.", "info");
          }
        });
      } else {
        setstimapdata([]);
        swal("Error", data.msg, "error");
      }
    } catch (error) {
      console.log("error", error);
      swal("Error", "Failed to fetch data. Please try again later.", "error");
    } finally {
      setTransactionLoaderState(false);
    }
  };

  const Fetchsitemapdata = async () => {
    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ company_id: siteid, url: location }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/FetchAllExtractSitemaps`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        // setstimapdata(data.data);
        Getsitemapdata();
      } else {
        setstimapdata([]);
        swal("Error", data.msg, "error");
      }
    } catch (error) {
      console.log("error", error);
      swal("Error", "Failed to fetch data. Please try again later.", "error");
    } finally {
      setTransactionLoaderState(false);
    }
  };

  const columns = [
    {
      dataField: "id",
      text: "Order #",
      sort: true,
    },
    {
      dataField: "url",
      text: "URL",
      sort: true,
    },
    {
      dataField: "priority",
      text: "Priority",
      sort: true,
    },
    {
      dataField: "frequency",
      text: "Frequency",
      sort: true,
    },
    {
      dataField: "lastModified",
      text: "Last Modified",
      sort: true,
    },
    {
      dataField: "action",
      text: "Action",
      sort: false,
      formatter: () => {
        return (
          <div>
            <button
              style={{
                background: "#FFF",
                color: "#007cb9",
                border: "1px #007cb9 solid",
              }}
              className="btn"
            >
              <Link to="/Go_seo/Addsitemap">
                <MdEdit /> Edit
              </Link>
            </button>
          </div>
        );
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  useEffect(() => {
    if (location && siteid) {
      Getsitemapdata();
    }
  }, [location, siteid]);

  // Filter data based on search query
  const filteredData = stimapdata.filter((item) => {
    const query = searchQuery.toLowerCase();
    return (
      item.url.toLowerCase().includes(query) ||
      item.priority.toLowerCase().includes(query) ||
      item.frequency.toLowerCase().includes(query) ||
      item.lastModified.toLowerCase().includes(query)
    );
  });

  return (
    <div>
      <div className="row p-0">
        <div className="col-12">
          <ToolkitProvider
            keyField="id"
            bootstrap4
            data={filteredData} // Use filtered data here
            columns={columns}
            search
          >
            {({ baseProps }) => (
              <div>
                <div className="row mt-2 mb-2">
                  <h6 style={{ color: "#441752" }} className="col-4 text">
                    Sitemap of <u>{location}</u>
                  </h6>
                  <div className="col d-flex justify-content-end">
                    <Form.Control
                      type="text"
                      placeholder="Search..."
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      style={{ maxWidth: "300px", marginRight: "10px" }}
                    />
                    <Link to="/Go_seo/Add_sitemap_author">
                      <Button className="ml-2" size="sm">
                        Add Sitemap
                      </Button>
                    </Link>
                    <Link>
                      {" "}
                      <Button
                        className="ml-2"
                        size="sm"
                        onClick={Fetchsitemapdata}
                      >
                        Fetch Sitemap
                      </Button>{" "}
                    </Link>
                  </div>
                </div>

                <BootstrapTable
                  rowStyle={rowStyle}
                  defaultSorted={defaultSorted}
                  pagination={paginationFactory()}
                  {...baseProps}
                  wrapperClasses="table-responsive"
                />
                {filteredData.length === 0 && (
                  <div className="text-center mt-4">No data found</div>
                )}
              </div>
            )}
          </ToolkitProvider>
        </div>
      </div>
    </div>
  );
};
export const Blogsitemaptable = ({ location }) => {
  const whiteStyle = { background: "#FFF" };
  const greyStyle = { background: "#e7eaf6" };
  const rowStyle = (row, index) => {
    return index % 2 === 0 ? greyStyle : whiteStyle;
  };

  const { setTransactionLoaderState,siteid,siteurl } = useContext(GlobalContext);
  const [stimapdata, setstimapdata] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
 
  const Getsitemapdata = async () => {
    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ company_id: siteid, url: location }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetAllsitemaps`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        setstimapdata(
          data.data.map((item, index) => ({
            id: `${index + 1}`,
            url: item.page_url,
            priority: item.priority,
            frequency: item.frequency,
            lastModified: item.last_modified,
            status: "Open",
            action: "",
          }))
        );
      } else if (data.status === "1") {
        swal({
          title: "Data Fetch Confirmation",
          text: "Do you want to fetch Sitemap?",
          icon: "info",
          buttons: ["Cancel", "Fetch"],
          dangerMode: false,
        }).then((fetchConfirmed) => {
          if (fetchConfirmed) {
            Fetchsitemapdata(siteurl, siteid);
          } else {
            swal("Info", "Data fetch cancelled.", "info");
          }
        });
      } else {
        setstimapdata([]);
        swal("Error", data.msg, "error");
      }
    } catch (error) {
      console.log("error", error);
      swal("Error", "Failed to fetch data. Please try again later.", "error");
    } finally {
      setTransactionLoaderState(false);
    }
  };

  const Fetchsitemapdata = async () => {
    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ company_id: siteid, url: location }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/FetchAllExtractSitemaps`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        // setstimapdata(data.data);
        Getsitemapdata();
      } else {
        setstimapdata([]);
        swal("Error", data.msg, "error");
      }
    } catch (error) {
      console.log("error", error);
      swal("Error", "Failed to fetch data. Please try again later.", "error");
    } finally {
      setTransactionLoaderState(false);
    }
  };

  const columns = [
    {
      dataField: "id",
      text: "Order #",
      sort: true,
    },
    {
      dataField: "url",
      text: "URL",
      sort: true,
    },
    {
      dataField: "priority",
      text: "Priority",
      sort: true,
    },
    {
      dataField: "frequency",
      text: "Frequency",
      sort: true,
    },
    {
      dataField: "lastModified",
      text: "Last Modified",
      sort: true,
    },
    {
      dataField: "action",
      text: "Action",
      sort: false,
      formatter: () => {
        return (
          <div>
            <button
              style={{
                background: "#FFF",
                color: "#007cb9",
                border: "1px #007cb9 solid",
              }}
              className="btn"
            >
              <Link to="/Go_seo/Addsitemap">
                <MdEdit /> Edit
              </Link>
            </button>
          </div>
        );
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  useEffect(() => {
    if (location && siteid) {
      Getsitemapdata();
    }
  }, [location, siteid]);
  // Filter data based on search query
  const filteredData = stimapdata.filter((item) => {
    const query = searchQuery.toLowerCase();
    return (
      item.url.toLowerCase().includes(query) ||
      item.priority.toLowerCase().includes(query) ||
      item.frequency.toLowerCase().includes(query) ||
      item.lastModified.toLowerCase().includes(query)
    );
  });

  return (
    <div>
      <div className="row p-0">
        <div className="col-12">
          <ToolkitProvider
            keyField="id"
            bootstrap4
            data={filteredData} // Use filtered data here
            columns={columns}
            search
          >
            {({ baseProps }) => (
              <div>
                <div className="row mt-2 mb-2">
                  <h6 style={{ color: "#441752" }} className="col-4 text">
                    Sitemap of <u>{location}</u>
                  </h6>
                  <div className="col d-flex justify-content-end">
                    <Form.Control
                      type="text"
                      placeholder="Search..."
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      style={{ maxWidth: "300px", marginRight: "10px" }}
                    />
                    <Link to="/Go_seo/Add_sitemap_blog">
                      <Button className="ml-2" size="sm">
                        Add Sitemap
                      </Button>
                    </Link>
                    <Link>
                      {" "}
                      <Button
                        className="ml-2"
                        size="sm"
                        onClick={Fetchsitemapdata}
                      >
                        Fetch Sitemap
                      </Button>{" "}
                    </Link>
                  </div>
                </div>

                <BootstrapTable
                  rowStyle={rowStyle}
                  defaultSorted={defaultSorted}
                  pagination={paginationFactory()}
                  {...baseProps}
                  wrapperClasses="table-responsive"
                />
                {filteredData.length === 0 && (
                  <div className="text-center mt-4">No data found</div>
                )}
              </div>
            )}
          </ToolkitProvider>
        </div>
      </div>
    </div>
  );
};
export const Pagetable = () => {
  const whiteStyle = { background: "#FFF" };
  const greyStyle = { background: "#e7eaf6" };
  const rowStyle = (row, index) => {
    if (index % 2 === 0) {
      return greyStyle;
    } else {
      return whiteStyle;
    }
  };
  const { user } = useContext(GlobalContext);
  const [urlOptions, setUrlOptions] = useState([]);
  const { setTransactionLoaderState } = useContext(GlobalContext);
  const [stimapdata, setstimapdata] = useState([]);
  const [searchURL, setSearchURL] = useState("");
  const [selectedId, setSelectedId] = useState(null);

  const Getsitemapdata = async (url) => {
    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ url }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetAllPages`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        setstimapdata(
          data.data.map((item) => ({
            id: item.id,
            base_url: item.base_url,
            page_url: item.page_url,
            url_pages: item.url_pages,
          }))
        );
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  const Getsitemapurl = async () => {
    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ userid: user.user_id }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GenerateUserAccessManagement`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        const options = data.data.map((item) => ({
          value: item.site_url,
          label: item.site_url,
          id: item.id,
        }));
        setUrlOptions(options);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  const handleSearch = () => {
    if (searchURL.trim() !== "") {
      Getsitemapdata(searchURL);
    } else {
      swal("Warning", "Please enter a valid URL.", "warning");
    }
  };

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
    },
    {
      dataField: "base_url",
      text: "Base URL",
      sort: true,
    },
    {
      dataField: "page_url",
      text: "Page URL",
      sort: true,
    },
    {
      dataField: "url_pages",
      text: "URL Pages",
      sort: true,
    },
    {
      dataField: "action",
      text: "Action",
      sort: false,
      formatter: () => {
        return (
          <div>
            <button
              style={{
                background: "#FFF",
                color: "#007cb9",
                border: "1px #007cb9 solid",
              }}
              className="btn"
            >
              <Link to="/Go_seo/Sitemapsetting">
                <i className="mdi mdi-eye-outline text-primary"></i> View
              </Link>
            </button>
          </div>
        );
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  useEffect(() => {
    Getsitemapurl("");
  }, []);

  return (
    <div>
      <div className="row">
        <div className="col-12">
          <ToolkitProvider
            keyField="id"
            bootstrap4
            data={stimapdata}
            columns={columns}
            search
          >
            {(props) => (
              <div>
                <div className="m-4 d-flex align-items-center justify-content-center">
                  <p className="mb-2 mr-2">Select URL:</p>
                  <Select
                    options={urlOptions}
                    className="basic-single"
                    classNamePrefix="select"
                    placeholder="Select URL"
                    value={urlOptions.find(
                      (option) => option.value === searchURL
                    )}
                    onChange={(selectedOption) => {
                      setSearchURL(selectedOption.value);
                      setSelectedId(selectedOption.id);
                    }}
                    styles={{
                      container: (provided) => ({ ...provided, width: "25%" }),
                    }}
                  />
                  <Button className="ml-2" size="sm" onClick={handleSearch}>
                    Search
                  </Button>
                </div>
                <BootstrapTable
                  rowStyle={rowStyle}
                  defaultSorted={defaultSorted}
                  pagination={paginationFactory()}
                  {...props.baseProps}
                  wrapperClasses="table-responsive"
                />
                {stimapdata.length === 0 ? (
                  <div className="text-center mt-4">No data found</div>
                ) : null}
              </div>
            )}
          </ToolkitProvider>
        </div>
      </div>
    </div>
  );
};
export const Onboardtable = ({ onboarddata }) => {
  const whiteStyle = { background: "#FFF" };
  const greyStyle = { background: "#e7eaf6" };
  const rowStyle = (row, index) => {
    if (index % 2 === 0) {
      return greyStyle;
    } else {
      return whiteStyle;
    }
  };

  const columns = [
    {
      dataField: "id",
      text: "Order #",
      sort: true,
    },
    {
      dataField: "companyName",
      text: "Company Name",
      sort: true,
    },
    {
      dataField: "companyDomain",
      text: "Company Domain",
      sort: true,
    },
    {
      dataField: "regNo",
      text: "Registration Number",
      sort: true,
    },
    {
      dataField: "gst",
      text: "GST",
      sort: true,
    },
    {
      dataField: "companyPan",
      text: "Company PAN",
      sort: true,
    },
    {
      dataField: "ownerName",
      text: "Owner Name",
      sort: true,
    },
    {
      dataField: "ownerMobile",
      text: "Owner Mobile",
      sort: true,
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  return (
    <div className="m-2">
  <h5>Onboarded Company</h5>
          <ToolkitProvider
            keyField="id"
            bootstrap4
            data={onboarddata}
            columns={columns}
            search
          >
            {(props) => (
              <div>
                <BootstrapTable
                  rowStyle={rowStyle}
                  defaultSorted={defaultSorted}
                  pagination={paginationFactory()}
                  {...props.baseProps}
                  wrapperClasses="table-responsive"
                />
                {onboarddata.length === 0 ? (
                  <div className="text-center mt-4">No data found</div>
                ) : null}
              </div>
            )}
          </ToolkitProvider>
     
    </div>
  );
};
export const Guestonboardtable = ({ onboarddata }) => {
  const whiteStyle = { background: "#FFF" };
  const greyStyle = { background: "#e7eaf6" };
  const rowStyle = (row, index) => {
    if (index % 2 === 0) {
      return greyStyle;
    } else {
      return whiteStyle;
    }
  };

  const columns = [
    {
      dataField: "id",
      text: "Order #",
      sort: true,
    },
    {
      dataField: "user_name",
      text: " Name",
      sort: true,
    },
    {
      dataField: "password",
      text: "Password",
      sort: true,
    },
    {
      dataField: "user_email",
      text: "Email",
      sort: true,
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  return (
    <div>
        <div className=" m-3 ">
        <h5>Onboarded Website</h5>
          <ToolkitProvider
            keyField="id"
            bootstrap4
            data={onboarddata}
            columns={columns}
            search
          >
            {(props) => (
              <div>
                <BootstrapTable
                  rowStyle={rowStyle}
                  defaultSorted={defaultSorted}
                  pagination={paginationFactory()}
                  {...props.baseProps}
                  wrapperClasses="table-responsive"
                />
                {onboarddata.length === 0 ? (
                  <div className="text-center mt-4">No data found</div>
                ) : null}
              </div>
            )}
          </ToolkitProvider>
        </div>
      </div>
  );
};
export const Utmtable = ({ onboarddata }) => {
  return (
    <div className="m-3 mt-4">
      <h2>UTM Details</h2>
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>ID</th>
              <th>Page URL</th>
              <th>UTM Source</th>
              <th>UTM Medium</th>
              <th>UTM Campaign</th>
              <th>UTM Term</th>
              <th>UTM Content</th>
              <th>Referrer</th>
              <th>User Agent</th>
              <th>UTM Timestamp</th>
              <th>Product Name</th>
            </tr>
          </thead>
          <tbody>
            {onboarddata.map((item) => (
              <tr key={item.id}>
                <td>{item.id}</td>
                <td>
                  <a
                    href={item.page_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item.page_url}
                  </a>
                </td>
                <td>{item.utm_source || "N/A"}</td>
                <td>{item.utm_medium || "N/A"}</td>
                <td>{item.utm_campaign || "N/A"}</td>
                <td>{item.utm_term || "N/A"}</td>
                <td>{item.utm_content || "N/A"}</td>
                <td>{item.referrer || "N/A"}</td>
                <td>{item.user_agent || "N/A"}</td>
                <td>{item.utm_timestamp || "N/A"}</td>
                <td>{item.product_name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export const Projecttable = ({ project }) => {
  const whiteStyle = { background: "#FFF" };
  const greyStyle = { background: "#e7eaf6" };

  const rowStyle = (row, index) => {
    return index % 2 === 0 ? greyStyle : whiteStyle;
  };

  const columns = [
    { dataField: "id", text: "ID", sort: true },
    { dataField: "site_url", text: "Site URL", sort: true },
    { dataField: "comp_url1", text: "Competitor URL 1", sort: true },
    { dataField: "comp_url2", text: "Competitor URL 2", sort: true },
    { dataField: "comp_url3", text: "Competitor URL 3", sort: true },
    { dataField: "comp_url4", text: "Competitor URL 4", sort: true },
    { dataField: "comp_url5", text: "Competitor URL 5", sort: true },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  return (
    <div>
      <div className="p-2 ">
        <ToolkitProvider
          keyField="id"
          bootstrap4
          data={project}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <BootstrapTable
                rowStyle={rowStyle}
                defaultSorted={defaultSorted}
                pagination={paginationFactory()}
                {...props.baseProps}
                wrapperClasses="table-responsive"
              />
              {project.length === 0 ? (
                <div className="text-center " style={{ width: "100%" }}>
                  <Spinner animation="border" />
                  <p>Loading...</p>
                </div>
              ) : null}
            </div>
          )}
        </ToolkitProvider>
      </div>
    </div>
  );
};
export const Campaigntable = ({ campaign }) => {
  const whiteStyle = { background: "#FFF" };
  const greyStyle = { background: "#e7eaf6" };
  const history = useHistory();
  const rowStyle = (row, index) => {
    return index % 2 === 0 ? greyStyle : whiteStyle;
  };

  const columns = [
    { dataField: "id", text: "ID", sort: true },
    { dataField: "campaign_id", text: "Campaign ID", sort: true },
    { dataField: "utm_source", text: "UTM Source", sort: true },
    {
      dataField: "utm_medium",
      text: "UTM Medium",
      formatter: (cell) => {
        const lines = cell ? cell?.match(/.{1,5}/g) : [];
        return (
          <div>
            {lines?.map((line, index) => (
              <span key={index}>
                {line}
                <br />
              </span>
            ))}
          </div>
        );
      },
      sort: true,
    },
    {
      dataField: "utm_campaign",
      text: "UTM Campaign",
      formatter: (cell) => {
        const lines = cell ? cell?.match(/.{1,10}/g) : [];
        return (
          <div>
            {lines?.map((line, index) => (
              <span key={index}>
                {line}
                <br />
              </span>
            ))}
          </div>
        );
      },
      sort: true,
    },
    {
      dataField: "utm_term",
      text: "UTM Term",
      formatter: (cell) => {
        const lines = cell ? cell?.match(/.{1,10}/g) : [];
        return (
          <div>
            {lines?.map((line, index) => (
              <span key={index}>
                {line}
                <br />
              </span>
            ))}
          </div>
        );
      },
      sort: true,
    },
    {
      dataField: "utm_content",
      text: "UTM Content",
      formatter: (cell) => {
        const lines = cell ? cell?.match(/.{1,10}/g) : [];
        return (
          <div>
            {lines?.map((line, index) => (
              <span key={index}>
                {line}
                <br />
              </span>
            ))}
          </div>
        );
      },
      sort: true,
    },
    {
      dataField: "generated_link",
      text: "Generated Link",
      formatter: (cell) => {
        const lines = cell ? cell?.match(/.{1,30}/g) : [];
        return (
          <div>
            {lines?.map((line, index) => (
              <span key={index}>
                {line}
                <br />
              </span>
            ))}
          </div>
        );
      },
      sort: true,
    },
    {
      dataField: "navigate",
      text: "Action",
      formatter: (cell, row) => {
        return (
          <button
            style={{
              background: "transparent",
              border: "none",
              cursor: "pointer",
            }}
            onClick={() => onRowClick(row)}
          >
            <FaArrowRight size={20} color="#007bff" />
          </button>
        );
      },
      headerStyle: { width: "50px", textAlign: "center" },
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];
  const onRowClick = (row) => {
    sessionStorage.setItem("selectedCampaign", JSON.stringify(row));
    history.push(`/Go_seo/Campaign_dashboard`);
  };
  const rowEvents = {
    onClick: (e, row) => onRowClick(row),
  };
  return (
    <div>
      <div className="p-2">
        <ToolkitProvider
          keyField="id"
          bootstrap4
          data={campaign}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <BootstrapTable
                rowStyle={rowStyle}
                defaultSorted={defaultSorted}
                pagination={paginationFactory()}
                {...props.baseProps}
                wrapperClasses="table-responsive"
                rowEvents={
                  {
                    // onClick: (e, row) => onRowClick(row),
                  }
                }
                noDataIndication={() => (
                  <tbody>
                    <tr>
                      <td colSpan={8} className="text-center">
                        No data found
                      </td>
                    </tr>
                  </tbody>
                )}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    </div>
  );
};
export const Backlinkssitetable = ({
  sites,
  loading,
  GetBackLinksCredentials,
}) => {
  const whiteStyle = { background: "#FFF" };
  const greyStyle = { background: "#e7eaf6" };

  const rowStyle = (row, index) => {
    return index % 2 === 0 ? greyStyle : whiteStyle;
  };

  const centerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const headerCenterStyle = { textAlign: "center" };
  const [selectedId, setSelectedId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    weburl: "",
    email: "",
    password: "",
    username: "",
    da: "",
    pa: "",
  });
  const { user } = useContext(GlobalContext);
  const handleEditClick = (row) => {
    setSelectedId(row.id);
    setFormData({
      weburl: row.siteName,
      email: row.email,
      password: row.password,
      username: row.username,
      da: row.domainAuthority,
      pa: row.pageAuthority,
    });
    setShowModal(true);
  };

  const handleModalClose = () => setShowModal(false);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const requestData = {
      id: selectedId,
      weburl: formData.weburl,
      email: formData.email,
      password: formData.password,
      username: formData.username,
      da: formData.da,
      pa: formData.pa,
      userid: user.user_id,
    };

    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      };

      console.log("Sending data to the server");
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/EditBacklinkCredential`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        swal("Success", data.result[0].message, "success");
        handleModalClose();
        GetBackLinksCredentials();
      } else {
        swal("Error", data.msg, "error");
        GetBackLinksCredentials();
      }

      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
    }
  };
  const countStyle = (count) => ({
    backgroundColor: count > 0 ? "green" : "gray",
    color: "white",
    borderRadius: "50%",
    padding: "5px 10px",
    width: "30px",
    height: "30px",
    textAlign: "center",
    lineHeight: "20px",
    fontSize: "14px",
  });

  const columns = [
    {
      dataField: "combined",
      text: (
        <>
          Site Name &<br /> Email
        </>
      ),
      sort: true,
      formatter: (cell, row) => (
        <div>
          {row.siteName}
          <div style={{ textAlign: "center" }}>&</div>
          {row.email}
        </div>
      ),
      headerStyle: () => headerCenterStyle,
    },
    {
      dataField: "combined",
      text: (
        <>
          Username &<br /> Password
        </>
      ),
      sort: true,
      formatter: (cell, row) => (
        <div style={centerStyle}>
          {row.username} &nbsp; /<br />
          {row.password}
        </div>
      ),
      headerStyle: () => headerCenterStyle,
    },
    {
      dataField: "combined",
      text: <>DA & PA</>,
      sort: true,
      formatter: (cell, row) => (
        <div style={centerStyle}>
          {row.domainAuthority} / {row.pageAuthority}
        </div>
      ),
      headerStyle: () => headerCenterStyle,
    },
    {
      dataField: "createdon",
      text: "Created On",
      sort: true,
      formatter: (cell) => <div style={centerStyle}>{cell}</div>,
      headerStyle: () => headerCenterStyle,
    },
    {
      dataField: "updatedon",
      text: "Updated On",
      sort: true,
      formatter: (cell) => <div style={centerStyle}>{cell}</div>,
      headerStyle: () => headerCenterStyle,
    },
    {
      dataField: "count",
      text: "Counts",
      sort: true,
      formatter: (cell) => (
        <div style={centerStyle}>
          <div style={countStyle(cell)}>{cell}</div>
        </div>
      ),
      headerStyle: () => headerCenterStyle,
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cell, row) => (
        <div style={centerStyle}>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="view-backlinks-tooltip">View Backlinks</Tooltip>
            }
          >
            <Link
              to="/Go_seo/Backlinks"
              onClick={() =>
                sessionStorage.setItem("selectedSite", JSON.stringify(row))
              }
            >
              <FaEye
                style={{
                  cursor: "pointer",
                  marginRight: "10px",
                  fontSize: "1.3rem",
                }}
              />
            </Link>
          </OverlayTrigger>

          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="edit-tooltip">Edit Site</Tooltip>}
          >
            <MdEdit
              style={{ cursor: "pointer", fontSize: "1.3rem" }}
              onClick={() => handleEditClick(row)}
            />
          </OverlayTrigger>
        </div>
      ),
      headerStyle: () => headerCenterStyle,
    },
  ];

  const defaultSorted = [
    {
      dataField: "siteName",
      order: "asc",
    },
  ];

  const paginationOptions = {
    sizePerPage: 50,
    hideSizePerPage: true,
    sizePerPageList: [50],
  };

  return (
    <div>
      <div className="p-2">
        {loading ? (
          <div className="text-center" style={{ width: "100%" }}>
            <Spinner animation="border" />
            <p>Loading...</p>
          </div>
        ) : sites.length === 0 ? ( // Check if there is no data
          <div className="text-center" style={{ padding: "20px" }}>
            <h5>No Data Found</h5>
          </div>
        ) : (
          <ToolkitProvider
            keyField="siteName"
            bootstrap4
            data={sites}
            columns={columns}
            search
          >
            {(props) => (
              <div
                style={{
                  maxHeight: "80vh",
                  overflowY: "auto",
                }}
                className="table-responsive"
              >
                <BootstrapTable
                  rowStyle={rowStyle}
                  defaultSorted={defaultSorted}
                  pagination={paginationFactory(paginationOptions)}
                  {...props.baseProps}
                  wrapperClasses="table-fixed-header"
                />
              </div>
            )}
          </ToolkitProvider>
        )}
      </div>
      <EditSiteModal
        showModal={showModal}
        handleModalClose={handleModalClose}
        handleFormSubmit={handleFormSubmit}
        formData={formData}
        setFormData={setFormData}
      />
    </div>
  );
};

export const Backlinkstable = ({ backlinkurl, loading, GetBackLinksUrl }) => {
  const whiteStyle = { background: "#FFF" };
  const greyStyle = { background: "#e7eaf6" };
  const rowStyle = (row, index) => {
    return index % 2 === 0 ? greyStyle : whiteStyle;
  };
  const centerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  const [showModal, setShowModal] = useState(false);
  const [selectedBacklink, setSelectedBacklink] = useState(null);
  const handleEditClick = (backlink) => {
    setSelectedBacklink(backlink);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleUpdateBacklink = () => {
    handleCloseModal();
  };
  const headerCenterStyle = { textAlign: "center" };

  const columns = [
    {
      dataField: "backlink_url",
      text: "Backlink",
      sort: true,
      formatter: (cell) => (
        <div style={centerStyle}>
          <a href={cell} target="_blank" rel="noopener noreferrer">
            {cell}
          </a>
        </div>
      ),
      headerStyle: () => headerCenterStyle,
    },
    {
      dataField: "created_on",
      text: "Created On",
      sort: true,
      formatter: (cell) => <div style={centerStyle}>{cell}</div>,
      headerStyle: () => headerCenterStyle,
    },
    {
      dataField: "updated_on",
      text: "Updated On",
      sort: true,
      formatter: (cell) => <div style={centerStyle}>{cell}</div>,
      headerStyle: () => headerCenterStyle,
    },
    {
      dataField: "is_indexed",
      text: "Indexed",
      sort: true,
      formatter: (cell) => <div style={centerStyle}>{cell}</div>,
      headerStyle: () => headerCenterStyle,
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cell, row) => (
        <div style={centerStyle}>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="edit-tooltip">Edit Backlink</Tooltip>}
          >
            <MdEdit
              style={{ cursor: "pointer", fontSize: "1.3rem" }}
              onClick={() => handleEditClick(row)}
            />
          </OverlayTrigger>
        </div>
      ),
      headerStyle: () => headerCenterStyle,
    },
  ];

  const defaultSorted = [
    {
      dataField: "siteName",
      order: "asc",
    },
  ];

  return (
    <div>
      <div className="p-2">
        <ToolkitProvider
          keyField="id"
          bootstrap4
          data={backlinkurl}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              {loading ? (
                <div className="text-center" style={{ width: "100%" }}>
                  <Spinner animation="border" />
                  <p>Loading...</p>
                </div>
              ) : (
                <BootstrapTable
                  rowStyle={rowStyle}
                  defaultSorted={defaultSorted}
                  pagination={paginationFactory()}
                  {...props.baseProps}
                  wrapperClasses="table-responsive"
                />
              )}
            </div>
          )}
        </ToolkitProvider>
      </div>
      <EditBacklinkModal
        show={showModal}
        handleClose={handleCloseModal}
        backlink={selectedBacklink}
        handleUpdate={handleUpdateBacklink}
        GetBackLinksUrl={GetBackLinksUrl}
      />
    </div>
  );
};

export const Reportingtable = ({ data }) => {
  return (
    <>
      <div className="row p-0">
        <div className="col-12">
          <div
            className="table-responsive"
            style={{ borderRadius: ".4em", border: "1px solid lightgrey" }}
          >
            <table className="table table-bordered m-0 p-0">
              <thead>
                <tr style={{ borderBottom: "1px solid lightgrey" }}>
                  <th
                    className="text-center"
                    style={{
                      color: "#0074D9",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    ID
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#0074D9",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    URL
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#0074D9",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Platform
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#0074D9",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Date
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.length > 0 ? (
                  data.map((item, index) => (
                    <tr
                      style={{ borderBottom: "1px solid lightgrey" }}
                      key={index}
                    >
                      <td
                        className="text-center align-middle"
                        style={{ border: "none" }}
                      >
                        {index + 1}
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{ border: "none" }}
                      >
                        <a
                          href={item.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {item.url}
                        </a>
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{ border: "none" }}
                      >
                        {item.social}
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{ border: "none" }}
                      >
                        {item.date}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="10" className="text-center">
                      No data found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export const LinkedIntable = ({ data }) => {
  return (
    <>
      <div className="row p-0">
        <div className="col-12">
          <div
            className="table-responsive"
            style={{ borderRadius: ".4em", border: "1px solid lightgrey" }}
          >
            <table className="table table-bordered m-0 p-0">
              <thead>
                <tr style={{ borderBottom: "1px solid lightgrey" }}>
                  <th
                    className="text-center"
                    style={{
                      color: "#0074D9",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    ID
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#0074D9",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    URL
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#0074D9",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Platform
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#0074D9",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Date
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.length > 0 ? (
                  data.map((item, index) => (
                    <tr
                      style={{ borderBottom: "1px solid lightgrey" }}
                      key={index}
                    >
                      <td
                        className="text-center align-middle"
                        style={{ border: "none" }}
                      >
                        {index + 1}
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{ border: "none" }}
                      >
                        <a
                          href={item.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {item.url}
                        </a>
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{ border: "none" }}
                      >
                        {item.social}
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{ border: "none" }}
                      >
                        {item.date}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="10" className="text-center">
                      No data found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
export const Instagramtable = ({ data }) => {
  return (
    <>
      <div className="row p-0">
        <div className="col-12">
          <div
            className="table-responsive"
            style={{ borderRadius: ".4em", border: "1px solid lightgrey" }}
          >
            <table className="table table-bordered m-0 p-0">
              <thead>
                <tr style={{ borderBottom: "1px solid lightgrey" }}>
                  <th
                    className="text-center"
                    style={{
                      color: "#0074D9",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    ID
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#0074D9",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    URL
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#0074D9",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Platform
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#0074D9",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Date
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.length > 0 ? (
                  data.map((item, index) => (
                    <tr
                      style={{ borderBottom: "1px solid lightgrey" }}
                      key={index}
                    >
                      <td
                        className="text-center align-middle"
                        style={{ border: "none" }}
                      >
                        {index + 1}
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{ border: "none" }}
                      >
                        <a
                          href={item.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {item.url}
                        </a>
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{ border: "none" }}
                      >
                        {item.social}
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{ border: "none" }}
                      >
                        {item.date}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="10" className="text-center">
                      No data found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
export const facebooktable = ({ data }) => {
  return (
    <>
      <div className="row p-0">
        <div className="col-12">
          <div
            className="table-responsive"
            style={{ borderRadius: ".4em", border: "1px solid lightgrey" }}
          >
            <table className="table table-bordered m-0 p-0">
              <thead>
                <tr style={{ borderBottom: "1px solid lightgrey" }}>
                  <th
                    className="text-center"
                    style={{
                      color: "#0074D9",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    ID
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#0074D9",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    URL
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#0074D9",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Platform
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#0074D9",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Date
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.length > 0 ? (
                  data.map((item, index) => (
                    <tr
                      style={{ borderBottom: "1px solid lightgrey" }}
                      key={index}
                    >
                      <td
                        className="text-center align-middle"
                        style={{ border: "none" }}
                      >
                        {index + 1}
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{ border: "none" }}
                      >
                        <a
                          href={item.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {item.url}
                        </a>
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{ border: "none" }}
                      >
                        {item.social}
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{ border: "none" }}
                      >
                        {item.date}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="10" className="text-center">
                      No data found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
export const Youtubetable = ({ data }) => {
  return (
    <>
      <div className="row p-0">
        <div className="col-12">
          <div
            className="table-responsive"
            style={{ borderRadius: ".4em", border: "1px solid lightgrey" }}
          >
            <table className="table table-bordered m-0 p-0">
              <thead>
                <tr style={{ borderBottom: "1px solid lightgrey" }}>
                  <th
                    className="text-center"
                    style={{
                      color: "#0074D9",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    ID
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#0074D9",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    URL
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#0074D9",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Platform
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#0074D9",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Date
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.length > 0 ? (
                  data.map((item, index) => (
                    <tr
                      style={{ borderBottom: "1px solid lightgrey" }}
                      key={index}
                    >
                      <td
                        className="text-center align-middle"
                        style={{ border: "none" }}
                      >
                        {index + 1}
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{ border: "none" }}
                      >
                        <a
                          href={item.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {item.url}
                        </a>
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{ border: "none" }}
                      >
                        {item.social}
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{ border: "none" }}
                      >
                        {item.date}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="10" className="text-center">
                      No data found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export const GuestpostTable = ({ tableData, handleInputChange }) => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const history = useHistory();
  const handleSubmit = async (rowData) => {
    setTransactionLoaderState(true);
    const { id, bidPrice, noFollow, articles, define_price } = rowData;
    const totalBid =
      articles && bidPrice ? (articles * bidPrice).toFixed(2) : "0";
    const totalAmount =
      articles && define_price ? (articles * define_price).toFixed(2) : "0";

    const requestBody = {
      userid: user.user_id,
      domainid: id.toString(),
      bid_price: bidPrice,
      do_follow: noFollow,
      articles_count: articles,
      status: "P",
      total_bid: totalBid,
      total_amount: totalAmount,
    };

    try {
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/RequestGp`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      const data = await res.json();
      if (data.status === "0") {
        swal("Success!", data.result[0].message, "success");
        history.push("/Go_seo/Guest_post_order");
        console.log("Request submitted successfully:", data);
      } else {
        swal("Error!", data.message, "error");
        console.error("Error submitting request:", data.message);
      }
    } catch (error) {
      swal("Error!", "API call error: " + error.message, "error");
      console.error("API call error:", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  const handleRowSubmit = (rowData) => {
    handleSubmit(rowData);
  };

  return (
    <table className="table table-bordered">
      <thead style={{ background: "#F1F0E8" }}>
        <tr>
          <th>Website Name</th>
          <th style={{ width: "13vw" }}>Category</th>
          <th style={{ width: "10vw" }}>SubCategory</th>
          <th>
            Price/-
            <br /> Article
          </th>
          <th>DA/PA</th>
          <th style={{ width: "8vw" }}>No. of Articles</th>
          <th style={{ width: "8vw" }}>No. of Do Follow</th>
          <th style={{ width: "8vw" }}>
            Bid Price /-
            <br /> Article
          </th>
          <th style={{ width: "8vw" }}>Total Bid Price</th>
          <th style={{ width: "8vw" }}>Total Define Price</th>
          <th style={{ width: "8vw" }}>Action</th>
        </tr>
      </thead>
      <tbody>
        {tableData && tableData.length > 0 ? (
          tableData.map((row) => (
            <tr key={row.id}>
              <td>
                {row.plan_name === "Premium" && (
                  <FaCrown
                    style={{
                      color: "#FFB200",
                      fontSize: "1rem",
                      display: "inline",
                    }}
                  />
                )}
                {row.is_verified === "Y" && (
                  <MdVerified
                    style={{
                      color: "green",
                      fontSize: "1rem",
                      display: "inline",
                    }}
                  />
                )}
                {row.webisie_name}
              </td>

              <td>{row.category_name}</td>
              <td>{row.subcategory_name}</td>
              <td>{row.define_price}</td>

              <td>
                {row.da}/{row.pa}
              </td>
              <td>
                <input
                  type="number"
                  className="form-control"
                  value={row.articles}
                  onChange={(e) =>
                    handleInputChange(row.id, "articles", e.target.value)
                  }
                />
              </td>
              <td>
                <input
                  type="number"
                  className="form-control"
                  value={row.noFollow}
                  onChange={(e) =>
                    handleInputChange(row.id, "noFollow", e.target.value)
                  }
                />
              </td>
              <td>
                <input
                  type="number"
                  className="form-control"
                  value={row.bidPrice}
                  onChange={(e) =>
                    handleInputChange(row.id, "bidPrice", e.target.value)
                  }
                />
              </td>

              <td>
                {" "}
                {row.articles && row.bidPrice
                  ? (row.articles * row.bidPrice).toFixed(2)
                  : 0}
              </td>
              <td>
                {row.articles && row.define_price
                  ? (row.articles * row.define_price).toFixed(2)
                  : 0}
              </td>
              <td>
                <Button
                  size="sm"
                  variant="info"
                  onClick={() => handleRowSubmit(row)}
                >
                  Submit
                </Button>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="9" className="text-center">
              No Data Found
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export const Pagessetting = ({ location }) => {
  const pageDisplayNames = {
    contact_us: "Contact Us",
    faq: "FAQ",
    terms_condition: "Terms & Conditions",
    cancellation_policy: "Cancellation Policy",
    refund_policy: "Refund Policy",
    New_login: "New Login",
    Generate_ID_Secret: "Generate ID Secret",
    privacy_policy: "Privacy Policy",
    Attendance_Management: "Attendance Management",
    EmployeeManagment: "Employee Management",
    Expense_Management: "Expense Management",
    Leave_Management: "Leave Management",
    Payroll_Management: "Payroll Management",
    Pricing: "Pricing",
    Home_Page: "Home Page",
  };

  const { setTransactionLoaderState ,siteid} = useContext(GlobalContext);
  const [stimapdata, setstimapdata] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedPage, setSelectedPage] = useState(null);
  const Getsitemapdata = async () => {
    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          webid: siteid,
          url: location,
        }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetAllPages`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        setstimapdata(
          data.data.map((item) => ({
            id: item.id,
            base_url: item.base_url,
            page_url: item.page_url,
            url_pages: item.url_pages,
          }))
        );
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    if (location && siteid) {
      Getsitemapdata();
    }
  }, [location, siteid]);
  const filteredData = stimapdata.filter((page) => {
    const query = searchQuery.toLowerCase();
    return (
      page.page_url.toLowerCase().includes(query) ||
      page.url_pages.toLowerCase().includes(query)
    );
  });

  return (
    <div className="m-1">
      <div className="row">
        <div className="col">
          <h6 style={{ color: "#441752" }}>
            Pages of <u>{location}</u>
          </h6>
        </div>
        <div className="col mr-3 d-flex justify-content-end">
          <Form.Control
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            style={{ width: "50%" }}
          />
        </div>
      </div>
      <div className="row pt-0">
        <div className="col-5 custom-scroll-hide" style={{ height: "90vh" }}>
          {filteredData.map((page) => (
            <div key={page.id} className="mb-2">
              <Card
                className="p-0"
                style={{
                  border:
                    selectedPage?.id === page.id
                      ? "2px solid #441752"
                      : "1px solid #dee2e6",
                  backgroundColor:
                    selectedPage?.id === page.id ? "rgba(234,229,244)" : "#fff",
                  transition: "background-color 0.3s ease, border 0.3s ease",
                }}
              >
                <Card.Body className="px-2 p-1 d-flex justify-content-between">
                  <span className="d-flex align-items-center" style={{color:"#441752", fontSize:".9rem"}}>{pageDisplayNames[page.url_pages] || page.url_pages}</span>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id={`tooltip-${page.id}`}>
                        Click for SEO setting
                      </Tooltip>
                    }
                  >
                    <Link
                      onClick={() => {
                        sessionStorage.setItem("pageid", JSON.stringify(page));
                        setSelectedPage(page); // Set the selected page
                      }}
                    >
                      <Button size="sm"  style={{ background: "#441752", color: "#fff" }}>View</Button>
                    </Link>
                  </OverlayTrigger>
                </Card.Body>
              </Card>
            </div>
          ))}
          {filteredData.length === 0 && (
            <div className="col-12 text-center mt-4">No data found</div>
          )}
        </div>

        <div className="col custom-scroll-hide" style={{ height: "90vh" }}>
          {selectedPage ? (
            <Sitemapsetting key={selectedPage.id} page={selectedPage} />
          ) : (
            <div className="text-center mt-4">No data found</div>
          )}
        </div>
      </div>
    </div>
  );
};
export const Pagesauthorsetting = ({ location }) => {
  const pageDisplayNames = {
    contact_us: "Contact Us",
    faq: "FAQ",
    terms_condition: "Terms & Conditions",
    cancellation_policy: "Cancellation Policy",
    refund_policy: "Refund Policy",
    New_login: "New Login",
    Generate_ID_Secret: "Generate ID Secret",
    privacy_policy: "Privacy Policy",
    Attendance_Management: "Attendance Management",
    EmployeeManagment: "Employee Management",
    Expense_Management: "Expense Management",
    Leave_Management: "Leave Management",
    Payroll_Management: "Payroll Management",
    Pricing: "Pricing",
    Home_Page: "Home Page",
  };

  const { setTransactionLoaderState,siteid } = useContext(GlobalContext);
  const [stimapdata, setstimapdata] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedPage, setSelectedPage] = useState(null);
  const Getsitemapdata = async () => {
    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          webid: siteid,
          url: location,
        }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetAllPages`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        setstimapdata(
          data.data.map((item) => ({
            id: item.id,
            base_url: item.base_url,
            page_url: item.page_url,
            url_pages: item.url_pages,
          }))
        );
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    Getsitemapdata();
  }, [siteid]);

  const filteredData = stimapdata.filter((page) => {
    const query = searchQuery.toLowerCase();
    return (
      page.page_url.toLowerCase().includes(query) ||
      page.url_pages.toLowerCase().includes(query)
    );
  });

  return (
    <div className="m-1">
      <div className="row">
        <div className="col">
          <h6 style={{ color: "#441752" }}>
            Authors of <u>{location}</u>
          </h6>
        </div>
        <div className="col mr-3 d-flex justify-content-end">
          <Form.Control
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            style={{ width: "50%" }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-5 custom-scroll-hide" style={{ height: "90vh" }}>
          {filteredData.map((page) => (
            <div key={page.id} className="mb-2">
              <Card
                className="p-0"
                style={{
                  border:
                    selectedPage?.id === page.id
                    ? "2px solid #441752"
                    : "1px solid #dee2e6",
                  backgroundColor:
                    selectedPage?.id === page.id ? "rgba(234,229,244)" : "#fff",
                  transition: "background-color 0.3s ease, border 0.3s ease",
                }}
              >
                <Card.Body className="px-2 p-1 d-flex justify-content-between">
                <span className="d-flex align-items-center" style={{color:"#441752", fontSize:".9rem"}}>{pageDisplayNames[page.url_pages] || page.url_pages}</span>
                <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id={`tooltip-${page.id}`}>
                        Click for SEO setting
                      </Tooltip>
                    }
                  >
                    <Link
                      onClick={() => {
                        sessionStorage.setItem("pageid", JSON.stringify(page));
                        setSelectedPage(page); // Set the selected page
                      }}
                    >
                      <Button style={{ background: "#441752", color: "#fff" }} size="sm">View</Button>
                    </Link>
                  </OverlayTrigger>
                </Card.Body>
              </Card>
            </div>
          ))}
          {filteredData.length === 0 && (
            <div className="col-12 text-center mt-4">No data found</div>
          )}
        </div>

        <div className="col custom-scroll-hide" style={{ height: "90vh" }}>
          {selectedPage ? (
            <Sitemapsetting key={selectedPage.id} page={selectedPage} />
          ) : (
            <div className="text-center mt-4">No data found</div>
          )}
        </div>
      </div>
    </div>
  );
};
export const Pageblogssetting = ({ location }) => {
  const pageDisplayNames = {
    contact_us: "Contact Us",
    faq: "FAQ",
    terms_condition: "Terms & Conditions",
    cancellation_policy: "Cancellation Policy",
    refund_policy: "Refund Policy",
    New_login: "New Login",
    Generate_ID_Secret: "Generate ID Secret",
    privacy_policy: "Privacy Policy",
    Attendance_Management: "Attendance Management",
    EmployeeManagment: "Employee Management",
    Expense_Management: "Expense Management",
    Leave_Management: "Leave Management",
    Payroll_Management: "Payroll Management",
    Pricing: "Pricing",
    Home_Page: "Home Page",
  };

  const { setTransactionLoaderState ,siteid} = useContext(GlobalContext);
  const [stimapdata, setstimapdata] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedPage, setSelectedPage] = useState(null);
  const Getsitemapdata = async () => {
    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          webid: siteid,
          url: location,
        }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetAllPages`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        setstimapdata(
          data.data.map((item) => ({
            id: item.id,
            base_url: item.base_url,
            page_url: item.page_url,
            url_pages: item.url_pages,
          }))
        );
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    Getsitemapdata();
  }, [siteid]);

  const filteredData = stimapdata.filter((page) => {
    const query = searchQuery.toLowerCase();
    return (
      page.page_url.toLowerCase().includes(query) ||
      page.url_pages.toLowerCase().includes(query)
    );
  });

  return (
    <div className="m-1">
      <div className="row">
        <div className="col">
          <h6 style={{ color: "#441752" }}>
            Blogs of <u>{location}</u>
          </h6>
        </div>
        <div className="col mr-3 d-flex justify-content-end">
          <Form.Control
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            style={{ width: "50%" }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-5 custom-scroll-hide" style={{ height: "90vh" }}>
          {filteredData.map((page) => (
            <div key={page.id} className="mb-2">
              <Card
                className="p-0"
                style={{
                  border:
                  selectedPage?.id === page.id
                  ? "2px solid #441752"
                  : "1px solid #dee2e6",
              backgroundColor:
                selectedPage?.id === page.id ? "rgba(234,229,244)" : "#fff",
              transition: "background-color 0.3s ease, border 0.3s ease",
            }}
              >
                <Card.Body className="px-2 p-1 d-flex justify-content-between">
                  <a
                    href={page.page_url} 
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      textDecoration: "none",
                      color: selectedPage?.id === page.id ? "#007bff" : "#000",
                    }}
                  >
                   <span className="d-flex align-items-center"  style={{color:"#441752", fontSize:".9rem"}}>{pageDisplayNames[page.url_pages] || page.url_pages}</span>
                   </a>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id={`tooltip-${page.id}`}>
                        Click for SEO setting
                      </Tooltip>
                    }
                  >
                    <Link
                      onClick={() => {
                        sessionStorage.setItem("pageid", JSON.stringify(page));
                        setSelectedPage(page); // Set the selected page
                      }}
                    >
                      <Button style={{ background: "#441752", color: "#fff" }} size="sm">View</Button>
                    </Link>
                  </OverlayTrigger>
                </Card.Body>
              </Card>
            </div>
          ))}
          {filteredData.length === 0 && (
            <div className="col-12 text-center mt-4">No data found</div>
          )}
        </div>

        <div className="col custom-scroll-hide" style={{ height: "90vh" }}>
          {selectedPage ? (
            <BlogSeoSettingForm
              key={selectedPage.id}
              page={selectedPage}
              location={location}
            />
          ) : (
            <div className="text-center mt-4">No data found</div>
          )}
        </div>
      </div>
    </div>
  );
};
