import React, { useEffect, useState } from "react";
import { Card, Row, Col, ProgressBar } from "react-bootstrap";
import { Chart } from "react-google-charts";
import { BASE_URL_API_TRANXT_UAT_API } from "../Utils/Constant";
import { FaCheckCircle } from "react-icons/fa";
import { ImCross } from "react-icons/im";
const Seoanalyzeres = ({ url }) => {
  const [seoData, setSeoData] = useState(null);
  const formatUrl = (url) => {
    if (!/^https?:\/\//i.test(url)) {
      return `https://${url}`;
    }
    return url;
  };
  useEffect(() => {
    const fetchData = async () => {
      const formattedUrl = formatUrl(url);
      const trimmedUrl = formattedUrl.trim();

      console.log(formattedUrl);
      const response = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetSeoScore`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ url: trimmedUrl }),
        }
      );
      const data = await response.json();
      setSeoData(data);
    };

    fetchData();
  }, []);

  if (!seoData) {
    return <div>Loading...</div>;
  }

  const pieData = [
    ["Task", "Hours per Day"],
    ["Score", seoData.score],
    ["Remaining", 100 - seoData.score],
  ];

  const pieOptions = {
    is3D: true,
    legend: { position: "none" },
    slices: {
      0: { offset: 0, color: "#FFCE56" },
      1: { offset: 0, color: "#C0C0C0" },
    },
  };

  const totalTests = seoData.totalTests;
  const passedTests = seoData.passedTests.length;
  const failedTests = seoData.failedTests.length;
  const warningTests = totalTests - passedTests - failedTests;

  const percentagePassed = (passedTests / totalTests) * 100;
  const percentageFailed = (failedTests / totalTests) * 100;
  const percentageWarning = (warningTests / totalTests) * 100;

  function breakLineAfterCharacters(text, charsPerLine) {
    const chunks = [];

    for (let i = 0; i < text.length; i += charsPerLine) {
      chunks.push(text.slice(i, i + charsPerLine));
    }

    return chunks.join("\n");
  }
  const splitTextIntoLines = (text) => {
    const words = text.split(" ");
    const lines = [];

    for (let i = 0; i < words.length; i += 10) {
      lines.push(words.slice(i, i + 15).join(" "));
    }

    return lines.map((line, index) => (
      <span key={index}>
        {line}
        <br />
      </span>
    ));
  };
  return (
    <>
      <div id="">
        <h4 className="text-center">Seo Audit Report</h4>
        <Row>
          <Col md={4}>
            <Card className="p-2" style={{ border: "none" }}>
              <Chart
                chartType="PieChart"
                data={pieData}
                options={pieOptions}
                width={"100%"}
                height={"350px"}
              />
            </Card>
          </Col>
          <Col md={8}>
            <Card className="p-2 mt-2">
              <Card.Title style={{ color: "black" }}>
                SEO Test Results
              </Card.Title>
              <div className="mb-3">
                <div className="d-flex justify-content-between mb-2">
                  <span>Total Tests</span>
                  <span>{totalTests}</span>
                </div>
                <ProgressBar now={100} label={`${totalTests}`} />
              </div>
              <div className="mb-3">
                <div className="d-flex justify-content-between mb-2">
                  <span>Passed Tests</span>
                  <span>{passedTests}</span>
                </div>
                <ProgressBar
                  now={percentagePassed}
                  label={`${passedTests} (${Math.round(percentagePassed)}%)`}
                  variant="success"
                />
              </div>
              <div className="mb-3">
                <div className="d-flex justify-content-between mb-2">
                  <span>Failed Tests</span>
                  <span>{failedTests}</span>
                </div>
                <ProgressBar
                  now={percentageFailed}
                  label={`${failedTests} (${Math.round(percentageFailed)}%)`}
                  variant="danger"
                />
              </div>
              <div className="mb-3">
                <div className="d-flex justify-content-between mb-2">
                  <span>Warning Tests</span>
                  <span>{warningTests}</span>
                </div>
                <ProgressBar
                  now={percentageWarning}
                  label={`${warningTests} (${Math.round(percentageWarning)}%)`}
                  variant="warning"
                />
              </div>
            </Card>
          </Col>
        </Row>

        <div className="row">
          <div className="col-md-4 col-10">
            {/* Mobile view snapshot */}
            <div className="mb-1 ">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h4 className="font-weight-bold text-secondary">
                  Mobile Snapshot
                </h4>
              </div>
              <div
                className="phone-frame"
                style={{
                  width: "100%",
                  maxWidth: "360px",
                  margin: "0 auto",
                  padding: "10px",
                  borderRadius: "30px",
                  border: "16px solid #333",
                  borderTopWidth: "56px",
                  position: "relative",
                  backgroundColor: "#fff",
                  boxShadow: "0 0 10px rgba(0,0,0,0.2)",
                }}
              >
                <div
                  className="phone-screen"
                  style={{
                    width: "100%",
                    height: "auto",
                    overflow: "hidden",
                    borderRadius: "20px",
                    backgroundColor: "#f0f0f0", // Light background for the screen
                  }}
                >
                  <img
                    src={seoData.mobile_view}
                    alt="Mobile View Snapshot"
                    className="img-fluid"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                </div>
              </div>
            </div>
            {/* Mobile view snapshot end */}
          </div>
          <div
            className="col-md-8 col-12 mt-md-0 mt-2 custom-scroll-hide"
            style={{ height: "100vh" }}
          >
            <div className="mb-4 p-3 border rounded">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h4 className="font-weight-bold text-secondary">
                  Common Keywords
                </h4>
              </div>
              <div className="d-flex align-items-center mb-3">
                <div className="badge badge-danger mr-2">!</div>
                <p className="m-0">
                  Here are the most common keywords we found on your homepage:
                </p>
              </div>
              <div className="d-flex flex-wrap">
                {seoData.keywordsInPage.map((keyword, index) => (
                  <div
                    key={index}
                    className="m-1 p-2 border rounded"
                    style={{
                      backgroundColor: "#f8f9fa",
                      borderColor: "#dee2e6",
                      fontSize: "0.9rem",
                    }}
                  >
                    {keyword}
                  </div>
                ))}
              </div>
            </div>

            <div className="mb-1 p-2 border rounded">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h4 className="font-weight-bold text-secondary">
                  SEO Description
                </h4>
              </div>
              <div className="d-flex align-items-center mb-3">
                <div className="badge badge-success mr-2"></div>
                <p>{seoData.metaDescription}</p>
              </div>
            </div>
            <div className="mb-1 p-2 border rounded">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h4 className="font-weight-bold text-secondary">H1 Heading</h4>
              </div>
              <div className="d-flex align-items-center mb-3">
                <div className="mr-2">
                  {seoData.tests["H1 Tags"].result ? (
                    <FaCheckCircle className="text-success" />
                  ) : (
                    <ImCross className="text-danger" />
                  )}
                </div>
                <p className="m-0">{seoData.tests["H1 Tags"].description}</p>
              </div>
              <pre className="bg-light p-2 rounded">
                {splitTextIntoLines(seoData.h1Tags.join(", "))}
              </pre>
            </div>
            <div className="mb-1 p-2 border rounded">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h4 className="font-weight-bold text-secondary">H2 Headings</h4>
              </div>
              <div className="d-flex align-items-center mb-3">
                <div className="mr-2">
                  {seoData.tests["H2 Tags"].result ? (
                    <FaCheckCircle className="text-success" />
                  ) : (
                    <ImCross className="text-danger" />
                  )}
                </div>
                <p className="m-0">{seoData.tests["H2 Tags"].description}</p>
              </div>
              <pre className="bg-light p-2 rounded">
              {splitTextIntoLines(seoData.h2Tags.join(", "))}
                {/* {seoData.h2Tags.join(", ")} */}
              </pre>
            </div>
            <div className="mb-1 p-2 border rounded">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h4 className="font-weight-bold text-secondary">
                  Image ALT Attributes
                </h4>
              </div>
              <div className="d-flex align-items-center mb-3">
                <div className="badge badge-success mr-2"></div>
                <p>
                  {seoData.imageAltTags.length > 0
                    ? "All images have alt tags."
                    : "No images found on the page."}
                </p>
              </div>
            </div>
            <div className="mb-1 p-2 border rounded">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h4 className="font-weight-bold text-secondary">
                  Keywords in Title & Description
                </h4>
              </div>
              <div className="d-flex align-items-center mb-3">
                <div className="badge badge-success mr-2"></div>
                <p>
                  One or more common keywords were found in the title and
                  description of your homepage.
                </p>
              </div>
              <pre className="bg-light p-2 rounded">
                title: {seoData.keywordsInTitle.join(", ")}
                <br />
                description:{" "}
                {breakLineAfterCharacters(seoData.keywordsInDescription, 10)}
              </pre>
            </div>
            {/* Mobile view snapshot */}

            {/* Mobile view snapshot end */}

            <div className="mb-1 p-2 border rounded">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h4 className="font-weight-bold text-secondary">Links Ratio</h4>
              </div>
              <div className="d-flex align-items-center mb-3">
                <p>
                  There are one or more issues with the number of links on your
                  homepage: Too few internal links ({seoData.internalLinks}).
                </p>
              </div>
              <pre className="bg-light p-2 rounded">
                Internal Links: {seoData.internalLinks}
                <br />
                External Links: {seoData.externalLinks}
              </pre>
            </div>
            {/* Additional Sections */}
            <div className="mb-1 p-2 border rounded">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h4 className="font-weight-bold text-secondary">
                  Title Length
                </h4>
              </div>
              <div className="d-flex align-items-center mb-3">
                <div className="mr-2">
                  {seoData.tests["Title Length"].result ? (
                    <FaCheckCircle className="text-success" />
                  ) : (
                    <ImCross className="text-danger" />
                  )}
                </div>
                <p className="m-0">
                  {seoData.tests["Title Length"].description}
                </p>
              </div>
              <pre className="bg-light p-2 rounded">
                Length: {seoData.titleLength} characters
              </pre>
            </div>

            <div className="mb-1 p-2 border rounded">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h4 className="font-weight-bold text-secondary">
                  Meta Description Length
                </h4>
              </div>
              <div className="d-flex align-items-center mb-3">
                <div className="mr-2">
                  {seoData.tests["Meta Description Length"].result ? (
                    <FaCheckCircle className="text-success" />
                  ) : (
                    <ImCross className="text-danger" />
                  )}
                </div>
                <p className="m-0">
                  {seoData.tests["Meta Description Length"].description}
                </p>
              </div>
              <pre className="bg-light p-2 rounded">
                Length: {seoData.metaDescriptionLength} characters
              </pre>
            </div>

            {/* New sections */}
            <div className="mb-1 p-2 border rounded">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h4 className="font-weight-bold text-secondary">
                  Search Preview
                </h4>
              </div>
              <div className="d-flex align-items-center mb-3">
                <div className="mr-2">
                  {seoData.tests["Search Preview"].result ? (
                    <FaCheckCircle className="text-success" />
                  ) : (
                    <ImCross className="text-danger" />
                  )}
                </div>
                <p className="m-0">
                  {seoData.tests["Search Preview"].description}
                </p>
              </div>
            </div>

            <div className="mb-1 p-2 border rounded">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h4 className="font-weight-bold text-secondary">
                  OpenGraph Meta Tags
                </h4>
              </div>
              <div className="d-flex align-items-center mb-3">
                <div className="mr-2">
                  {seoData.tests["OpenGraph Meta Tags"].result ? (
                    <FaCheckCircle className="text-success" />
                  ) : (
                    <ImCross className="text-danger" />
                  )}
                </div>
                <p className="m-0">
                  {seoData.tests["OpenGraph Meta Tags"].description}
                </p>
              </div>
            </div>

            <div className="mb-1 p-2 border rounded">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h4 className="font-weight-bold text-secondary">Robots.txt</h4>
              </div>
              <div className="d-flex align-items-center mb-3">
                <div className="mr-2">
                  {seoData.tests["Robots.txt"].result ? (
                    <FaCheckCircle className="text-success" />
                  ) : (
                    <ImCross className="text-danger" />
                  )}
                </div>
                <p className="m-0">{seoData.tests["Robots.txt"].description}</p>
              </div>
            </div>

            <div className="mb-1 p-2 border rounded">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h4 className="font-weight-bold text-secondary">
                  Schema Meta Data
                </h4>
              </div>
              <div className="d-flex align-items-center mb-3">
                <div className="mr-2">
                  {seoData.tests["Schema Meta Data"].result ? (
                    <FaCheckCircle className="text-success" />
                  ) : (
                    <ImCross className="text-danger" />
                  )}
                </div>
                <p className="m-0">
                  {seoData.tests["Schema Meta Data"].description}
                </p>
              </div>
            </div>

            <div className="mb-1 p-2 border rounded">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h4 className="font-weight-bold text-secondary">Minify CSS</h4>
              </div>
              <div className="d-flex align-items-center mb-3">
                <div className="mr-2">
                  {seoData.tests["Minify CSS"].result ? (
                    <FaCheckCircle className="text-success" />
                  ) : (
                    <ImCross className="text-danger" />
                  )}
                </div>
                <p className="m-0">{seoData.tests["Minify CSS"].description}</p>
              </div>
            </div>

            <div className="mb-1 p-2 border rounded">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h4 className="font-weight-bold text-secondary">
                  Minify JavaScript
                </h4>
              </div>
              <div className="d-flex align-items-center mb-3">
                <div className="mr-2">
                  {seoData.tests["Minify JavaScript"].result ? (
                    <FaCheckCircle className="text-success" />
                  ) : (
                    <ImCross className="text-danger" />
                  )}
                </div>
                <p className="m-0">
                  {seoData.tests["Minify JavaScript"].description}
                </p>
              </div>
            </div>

            <div className="mb-1 p-2 border rounded">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h4 className="font-weight-bold text-secondary">Page Size</h4>
              </div>
              <div className="d-flex align-items-center mb-3">
                <div className="mr-2">
                  {seoData.tests["Page Size"].result ? (
                    <FaCheckCircle className="text-success" />
                  ) : (
                    <ImCross className="text-danger" />
                  )}
                </div>
                <p className="m-0">{seoData.tests["Page Size"].description}</p>
              </div>
            </div>

            <div className="mb-1 p-2 border rounded">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h4 className="font-weight-bold text-secondary">
                  Response Time
                </h4>
              </div>
              <div className="d-flex align-items-center mb-3">
                <div className="mr-2">
                  {seoData.tests["Response Time"].result ? (
                    <FaCheckCircle className="text-success" />
                  ) : (
                    <ImCross className="text-danger" />
                  )}
                </div>
                <p className="m-0">
                  {seoData.tests["Response Time"].description}
                </p>
              </div>
            </div>

            <div className="mb-1 p-2 border rounded">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h4 className="font-weight-bold text-secondary">
                  Directory Listing
                </h4>
              </div>
              <div className="d-flex align-items-center mb-3">
                <div className="mr-2">
                  {seoData.tests["Directory Listing"].result ? (
                    <FaCheckCircle className="text-success" />
                  ) : (
                    <ImCross className="text-danger" />
                  )}
                </div>
                <p className="m-0">
                  {seoData.tests["Directory Listing"].description}
                </p>
              </div>
            </div>

            <div className="mb-1 p-2 border rounded">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h4 className="font-weight-bold text-secondary">
                  Secure Connection
                </h4>
              </div>
              <div className="d-flex align-items-center mb-3">
                <div className="mr-2">
                  {seoData.tests["Secure Connection"].result ? (
                    <FaCheckCircle className="text-success" />
                  ) : (
                    <ImCross className="text-danger" />
                  )}
                </div>
                <p className="m-0">
                  {seoData.tests["Secure Connection"].description}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Seoanalyzeres;
