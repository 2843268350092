import React, { useContext, useEffect, useState } from "react";

const Linkedin = () => {
 
  return (
  <>
  <h1>Linkedin</h1>
  </>
  );
};

export default Linkedin;
