import React, { useContext, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Card, Col, Row, ProgressBar } from "react-bootstrap";
import { BASE_URL_API_TRANXT_UAT_API } from "../Utils/Constant";
import { FaCircle, FaCheckCircle, FaExclamationCircle } from "react-icons/fa";
import Custormsidebar from "../shared/Customsidebar";
import { GlobalContext } from "../Context/GlobalState";

const Domainpage = () => {

  const { siteurl } = useContext(GlobalContext);
  const url = sessionStorage.getItem("selectedURLLabel");
  const [URL, setURL] = useState(siteurl || "");
  const [showResults, setShowResults] = useState(false);
  const handleChange = (event) => {
    setURL(event.target.value);
  };
  useEffect(() => {
    setURL(siteurl || "");
  }, [siteurl]);
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Submitted URL:", URL);
    setShowResults(true);
  };
  const handleGoBack = () => {
    setShowResults(false);
    setURL("");
  };
  const seoData = [
    {
      image: require("../../assets/images/seoimages/da1.svg"),
      alt: "Boost Rankings",
      title: "Obtain a thorough analysis",
      items: [
        "Gain a complete understanding of a domain and its online visibility",
        "Examine a domain’s growth pattern over time",
        "Identify the markets where a domain is most prominent",
        "Explore the top keywords driving traffic from both organic and paid channels",
      ],
    },
    {
      image: require("../../assets/images/seoimages/da3.svg"),
      alt: "Drive Traffic",
      title: "Compare Multiple Domains",
      items: [
        "Save time on summary analysis by comparing up to five competitors in a single report",
        "Examine key metrics such as organic search traffic, paid search traffic, and backlink count, either globally or by specific country",
        "Choose between different domain types: root domain, subdomain, or subfolder",
      ],
    },
    {
      image: require("../../assets/images/seoimages/da4.svg"),
      alt: "Build Credibility",
      title: "Growth Insights",
      items: [
        "Quickly access the most important data on a domain's growth trends in one convenient location",
        "Assess a domain’s development in organic search, paid search, and backlinks over a defined period: three months, a quarter, six months, a season, or one year",
        "Export the report effortlessly in either xls or csv format",
      ],
    },
    {
      image: require("../../assets/images/seoimages/da2.svg"),
      alt: "Why DA & PA Matter",
      title: "Compare by Countries",
      items: [
        "Compare a domain’s organic and paid performance across various countries",
        "Analyze the organic market share of a domain in specific regions",
        "Export the performance trends to xls or csv formats",
      ],
    },
  ];
  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    setHasLoaded(true);
  }, []);
  return (
    <div
      className=" pt-4 custom-scroll"
      style={{
        height: "90vh",
        overflowY: "auto",
        // backgroundColor: "rgba(244,245,249)",
      }}
    >
      <style>
        {`
    .custom-scroll::-webkit-scrollbar {
      display: none; /* Hide the scrollbar */
    }
    .custom-scroll {
      -ms-overflow-style: none;  /* For Internet Explorer 10+ */
      scrollbar-width: none;     /* For Firefox */
    }
  `}
      </style>
      {!showResults ? (
        <div
          className=""
          // style={{ height: "50vh" }}
        >
          <Row className="justify-content-center">
            <Col
              md={10}
              className=" p-4"
              style={{ background: "rgba(234,229,244)", borderRadius: ".4rem" }}
            >
              <div className="text-center">
                <h4 className="mb-3" style={{ color: "#441752" }}>
                  Check Your Domain Information
                </h4>
                <p
                  className="mt-2 text-center"
                  style={{
                    fontSize: "1rem",
                    color: "#441752",
                    marginBottom: "40px",
                  }}
                >
                  Learn how Domain Authority (DA) and Page Authority (PA) can
                  boost your website's rankings and drive more traffic. Discover
                  <br />
                  actionable insights, tips, and guides to enhance your online
                  presence.
                </p>
                <form
                  onSubmit={handleSubmit}
                  className="d-flex align-items-center justify-content-center"
                >
                  <div
                    className="form-group mb-0 mr-2"
                    style={{ width: "20rem" }}
                  >
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Website URL e.g. https://google.com"
                      value={URL}
                      onChange={handleChange}
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn "
                    style={{ background: "#441752", color: "#fff" }}
                  >
                    Inspect
                  </button>
                </form>
              </div>
            </Col>
          </Row>
          <div>
            <Row className="justify-content-center">
              <Col
                md={10}
                className="p-1"
                style={{
                  background: "rgba(234,229,244)",
                  borderRadius: ".4rem",
                }}
              >
                {seoData.map((data, index) => (
                  <div
                    key={index}
                    className={`row align-items-center ${
                      index % 2 !== 0 ? "flex-row-reverse" : ""
                    }`}
                  >
                    <div
                      className={`col-md-6 ${
                        hasLoaded ? "unique-zoom-in" : ""
                      }`}
                      style={{ animationDelay: `${index * 0.2}s` }}
                    >
                      <img
                        src={data.image}
                        alt={data.alt}
                        className="img-fluid"
                        style={{ animationDelay: `${index * 0.3}s` }}
                      />
                    </div>
                    <div
                      className={`col-md-6 ${
                        hasLoaded ? "unique-slide-up" : ""
                      }`}
                      style={{ animationDelay: `${index * 0.3}s` }}
                    >
                      <h5
                        style={{ color: "#441752" }}
                        className={hasLoaded ? "unique-fade-in" : ""}
                      >
                        {data.title}
                      </h5>
                      <ul style={{ fontSize: ".9rem", color: "#441752" }}>
                        {data.items.map((item, idx) => (
                          <li key={idx}>{item}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                ))}
              </Col>
            </Row>
          </div>
        </div>
      ) : (
        <div>
          <div className="row">
            <div className="col">
              <h5 className="">Your Domain Information is Ready!</h5>
            </div>
            <div className="col justify-content-end d-flex ">
              <Button
                size="sm"
                className="btn btn-primary"
                onClick={handleGoBack}
              >
                Go Back and Analyze
              </Button>
            </div>
          </div>
          <Domaininfo url={URL} />
        </div>
      )}
    </div>
  );
};

const Domaininfo = ({ url }) => {
  const [seoData, setSeoData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const trimmedUrl = url.trim();
        const parsedUrl = new URL(trimmedUrl);
        const domain = parsedUrl.hostname;

        const response = await fetch(
          `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetDomainAge`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ domainName: domain }),
          }
        );

        const data = await response.json();
        setSeoData(data.data);
      } catch (error) {
        console.error("Error fetching domain data:", error);
      }
    };

    if (url) {
      fetchData();
    }
  }, [url]);

  if (!seoData) {
    return <div>Loading...</div>;
  }

  const {
    domainName,
    createdDate,
    updatedDate,
    expiresDate,
    registrantName,
    registrantOrganization,
    registrantCountry,
    estimatedDomainAge,
  } = seoData;

  const domainAuthority = 8;
  const pageAuthority = 7;
  const daColor = domainAuthority >= 40 ? "success" : "warning";
  const paColor = pageAuthority >= 40 ? "success" : "warning";

  return (
    <div>
      <h4 className="text-center mb-4">Domain Analysis</h4>
      <Row>
        <Col md={6}>
          <Card className="shadow-sm mb-4 p-2">
            <Card.Body className="p-2">
              <Card.Title style={{ color: "#000" }} className="text-center">
                Domain Overview
              </Card.Title>
              <Card.Text>
                <strong>Domain Name:</strong> {domainName}
              </Card.Text>
              <Card.Text>
                <strong>Estimated Domain Age:</strong> {estimatedDomainAge}
              </Card.Text>
              <Card.Text>
                <strong>Created Date:</strong>{" "}
                {new Date(createdDate).toLocaleString()}
              </Card.Text>
              <Card.Text>
                <strong>Updated Date:</strong>{" "}
                {new Date(updatedDate).toLocaleString()}
              </Card.Text>
              <Card.Text>
                <strong>Expires Date:</strong>{" "}
                {new Date(expiresDate).toLocaleString()}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col md={6}>
          <Card className="shadow-sm mb-4">
            <Card.Body className="p-2">
              <Card.Title style={{ color: "#000" }} className="text-center">
                Domain Authority (DA) & Page Authority (PA)
              </Card.Title>
              <Card.Text>
                <strong>Domain Authority:</strong> {domainAuthority}
                <div className="mt-2">
                  <ProgressBar now={domainAuthority} variant={daColor} />
                  <div className="text-center mt-2">
                    {domainAuthority >= 40 ? (
                      <FaCheckCircle color="green" />
                    ) : (
                      <FaExclamationCircle color="orange" />
                    )}
                  </div>
                </div>
              </Card.Text>
              <Card.Text>
                <strong>Page Authority:</strong> {pageAuthority}
                <div className="mt-2">
                  <ProgressBar now={pageAuthority} variant={paColor} />
                  <div className="text-center mt-2">
                    {pageAuthority >= 40 ? (
                      <FaCheckCircle color="green" />
                    ) : (
                      <FaExclamationCircle color="orange" />
                    )}
                  </div>
                </div>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card className="shadow-sm p-2">
            <Card.Body className="p-2">
              <Card.Title style={{ color: "#000" }} className="text-center">
                Registrant Information
              </Card.Title>
              {registrantName || registrantOrganization || registrantCountry ? (
                <>
                  <Card.Text>
                    <strong>Registrant Name:</strong> {registrantName || "N/A"}
                  </Card.Text>
                  <Card.Text>
                    <strong>Registrant Organization:</strong>{" "}
                    {registrantOrganization || "N/A"}
                  </Card.Text>
                  <Card.Text>
                    <strong>Registrant Country:</strong>{" "}
                    {registrantCountry || "N/A"}
                  </Card.Text>
                </>
              ) : (
                <Card.Text>No registrant information available.</Card.Text>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Domainpage;
