import React, { useContext, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Col, Row } from "react-bootstrap";
import Seoanalyzeres from "./Seoanalyzers";
import { Accordion, Card } from "react-bootstrap";
import { RiArrowDropDownLine } from "react-icons/ri";
import { GlobalContext } from "../Context/GlobalState";
function Seoanalyzer() {
  const url = sessionStorage.getItem("selectedURLLabel");
  const { siteurl } = useContext(GlobalContext);
  console.log(url);
  const [URL, setURL] = useState(siteurl);
  const [showResults, setShowResults] = useState(false);
  useEffect(() => {
    setURL(siteurl || "");
  }, [siteurl]);
  const handleChange = (event) => {
    setURL(event.target.value);
  };

  console.log(url);
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Submitted URL:", URL);
    setShowResults(true);
  };

  const handleGoBack = () => {
    setShowResults(false);
    setURL("");
  };
  const faq = [
    {
      pk_faq_id: 1,
      question: "What is an SEO audit?",
      answer:
        "An SEO audit is a thorough evaluation of a website to identify any issues that might be preventing it from ranking well in search engines. It includes checking various aspects of a website, such as the site’s structure, content, backlinks, and performance. The audit helps in understanding the current state of SEO and provides a roadmap for improving the site’s visibility and rankings on search engines. Key areas covered include technical SEO, on-page SEO (content, keywords, meta tags), and off-page SEO (backlinks).",
    },
    {
      pk_faq_id: 2,
      question: "Why is an SEO audit important?",
      answer:
        "An SEO audit is crucial because it helps identify issues that could negatively affect your website’s search engine performance. With search engine algorithms constantly evolving, regular audits ensure that your site stays up-to-date with the latest best practices. It allows you to uncover hidden problems, such as broken links, slow page speed, duplicate content, and mobile usability issues, which could harm your rankings. An audit also helps in improving the overall user experience, which is a key factor for search engine rankings and user retention.",
    },
    {
      pk_faq_id: 3,
      question: "How often should an SEO audit be done?",
      answer:
        "SEO audits should be done regularly to keep the website optimized according to the latest SEO best practices. It is recommended to perform an SEO audit at least once every 6 to 12 months. However, if there are significant changes to the website, such as a redesign, new content, or a change in SEO strategy, an audit should be conducted immediately. Additionally, if you notice a drop in search engine rankings or website traffic, an SEO audit is essential to identify and address any issues.",
    },
    {
      pk_faq_id: 4,
      question: "What are the main components of an SEO audit?",
      answer:
        "An SEO audit typically involves several components to ensure a website is fully optimized for search engines. Key components include: \n\n1. **Technical SEO Audit**: Analyzing site speed, mobile optimization, crawling and indexing issues, structured data, and internal linking. \n2. **On-Page SEO Audit**: Reviewing content quality, keyword optimization, meta tags, header tags, URL structure, and images. \n3. **Backlink Analysis**: Identifying the quality of backlinks, potential toxic links, and opportunities for acquiring high-quality links. \n4. **User Experience (UX) and Mobile Usability**: Ensuring that the site provides a smooth experience across all devices and that pages are easy to navigate. \n5. **Content Audit**: Ensuring the site’s content is valuable, engaging, and targeting the right keywords.",
    },
    {
      pk_faq_id: 5,
      question: "What tools can be used for an SEO audit?",
      answer:
        "There are several powerful tools available to help conduct a comprehensive SEO audit. Some of the most popular tools include: \n\n1. **Google Search Console**: Free tool by Google that helps monitor indexing status, search queries, site performance, and crawl errors. \n2. **SEMrush**: Provides a comprehensive SEO audit tool that checks site health, identifies technical issues, and tracks keyword rankings. \n3. **Ahrefs**: Known for its backlink analysis tool, Ahrefs also offers site audit features to analyze technical SEO factors. \n4. **Moz**: Offers an SEO audit tool that analyzes on-page elements, backlinks, and technical factors. \n5. **Screaming Frog**: A desktop tool that crawls websites and identifies various SEO issues like broken links, missing tags, and duplicate content. \n6. **Ubersuggest**: A free tool offering keyword research and SEO audit features.",
    },
    {
      pk_faq_id: 6,
      question: "What is a technical SEO audit?",
      answer:
        "A technical SEO audit focuses on analyzing and improving the technical aspects of a website that affect search engine crawling and indexing. This includes: \n\n1. **Site Speed**: Ensuring fast load times, as slow sites lead to higher bounce rates and lower rankings. \n2. **Mobile Optimization**: Ensuring that the site is mobile-friendly, as Google prioritizes mobile-first indexing. \n3. **XML Sitemap and Robots.txt**: Checking that these files are correctly set up to guide search engine crawlers. \n4. **Redirects and Broken Links**: Ensuring there are no 404 errors or unnecessary redirects that could hurt SEO. \n5. **Structured Data**: Implementing schema markup to help search engines understand the content of the pages. \n6. **Canonical Tags**: Ensuring proper canonical tags are used to avoid duplicate content issues.",
    },
    {
      pk_faq_id: 7,
      question: "How do you fix common SEO audit issues?",
      answer:
        "To fix common SEO issues found during an audit, here are some solutions: \n\n1. **Broken Links**: Fix or remove any broken links that lead to 404 pages by redirecting them to relevant content. \n2. **Slow Page Load Times**: Optimize images, leverage browser caching, and minify CSS and JavaScript to improve load speed. \n3. **Missing Meta Tags**: Add unique and relevant title tags and meta descriptions for each page. \n4. **Duplicate Content**: Implement canonical tags or rewrite content to avoid penalties for duplicate content. \n5. **Mobile Optimization**: Ensure the website is responsive and provides a good user experience on mobile devices. \n6. **Keyword Optimization**: Update content to target relevant and high-traffic keywords. Use keyword research tools to optimize page content and metadata.",
    },
  ];

  return (
    <div className="m-3 mt-4">
      {!showResults ? (
        <div
        // style={{ height: "50vh" }}
        >
          <div
            className="card mx-3 p-0"
            style={{ background: "rgba(234,229,244)", borderRadius: ".4rem" }}
          >
            <div className="text-center">
              <h4 className="m-3" style={{ color: "#441752" }}>
                SEO Inspector
              </h4>
              <h6 className="mb-3" style={{ color: "#441752" }}>
                Ensure every page is perfectly optimized and get actionable
                insights to
                <br />
                boost your online presence.
              </h6>
              <form
                onSubmit={handleSubmit}
                className="d-flex align-items-center justify-content-center"
              >
                <div
                  className="form-group mb-0 mr-2"
                  style={{ width: "20rem" }}
                >
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Website URL e.g. https://google.com"
                    value={URL}
                    onChange={handleChange}
                  />
                </div>
                <button
                  type="submit"
                  className="btn "
                  style={{ background: "#441752", color: "#fff" }}
                >
                  Inspect
                </button>
              </form>
            </div>
            <div className="seo-roadmap-container mt-3">
              <div className="row text-center seo-roadmap-cards">
                {[
                  {
                    title: "How It Works",
                    content:
                      "Enter your URL, and we’ll analyze your site’s SEO performance, highlighting strengths and weaknesses.",
                  },
                  {
                    title: "Why It’s Important",
                    content:
                      "SEO ensures your website ranks higher in search results, driving traffic and improving visibility.",
                  },
                  {
                    title: "What We Check",
                    content:
                      "We analyze keywords, backlinks, on-page optimization, speed, and mobile-friendliness.",
                  },
                  {
                    title: "Benefits",
                    content:
                      "Improve your website’s ranking, attract organic traffic, and outperform competitors in search results.",
                  },
                ].map((item, index) => (
                  <div className="col-md-3 mb-4" key={index}>
                    <div className="seo-roadmap-card p-3 shadow-sm seo-roadmap-card-animate">
                      <h6 className="seo-roadmap-card-title">{item.title}</h6>
                      <p className="seo-roadmap-card-content">{item.content}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div
            className="card mx-3 p-0 mt-3"
            style={{ background: "rgba(234,229,244)", borderRadius: ".4rem" }}
          >
            <Row>
            <Col md={8}>
              <Accordion>
                {faq.map((item) => (
                  <Card className="m-2 p-2" key={item.pk_faq_id}>
                    <Accordion.Toggle
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderRadius: ".2rem",
                        borderBottom: "1px solid #441752",
                      }}
                      className="m-0 p-2"
                      as={Card.Header}
                      eventKey={item.pk_faq_id}
                    >
                      {item.question}
                      <div>
                        <RiArrowDropDownLine style={{ fontSize: "1.5rem" }} />
                      </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={item.pk_faq_id}>
                      <Card.Body
                        className="p-2"
                        style={{
                          // background: "#FEFAF6",
                          // border: "1px solid #441752",
                          color: "#441752",
                          fontWeight: "600",
                          fontSize: ".8rem",
                          borderTop: "none",
                        }}
                      >
                        {item.answer}
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                ))}
              </Accordion>
            </Col>
            <Col  md={4} className="d-flex align-items-center p-0 m-0 justify-content-center">
              <div>
                <img
                  src={require("../../assets/images/seoimages/da5.svg")}
                  alt="seo audit"
                  className="img-fluid"
                />
              </div>
            </Col></Row>
          </div>
        </div>
      ) : (
        <div>
          <div className="row">
            <div className="col">
              <h5 className="">Your SEO audit report is ready!</h5>
            </div>
            <div className="col justify-content-end d-flex ">
              <Button
                size="sm"
                className="btn btn-primary"
                onClick={handleGoBack}
              >
                Go Back and Analyze
              </Button>
            </div>
          </div>
          <Seoanalyzeres url={URL} />
        </div>
      )}
    </div>
  );
}

export default Seoanalyzer;
