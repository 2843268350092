import React, { useState } from "react";
import { Form, Button, Row, Col, Card } from "react-bootstrap";

export const BookArticleForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    authorName: "",
    authorUrl: "",
    publisherName: "",
    publisherLogo: "",
    datePublished: "",
    dateModified: "",
    headline: "",
    imageUrls: "",
    pageUrl: "",
    description: "",
    isbn: "",
    bookFormat: "https://schema.org/EBook",
    numberOfPages: "",
    offerUrl: "",
    offerPrice: "",
    offerCurrency: "USD",
    availability: "https://schema.org/InStock",
    reviewAuthor: "",
    reviewDate: "",
    reviewBody: "",
    reviewRating: "4.5",
    aggregateRatingValue: "4.6",
    reviewCount: "123"
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const jsonFormat = JSON.stringify({
    "@context": "https://schema.org",
    "@type": ["Book", "Article", "NewsArticle", "BlogPosting"],
    "name": formData.name,
    "author": {
      "@type": "Person",
      "name": formData.authorName,
      "url": formData.authorUrl
    },
    "publisher": {
      "@type": "Organization",
      "name": formData.publisherName,
      "logo": {
        "@type": "ImageObject",
        "url": formData.publisherLogo
      }
    },
    "datePublished": formData.datePublished,
    "dateModified": formData.dateModified,
    "headline": formData.headline,
    "image": formData.imageUrls.split(","),
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": formData.pageUrl
    },
    "description": formData.description,
    "isbn": formData.isbn,
    "bookFormat": formData.bookFormat,
    "numberOfPages": formData.numberOfPages,
    "offers": {
      "@type": "Offer",
      "url": formData.offerUrl,
      "price": formData.offerPrice,
      "priceCurrency": formData.offerCurrency,
      "availability": formData.availability
    },
    "review": {
      "@type": "Review",
      "author": {
        "@type": "Person",
        "name": formData.reviewAuthor
      },
      "datePublished": formData.reviewDate,
      "reviewBody": formData.reviewBody,
      "reviewRating": {
        "@type": "Rating",
        "ratingValue": formData.reviewRating,
        "bestRating": "5"
      }
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": formData.aggregateRatingValue,
      "reviewCount": formData.reviewCount
    }
  }, null, 2); // Format the JSON with indentation for better readability

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      {/* Left Column - Form */}
      <div style={{ width: "48%" }}>
        <Form>
          <h3>Book Details Form</h3>

          <Form.Group controlId="name">
            <Form.Label>Book Title</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              placeholder="Example Book Title"
            />
          </Form.Group>

          <Form.Group controlId="authorName">
            <Form.Label>Author Name</Form.Label>
            <Form.Control
              type="text"
              name="authorName"
              value={formData.authorName}
              onChange={handleInputChange}
              placeholder="Author Name"
            />
          </Form.Group>

          <Form.Group controlId="authorUrl">
            <Form.Label>Author URL</Form.Label>
            <Form.Control
              type="url"
              name="authorUrl"
              value={formData.authorUrl}
              onChange={handleInputChange}
              placeholder="https://example.com/author-profile"
            />
          </Form.Group>

          <Form.Group controlId="publisherName">
            <Form.Label>Publisher Name</Form.Label>
            <Form.Control
              type="text"
              name="publisherName"
              value={formData.publisherName}
              onChange={handleInputChange}
              placeholder="Example Publisher"
            />
          </Form.Group>

          <Form.Group controlId="publisherLogo">
            <Form.Label>Publisher Logo URL</Form.Label>
            <Form.Control
              type="url"
              name="publisherLogo"
              value={formData.publisherLogo}
              onChange={handleInputChange}
              placeholder="https://example.com/logo.png"
            />
          </Form.Group>

          <Form.Group controlId="datePublished">
            <Form.Label>Date Published</Form.Label>
            <Form.Control
              type="date"
              name="datePublished"
              value={formData.datePublished}
              onChange={handleInputChange}
            />
          </Form.Group>

          <Form.Group controlId="dateModified">
            <Form.Label>Date Modified</Form.Label>
            <Form.Control
              type="date"
              name="dateModified"
              value={formData.dateModified}
              onChange={handleInputChange}
            />
          </Form.Group>

          <Form.Group controlId="headline">
            <Form.Label>Headline</Form.Label>
            <Form.Control
              type="text"
              name="headline"
              value={formData.headline}
              onChange={handleInputChange}
              placeholder="Example Headline"
            />
          </Form.Group>

          <Form.Group controlId="imageUrls">
            <Form.Label>Image URLs (comma separated)</Form.Label>
            <Form.Control
              type="text"
              name="imageUrls"
              value={formData.imageUrls}
              onChange={handleInputChange}
              placeholder="https://example.com/image1.jpg, https://example.com/image2.jpg"
            />
          </Form.Group>

          <Form.Group controlId="pageUrl">
            <Form.Label>Page URL</Form.Label>
            <Form.Control
              type="url"
              name="pageUrl"
              value={formData.pageUrl}
              onChange={handleInputChange}
              placeholder="https://example.com/page-url"
            />
          </Form.Group>

          <Form.Group controlId="description">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              placeholder="Description of the book or article"
            />
          </Form.Group>

          <Form.Group controlId="isbn">
            <Form.Label>ISBN</Form.Label>
            <Form.Control
              type="text"
              name="isbn"
              value={formData.isbn}
              onChange={handleInputChange}
              placeholder="978-3-16-148410-0"
            />
          </Form.Group>

          <Form.Group controlId="numberOfPages">
            <Form.Label>Number of Pages</Form.Label>
            <Form.Control
              type="number"
              name="numberOfPages"
              value={formData.numberOfPages}
              onChange={handleInputChange}
            />
          </Form.Group>

          <Form.Group controlId="offerUrl">
            <Form.Label>Offer URL</Form.Label>
            <Form.Control
              type="url"
              name="offerUrl"
              value={formData.offerUrl}
              onChange={handleInputChange}
              placeholder="https://example.com/offer-url"
            />
          </Form.Group>

          <Form.Group controlId="offerPrice">
            <Form.Label>Offer Price</Form.Label>
            <Form.Control
              type="number"
              name="offerPrice"
              value={formData.offerPrice}
              onChange={handleInputChange}
              placeholder="19.99"
            />
          </Form.Group>

          <Form.Group controlId="reviewAuthor">
            <Form.Label>Review Author</Form.Label>
            <Form.Control
              type="text"
              name="reviewAuthor"
              value={formData.reviewAuthor}
              onChange={handleInputChange}
              placeholder="Review Author"
            />
          </Form.Group>

          <Form.Group controlId="reviewDate">
            <Form.Label>Review Date</Form.Label>
            <Form.Control
              type="date"
              name="reviewDate"
              value={formData.reviewDate}
              onChange={handleInputChange}
            />
          </Form.Group>

          <Form.Group controlId="reviewBody">
            <Form.Label>Review Body</Form.Label>
            <Form.Control
              as="textarea"
              name="reviewBody"
              value={formData.reviewBody}
              onChange={handleInputChange}
              placeholder="Review of the book"
            />
          </Form.Group>

          <Form.Group controlId="reviewRating">
            <Form.Label>Review Rating</Form.Label>
            <Form.Control
              type="number"
              name="reviewRating"
              value={formData.reviewRating}
              onChange={handleInputChange}
              min="0"
              max="5"
            />
          </Form.Group>

          <Button variant="primary" type="submit">
            Generate JSON
          </Button>
        </Form>
      </div>

      {/* Right Column - Generated JSON */}
      <div style={{ width: "48%" }}>
        <Card>
          <Card.Body>
            <Card.Title>Generated JSON</Card.Title>
            <pre>{jsonFormat}</pre>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default BookArticleForm;
