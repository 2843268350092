import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import cogoToast from "cogo-toast";
import { BASE_URL_API_TRANXT_UAT_API } from "../Utils/Constant";

const Forgotpassword = () => {
  const [step, setStep] = useState(1);
  const [username, setUsername] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [transactionLoaderState, setTransactionLoaderState] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setTransactionLoaderState(true);

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    let raw = {};
    if (step === 1) {
      raw = {
        us_loginid: username,
        us_password: "string",
        step: "1",
        otp: "string",
        device: "string",
        latitude: "string",
        longitude: "string",
      };
    } else if (step === 2) {
      raw = {
        us_loginid: username,
        us_password: "string",
        step: "2",
        otp,
        device: "string",
        latitude: "string",
        longitude: "string",
      };
    } else if (step === 3) {
      if (newPassword !== confirmPassword) {
        cogoToast.error("Passwords do not match!");
        setTransactionLoaderState(false);
        return;
      }
      raw = {
        us_loginid: username,
        us_password: newPassword,
        step: "3",
        otp,
        device: "string",
        latitude: "string",
        longitude: "string",
      };
    }

    try {
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(raw),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/ForgetPassword`,
        requestOptions
      );

      const data = await res.json();

      if (data.status === "0") {
        cogoToast.success(`${data.data.message}`);
        if (step === 3) {
          setRedirect(true);
        } else {
          setStep(step + 1);
        }
      } else {
        cogoToast.error(data?.msg || "Something went wrong.");
      }
    } catch (error) {
      console.error("Error:", error);
      cogoToast.error("An error occurred. Please try again.");
    }

    setTransactionLoaderState(false);
  };

  if (redirect) {
    return <Redirect to="/user-pages/login-2" />;
  }

  return (
    <div
      className="d-flex align-items-center justify-content-center auth auth-img-bg "
      style={{ height: "100vh", background: "#F4F6FF" }}
    >
      <div className="row flex-grow justify-content-center">
        <div className="col-lg-6 d-flex align-items-center justify-content-center">
          <div
            style={{
              background: "#FBFBFB",
              border: "1px solid #E4E0E1",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}
            className="auth-form-transparent text-left p-3"
          >
            <div className="d-flex justify-content-center mt-2 mb-3">
              <img
                src={require("../../assets/images/seoimages/logo.svg")}
                alt="GoSeo"
                style={{ width: "8rem" }}
              />
            </div>
            <h4 className="text-center text-primary font-weight-bold mb-3">
              Welcome to GoSeo!
            </h4>
            <h6 className="font-weight-light text-center mb-4">
              Let's help you reset your password.
            </h6>
            <form onSubmit={handleSubmit} className="mb-3">
              {step === 1 && (
                <div>
                  <div className="form-group">
                    <label htmlFor="username">Username</label>
                    <input
                      type="text"
                      id="username"
                      className="form-control"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      required
                    />
                  </div>
                </div>
              )}

              {step === 2 && (
                <div>
                  <div className="form-group">
                    <label htmlFor="otp">OTP</label>
                    <input
                      type="text"
                      id="otp"
                      className="form-control"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                      required
                    />
                  </div>
                </div>
              )}

              {step === 3 && (
                <div>
                  <div className="form-group">
                    <label htmlFor="newPassword">New Password</label>
                    <input
                      type="password"
                      id="newPassword"
                      className="form-control"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="confirmPassword">Confirm Password</label>
                    <input
                      type="password"
                      id="confirmPassword"
                      className="form-control"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                    />
                  </div>
                </div>
              )}

              <button
                type="submit"
                className="btn btn-primary btn-block"
                disabled={transactionLoaderState}
              >
                {transactionLoaderState ? "Submitting..." : "Submit"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forgotpassword;
