import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { GlobalContext } from "../Context/GlobalState";
import { BASE_URL_API_TRANXT_UAT_API } from "../Utils/Constant";
import swal from "sweetalert";
import Select from "react-select";
import { AddTaskModal } from "./Modal";
import { Reportingtable } from "./table";
const Socialreporting = () => {
  const [taskdata, settaskdata] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedPlatform, setSelectedPlatform] = useState(null);
  const [selectedDate, setSelectedDate] = useState("");
  const [showModal, setShowModal] = useState(false);
  const { user, setTransactionLoaderState } = useContext(GlobalContext);

  const socialOptions = [
    { value: "instagram", label: "Instagram" },
    { value: "facebook", label: "Facebook" },
    { value: "linkedin", label: "LinkedIn" },
    { value: "youtube", label: "YouTube" },
  ];
  const GetVendorDetails = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          userid: selectedUser.value,
          social: selectedPlatform.value,
          date: selectedDate,
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetInsertedDailyUrl`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0" && data.data) {
        settaskdata(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.error("Error:", error);
      setTransactionLoaderState(false);
    }
  };

  const handleAddTaskSubmit = async (formData) => {
    const { url, social, date } = formData;
    const userid = user?.user_id;

    const requestData = {
      url,
      userid,
      social,
      date,
    };

    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      };

      console.log("Sending data to the server", requestData);
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/InsertDailyUrlData`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        swal("Success", data.result[0].message, "success");
      } else {
        swal("Error", data.msg, "error");
      }

      console.log("Form submission completed.");
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const GetGoseoUsers = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({}),
        redirect: "follow",
      };

      const response = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetGoseoUsers`,
        requestOptions
      );
      const data = await response.json();
      if (data.status === "0") {
        const userOptions = data.data.map((user) => ({
          value: user.id,
          label: user.user_name,
        }));
        setUsers(userOptions);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.error("Error fetching users:", error);
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    GetVendorDetails();
    GetGoseoUsers();
  }, []);
  const customStyles = {
    control: (base) => ({
      ...base,
      backgroundColor: "white",
      color: "black",
      borderColor: "#ced4da",
      boxShadow: "none",
      "&:hover": {
        borderColor: "#86b7fe",
      },
    }),
    singleValue: (base) => ({
      ...base,
      color: "black",
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: "white",
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isFocused ? "#e9ecef" : "white",
      color: "black",
      "&:active": {
        backgroundColor: "#dee2e6",
      },
    }),
  };
  return (
    <>
      <div>
        <div className="row mt-3 mx-1 p-0 mb-2">
          <h4 className="col d-flex align-items-center">Reporting</h4>
          <div className="col d-flex justify-content-end">
            <Button size="sm" onClick={() => setShowModal(true)}>
              Add Task
            </Button>
          </div>
        </div>

        <Row className="mx-md-1 p-0">
          <Col md={3}>
            <Form.Group className="mb-3">
              <Form.Label>Select User</Form.Label>
              <Select
                options={users}
                value={selectedUser}
                onChange={setSelectedUser}
                placeholder="Select User"
                styles={customStyles}
              />
            </Form.Group>
          </Col>

          <Col md={3}>
            <Form.Group className="mb-3">
              <Form.Label>Select Platform</Form.Label>
              <Select
                options={socialOptions}
                value={selectedPlatform}
                onChange={setSelectedPlatform}
                placeholder="Select Platform"
                styles={customStyles}
              />
            </Form.Group>
          </Col>

          <Col md={3}>
            <Form.Group className="mb-3">
              <Form.Label>Select Date</Form.Label>
              <Form.Control
                type="date"
                value={selectedDate}
                onChange={(e) => setSelectedDate(e.target.value)}
              />
            </Form.Group>
          </Col>

          <Col md={3} className="d-flex align-items-center mt-2">
            <div>
              {" "}
              <Button
                style={{
                  backgroundColor: "#2ca01c",
                  border: "none",
                }}
                onClick={GetVendorDetails}
              >
                Search
              </Button>
            </div>
          </Col>
        </Row>

        <div
          className="p-0 mx-md-3"
          style={{
            borderTopLeftRadius: "1rem",
            borderTopRightRadius: "1rem",
          }}
        >
          <Reportingtable data={taskdata} />
        </div>
      </div>

      <AddTaskModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        handleSubmit={handleAddTaskSubmit}
      />
    </>
  );
};

export default Socialreporting;
