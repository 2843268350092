import React, { useState, useEffect, useContext } from "react";
import { Form, Button, Spinner, Col, Row } from "react-bootstrap";
import { AddbacklinksSiteModal } from "./Modal";
import { Backlinkssitetable } from "./Table";
import { BASE_URL_API_TRANXT_UAT_API } from "../Utils/Constant";
import { GlobalContext } from "../Context/GlobalState";
import Select from "react-select";
const Backlinksite = () => {
  const getCurrentFormattedDate = () => {
    const currentDate = new Date();
    return `${currentDate.getFullYear()}-${String(
      currentDate.getMonth() + 1
    ).padStart(2, "0")}-${String(currentDate.getDate()).padStart(2, "0")}`;
  };
  const url = sessionStorage.getItem("selectedURLLabel");
  const [showModal, setShowModal] = useState(false);
  const [sites, setSites] = useState([]);
  const [filteredSites, setFilteredSites] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const { user, setTransactionLoaderState, siteid,siteurl,Productname } = useContext(GlobalContext);
  console.log(user.user_type);
  const [fromDate, setFromDate] = useState(getCurrentFormattedDate());
  const [toDate, setToDate] = useState(getCurrentFormattedDate());
  
  const handleFromDateChange = (e) => setFromDate(e.target.value);
  const handleToDateChange = (e) => setToDate(e.target.value);

  const GetBackLinksCredentials = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          userid: selectedUser?.value || user.user_id,
          from_date: fromDate || getCurrentFormattedDate(),
          to_date: toDate || getCurrentFormattedDate(),
          webid:siteid
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetBackLinksCredentials`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        const sitesData = data.data.map((item) => ({
          id: item.id,
          siteName: item.website_url,
          email: item.email,
          username: item.username,
          password: item.password,
          domainAuthority: item.domain_authority,
          pageAuthority: item.page_authority,
          createdon: new Date(item.created_on).toLocaleDateString(),
          updatedon: new Date(item.updated_on).toLocaleDateString(),
          count: item.total_url,
          userid: item.userid,
        }));
        setSites(sitesData);
        setFilteredSites(sitesData);
      } else {
        setSites([]);
        setFilteredSites([]);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  const GetGoseoUsers = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({}),
        redirect: "follow",
      };

      const response = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetGoseoUsers`,
        requestOptions
      );
      const data = await response.json();
      if (data.status === "0") {
        const userOptions = data.data.map((user) => ({
          value: user.id,
          label: user.user_name,
        }));
        setUsers(userOptions);
      }
    } catch (error) {
      console.log("Error fetching users:", error);
    }
  };

  useEffect(() => {
    GetGoseoUsers();
    
    const storedSites = sessionStorage.getItem("backlinksSites");
    
    if (storedSites) {
      // Parse and set the sites if they exist in sessionStorage
      const parsedSites = JSON.parse(storedSites);
      setSites(parsedSites);
      setFilteredSites(parsedSites);
      setLoading(false);
    } else if (siteid) {
      // Call GetBackLinksCredentials only if siteid is present and no storedSites
      GetBackLinksCredentials();
    }
  }, [siteid]);

  const handleSearch = (query) => {
    setSearchQuery(query);
    const filtered = sites.filter((site) => {
      return (
        site.siteName.toLowerCase().includes(query.toLowerCase()) ||
        site.email.toLowerCase().includes(query.toLowerCase()) ||
        site.username.toLowerCase().includes(query.toLowerCase()) ||
        site.password.toLowerCase().includes(query.toLowerCase()) ||
        site.domainAuthority.toString().includes(query) ||
        site.pageAuthority.toString().includes(query)
      );
    });
    setFilteredSites(filtered);
  };

  const handleAddSiteClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleUserChange = (selectedOption) => {
    setSelectedUser(selectedOption);
    if (selectedOption) {
      const filteredByUser = sites.filter(
        (site) => site.userid === selectedOption.value
      );
      setFilteredSites(filteredByUser);
    } else {
      setFilteredSites(sites);
    }
  };
  const customStyles = {
    control: (base) => ({
      ...base,
      backgroundColor: "white",
      color: "black",
      borderColor: "#ced4da",
      boxShadow: "none",
      "&:hover": {
        borderColor: "#86b7fe",
      },
    }),
    singleValue: (base) => ({
      ...base,
      color: "black",
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: "white",
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isFocused ? "#e9ecef" : "white",
      color: "black",
      "&:active": {
        backgroundColor: "#dee2e6",
      },
    }),
  };
  return (
    <>
      <div className="mx-3 mt-3 mb-0">
        <div className="row">
          <div className="col">
            <h5>
              Backlinks Sites of <u>{url}</u>
            </h5>
          </div>
          <div className="col mr-3 d-flex justify-content-end">
            <Form.Control
              type="text"
              placeholder="Search..."
              style={{ width: "50%" }}
              value={searchQuery}
              className="ml-2"
              onChange={(e) => handleSearch(e.target.value)}
            />
            <Button
              variant="info"
              className="ml-2"
              size="sm"
              onClick={handleAddSiteClick}
            >
              Add Site
            </Button>
          </div>
        </div>
        <Form>
          <Row className="">
            {user.user_type === "A" && (
              <Col md={3}>
                <Form.Group className="">
                  <Form.Label>Select User</Form.Label>
                  <Select
                    options={users}
                    value={selectedUser}
                    onChange={handleUserChange}
                    placeholder="Select User"
                    styles={customStyles}
                  />
                </Form.Group>
              </Col>
            )}
            <Col md={3}>
              <Form.Group controlId="fromDate">
                <Form.Label>From Date</Form.Label>
                <Form.Control
                  type="date"
                  value={fromDate}
                  onChange={handleFromDateChange}
                />
              </Form.Group>
            </Col>

            <Col md={3}>
              <Form.Group controlId="toDate">
                <Form.Label>To Date</Form.Label>
                <Form.Control
                  type="date"
                  value={toDate}
                  onChange={handleToDateChange}
                />
              </Form.Group>
            </Col>

            <Col md={3} className="d-flex align-items-center mt-2">
              <Button
                style={{
                  backgroundColor: "#2ca01c",
                  border: "none",
                  width: "100%",
                }}
                onClick={GetBackLinksCredentials}
              >
                Search
              </Button>
            </Col>
          </Row>
        </Form>
      </div>

      <Backlinkssitetable
        sites={filteredSites}
        GetBackLinksCredentials={GetBackLinksCredentials}
      />

      <AddbacklinksSiteModal
        show={showModal}
        handleClose={handleCloseModal}
        GetBackLinksCredentials={GetBackLinksCredentials}
      />
    </>
  );
};

export default Backlinksite;
