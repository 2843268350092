import React from "react";
import Lottie from 'lottie-react';
import TransactionLoaderGif from '../../assets/animation/loader.json';
import LoadingOverlay from "react-loading-overlay";
const TransactionLoader = ({ children, isActive, text }) => {
  return (
    <LoadingOverlay
    active={isActive} 
    spinner={<Lottie style={{height:'200px'}} animationData={TransactionLoaderGif}/>} 
    text={text}>
      {children}
    </LoadingOverlay>
  );
};

export default TransactionLoader;