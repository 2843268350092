import React, { useContext, useState } from "react";
import { GlobalContext } from "../Context/GlobalState";
import { Nav, Tab, Row, Col, Tabs, Container } from "react-bootstrap";
import Reportingdashboard from "./Reportingdashboard";
import Reporting from "./Report";

const Smodashboard = () => {
  const { user } = useContext(GlobalContext);
  console.log(user.user_type);

  const [key, setKey] = useState("dashboard");
  const tabStyle = {
    fontSize: "1.25rem",
    textAlign: "center",
    padding: "10px",
  };
  const activeTabStyle = {
    ...tabStyle,
    fontWeight: "bold",
  };
  return (
    <>
      <div className="mt-4 ">
        <Tabs
          id="social-media-tabs"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className=""
          fill
        >
          <Tab className=""
            eventKey="dashboard"
            title={
              <span style={key === "dashboard" ? activeTabStyle : tabStyle}>
                Dashboard
              </span>
            }
          >
            <div className="tab-content m-0 p-0">
              <Reportingdashboard />
            </div>
          </Tab>
          <Tab
            eventKey="Reporting"
            title={
              <span style={key === "Reporting" ? activeTabStyle : tabStyle}>
                Reporting
              </span>
            }
          >
            <div className="tab-content m-0 p-0">
              <Reporting />
            </div>
          </Tab>
        </Tabs>
      </div>
    </>
  );
};

export default Smodashboard;
