import React, { useContext, useEffect, useState } from "react";
import { Form, Button, Table } from "react-bootstrap";
import { IoIosRemoveCircle, IoIosAddCircle } from "react-icons/io";
import { IoArrowBackCircle } from "react-icons/io5";
import { Link } from "react-router-dom";
import Select from "react-select";
import swal from "sweetalert";
import { BASE_URL_API_TRANXT_UAT_API } from "../Utils/Constant";
import { GlobalContext } from "../Context/GlobalState";

const Addsitemapblog = () => {
    const [formDataList, setFormDataList] = useState([
      { url: "", priority: "", frequency: "", lastmod: "" },
    ]);
  
    const [stimapdata, setstimapdata] = useState([]);
    const { setTransactionLoaderState,siteurl,siteid } = useContext(GlobalContext);
  
    const [searchQuery, setSearchQuery] = useState("");
  
    const handleChange = (index, field, value) => {
      const updatedFormDataList = formDataList.map((formData, i) =>
        index === i ? { ...formData, [field]: value } : formData
      );
      setFormDataList(updatedFormDataList);
    };
  
    const handleAddRow = () => {
      setFormDataList([
        ...formDataList,
        { url: "", priority: "", frequency: "", lastmod: "" },
      ]);
    };
  
    const handleRemoveRow = (index) => {
      setFormDataList(formDataList.filter((_, i) => i !== index));
    };
    const [selectedLocation, setSelectedLocation] = useState("");
    const extract = async () => {
      setTransactionLoaderState(true);
      const trimmedUrl = siteurl.trim();
  
      try {
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ webid: siteid, url: trimmedUrl }),
        };
  
        const res = await fetch(
          `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/extract`,
          requestOptions
        );
        const data = await res.json();
  
        if (data.status === "0") {
          setstimapdata(data.sitemaps);
          const pageLocation = data.sitemaps.find((sitemap) =>
            sitemap.location.includes("sitemap_blogs.xml")
          )?.location;
          if (pageLocation) {
            setSelectedLocation(pageLocation);
          }
        } else {
          setstimapdata([]);
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setTransactionLoaderState(false);
      }
    };
  
    useEffect(() => {
      extract();
    }, []);
    const handleSubmit = async (e) => {
      e.preventDefault();
  
      try {
        const urls = formDataList.map((formData) => ({
          loc: formData.url,
          lastmod: formData.lastmod,
          changefreq: formData.frequency,
          priority: parseFloat(formData.priority),
        }));
  
        const requestBody = {
          action: "generate_pages_sitemap",
          base_url: siteurl.trim(),
          urls: urls,
        };
  
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        };
  
        console.log("Sending data to the server:", requestBody);
        const res = await fetch(
          `https://goseo.in/seo_manager.php`,
          requestOptions
        );
        const data = await res.json();
  
        if (data.status === 200) {
          swal("Success", data.message, "success");
          // setFormDataList([
          //   { page: "", priority: "", frequency: "", lastmod: "" },
          // ]);
          Getsitemapdata();
        } else {
          swal("Error", data.message, "error");
        }
        console.log("Form submission completed.");
      } catch (error) {
        console.log("Error:", error);
        swal("Error", "Failed to submit data. Please try again later.", "error");
      }
    };
  
    const Getsitemapdata = async () => {
      setTransactionLoaderState(true);
      try {
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            url: selectedLocation,
            company_id: siteid,
          }),
        };
  
        const res = await fetch(
          `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetAllsitemaps`,
          requestOptions
        );
        const data = await res.json();
  
        if (data.status === "0") {
          const formattedData = data.data.map((item) => {
            const formattedDate = item.last_modified
              ? item.last_modified.split("T")[0]
              : "";
            return {
              url: item.page_url,
              priority: item.priority,
              frequency: item.frequency,
              lastmod: formattedDate,
            };
          });
          setFormDataList(formattedData);
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setTransactionLoaderState(false);
      }
    };
  
    const options = [
      { value: "monthly", label: "Monthly" },
      { value: "yearly", label: "Yearly" },
      { value: "quarterly", label: "Quarterly" },
    ];
  
    const customStyles = {
      control: (provided) => ({
        ...provided,
        backgroundColor: "white",
        borderColor: "#ced4da",
        minHeight: "38px",
      }),
      indicatorSeparator: () => ({
        display: "none",
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        color: "#495057",
      }),
      menu: (provided) => ({
        ...provided,
        backgroundColor: "white",
        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
      }),
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? "#007bff" : "white",
        color: state.isSelected ? "white" : "#495057",
        "&:hover": {
          backgroundColor: "#007bff",
          color: "white",
        },
      }),
      placeholder: (provided) => ({
        ...provided,
        color: "#6c757d",
      }),
    };
  
    useEffect(() => {
      Getsitemapdata();
    }, [selectedLocation]);
  
    const filteredData = formDataList.filter((item) => {
      const query = searchQuery.toLowerCase();
      return (
        item.url.toLowerCase().includes(query) ||
        item.priority.toLowerCase().includes(query) ||
        item.frequency.toLowerCase().includes(query) ||
        item.lastmod.toLowerCase().includes(query)
      );
    });
  return (
    <div className="m-3">
   <div className="row align-items-center mb-3">
        <div className="col-md-8">
          <h5>
            Add Blogs Sitemap of <u>{siteurl}</u>
          </h5>
        </div>
        <div className="col-md-4 d-flex justify-content-end align-items-center">
          <Form.Control
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            style={{ maxWidth: "300px", marginRight: "10px" }}
          />
          <Link to="/Go_seo/Sitemap">
            <IoArrowBackCircle size={30} />
          </Link>
        </div>
      </div>

      <Form onSubmit={handleSubmit}>
        <Table bordered>
          <thead>
            <tr>
              <th style={{ width: "45%" }}>URL</th>
              <th style={{ width: "10%" }}>Priority</th>
              <th style={{ width: "25%" }}>Frequency</th>
              <th style={{ width: "20%" }}>Last Modified</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((formData, index) => (
              <tr key={index} style={{border:"none"}}>
                <td className="p-1"  >
                  <Form.Control
                    type="text"
                    name="url"
                    value={formData.url}
                    onChange={(e) => handleChange(index, "url", e.target.value)}
                    placeholder="Enter URL"
                   
                  />
                </td>
                <td>
                  <Form.Control
                    type="text"
                    name="priority"
                    value={formData.priority}
                    onChange={(e) =>
                      handleChange(index, "priority", e.target.value)
                    }
                    placeholder="Enter Priority"
                  />
                </td>
                <td>
                  <Select
                    options={options}
                    value={options.find(
                      (option) => option.value === formData.frequency
                    )}
                    onChange={(selectedOption) =>
                      handleChange(index, "frequency", selectedOption.value)
                    }
                    styles={customStyles}
                  />
                </td>
                <td>
                  <Form.Control
                    type="date"
                    name="lastmod"
                    value={formData.lastmod}
                    onChange={(e) =>
                      handleChange(index, "lastmod", e.target.value)
                    }
                    placeholder="Enter Last Modified Date"
                  />
                </td>
                <td>
                  <IoIosRemoveCircle
                    onClick={() => handleRemoveRow(index)}
                    style={{
                      cursor: "pointer",
                      fontSize: "24px",
                      color: "red",
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <div className="d-flex ">
        
            <IoIosAddCircle style={{color:"green"}} size={24}  onClick={handleAddRow}/> 
       &nbsp;
          <Button size="sm" variant="primary" type="submit" className="mb-3">
            Save
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default Addsitemapblog;
