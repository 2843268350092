import React, { useEffect, useState } from "react";
import {
  Form,
  Row,
  Col,
  Button,
  Card,
  Container,
  InputGroup,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
export const Schemacreation = () => {
  const featureId = sessionStorage.getItem("featureId");
  console.log(featureId);
  return (
    <div className="container mt-4">
      <div className=" d-flex justify-content-end">
        <Link to="/Go_seo/Structure_data">
          <Button size="sm" className="" variant="info">
            Back
          </Button>
        </Link>
      </div>
      <div>
        {featureId === "1" ? (
          <>
            <Article />
          </>
        ) : featureId === "2" ? (
          <>
            <Book />
          </>
        ) : featureId === "3" ? (
          <>
            <BreadcrumbForm />
          </>
        ) : featureId === "4" ? (
          <>
            <CarouselForm />
          </>
        ) : featureId === "5" ? (
          <>
            <CourseForm />
          </>
        ) : featureId === "6" ? (
          <>
            <Courselist />
          </>
        ) : featureId === "7" ? (
          <>
            <DatasetForm />
          </>
        ) : featureId === "8" ? (
          <>
            <DiscussionForumPostingForm />
          </>
        ) : featureId === "9" ? (
          <>
            <EducationQuizForm />
          </>
        ) : featureId === "10" ? (
          <>
            <EmployerAggregateRatingForm />
          </>
        ) : featureId === "11" ? (
          <>
            <OccupationForm />
          </>
        ) : featureId === "12" ? (
          <>
            <EventForm />
          </>
        ) : featureId === "13" ? (
          <>
            <ClaimReviewForm />
          </>
        ) : (
          <p>Feature ID not recognized</p>
        )}
      </div>
    </div>
  );
};

export const Article = () => {
  const featureId = sessionStorage.getItem("featureId");
  console.log(featureId);
  const [schemaData, setSchemaData] = useState({
    "@context": "https://schema.org",
    "@type": ["Article", "NewsArticle", "BlogPosting"],
    headline: "",
    image: ["", "", ""],
    datePublished: "",
    dateModified: "",
    author: [
      { "@type": "Person", name: "", url: "" },
      { "@type": "Person", name: "", url: "" },
    ],
    publisher: {
      "@type": "Organization",
      name: "",
      logo: { "@type": "ImageObject", url: "" },
    },
    mainEntityOfPage: { "@type": "WebPage", "@id": "" },
    description: "",
  });

  const handleChange = (e, path) => {
    const keys = path.split(".");
    const updatedData = { ...schemaData };

    let current = updatedData;
    keys.forEach((key, index) => {
      if (index === keys.length - 1) {
        current[key] = e.target.value;
      } else {
        current = current[key];
      }
    });

    setSchemaData(updatedData);
  };
  const placeholders = [
    "https://example.com/photos/1x1/photo.jpg",
    "https://example.com/photos/4x3/photo.jpg",
    "https://example.com/photos/16x9/photo.jpg",
  ];

  return (
    <div>
      <Row>
        <Col md={6}>
          <h5>Create Schema</h5>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Headline</Form.Label>
              <Form.Control
                type="text"
                value={schemaData.headline}
                onChange={(e) => handleChange(e, "headline")}
                placeholder="Enter headline"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Image URLs</Form.Label>
              {schemaData.image.map((img, index) => (
                <Form.Control
                  key={index}
                  type="text"
                  className="mb-2"
                  value={img}
                  onChange={(e) => handleChange(e, `image.${index}`)}
                  placeholder={placeholders[index] || `Image URL ${index + 1}`}
                />
              ))}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Date Published</Form.Label>
              <Form.Control
                type="datetime-local"
                value={schemaData.datePublished}
                onChange={(e) => handleChange(e, "datePublished")}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Date Modified</Form.Label>
              <Form.Control
                type="datetime-local"
                value={schemaData.dateModified}
                onChange={(e) => handleChange(e, "dateModified")}
              />
            </Form.Group>

            <h4>Authors</h4>
            {schemaData.author.map((author, index) => (
              <Row key={index}>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={author.name}
                      onChange={(e) => handleChange(e, `author.${index}.name`)}
                      placeholder="Author Name"
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>URL</Form.Label>
                    <Form.Control
                      type="text"
                      value={author.url}
                      onChange={(e) => handleChange(e, `author.${index}.url`)}
                      placeholder="Author URL"
                    />
                  </Form.Group>
                </Col>
              </Row>
            ))}

            <Form.Group className="mb-3">
              <Form.Label>Publisher Name</Form.Label>
              <Form.Control
                type="text"
                value={schemaData.publisher.name}
                onChange={(e) => handleChange(e, "publisher.name")}
                placeholder="Publisher Name"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Publisher Logo URL</Form.Label>
              <Form.Control
                type="text"
                value={schemaData.publisher.logo.url}
                onChange={(e) => handleChange(e, "publisher.logo.url")}
                placeholder="Logo URL"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Main Entity Page URL</Form.Label>
              <Form.Control
                type="text"
                value={schemaData.mainEntityOfPage["@id"]}
                onChange={(e) => handleChange(e, "mainEntityOfPage.@id")}
                placeholder="Page URL"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={schemaData.description}
                onChange={(e) => handleChange(e, "description")}
                placeholder="Enter a brief description"
              />
            </Form.Group>

            <Button variant="primary">Submit</Button>
          </Form>
        </Col>

        <Col md={6}>
          <h5>JSON Preview</h5>
          <pre
            style={{
              background: "#f8f8f8",
              padding: "10px",
              borderRadius: "5px",
              overflowX: "auto",
              height: "600px",
            }}
          >
            {JSON.stringify(schemaData, null, 2)}
          </pre>
        </Col>
      </Row>
    </div>
  );
};

export const Book = () => {
  const featureId = sessionStorage.getItem("featureId");
  console.log(featureId);
  const [formData, setFormData] = useState({
    name: "",
    authorName: "",
    authorUrl: "",
    publisherName: "",
    publisherLogo: "",
    datePublished: "",
    dateModified: "",
    headline: "",
    imageUrls: "",
    pageUrl: "",
    description: "",
    isbn: "",
    bookFormat: "https://schema.org/EBook",
    numberOfPages: "",
    offerUrl: "",
    offerPrice: "",
    offerCurrency: "USD",
    availability: "https://schema.org/InStock",
    reviewAuthor: "",
    reviewDate: "",
    reviewBody: "",
    reviewRating: "4.5",
    aggregateRatingValue: "4.6",
    reviewCount: "123",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const jsonFormat = JSON.stringify(
    {
      "@context": "https://schema.org",
      "@type": ["Book", "Article", "NewsArticle", "BlogPosting"],
      name: formData.name,
      author: {
        "@type": "Person",
        name: formData.authorName,
        url: formData.authorUrl,
      },
      publisher: {
        "@type": "Organization",
        name: formData.publisherName,
        logo: {
          "@type": "ImageObject",
          url: formData.publisherLogo,
        },
      },
      datePublished: formData.datePublished,
      dateModified: formData.dateModified,
      headline: formData.headline,
      image: formData.imageUrls.split(","),
      mainEntityOfPage: {
        "@type": "WebPage",
        "@id": formData.pageUrl,
      },
      description: formData.description,
      isbn: formData.isbn,
      bookFormat: formData.bookFormat,
      numberOfPages: formData.numberOfPages,
      offers: {
        "@type": "Offer",
        url: formData.offerUrl,
        price: formData.offerPrice,
        priceCurrency: formData.offerCurrency,
        availability: formData.availability,
      },
      review: {
        "@type": "Review",
        author: {
          "@type": "Person",
          name: formData.reviewAuthor,
        },
        datePublished: formData.reviewDate,
        reviewBody: formData.reviewBody,
        reviewRating: {
          "@type": "Rating",
          ratingValue: formData.reviewRating,
          bestRating: "5",
        },
      },
      aggregateRating: {
        "@type": "AggregateRating",
        ratingValue: formData.aggregateRatingValue,
        reviewCount: formData.reviewCount,
      },
    },
    null,
    2
  );

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <Col md={6}>
        <h5>Book Details Form</h5>

        <Form>
          <Form.Group controlId="name">
            <Form.Label>Book Title</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              placeholder="Example Book Title"
            />
          </Form.Group>

          <Form.Group controlId="authorName">
            <Form.Label>Author Name</Form.Label>
            <Form.Control
              type="text"
              name="authorName"
              value={formData.authorName}
              onChange={handleInputChange}
              placeholder="Author Name"
            />
          </Form.Group>

          <Form.Group controlId="authorUrl">
            <Form.Label>Author URL</Form.Label>
            <Form.Control
              type="url"
              name="authorUrl"
              value={formData.authorUrl}
              onChange={handleInputChange}
              placeholder="https://example.com/author-profile"
            />
          </Form.Group>

          <Form.Group controlId="publisherName">
            <Form.Label>Publisher Name</Form.Label>
            <Form.Control
              type="text"
              name="publisherName"
              value={formData.publisherName}
              onChange={handleInputChange}
              placeholder="Example Publisher"
            />
          </Form.Group>

          <Form.Group controlId="publisherLogo">
            <Form.Label>Publisher Logo URL</Form.Label>
            <Form.Control
              type="url"
              name="publisherLogo"
              value={formData.publisherLogo}
              onChange={handleInputChange}
              placeholder="https://example.com/logo.png"
            />
          </Form.Group>

          <Form.Group controlId="datePublished">
            <Form.Label>Date Published</Form.Label>
            <Form.Control
              type="date"
              name="datePublished"
              value={formData.datePublished}
              onChange={handleInputChange}
            />
          </Form.Group>

          <Form.Group controlId="dateModified">
            <Form.Label>Date Modified</Form.Label>
            <Form.Control
              type="date"
              name="dateModified"
              value={formData.dateModified}
              onChange={handleInputChange}
            />
          </Form.Group>

          <Form.Group controlId="headline">
            <Form.Label>Headline</Form.Label>
            <Form.Control
              type="text"
              name="headline"
              value={formData.headline}
              onChange={handleInputChange}
              placeholder="Example Headline"
            />
          </Form.Group>

          <Form.Group controlId="imageUrls">
            <Form.Label>Image URLs (comma separated)</Form.Label>
            <Form.Control
              type="text"
              name="imageUrls"
              value={formData.imageUrls}
              onChange={handleInputChange}
              placeholder="https://example.com/image1.jpg, https://example.com/image2.jpg"
            />
          </Form.Group>

          <Form.Group controlId="pageUrl">
            <Form.Label>Page URL</Form.Label>
            <Form.Control
              type="url"
              name="pageUrl"
              value={formData.pageUrl}
              onChange={handleInputChange}
              placeholder="https://example.com/page-url"
            />
          </Form.Group>

          <Form.Group controlId="description">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              placeholder="Description of the book or article"
            />
          </Form.Group>

          <Form.Group controlId="isbn">
            <Form.Label>ISBN</Form.Label>
            <Form.Control
              type="text"
              name="isbn"
              value={formData.isbn}
              onChange={handleInputChange}
              placeholder="978-3-16-148410-0"
            />
          </Form.Group>

          <Form.Group controlId="numberOfPages">
            <Form.Label>Number of Pages</Form.Label>
            <Form.Control
              type="number"
              name="numberOfPages"
              value={formData.numberOfPages}
              onChange={handleInputChange}
            />
          </Form.Group>

          <Form.Group controlId="offerUrl">
            <Form.Label>Offer URL</Form.Label>
            <Form.Control
              type="url"
              name="offerUrl"
              value={formData.offerUrl}
              onChange={handleInputChange}
              placeholder="https://example.com/offer-url"
            />
          </Form.Group>

          <Form.Group controlId="offerPrice">
            <Form.Label>Offer Price</Form.Label>
            <Form.Control
              type="number"
              name="offerPrice"
              value={formData.offerPrice}
              onChange={handleInputChange}
              placeholder="19.99"
            />
          </Form.Group>

          <Form.Group controlId="reviewAuthor">
            <Form.Label>Review Author</Form.Label>
            <Form.Control
              type="text"
              name="reviewAuthor"
              value={formData.reviewAuthor}
              onChange={handleInputChange}
              placeholder="Review Author"
            />
          </Form.Group>

          <Form.Group controlId="reviewDate">
            <Form.Label>Review Date</Form.Label>
            <Form.Control
              type="date"
              name="reviewDate"
              value={formData.reviewDate}
              onChange={handleInputChange}
            />
          </Form.Group>

          <Form.Group controlId="reviewBody">
            <Form.Label>Review Body</Form.Label>
            <Form.Control
              as="textarea"
              name="reviewBody"
              value={formData.reviewBody}
              onChange={handleInputChange}
              placeholder="Review of the book"
            />
          </Form.Group>

          <Form.Group controlId="reviewRating">
            <Form.Label>Review Rating</Form.Label>
            <Form.Control
              type="number"
              name="reviewRating"
              value={formData.reviewRating}
              onChange={handleInputChange}
              min="0"
              max="5"
            />
          </Form.Group>

          <Button variant="primary" type="submit">
            Generate JSON
          </Button>
        </Form>
      </Col>

      <Col md={6}>
        <h5>JSON Preview</h5>
        <pre>{jsonFormat}</pre>
      </Col>
    </div>
  );
};

export const BreadcrumbForm = () => {
  const [breadcrumbTrails, setBreadcrumbTrails] = useState([
    {
      items: [{ name: "", item: "", position: 1 }],
    },
  ]);
  const updateItem = (trailIndex, itemIndex, field, value) => {
    const updatedTrails = [...breadcrumbTrails];
    updatedTrails[trailIndex].items[itemIndex][field] = value;
    setBreadcrumbTrails(updatedTrails);
  };
  const addTrail = () => {
    setBreadcrumbTrails([
      ...breadcrumbTrails,
      { items: [{ name: "", item: "", position: 1 }] },
    ]);
  };

  const addItem = (trailIndex) => {
    const updatedTrails = [...breadcrumbTrails];
    updatedTrails[trailIndex].items.push({
      name: "",
      item: "",
      position: updatedTrails[trailIndex].items.length + 1,
    });
    setBreadcrumbTrails(updatedTrails);
  };

  const removeItem = (trailIndex, itemIndex) => {
    const updatedTrails = [...breadcrumbTrails];
    updatedTrails[trailIndex].items.splice(itemIndex, 1);
    updatedTrails[trailIndex].items.forEach((item, index) => {
      item.position = index + 1;
    });
    setBreadcrumbTrails(updatedTrails);
  };

  const generateJsonLD = () => {
    return JSON.stringify(
      breadcrumbTrails.map((trail) => ({
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        itemListElement: trail.items.map((item) => ({
          "@type": "ListItem",
          position: item.position,
          name: item.name,
          ...(item.item && { item: item.item }),
        })),
      })),
      null,
      2
    );
  };

  return (
    <div>
      <Row>
        <Col md={6}>
          <h5 className="mb-4">Breadcrumb Trails</h5>
          {breadcrumbTrails.map((trail, trailIndex) => (
            <div className="mb-2" key={trailIndex}>
              <div>
                <h6>Breadcrumb Trail {trailIndex + 1}</h6>
                {trail.items.map((item, itemIndex) => (
                  <InputGroup className="mb-3" key={itemIndex}>
                    <Form.Control
                      placeholder="Name"
                      value={item.name}
                      onChange={(e) =>
                        updateItem(
                          trailIndex,
                          itemIndex,
                          "name",
                          e.target.value
                        )
                      }
                    />
                    <Form.Control
                      placeholder="Item (URL)"
                      value={item.item}
                      onChange={(e) =>
                        updateItem(
                          trailIndex,
                          itemIndex,
                          "item",
                          e.target.value
                        )
                      }
                    />
                    <Button
                      variant="danger"
                      onClick={() => removeItem(trailIndex, itemIndex)}
                    >
                      Remove
                    </Button>
                  </InputGroup>
                ))}
                <Button
                  variant="outline-primary"
                  onClick={() => addItem(trailIndex)}
                  className="mt-1"
                >
                  Add Item
                </Button>
              </div>
            </div>
          ))}
          <Button variant="success" onClick={addTrail}>
            Add New Trail
          </Button>
        </Col>

        <Col md={6}>
          <h5 className="mb-4">Generated JSON-LD</h5>
          <div>
            <pre style={{ background: "#f8f9fa", padding: "10px" }}>
              {generateJsonLD()}
            </pre>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export const CarouselForm = () => {
  const [carouselItems, setCarouselItems] = useState([
    { position: 1, url: "", title: "", description: "" },
  ]);

  const updateItem = (itemIndex, field, value) => {
    const updatedItems = [...carouselItems];
    updatedItems[itemIndex][field] = value;
    setCarouselItems(updatedItems);
  };

  const addItem = () => {
    setCarouselItems([
      ...carouselItems,
      {
        position: carouselItems.length + 1,
        url: "",
        title: "",
        description: "",
      },
    ]);
  };

  const removeItem = (itemIndex) => {
    const updatedItems = [...carouselItems];
    updatedItems.splice(itemIndex, 1);
    updatedItems.forEach((item, index) => {
      item.position = index + 1;
    });
    setCarouselItems(updatedItems);
  };

  const generateJsonLD = () => {
    return JSON.stringify(
      {
        "@context": "https://schema.org",
        "@type": "ItemList",
        itemListElement: carouselItems.map((item) => ({
          "@type": "ListItem",
          position: item.position,
          url: item.url,
          name: item.title,
          description: item.description,
        })),
      },
      null,
      2
    );
  };

  return (
    <div>
      <Row>
        <Col md={6}>
          <h5 className="mb-4">Carousel Items</h5>
          {carouselItems.map((item, itemIndex) => (
            <div key={itemIndex} className="mb-4">
              <h6>Carousel Item {itemIndex + 1}</h6>
              <InputGroup className="mb-3">
                <Form.Control
                  placeholder="Position"
                  value={item.position}
                  onChange={(e) =>
                    updateItem(itemIndex, "position", e.target.value)
                  }
                  disabled
                />
              </InputGroup>
              <InputGroup className="mb-3">
                <Form.Control
                  placeholder="URL"
                  value={item.url}
                  onChange={(e) => updateItem(itemIndex, "url", e.target.value)}
                />
              </InputGroup>
              <InputGroup className="mb-3">
                <Form.Control
                  placeholder="Title"
                  value={item.title}
                  onChange={(e) =>
                    updateItem(itemIndex, "title", e.target.value)
                  }
                />
              </InputGroup>
              <InputGroup className="mb-3">
                <Form.Control
                  as="textarea"
                  placeholder="Description"
                  value={item.description}
                  onChange={(e) =>
                    updateItem(itemIndex, "description", e.target.value)
                  }
                />
              </InputGroup>
              <Button variant="danger" onClick={() => removeItem(itemIndex)}>
                Remove Item
              </Button>
            </div>
          ))}
          <Button variant="outline-primary" onClick={addItem} className="mt-3">
            Add New Item
          </Button>
        </Col>

        <Col md={6}>
          <h5 className="mb-4">Generated JSON-LD</h5>
          <div style={{ background: "#f8f9fa", padding: "10px" }}>
            <pre>{generateJsonLD()}</pre>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export const CourseForm = () => {
  const [courseData, setCourseData] = useState({
    name: "",
    url: "",
    description: "",
    providerName: "",
    providerUrl: "",
    educationalCredential: "",
    courseMode: "",
    startDate: "",
    endDate: "",
    instructorName: "",
    instructorUrl: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCourseData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const generateJsonLD = () => {
    return JSON.stringify(
      {
        "@context": "https://schema.org",
        "@type": "Course",
        name: courseData.name,
        url: courseData.url,
        description: courseData.description,
        provider: {
          "@type": "Organization",
          name: courseData.providerName,
          url: courseData.providerUrl,
        },
        educationalCredentialAwarded: courseData.educationalCredential,
        courseMode: courseData.courseMode,
        startDate: courseData.startDate,
        endDate: courseData.endDate,
        instructor: {
          "@type": "Person",
          name: courseData.instructorName,
          url: courseData.instructorUrl,
        },
      },
      null,
      2
    );
  };
  const handleSelectChange = (selectedOption) => {
    setCourseData((prevData) => ({
      ...prevData,
      courseMode: selectedOption.value,
    }));
  };

  return (
    <div className="row ">
      <Col>
        <h5 className="mb-4">Course Information</h5>
        <form>
          <div className="mb-1">
            <label className="form-label">Course Title</label>
            <input
              type="text"
              name="name"
              className="form-control"
              value={courseData.name}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="mb-1">
            <label className="form-label">Course URL</label>
            <input
              type="url"
              name="url"
              className="form-control"
              value={courseData.url}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="mb-1">
            <label className="form-label">Description</label>
            <textarea
              name="description"
              className="form-control"
              value={courseData.description}
              onChange={handleInputChange}
              rows="3"
              required
            />
          </div>
          <div className="mb-1">
            <label className="form-label">Provider Name</label>
            <input
              type="text"
              name="providerName"
              className="form-control"
              value={courseData.providerName}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="mb-1">
            <label className="form-label">Provider URL</label>
            <input
              type="url"
              name="providerUrl"
              className="form-control"
              value={courseData.providerUrl}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="mb-1">
            <label className="form-label">Educational Credential</label>
            <input
              type="text"
              name="educationalCredential"
              className="form-control"
              value={courseData.educationalCredential}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="mb-1">
            <label className="form-label">Course Mode</label>
            <Select
              name="courseMode"
              options={[
                { value: "Online", label: "Online" },
                { value: "In-person", label: "In-person" },
                { value: "Hybrid", label: "Hybrid" },
              ]}
              value={{
                value: courseData.courseMode,
                label: courseData.courseMode,
              }}
              placeholder="Select Mode"
              onChange={handleSelectChange}
              required
              styles={{
                control: (base) => ({
                  ...base,
                  backgroundColor: "white",
                  color: "black",
                  borderColor: "#ccc",
                }),
                menu: (base) => ({
                  ...base,
                  backgroundColor: "white",
                }),
                option: (base, state) => ({
                  ...base,
                  backgroundColor: state.isSelected ? "#f0f0f0" : "white",
                  color: "black",
                  "&:hover": {
                    backgroundColor: "#e6e6e6",
                  },
                }),
                singleValue: (base) => ({
                  ...base,
                  color: "black",
                }),
                placeholder: (base) => ({
                  ...base,
                  color: "black",
                }),
              }}
            />
          </div>
          <div className="mb-1">
            <label className="form-label">Start Date</label>
            <input
              type="date"
              name="startDate"
              className="form-control"
              value={courseData.startDate}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="mb-1">
            <label className="form-label">End Date</label>
            <input
              type="date"
              name="endDate"
              className="form-control"
              value={courseData.endDate}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="mb-1">
            <label className="form-label">Instructor Name</label>
            <input
              type="text"
              name="instructorName"
              className="form-control"
              value={courseData.instructorName}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="mb-1">
            <label className="form-label">Instructor URL</label>
            <input
              type="url"
              name="instructorUrl"
              className="form-control"
              value={courseData.instructorUrl}
              onChange={handleInputChange}
              required
            />
          </div>
        </form>
      </Col>

      <Col>
        {" "}
        <h5 className="">Generated JSON-LD</h5>
        <pre className="bg-light p-3 rounded">{generateJsonLD()}</pre>
      </Col>
    </div>
  );
};
export const Courselist = () => {
  const [courses, setCourses] = useState([
    {
      url: "",
      name: "",
      description: "",
      providerName: "",
      providerUrl: "",
    },
  ]);

  const handleChange = (e, index, field) => {
    const newCourses = [...courses];
    newCourses[index][field] = e.target.value;
    setCourses(newCourses);
  };

  const handleAddCourse = () => {
    setCourses([
      ...courses,
      {
        url: "",
        name: "",
        description: "",
        providerName: "",
        providerUrl: "",
      },
    ]);
  };

  const generateSchema = () => {
    return {
      "@context": "https://schema.org",
      "@type": "ItemList",
      itemListElement: courses.map((course, index) => ({
        "@type": "ListItem",
        position: index + 1,
        item: {
          "@type": "Course",
          url: course.url,
          name: course.name,
          description: course.description,
          provider: {
            "@type": "Organization",
            name: course.providerName,
            sameAs: course.providerUrl,
          },
        },
      })),
    };
  };

  return (
    <Row>
      <Col md={6}>
        <h5>Course List Form</h5>
        {courses.map((course, index) => (
          <div key={index} className="course-form mb-3">
            <Form.Group>
              <Form.Control
                type="url"
                placeholder="Course URL"
                value={course.url}
                onChange={(e) => handleChange(e, index, "url")}
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="text"
                placeholder="Course Name"
                value={course.name}
                onChange={(e) => handleChange(e, index, "name")}
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                as="textarea"
                placeholder="Course Description"
                value={course.description}
                onChange={(e) => handleChange(e, index, "description")}
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="text"
                placeholder="Provider Name"
                value={course.providerName}
                onChange={(e) => handleChange(e, index, "providerName")}
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="url"
                placeholder="Provider URL"
                value={course.providerUrl}
                onChange={(e) => handleChange(e, index, "providerUrl")}
              />
            </Form.Group>
          </div>
        ))}
        <Button onClick={handleAddCourse} variant="primary">
          Add Another Course
        </Button>
      </Col>
      <Col md={6}>
        <h5>Generated Schema (JSON-LD)</h5>
        <pre>{JSON.stringify(generateSchema(), null, 2)}</pre>
      </Col>
    </Row>
  );
};

export const DatasetForm = () => {
  const [formData, setFormData] = useState({
    "@context": "https://schema.org/",
    "@type": "Dataset",
    name: "",
    description: "",
    url: "",
    sameAs: "",
    identifier: [],
    keywords: [],
    license: "",
    isAccessibleForFree: false,
    temporalCoverage: "",
    spatialCoverage: {
      "@type": "Place",
      geo: {
        "@type": "GeoShape",
        box: "",
      },
    },
    creator: {
      "@type": "Organization",
      name: "",
      url: "",
      contactPoint: {
        "@type": "ContactPoint",
        contactType: "",
        telephone: "",
        email: "",
      },
    },
    publisher: {
      "@type": "Organization",
      name: "",
      url: "",
    },
    funder: {
      "@type": "Organization",
      name: "",
      sameAs: "",
    },
    citation: "",
    includedInDataCatalog: {
      "@type": "DataCatalog",
      name: "",
      url: "",
    },
    distribution: [],
    hasPart: [],
  });

  const handleChange = (path, value) => {
    const updatedFormData = { ...formData };
    const keys = path.split(".");
    let current = updatedFormData;

    keys.forEach((key, index) => {
      if (index === keys.length - 1) {
        current[key] = value;
      } else {
        current = current[key];
      }
    });

    setFormData(updatedFormData);
  };

  const handleAddItem = (path, itemTemplate) => {
    const updatedFormData = { ...formData };
    const keys = path.split(".");
    let current = updatedFormData;

    keys.forEach((key, index) => {
      if (index === keys.length - 1) {
        current[key].push(itemTemplate);
      } else {
        current = current[key];
      }
    });

    setFormData(updatedFormData);
  };

  const handleRemoveItem = (path, index) => {
    const updatedFormData = { ...formData };
    const keys = path.split(".");
    let current = updatedFormData;

    keys.forEach((key, idx) => {
      if (idx === keys.length - 1) {
        current[key].splice(index, 1);
      } else {
        current = current[key];
      }
    });

    setFormData(updatedFormData);
  };

  const generateForm = (data, parentPath = "") => {
    return Object.keys(data).map((key) => {
      const path = parentPath ? `${parentPath}.${key}` : key;

      if (typeof data[key] === "object" && !Array.isArray(data[key])) {
        return (
          <div key={path}>
            <h5>{key}</h5>
            {generateForm(data[key], path)}
          </div>
        );
      } else if (Array.isArray(data[key])) {
        return (
          <div key={path}>
            <h5>{key}</h5>
            {data[key].map((item, index) => (
              <div key={`${path}.${index}`} className="border p-2 mb-2">
                {generateForm(item, `${path}.${index}`)}
                <button
                  type="button"
                  className="btn btn-danger btn-sm"
                  onClick={() => handleRemoveItem(path, index)}
                >
                  Remove {key.slice(0, -1)}
                </button>
              </div>
            ))}
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={() =>
                handleAddItem(
                  path,
                  key === "distribution"
                    ? {
                        "@type": "DataDownload",
                        encodingFormat: "",
                        contentUrl: "",
                      }
                    : {
                        "@type": "Dataset",
                        name: "",
                        description: "",
                        license: "",
                        creator: { "@type": "Organization", name: "" },
                      }
                )
              }
            >
              Add {key.slice(0, -1)}
            </button>
          </div>
        );
      } else {
        return (
          <div key={path} className="mb-3">
            <label>{key}</label>
            <input
              type="text"
              value={data[key]}
              onChange={(e) => handleChange(path, e.target.value)}
              className="form-control"
            />
          </div>
        );
      }
    });
  };

  return (
    <div className="">
      <div className="row">
        <div
          className="col-md-6"
          style={{
            height: "90vh",
            overflowY: "auto",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
          }}
        >
          <style>
            {`
      .col-md-6::-webkit-scrollbar {
        display: none; /* For Chrome, Safari, and Opera */
      }
    `}
          </style>
          <h5>Dataset Form</h5>
          {generateForm(formData)}
        </div>
        <div
          className="col-md-6"
          style={{
            height: "90vh",
            overflowY: "auto",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
          }}
        >
          <style>
            {`
      .col-md-6::-webkit-scrollbar {
        display: none; /* For Chrome, Safari, and Opera */
      }
    `}
          </style>
          <h5>Generated JSON</h5>
          <pre>{JSON.stringify(formData, null, 2)}</pre>
        </div>
      </div>
    </div>
  );
};

export const DiscussionForumPostingForm = () => {
  const [posts, setPosts] = useState([
    {
      mainEntityOfPage: "",
      headline: "",
      text: "",
      url: "",
      datePublished: "",
      dateModified: "",
      author: { name: "", url: "" },
      interactionStatistic: [
        {
          interactionType: "https://schema.org/LikeAction",
          userInteractionCount: 0,
        },
        {
          interactionType: "https://schema.org/CommentAction",
          userInteractionCount: 0,
        },
      ],
      comment: [
        {
          text: "Default comment", // Default comment added here
          datePublished: "",
          author: { name: "Default Author", url: "" },
          interactionStatistic: {
            interactionType: "https://schema.org/LikeAction",
            userInteractionCount: 0,
          },
          comment: [
            {
              text: "Default reply",
              author: { name: "Default Reply Author", url: "" },
            },
          ],
        },
      ],
    },
  ]);

  const handleChange = (e, index, field, path = "") => {
    const newPosts = [...posts];
    const fieldPath = path ? `${path}.${field}` : field;
    const value = e.target.value;

    const keys = fieldPath.split(".");
    let current = newPosts[index];

    // Update the nested field value
    keys.forEach((key, idx) => {
      if (idx === keys.length - 1) {
        current[key] = value;
      } else {
        current = current[key];
      }
    });

    setPosts(newPosts);
  };

  const handleAddComment = (index) => {
    const newPosts = [...posts];
    newPosts[index].comment.push({
      text: "", // New comment is initially empty
      datePublished: "",
      author: { name: "", url: "" },
      interactionStatistic: {
        interactionType: "https://schema.org/LikeAction",
        userInteractionCount: 0,
      },
      comment: [],
    });
    setPosts(newPosts);
  };

  const handleRemoveComment = (postIndex, commentIndex) => {
    const newPosts = [...posts];
    newPosts[postIndex].comment.splice(commentIndex, 1);
    setPosts(newPosts);
  };

  const handleAddReply = (postIndex, commentIndex) => {
    const newPosts = [...posts];
    newPosts[postIndex].comment[commentIndex].comment.push({
      text: "",
      author: { name: "", url: "" },
    });
    setPosts(newPosts);
  };

  const handleRemoveReply = (postIndex, commentIndex, replyIndex) => {
    const newPosts = [...posts];
    newPosts[postIndex].comment[commentIndex].comment.splice(replyIndex, 1);
    setPosts(newPosts);
  };

  const generateSchema = () => {
    return {
      "@context": "https://schema.org",
      "@type": "DiscussionForumPosting",
      mainEntityOfPage: posts[0].mainEntityOfPage,
      headline: posts[0].headline,
      text: posts[0].text,
      url: posts[0].url,
      datePublished: posts[0].datePublished,
      dateModified: posts[0].dateModified,
      author: {
        "@type": "Person",
        name: posts[0].author.name,
        sameAs: posts[0].author.url,
      },
      interactionStatistic: posts[0].interactionStatistic,
      comment: posts[0].comment.map((comment, idx) => ({
        "@type": "Comment",
        text: comment.text,
        datePublished: comment.datePublished,
        author: {
          "@type": "Person",
          name: comment.author.name,
          sameAs: comment.author.url,
        },
        interactionStatistic: comment.interactionStatistic,
        comment: comment.comment.map((reply, replyIdx) => ({
          "@type": "Comment",
          text: reply.text,
          author: {
            "@type": "Person",
            name: reply.author.name,
            sameAs: reply.author.url,
          },
        })),
      })),
    };
  };

  useEffect(() => {
    // Automatically add a comment when the component mounts
    handleAddComment(0);
  }, []);

  return (
    <Row>
      <Col
        md={6}
        style={{
          height: "90vh",
          overflowY: "auto",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
        }}
      >
        <style>
          {`
      .col-md-6::-webkit-scrollbar {
        display: none; /* For Chrome, Safari, and Opera */
      }
    `}
        </style>
        <h5>Discussion Forum Posting Form</h5>
        {posts.map((post, index) => (
          <div key={index} className="post-form mb-3">
            <Form.Group>
              <Form.Control
                type="text"
                placeholder="Main Entity of Page"
                value={post.mainEntityOfPage}
                onChange={(e) => handleChange(e, index, "mainEntityOfPage")}
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="text"
                placeholder="Headline"
                value={post.headline}
                onChange={(e) => handleChange(e, index, "headline")}
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                as="textarea"
                placeholder="Text"
                value={post.text}
                onChange={(e) => handleChange(e, index, "text")}
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="url"
                placeholder="URL"
                value={post.url}
                onChange={(e) => handleChange(e, index, "url")}
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="datetime-local"
                placeholder="Date Published"
                value={post.datePublished}
                onChange={(e) => handleChange(e, index, "datePublished")}
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="datetime-local"
                placeholder="Date Modified"
                value={post.dateModified}
                onChange={(e) => handleChange(e, index, "dateModified")}
              />
            </Form.Group>

            {/* Author Info */}
            <Form.Group>
              <Form.Control
                type="text"
                placeholder="Author Name"
                value={post.author.name}
                onChange={(e) => handleChange(e, index, "name", "author")}
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="url"
                placeholder="Author URL"
                value={post.author.url}
                onChange={(e) => handleChange(e, index, "url", "author")}
              />
            </Form.Group>

            {/* Interaction Counts */}
            <Form.Group>
              <Form.Control
                type="number"
                placeholder="Likes Count"
                value={post.interactionStatistic[0].userInteractionCount}
                onChange={(e) =>
                  handleChange(
                    e,
                    index,
                    "userInteractionCount",
                    "interactionStatistic[0]"
                  )
                }
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="number"
                placeholder="Comments Count"
                value={post.interactionStatistic[1].userInteractionCount}
                onChange={(e) =>
                  handleChange(
                    e,
                    index,
                    "userInteractionCount",
                    "interactionStatistic[1]"
                  )
                }
              />
            </Form.Group>

            {/* Comments Section */}
            <div className="comment-section">
              {post.comment.map((comment, commentIndex) => (
                <div key={commentIndex}>
                  <Form.Group>
                    <Form.Control
                      as="textarea"
                      placeholder="Comment Text"
                      value={comment.text}
                      onChange={(e) =>
                        handleChange(
                          e,
                          index,
                          "text",
                          `comment[${commentIndex}]`
                        )
                      }
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Control
                      type="text"
                      placeholder="Comment Author"
                      value={comment.author.name}
                      onChange={(e) =>
                        handleChange(
                          e,
                          index,
                          "name",
                          `comment[${commentIndex}].author`
                        )
                      }
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Control
                      type="datetime-local"
                      placeholder="Comment Date"
                      value={comment.datePublished}
                      onChange={(e) =>
                        handleChange(
                          e,
                          index,
                          "datePublished",
                          `comment[${commentIndex}]`
                        )
                      }
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Control
                      type="number"
                      placeholder="Comment Likes"
                      value={comment.interactionStatistic.userInteractionCount}
                      onChange={(e) =>
                        handleChange(
                          e,
                          index,
                          "userInteractionCount",
                          `comment[${commentIndex}].interactionStatistic`
                        )
                      }
                    />
                  </Form.Group>

                  {/* Replies Section */}
                  <div className="reply-section">
                    {comment.comment.map((reply, replyIndex) => (
                      <div key={replyIndex} className="reply-item">
                        <Form.Group>
                          <Form.Control
                            as="textarea"
                            placeholder="Reply Text"
                            value={reply.text}
                            onChange={(e) =>
                              handleChange(
                                e,
                                index,
                                "text",
                                `comment[${commentIndex}].comment[${replyIndex}]`
                              )
                            }
                          />
                        </Form.Group>
                        <Form.Group>
                          <Form.Control
                            type="text"
                            placeholder="Reply Author"
                            value={reply.author.name}
                            onChange={(e) =>
                              handleChange(
                                e,
                                index,
                                "name",
                                `comment[${commentIndex}].comment[${replyIndex}].author`
                              )
                            }
                          />
                        </Form.Group>
                        <Button
                          size="sm"
                          className="mt-1 mb-1"
                          variant="danger"
                          onClick={() =>
                            handleRemoveReply(index, commentIndex, replyIndex)
                          }
                        >
                          Remove Reply
                        </Button>
                      </div>
                    ))}
                    <Button
                      size="sm"
                      className="mt-1 mb-1"
                      variant="secondary"
                      onClick={() => handleAddReply(index, commentIndex)}
                    >
                      Add Reply
                    </Button>
                  </div>

                  <Button
                    size="sm"
                    className="mt-1 mb-1"
                    variant="danger"
                    onClick={() => handleRemoveComment(index, commentIndex)}
                  >
                    Remove Comment
                  </Button>
                </div>
              ))}
              <Button
                size="sm"
                className="mt-1 mb-1"
                variant="primary"
                onClick={() => handleAddComment(index)}
              >
                Add Comment
              </Button>
            </div>
          </div>
        ))}
      </Col>
      <Col
        md={6}
        style={{
          height: "90vh",
          overflowY: "auto",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
        }}
      >
        <style>
          {`
      .col-md-6::-webkit-scrollbar {
        display: none; /* For Chrome, Safari, and Opera */
      }
    `}
        </style>
        <h5>Generated Schema</h5>
        <pre>{JSON.stringify(generateSchema(), null, 2)}</pre>
      </Col>
    </Row>
  );
};

export const EducationQuizForm = () => {
  const [quiz, setQuiz] = useState({
    name: "",
    subject: "",
    gradeLevel: "",
    questions: [
      {
        text: "",
        acceptedAnswer: "",
      },
    ],
  });

  const handleChange = (e, index, field) => {
    const newQuiz = { ...quiz };
    if (field === "questions") {
      newQuiz.questions[index][e.target.name] = e.target.value;
    } else {
      newQuiz[field] = e.target.value;
    }
    setQuiz(newQuiz);
  };

  const handleAddQuestion = () => {
    setQuiz({
      ...quiz,
      questions: [
        ...quiz.questions,
        {
          text: "",
          acceptedAnswer: "",
        },
      ],
    });
  };

  const generateSchema = () => {
    return {
      "@context": "https://schema.org/",
      "@type": "Quiz",
      about: {
        "@type": "Thing",
        name: quiz.name,
      },
      educationalAlignment: [
        {
          "@type": "AlignmentObject",
          alignmentType: "educationalSubject",
          targetName: quiz.subject,
        },
        {
          "@type": "AlignmentObject",
          alignmentType: "educationalLevel",
          targetName: quiz.gradeLevel,
        },
      ],
      hasPart: quiz.questions.map((question) => ({
        "@context": "https://schema.org/",
        "@type": "Question",
        eduQuestionType: "Flashcard",
        text: question.text,
        acceptedAnswer: {
          "@type": "Answer",
          text: question.acceptedAnswer,
        },
      })),
    };
  };

  return (
    <Row>
      <Col
        md={6}
        style={{
          height: "90vh",
          overflowY: "auto",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
        }}
      >
        <style>
          {`
      .col-md-6::-webkit-scrollbar {
        display: none; /* For Chrome, Safari, and Opera */
      }
    `}
        </style>
        <h5>Quiz Form</h5>
        {/* Quiz Name */}
        <Form.Group>
          <Form.Control
            type="text"
            placeholder="Quiz Name"
            value={quiz.name}
            onChange={(e) => handleChange(e, null, "name")}
          />
        </Form.Group>

        {/* Subject */}
        <Form.Group>
          <Form.Control
            type="text"
            placeholder="Subject"
            value={quiz.subject}
            onChange={(e) => handleChange(e, null, "subject")}
          />
        </Form.Group>

        {/* Grade Level */}
        <Form.Group>
          <Form.Control
            type="text"
            placeholder="Grade Level"
            value={quiz.gradeLevel}
            onChange={(e) => handleChange(e, null, "gradeLevel")}
          />
        </Form.Group>

        {/* Questions */}
        {quiz.questions.map((question, index) => (
          <div key={index} className="question-form mb-3">
            <h6>Question {index + 1}</h6>

            {/* Question Text */}
            <Form.Group>
              <Form.Control
                type="text"
                placeholder="Question Text"
                name="text"
                value={question.text}
                onChange={(e) => handleChange(e, index, "questions")}
              />
            </Form.Group>

            {/* Accepted Answer */}
            <Form.Group>
              <Form.Control
                type="text"
                placeholder="Accepted Answer"
                name="acceptedAnswer"
                value={question.acceptedAnswer}
                onChange={(e) => handleChange(e, index, "questions")}
              />
            </Form.Group>
          </div>
        ))}

        {/* Add Question Button */}
        <Button onClick={handleAddQuestion} variant="primary">
          Add Another Question
        </Button>
      </Col>

      {/* Display the generated Schema */}
      <Col
        md={6}
        style={{
          height: "90vh",
          overflowY: "auto",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
        }}
      >
        <style>
          {`
      .col-md-6::-webkit-scrollbar {
        display: none; /* For Chrome, Safari, and Opera */
      }
    `}
        </style>
        <h5>Generated Schema (JSON-LD)</h5>
        <pre>{JSON.stringify(generateSchema(), null, 2)}</pre>
      </Col>
    </Row>
  );
};

export const EmployerAggregateRatingForm = () => {
  const [ratingData, setRatingData] = useState({
    organizationName: "",
    organizationUrl: "",
    ratingValue: "",
    bestRating: 5,
    worstRating: 1,
    ratingCount: "",
    reviewCount: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRatingData({
      ...ratingData,
      [name]: value,
    });
  };

  const generateSchema = () => {
    return {
      "@context": "https://schema.org/",
      "@type": "EmployerAggregateRating",
      itemReviewed: {
        "@type": "Organization",
        name: ratingData.organizationName,
        sameAs: ratingData.organizationUrl,
      },
      ratingValue: ratingData.ratingValue,
      bestRating: ratingData.bestRating,
      worstRating: ratingData.worstRating,
      ratingCount: ratingData.ratingCount,
      reviewCount: ratingData.reviewCount,
    };
  };

  return (
    <Row>
      <Col md={6}>
        <h5>Employer Aggregate Rating Form</h5>

        {/* Organization Name */}
        <Form.Group>
          <Form.Control
            type="text"
            placeholder="Organization Name"
            name="organizationName"
            value={ratingData.organizationName}
            onChange={handleChange}
          />
        </Form.Group>

        {/* Organization URL */}
        <Form.Group>
          <Form.Control
            type="url"
            placeholder="Organization URL"
            name="organizationUrl"
            value={ratingData.organizationUrl}
            onChange={handleChange}
          />
        </Form.Group>

        {/* Rating Value */}
        <Form.Group>
          <Form.Control
            type="number"
            placeholder="Rating Value"
            name="ratingValue"
            value={ratingData.ratingValue}
            onChange={handleChange}
          />
        </Form.Group>

        {/* Best Rating */}
        <Form.Group>
          <Form.Control
            type="number"
            placeholder="Best Rating"
            name="bestRating"
            value={ratingData.bestRating}
            onChange={handleChange}
          />
        </Form.Group>

        {/* Worst Rating */}
        <Form.Group>
          <Form.Control
            type="number"
            placeholder="Worst Rating"
            name="worstRating"
            value={ratingData.worstRating}
            onChange={handleChange}
          />
        </Form.Group>

        {/* Rating Count */}
        <Form.Group>
          <Form.Control
            type="number"
            placeholder="Rating Count"
            name="ratingCount"
            value={ratingData.ratingCount}
            onChange={handleChange}
          />
        </Form.Group>

        {/* Review Count */}
        <Form.Group>
          <Form.Control
            type="number"
            placeholder="Review Count"
            name="reviewCount"
            value={ratingData.reviewCount}
            onChange={handleChange}
          />
        </Form.Group>

        {/* Submit Button */}
        <Button
          variant="primary"
          onClick={() => alert(JSON.stringify(generateSchema(), null, 2))}
        >
          Generate Schema
        </Button>
      </Col>

      {/* Display the generated Schema */}
      <Col md={6}>
        <h5>Generated Schema (JSON-LD)</h5>
        <pre>{JSON.stringify(generateSchema(), null, 2)}</pre>
      </Col>
    </Row>
  );
};

export const OccupationForm = () => {
  const [occupationData, setOccupationData] = useState([
    {
      name: "",
      description: "",
      estimatedSalary: [
        {
          name: "",
          currency: "",
          duration: "",
          minValue: "",
          maxValue: "",
          median: "",
        },
      ],
      occupationLocation: [{ name: "" }],
      hiringOrganization: { name: "" },
      sampleSize: "",
      industry: "",
      jobBenefits: "",
      yearsExperienceMin: "",
      yearsExperienceMax: "",
    },
  ]);

  const handleChange = (e, index, field) => {
    const { name, value } = e.target;
    const newOccupationData = [...occupationData];
    if (field) {
      newOccupationData[index][field] = value;
    } else {
      newOccupationData[index][name] = value;
    }
    setOccupationData(newOccupationData);
  };

  const handleSalaryChange = (e, index, salaryIndex, field) => {
    const { value } = e.target;
    const newOccupationData = [...occupationData];
    newOccupationData[index].estimatedSalary[salaryIndex][field] = value;
    setOccupationData(newOccupationData);
  };

  const handleLocationChange = (e, index, locationIndex) => {
    const { name, value } = e.target;
    const newOccupationData = [...occupationData];
    newOccupationData[index].occupationLocation[locationIndex][name] = value;
    setOccupationData(newOccupationData);
  };

  const handleAddOccupation = () => {
    setOccupationData([
      ...occupationData,
      {
        name: "",
        description: "",
        estimatedSalary: [
          {
            name: "",
            currency: "",
            duration: "",
            minValue: "",
            maxValue: "",
            median: "",
          },
        ],
        occupationLocation: [{ name: "" }],
        hiringOrganization: { name: "" },
        sampleSize: "",
        industry: "",
        jobBenefits: "",
        yearsExperienceMin: "",
        yearsExperienceMax: "",
      },
    ]);
  };

  const handleRemoveOccupation = (index) => {
    const newOccupationData = occupationData.filter((_, i) => i !== index);
    setOccupationData(newOccupationData);
  };

  const handleAddLocation = (index) => {
    const newOccupationData = [...occupationData];
    newOccupationData[index].occupationLocation.push({ name: "" });
    setOccupationData(newOccupationData);
  };

  const handleRemoveLocation = (occupationIndex, locationIndex) => {
    const newOccupationData = [...occupationData];
    newOccupationData[occupationIndex].occupationLocation = newOccupationData[
      occupationIndex
    ].occupationLocation.filter((_, i) => i !== locationIndex);
    setOccupationData(newOccupationData);
  };

  const handleAddSalary = (index) => {
    const newOccupationData = [...occupationData];
    newOccupationData[index].estimatedSalary.push({
      name: "",
      currency: "",
      duration: "",
      minValue: "",
      maxValue: "",
      median: "",
    });
    setOccupationData(newOccupationData);
  };

  const handleRemoveSalary = (occupationIndex, salaryIndex) => {
    const newOccupationData = [...occupationData];
    newOccupationData[occupationIndex].estimatedSalary = newOccupationData[
      occupationIndex
    ].estimatedSalary.filter((_, i) => i !== salaryIndex);
    setOccupationData(newOccupationData);
  };

  const generateSchema = () => {
    return {
      "@context": "https://schema.org/",
      "@type": "OccupationAggregationByEmployer",
      name: occupationData[0].name,
      description: occupationData[0].description,
      estimatedSalary: occupationData[0].estimatedSalary.map((salary) => ({
        "@type": "MonetaryAmountDistribution",
        name: salary.name,
        currency: salary.currency,
        duration: salary.duration,
        minValue: salary.minValue,
        maxValue: salary.maxValue,
        median: salary.median,
      })),
      occupationLocation: occupationData[0].occupationLocation.map(
        (location) => ({
          "@type": "City",
          name: location.name,
        })
      ),
      hiringOrganization: {
        "@type": "Organization",
        name: occupationData[0].hiringOrganization.name,
      },
      sampleSize: occupationData[0].sampleSize,
      industry: occupationData[0].industry,
      jobBenefits: occupationData[0].jobBenefits,
      yearsExperienceMin: occupationData[0].yearsExperienceMin,
      yearsExperienceMax: occupationData[0].yearsExperienceMax,
    };
  };

  return (
    <Row>
      <Col
        md={6}
        style={{
          height: "90vh",
          overflowY: "auto",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
        }}
      >
        <h5>Occupation Form</h5>
        {occupationData.map((occupation, index) => (
          <div key={index} className="occupation-form mb-3">
            <Form.Group>
              <Form.Control
                type="text"
                placeholder="Occupation Name"
                value={occupation.name}
                onChange={(e) => handleChange(e, index, "name")}
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                as="textarea"
                placeholder="Occupation Description"
                value={occupation.description}
                onChange={(e) => handleChange(e, index, "description")}
              />
            </Form.Group>

            <h6>Salary Information</h6>
            {occupation.estimatedSalary.map((salary, salaryIndex) => (
              <div key={salaryIndex} className="salary-form">
                <Form.Group>
                  <Form.Control
                    type="text"
                    placeholder="Salary Name"
                    value={salary.name}
                    onChange={(e) =>
                      handleSalaryChange(e, index, salaryIndex, "name")
                    }
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    type="text"
                    placeholder="Currency"
                    value={salary.currency}
                    onChange={(e) =>
                      handleSalaryChange(e, index, salaryIndex, "currency")
                    }
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    type="text"
                    placeholder="Duration"
                    value={salary.duration}
                    onChange={(e) =>
                      handleSalaryChange(e, index, salaryIndex, "duration")
                    }
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    type="number"
                    placeholder="Min Value"
                    value={salary.minValue}
                    onChange={(e) =>
                      handleSalaryChange(e, index, salaryIndex, "minValue")
                    }
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    type="number"
                    placeholder="Max Value"
                    value={salary.maxValue}
                    onChange={(e) =>
                      handleSalaryChange(e, index, salaryIndex, "maxValue")
                    }
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    type="number"
                    placeholder="Median Value"
                    value={salary.median}
                    onChange={(e) =>
                      handleSalaryChange(e, index, salaryIndex, "median")
                    }
                  />
                </Form.Group>
                <Button
                  size="sm"
                  className="mt-1 mb-1"
                  onClick={() => handleRemoveSalary(index, salaryIndex)}
                  variant="outline-danger"
                >
                  Remove Salary
                </Button>
              </div>
            ))}
            <Button
              size="sm"
              className="mt-1 mb-1"
              onClick={() => handleAddSalary(index)}
              variant="outline-primary"
            >
              Add Salary
            </Button>

            <h6>Location Information</h6>
            {occupation.occupationLocation.map((location, locationIndex) => (
              <div key={locationIndex} className="location-form">
                <Form.Group>
                  <Form.Control
                    type="text"
                    placeholder="Location Name"
                    value={location.name}
                    name="name"
                    onChange={(e) =>
                      handleLocationChange(e, index, locationIndex)
                    }
                  />
                </Form.Group>
                <Button
                  size="sm"
                  className="mt-1 mb-1"
                  onClick={() => handleRemoveLocation(index, locationIndex)}
                  variant="outline-danger"
                >
                  Remove Location
                </Button>
              </div>
            ))}
            <Button
              size="sm"
              className="mt-1 mb-1"
              onClick={() => handleAddLocation(index)}
              variant="outline-primary"
            >
              Add Location
            </Button>

            <Form.Group>
              <Form.Control
                type="text"
                placeholder="Hiring Organization"
                value={occupation.hiringOrganization.name}
                onChange={(e) =>
                  handleChange(e, index, "hiringOrganization.name")
                }
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="text"
                placeholder="Sample Size"
                value={occupation.sampleSize}
                onChange={(e) => handleChange(e, index, "sampleSize")}
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="text"
                placeholder="Industry"
                value={occupation.industry}
                onChange={(e) => handleChange(e, index, "industry")}
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                as="textarea"
                placeholder="Job Benefits"
                value={occupation.jobBenefits}
                onChange={(e) => handleChange(e, index, "jobBenefits")}
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="number"
                placeholder="Min Years of Experience"
                value={occupation.yearsExperienceMin}
                onChange={(e) => handleChange(e, index, "yearsExperienceMin")}
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="number"
                placeholder="Max Years of Experience"
                value={occupation.yearsExperienceMax}
                onChange={(e) => handleChange(e, index, "yearsExperienceMax")}
              />
            </Form.Group>
          </div>
        ))}
        <Button onClick={handleAddOccupation} variant="primary">
          Add Another Occupation
        </Button>
      </Col>

      <Col
        md={6}
        style={{
          height: "90vh",
          overflowY: "auto",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
        }}
      >
        <h5>Generated Schema (JSON-LD)</h5>
        <pre>{JSON.stringify(generateSchema(), null, 2)}</pre>
      </Col>
    </Row>
  );
};
export const EventForm = () => {
  const [event, setEvent] = useState({
    name: "",
    startDate: "",
    endDate: "",
    location: {
      name: "",
      streetAddress: "",
      addressLocality: "",
      addressRegion: "",
      postalCode: "",
      addressCountry: "",
    },
    offerUrl: "",
    priceCurrency: "",
    price: "",
    performer: "",
    organizer: {
      name: "",
      url: "",
    },
  });

  const handleChange = (e, field) => {
    const updatedEvent = { ...event };
    if (field.includes(".")) {
      const [obj, key] = field.split(".");
      updatedEvent[obj][key] = e.target.value;
    } else {
      updatedEvent[field] = e.target.value;
    }
    setEvent(updatedEvent);
  };

  const generateSchema = () => {
    return {
      "@context": "https://schema.org",
      "@type": "Event",
      name: event.name,
      startDate: event.startDate,
      endDate: event.endDate,
      location: {
        "@type": "Place",
        name: event.location.name,
        address: {
          "@type": "PostalAddress",
          streetAddress: event.location.streetAddress,
          addressLocality: event.location.addressLocality,
          addressRegion: event.location.addressRegion,
          postalCode: event.location.postalCode,
          addressCountry: event.location.addressCountry,
        },
      },
      offers: {
        "@type": "Offer",
        url: event.offerUrl,
        priceCurrency: event.priceCurrency,
        price: event.price,
      },
      performer: {
        "@type": "Person",
        name: event.performer,
      },
      organizer: {
        "@type": "Organization",
        name: event.organizer.name,
        url: event.organizer.url,
      },
    };
  };

  return (
    <Row>
      <Col
        md={6}
        style={{
          height: "90vh",
          overflowY: "auto",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
        }}
      >
        <style>
          {`
      .col-md-6::-webkit-scrollbar {
        display: none; /* For Chrome, Safari, and Opera */
      }
    `}
        </style>
        <h5>Event Form</h5>
        <Form>
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="Event Name"
              value={event.name}
              onChange={(e) => handleChange(e, "name")}
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              type="datetime-local"
              placeholder="Start Date"
              value={event.startDate}
              onChange={(e) => handleChange(e, "startDate")}
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              type="datetime-local"
              placeholder="End Date"
              value={event.endDate}
              onChange={(e) => handleChange(e, "endDate")}
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="Venue Name"
              value={event.location.name}
              onChange={(e) => handleChange(e, "location.name")}
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="Street Address"
              value={event.location.streetAddress}
              onChange={(e) => handleChange(e, "location.streetAddress")}
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="City"
              value={event.location.addressLocality}
              onChange={(e) => handleChange(e, "location.addressLocality")}
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="State"
              value={event.location.addressRegion}
              onChange={(e) => handleChange(e, "location.addressRegion")}
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="Postal Code"
              value={event.location.postalCode}
              onChange={(e) => handleChange(e, "location.postalCode")}
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="Country"
              value={event.location.addressCountry}
              onChange={(e) => handleChange(e, "location.addressCountry")}
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              type="url"
              placeholder="Offer URL"
              value={event.offerUrl}
              onChange={(e) => handleChange(e, "offerUrl")}
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="Currency"
              value={event.priceCurrency}
              onChange={(e) => handleChange(e, "priceCurrency")}
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              type="number"
              placeholder="Price"
              value={event.price}
              onChange={(e) => handleChange(e, "price")}
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="Performer"
              value={event.performer}
              onChange={(e) => handleChange(e, "performer")}
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="Organizer Name"
              value={event.organizer.name}
              onChange={(e) => handleChange(e, "organizer.name")}
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              type="url"
              placeholder="Organizer URL"
              value={event.organizer.url}
              onChange={(e) => handleChange(e, "organizer.url")}
            />
          </Form.Group>
        </Form>
      </Col>
      <Col
        md={6}
        style={{
          height: "90vh",
          overflowY: "auto",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
        }}
      >
        <style>
          {`
      .col-md-6::-webkit-scrollbar {
        display: none; /* For Chrome, Safari, and Opera */
      }
    `}
        </style>
        <h5>Generated Schema (JSON-LD)</h5>
        <pre>{JSON.stringify(generateSchema(), null, 2)}</pre>
      </Col>
    </Row>
  );
};

export const ClaimReviewForm = () => {
  const [claimReview, setClaimReview] = useState({
    url: "",
    claimReviewed: "",
    claimSource: "",
    claimDate: "",
    article: {
      url: "",
      headline: "",
      datePublished: "",
      author: "",
      image: "",
      publisher: {
        name: "",
        logoUrl: "",
      },
    },
    ratingValue: 1,
    authorName: "",
  });

  // Handle changes for nested fields
  const handleChange = (e, field, nestedField) => {
    const { value } = e.target;

    if (nestedField) {
      setClaimReview((prevState) => ({
        ...prevState,
        [nestedField]: {
          ...prevState[nestedField],
          [field]: value,
        },
      }));
    } else {
      setClaimReview((prevState) => ({
        ...prevState,
        [field]: value,
      }));
    }
  };

  const generateSchema = () => {
    return {
      "@context": "https://schema.org",
      "@type": "ClaimReview",
      url: claimReview.url,
      claimReviewed: claimReview.claimReviewed,
      itemReviewed: {
        "@type": "Claim",
        author: {
          "@type": "Organization",
          name: claimReview.claimSource,
          sameAs: claimReview.claimSource,
        },
        datePublished: claimReview.claimDate,
        appearance: {
          "@type": "OpinionNewsArticle",
          url: claimReview.article.url,
          headline: claimReview.article.headline,
          datePublished: claimReview.article.datePublished,
          author: {
            "@type": "Person",
            name: claimReview.article.author,
          },
          image: claimReview.article.image,
          publisher: {
            "@type": "Organization",
            name: claimReview.article.publisher.name,
            logo: {
              "@type": "ImageObject",
              url: claimReview.article.publisher.logoUrl,
            },
          },
        },
      },
      author: {
        "@type": "Organization",
        name: claimReview.authorName,
      },
      reviewRating: {
        "@type": "Rating",
        ratingValue: claimReview.ratingValue,
        bestRating: 5,
        worstRating: 1,
        alternateName: claimReview.ratingValue === 1 ? "False" : "True",
      },
    };
  };

  return (
    <Row>
      <Col md={6}>
        <h5>Claim Review Form</h5>
        <Form>
          {/* Claim Review URL */}
          <Form.Group>
            <Form.Control
              type="url"
              placeholder="Claim Review URL"
              value={claimReview.url}
              onChange={(e) => handleChange(e, "url")}
            />
          </Form.Group>

          {/* Claim Reviewed */}
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="Claim Reviewed"
              value={claimReview.claimReviewed}
              onChange={(e) => handleChange(e, "claimReviewed")}
            />
          </Form.Group>

          {/* Claim Source */}
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="Claim Source (Organization Name)"
              value={claimReview.claimSource}
              onChange={(e) => handleChange(e, "claimSource")}
            />
          </Form.Group>

          {/* Claim Published Date */}
          <Form.Group>
            <Form.Control
              type="date"
              placeholder="Claim Published Date"
              value={claimReview.claimDate}
              onChange={(e) => handleChange(e, "claimDate")}
            />
          </Form.Group>

          <h6>Article Details</h6>
          {/* Article URL */}
          <Form.Group>
            <Form.Control
              type="url"
              placeholder="Article URL"
              value={claimReview.article.url}
              onChange={(e) => handleChange(e, "url", "article")}
            />
          </Form.Group>

          {/* Article Headline */}
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="Article Headline"
              value={claimReview.article.headline}
              onChange={(e) => handleChange(e, "headline", "article")}
            />
          </Form.Group>

          {/* Article Published Date */}
          <Form.Group>
            <Form.Control
              type="date"
              placeholder="Article Published Date"
              value={claimReview.article.datePublished}
              onChange={(e) => handleChange(e, "datePublished", "article")}
            />
          </Form.Group>

          {/* Article Author */}
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="Article Author"
              value={claimReview.article.author}
              onChange={(e) => handleChange(e, "author", "article")}
            />
          </Form.Group>

          {/* Article Image URL */}
          <Form.Group>
            <Form.Control
              type="url"
              placeholder="Article Image URL"
              value={claimReview.article.image}
              onChange={(e) => handleChange(e, "image", "article")}
            />
          </Form.Group>

          <h6>Publisher Details</h6>
          {/* Publisher Name */}
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="Publisher Name"
              value={claimReview.article.publisher.name}
              onChange={(e) => handleChange(e, "name", "article.publisher")}
            />
          </Form.Group>

          {/* Publisher Logo URL */}
          <Form.Group>
            <Form.Control
              type="url"
              placeholder="Publisher Logo URL"
              value={claimReview.article.publisher.logoUrl}
              onChange={(e) => handleChange(e, "logoUrl", "article.publisher")}
            />
          </Form.Group>

          {/* Review Author Name */}
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="Review Author Name"
              value={claimReview.authorName}
              onChange={(e) => handleChange(e, "authorName")}
            />
          </Form.Group>

          {/* Rating Value */}
          <Form.Group>
            <Form.Control
              as="select"
              value={claimReview.ratingValue}
              onChange={(e) => handleChange(e, "ratingValue")}
            >
              <option value={1}>False</option>
              <option value={2}>Mostly False</option>
              <option value={3}>Mostly True</option>
              <option value={4}>True</option>
              <option value={5}>Verified</option>
            </Form.Control>
          </Form.Group>
        </Form>
      </Col>
      <Col md={6}>
        <h5>Generated Schema (JSON-LD)</h5>
        <pre>{JSON.stringify(generateSchema(), null, 2)}</pre>
      </Col>
    </Row>
  );
};


