import React, { Component, useContext, useEffect, useState } from "react";

import { GlobalContext } from "../Context/GlobalState";
import { BASE_URL_API_TRANXT_UAT_API } from "../Utils/Constant";
import { GuestOboardform } from "./Forms";
import { Guestonboardtable } from "./Table";

const Guestpostonboard = () => {
  const { setTransactionLoaderState } = useContext(GlobalContext);
  const [onboarddata, setonboarddata] = useState([]);
  const Getonboarddata = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({}),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetGpUsers`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setonboarddata(
          data.data.map((item, index) => ({
            id: `${index + 1}`,
            user_name: item.user_name,
            password: item.password,
            user_email: item.user_email,
           
          }))
        );
      } else {
        setonboarddata([]);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
  
    }
  };

  useEffect(() => {
    Getonboarddata("");
  }, []);
  return (
    <div>
   <GuestOboardform Getonboarddata={Getonboarddata}/>
   <Guestonboardtable  onboarddata={onboarddata}/>
    </div>
  );
};

export default Guestpostonboard;
