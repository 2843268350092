import React, { useState } from "react";
import { Tabs, Tab, Container } from "react-bootstrap";
import {
  Facebooktable,
  Instagramtable,
  LinkedIntable,
  Youtubetable,
} from "./table";

const Reporting = () => {
  const [key, setKey] = useState("youtube");

  const tabStyle = {
    fontSize: "1.25rem",
    textAlign: "center",
    padding: "10px",
  };

  const activeTabStyle = {
    ...tabStyle,
    fontWeight: "bold",
  };

  return (
    <Container className="mt-4 m-0">
      <Tabs
        id="social-media-tabs"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3"
        fill
      >
        <Tab 
          eventKey="youtube"
          title={
            <span style={key === "youtube" ? activeTabStyle : tabStyle}>
              YouTube
            </span>
          }
        >
          <div>
            <Youtubetable />
          </div>
        </Tab>
        <Tab
          eventKey="facebook"
          title={
            <span style={key === "facebook" ? activeTabStyle : tabStyle}>
              Facebook
            </span>
          }
        >
          <div>
            <Facebooktable />{" "}
          </div>
        </Tab>
        <Tab
          eventKey="instagram"
          title={
            <span style={key === "instagram" ? activeTabStyle : tabStyle}>
              Instagram
            </span>
          }
        >
          <div>
            <Instagramtable />{" "}
          </div>
        </Tab>
        <Tab
          eventKey="linkedin"
          title={
            <span style={key === "linkedin" ? activeTabStyle : tabStyle}>
              LinkedIn
            </span>
          }
        >
          <div>
            <LinkedIntable />{" "}
          </div>
        </Tab>
      </Tabs>
    </Container>
  );
};

export default Reporting;
