import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import { Trans } from "react-i18next";
import {
  MdCampaign,
  MdDesktopAccessDisabled,
  MdOutlineArticle,
} from "react-icons/md";
import { IoMdAddCircle } from "react-icons/io";
import {
  FaChartLine,
  FaChartPie,
  FaCheckDouble,
  FaFacebook,
  FaLink,
  FaLinkedin,
  FaLock,
  FaMobileAlt,
  FaRegNewspaper,
  FaSitemap,
  FaYoutube,
} from "react-icons/fa";
import { IoShareSocialSharp } from "react-icons/io5";
import { GlobalContext } from "../Context/GlobalState";
import { RiEdgeNewFill, RiPagesLine } from "react-icons/ri";
import { FcDataConfiguration } from "react-icons/fc";
import { BiSolidReport } from "react-icons/bi";
import { PiTreeStructureFill } from "react-icons/pi";
import { GrDocumentTxt } from "react-icons/gr";
import { CgInternal } from "react-icons/cg";
import { TbHelpOctagonFilled, TbReport } from "react-icons/tb";
import { FaSquareInstagram } from "react-icons/fa6";
import { TiNews } from "react-icons/ti";

const style = {
  color: "#FFF",
};
const activeStyle = {
  color: "#FFF",
  fontWeight: "bold",
};

const Sidebar = () => {
  const [seoMenuOpen, setSeoMenuOpen] = useState(false);
  const [blogsMenuOpen, setBlogsMenuOpen] = useState(false);
  const [workspaceMenuOpen, setWorkspaceMenuOpen] = useState(false);
  const [asoMenuOpen, setAsoMenuOpen] = useState(false);
  const [asoSettingsMenuOpen, setAsoSettingsMenuOpen] = useState(false);
  const [guestPostingMenuOpen, setGuestPostingMenuOpen] = useState(false);
  const [onboardMenuOpen, setonboardMenuOpen] = useState(false);
  const { user, isVerified } = useContext(GlobalContext);
  console.log(user);
  const location = useLocation();

  const isPathActive = (path) => {
    return location.pathname.startsWith(path);
  };
  const handleMenuClick = (menuState) => {
    if (
      menuState !== "seoMenuOpen" &&
      menuState !== "blogsMenuOpen" &&
      menuState !== "workspaceMenuOpen" &&
      menuState !== "asoMenuOpen" &&
      menuState !== "asoSettingsMenuOpen" &&
      menuState !== "guestPostingMenuOpen" &&
      menuState !== "onboardMenuOpen"
    ) {
      return;
    }
    toggleMenuState(menuState);
  };

  const toggleMenuState = (menuState) => {
    if (menuState === "seoMenuOpen") setSeoMenuOpen(!seoMenuOpen);
    if (menuState === "blogsMenuOpen") setBlogsMenuOpen(!blogsMenuOpen);
    if (menuState === "workspaceMenuOpen")
      setWorkspaceMenuOpen(!workspaceMenuOpen);
    if (menuState === "asoMenuOpen") setAsoMenuOpen(!asoMenuOpen);
    if (menuState === "asoSettingsMenuOpen")
      setAsoSettingsMenuOpen(!asoSettingsMenuOpen);
    if (menuState === "guestPostingMenuOpen")
      setGuestPostingMenuOpen(!guestPostingMenuOpen);
    if (menuState === "onboardMenuOpen") setonboardMenuOpen(!onboardMenuOpen);
  };
  useEffect(() => {
    // fetchUserInfo();
    onRouteChanged();

    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", () => {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", () => {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });

    return () => {
      document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
        el.removeEventListener("mouseover", () => {});
        el.removeEventListener("mouseout", () => {});
      });
    };
  }, []);

  const onRouteChanged = () => {
    document.querySelector("#sidebar").classList.remove("active");
    const dropdownPaths = [
      { path: "/Go_seo", state: "seoMenuOpen" },
      { path: "/Go_blog", state: "blogsMenuOpen" },
    ];

    dropdownPaths.forEach((obj) => {
      if (isPathActive(obj.path)) {
        toggleMenuState(obj.state);
      }
    });
  };
  // Helper function to determine visibility of menu items
  const canViewMenu = (menuType) => {
    const userType = user?.user_type; // Get user_type from user context
    if (menuType === "A") {
      return userType === "A";
    }
    if (menuType === "U") {
      return userType === "U";
    }
    if (menuType === "S") {
      return userType === "S";
    }
    return false;
  };

  return (
    <nav
      style={{ background: "#31393C", minHeight: "100vh" }}
      className="sidebar sidebar-offcanvas"
      id="sidebar"
    >
      <div className="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top">
        <a className="sidebar-brand brand-logo" href="index.html">
          <strong style={{ color: "#FFF" }}>GoSeo</strong>
        </a>
        <a className="sidebar-brand brand-logo-mini" href="index.html">
          Go
        </a>
      </div>

      <ul
        className="nav"
        style={{
          overflowY: "scroll",
          height: "100vh",
          scrollbarWidth: "none", // For Firefox
          msOverflowStyle: "none", // For IE and Edge
        }}
      >
        {/* Onboard - Only for user_type "A" */}
        {canViewMenu("A") && (
          <>
            <li
              className={
                onboardMenuOpen
                  ? "nav-item menu-items active"
                  : "nav-item menu-items"
              }
              style={{
                ...(onboardMenuOpen ? activeStyle : style),
                // marginTop: "10px",
              }}
            >
              <div
                className={
                  onboardMenuOpen ? "nav-link menu-expanded" : "nav-link"
                }
                onClick={() => handleMenuClick("onboardMenuOpen")}
                data-toggle="collapse"
              >
                <span className="p-2">
                  <IoShareSocialSharp
                    style={{
                      color: "#A5B68D",
                      fontSize: "1.2rem",
                      marginRight: "1rem",
                    }}
                  />
                </span>
                <span style={{ color: "#FFF" }} className="menu-title">
                  Onboard
                </span>

                <i className="menu-arrow" style={{ color: "pink" }}></i>
              </div>
              <Collapse in={onboardMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li
                    style={{
                      ...(isPathActive("/Go_seo/Comapny_onboard")
                        ? activeStyle
                        : style),
                      marginTop: "10px",
                    }}
                    className={
                      isPathActive("/Go_seo/Comapny_onboard")
                        ? "nav-item menu-items active"
                        : "nav-item menu-items"
                    }
                  >
                    <Link className="nav-link" to="/Go_seo/Comapny_onboard">
                      <span className="p-2">
                        <MdOutlineArticle
                          style={{ color: "#A5B68D", fontSize: "1rem" }}
                        />
                      </span>
                      <span style={{ color: "#FFF" }} className="menu-title">
                        Company
                      </span>
                    </Link>
                  </li>
                  <li
                    style={{
                      ...(isPathActive("/Go_seo/Guestpost_Onboard")
                        ? activeStyle
                        : style),
                    }}
                    className={
                      isPathActive("/Go_seo/Guestpost_Onboard")
                        ? "nav-item menu-items active"
                        : "nav-item menu-items"
                    }
                  >
                    <Link className="nav-link" to="/Go_seo/Guestpost_Onboard">
                      <span className="p-2">
                        <MdOutlineArticle
                          style={{
                            color: "#A5B68D",
                            // marginRight: "1rem",
                            fontSize: "1rem",
                          }}
                        />
                      </span>
                      <span style={{ color: "#FFF" }} className="menu-title">
                        Guest Post
                      </span>
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li>
            <li
              style={{
                ...(isPathActive("/Go_seo/Guest_post_request")
                  ? activeStyle
                  : style),
                // marginTop: ".5rem",
                // ...(!isVerified && { pointerEvents: "none", opacity: "0.5" }),
              }}
              className={
                isPathActive("/Go_seo/Guest_post_request")
                  ? "nav-item menu-items active"
                  : "nav-item menu-items"
              }
            >
              <Link className="nav-link" to="/Go_seo/Guest_post_request">
                <span className="p-2">
                  <MdOutlineArticle
                    style={{
                      color: "#A5B68D",
                      marginRight: "1rem",
                      fontSize: "1.2rem",
                    }}
                  />
                </span>
                <span className="menu-title" style={{ color: "#FFF" }}>
                  Guest Post
                </span>
                &nbsp; &nbsp; &nbsp;
              </Link>
            </li>
            <li
              style={{
                ...(isPathActive("/Go_seo/Project") ? activeStyle : style),
                marginTop: ".4rem",
                // ...(!isVerified && { pointerEvents: "none", opacity: "0.5" }),
              }}
              className={
                isPathActive("/Go_seo/Project")
                  ? "nav-item menu-items active"
                  : "nav-item menu-items"
              }
            >
              <Link className="nav-link" to="/Go_seo/Project">
                <span className="p-2">
                  <IoMdAddCircle
                    style={{
                      color: "#C0EBA6",
                      marginRight: "1rem",
                      fontSize: "1.2rem",
                    }}
                  />
                </span>
                <span className="menu-title" style={{ color: "#FFF" }}>
                  Add Website
                </span>
                &nbsp; &nbsp; &nbsp;
              </Link>
            </li>
            <li
              style={{
                ...(isPathActive("/Go_seo/Access") ? activeStyle : style),
                // marginTop: ".5rem",
              }}
              className={
                isPathActive("/Go_seo/Access")
                  ? "nav-item menu-items active"
                  : "nav-item menu-items"
              }
            >
              <Link
                className="nav-link"
                to="/Go_seo/Access"
                style={{ color: "#FFF" }}
              >
                <span className="p-2">
                  <MdDesktopAccessDisabled
                    style={{
                      color: !isVerified ? "#AAA" : "#15F5BA",
                      marginRight: "1rem",
                      fontSize: "1.2rem",
                    }}
                  />
                </span>
                <span className="menu-title">Access </span>
              </Link>
            </li>
          </>
        )}

        {/* ASO - Only for user_type "U" */}
        {canViewMenu("U") && (
          <>
            <li
              style={{
                ...(isPathActive("/Go_seo/Dashboard") ? activeStyle : style),
                // marginTop: "12px",
              }}
              className={
                isPathActive("/Go_seo/Dashboard")
                  ? "nav-item menu-items active"
                  : "nav-item menu-items"
              }
            >
              <Link
                className="nav-link"
                to="/Go_seo/Dashboard"
                onClick={(e) => {
                  if (isPathActive("/Go_seo/Dashboard")) {
                    e.preventDefault();
                  }
                }}
              >
                <span className="p-2">
                  <FaChartPie
                    style={{
                      color: "#CDC1FF",
                      marginRight: "1rem",
                      fontSize: "1.2rem",
                    }}
                  />
                </span>
                <span
                  style={{ color: "#FFF", fontSize: ".8rem" }}
                  className="menu-title"
                >
                  <Trans>Dashboard</Trans>
                </span>
              </Link>
            </li>
            <li
              style={{
                ...(isPathActive("/Go_seo/Domain_page") ? activeStyle : style),
                // marginTop: ".5rem",
                // ...(!isVerified && { pointerEvents: "none", opacity: "0.5" }),
              }}
              className={
                isPathActive("/Go_seo/Domain_page")
                  ? "nav-item menu-items active"
                  : "nav-item menu-items"
              }
            >
              <Link className="nav-link" to="/Go_seo/Domain_page">
                <span className="p-2">
                  <FaCheckDouble
                    style={{
                      color: !isVerified ? "#AAA" : "#009990",
                      marginRight: "1rem",
                      fontSize: "1.2rem",
                    }}
                  />
                </span>
                <span className="menu-title" style={{ color: "#FFF" }}>
                  {" "}
                  DA/PA
                </span>
              </Link>
            </li>
            <li
              style={{
                ...(isPathActive("/Go_seo/Seo_analyzer") ? activeStyle : style),
                marginTop: "10px",
              }}
              className={
                isPathActive("/Go_seo/Seo_analyzer")
                  ? "nav-item menu-items active"
                  : "nav-item menu-items"
              }
            >
              <Link className="nav-link" to="/Go_seo/Seo_analyzer">
                <span className="p-2">
                  <BiSolidReport
                    style={{
                      color: "#FADFA1",
                      marginRight: "1rem",
                      fontSize: "1.2rem",
                    }}
                  />
                </span>
                <span style={{ color: "#FFF" }} className="menu-title">
                  SEO Audit
                </span>
              </Link>
            </li>

            {/* <li
              style={{
                ...(isPathActive("/Go_seo/Productblog") ? activeStyle : style),
                marginTop: ".5rem",
                // ...(!isVerified && { pointerEvents: "none", opacity: "0.5" }),
              }}
              className={
                isPathActive("/Go_seo/Productblog")
                  ? "nav-item menu-items active"
                  : "nav-item menu-items"
              }
            >
              <Link className="nav-link" to="/Go_seo/Productblog">
                <span className="p-2">
                  <FaRegNewspaper
                    style={{
                      color: "#C0EBA6",
                      marginRight: "1rem",
                      fontSize: "1.2rem",
                    }}
                  />
                </span>
                <span className="menu-title" style={{ color: "#FFF" }}>
                  Site Blog
                </span>
                &nbsp; &nbsp; &nbsp;
              </Link>
            </li> */}

            <li
              style={{
                ...(isPathActive("/Go_seo/Guestpost") ? activeStyle : style),
                // marginTop: ".5rem",
                // ...(!isVerified && { pointerEvents: "none", opacity: "0.5" }),
              }}
              className={
                isPathActive("/Go_seo/Guestpost")
                  ? "nav-item menu-items active"
                  : "nav-item menu-items"
              }
            >
              <Link className="nav-link" to="/Go_seo/Guestpost">
                <span className="p-2">
                  <MdOutlineArticle
                    style={{
                      color: "#A5B68D",
                      marginRight: "1rem",
                      fontSize: "1.2rem",
                    }}
                  />
                </span>
                <span className="menu-title" style={{ color: "#FFF" }}>
                  Guest Post
                </span>
                &nbsp; &nbsp; &nbsp;
              </Link>
            </li>

            <li
              className="nav-item menu-items"
              style={{
                // marginTop: ".5rem",
                ...(seoMenuOpen ? { backgroundColor: "" } : {}),
                ...(!isVerified && { pointerEvents: "none", opacity: "0.5" }),
              }}
            >
              <div
                className={seoMenuOpen ? "nav-link menu-expanded" : "nav-link"}
                onClick={() => handleMenuClick("seoMenuOpen")}
                data-toggle="collapse"
              >
                <span className="p-2">
                  <RiEdgeNewFill
                    style={{
                      color: "#E5D9F2",
                      marginRight: "1rem",
                      fontSize: "1.2rem",
                    }}
                  />
                </span>
                <span style={{ color: "#FFF" }} className="menu-title">
                  <Trans>SEO</Trans>
                </span>
                &nbsp; &nbsp; &nbsp;
                {!isVerified && (
                  <FaLock
                    style={{
                      color: "#fff",
                      marginRight: "0.5rem",
                      fontSize: "1rem",
                    }}
                  />
                )}
                <i className="menu-arrow" style={{ color: "pink" }}></i>
              </div>
              <Collapse in={seoMenuOpen}>
                <div>
                  <ul className="nav flex-column sub-menu">
                    {/* SEO Audit */}

                    {/* Campaign */}
                    <li
                      style={
                        isPathActive("/Go_seo/Campaign") ? activeStyle : style
                      }
                      className={
                        isPathActive("/Go_seo/Campaign")
                          ? "nav-item menu-items active"
                          : "nav-item menu-items"
                      }
                    >
                      <Link className="nav-link" to="/Go_seo/Campaign">
                        <span className="p-2">
                          <MdCampaign
                            style={{
                              color: "#98DED9",
                              marginRight: "1rem",
                              fontSize: "1.5rem",
                            }}
                          />
                        </span>
                        <span style={{ color: "#FFF" }} className="menu-title">
                          Campaign
                        </span>
                      </Link>
                    </li>

                    {/* Workspace Menu */}
                    <li
                      className={
                        workspaceMenuOpen
                          ? "nav-item menu-items active"
                          : "nav-item menu-items"
                      }
                      style={{
                        ...(workspaceMenuOpen ? activeStyle : style),
                        // marginTop: ".5rem",
                      }}
                    >
                      <div
                        className={
                          workspaceMenuOpen
                            ? "nav-link menu-expanded"
                            : "nav-link"
                        }
                        onClick={() => handleMenuClick("workspaceMenuOpen")}
                        data-toggle="collapse"
                      >
                        <span className="p-2">
                          <FcDataConfiguration
                            style={{
                              color: "Plum",
                              marginRight: "1rem",
                              fontSize: "1.2rem",
                            }}
                          />
                        </span>
                        <span style={{ color: "#FFF" }} className="menu-title">
                          <Trans>WorkSpace</Trans>
                        </span>
                        <i className="menu-arrow" style={{ color: "pink" }}></i>
                      </div>
                      <Collapse in={workspaceMenuOpen}>
                        <div>
                          <ul className="nav px-0 mx-3 flex-column sub-menu">
                            {/* Sitemap */}
                            <li
                              style={{
                                ...(isPathActive("/Go_seo/Sitemap")
                                  ? activeStyle
                                  : style),
                                // marginTop: "12px",
                              }}
                              className={
                                isPathActive("/Go_seo/Sitemap")
                                  ? "nav-item menu-items active"
                                  : "nav-item menu-items"
                              }
                            >
                              <Link className="nav-link" to="/Go_seo/Sitemap">
                                <span className="p-2">
                                  <FaSitemap
                                    style={{
                                      color: "#F05A7E",
                                      marginRight: "1rem",
                                      fontSize: "1.2rem",
                                    }}
                                  />
                                </span>
                                <span
                                  style={{ color: "#FFF" }}
                                  className="menu-title"
                                >
                                  <Trans>Sitemap</Trans>
                                </span>
                              </Link>
                            </li>

                            {/* Backlinks */}
                            <li
                              style={
                                isPathActive("/Go_seo/Backlinks_Sites")
                                  ? activeStyle
                                  : style
                              }
                              className={
                                isPathActive("/Go_seo/Backlinks_Sites")
                                  ? "nav-item menu-items active"
                                  : "nav-item menu-items"
                              }
                            >
                              <Link
                                className="nav-link"
                                to="/Go_seo/Backlinks_Sites"
                              >
                                <span className="p-2">
                                  <FaLink
                                    style={{
                                      color: "#A5B68D",
                                      marginRight: "1rem",
                                      fontSize: "1rem",
                                    }}
                                  />
                                </span>
                                <span
                                  style={{ color: "#FFF" }}
                                  className="menu-title"
                                >
                                  Backlinks
                                </span>
                              </Link>
                            </li>

                            {/* Other Links */}
                            <li
                              style={
                                isPathActive("/Go_seo/Pages")
                                  ? activeStyle
                                  : style
                              }
                              className={
                                isPathActive("/Go_seo/Pages")
                                  ? "nav-item menu-items active"
                                  : "nav-item menu-items"
                              }
                            >
                              <Link className="nav-link" to="/Go_seo/Pages">
                                <span className="p-2">
                                  <RiPagesLine
                                    style={{
                                      color: "#F6E96B",
                                      marginRight: "1rem",
                                      fontSize: "1.2rem",
                                    }}
                                  />
                                </span>
                                <span
                                  style={{ color: "#FFF" }}
                                  className="menu-title"
                                >
                                  Pages
                                </span>
                              </Link>
                            </li>

                            <li
                              style={
                                isPathActive("/Go_seo/Social_links")
                                  ? activeStyle
                                  : style
                              }
                              className={
                                isPathActive("/Go_seo/Social_links")
                                  ? "nav-item menu-items active"
                                  : "nav-item menu-items"
                              }
                            >
                              <Link
                                className="nav-link"
                                to="/Go_seo/Social_links"
                              >
                                <span className="p-2">
                                  <IoShareSocialSharp
                                    style={{
                                      color: "#6EACDA",
                                      marginRight: "1rem",
                                      fontSize: "1.2rem",
                                    }}
                                  />
                                </span>
                                <span
                                  style={{ color: "#FFF" }}
                                  className="menu-title"
                                >
                                  Social <br /> Links
                                </span>
                              </Link>
                            </li>
                            <li
                              style={{
                                ...(isPathActive("/Go_seo/Internal_links")
                                  ? activeStyle
                                  : style),
                              }}
                              className={
                                isPathActive("/Go_seo/Internal_links")
                                  ? "nav-item menu-items active"
                                  : "nav-item menu-items"
                              }
                            >
                              <Link
                                className="nav-link"
                                to="/Go_seo/Internal_links"
                              >
                                <span className="p-2">
                                  <CgInternal
                                    style={{
                                      color: "#88D66C",
                                      marginRight: "1rem",
                                      fontSize: "1.2rem",
                                    }}
                                  />
                                </span>
                                <span
                                  style={{ color: "#FFF" }}
                                  className="menu-title"
                                >
                                  Internal
                                  <br /> Links
                                </span>
                              </Link>
                            </li>

                            <li
                              style={{
                                ...(isPathActive("/Go_seo/Robot_txt")
                                  ? activeStyle
                                  : style),
                              }}
                              className={
                                isPathActive("/Go_seo/Robot_txt")
                                  ? "nav-item menu-items active"
                                  : "nav-item menu-items"
                              }
                            >
                              <Link className="nav-link" to="/Go_seo/Robot_txt">
                                <span className="p-2">
                                  <GrDocumentTxt
                                    style={{
                                      color: "Plum",
                                      marginRight: "1rem",
                                      fontSize: "1.2rem",
                                    }}
                                  />
                                </span>
                                <span
                                  style={{ color: "#FFF" }}
                                  className="menu-title"
                                >
                                  Robots.txt
                                </span>
                              </Link>
                            </li>

                            <li
                              style={{
                                ...(isPathActive("/Go_seo/Structure_data")
                                  ? activeStyle
                                  : style),
                              }}
                              className={
                                isPathActive("/Go_seo/Structure_data")
                                  ? "nav-item menu-items active"
                                  : "nav-item menu-items"
                              }
                            >
                              <Link
                                className="nav-link"
                                to="/Go_seo/Structure_data"
                              >
                                <span className="p-2">
                                  <PiTreeStructureFill
                                    style={{
                                      color: "#009990",
                                      marginRight: "1rem",
                                      fontSize: "1.2rem",
                                    }}
                                  />
                                </span>
                                <span
                                  style={{ color: "#FFF" }}
                                  className="menu-title"
                                >
                                  Structure
                                  <br /> Data
                                </span>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </Collapse>
                    </li>
                  </ul>
                </div>
              </Collapse>
            </li>
            {/* <li
              className={
                asoSettingsMenuOpen
                  ? "nav-item menu-items active"
                  : "nav-item menu-items"
              }
              style={{
                ...(asoSettingsMenuOpen ? activeStyle : style),
                ...(!isVerified && { pointerEvents: "none", opacity: "0.5" }),
              }}
            >
              <div
                className={
                  asoSettingsMenuOpen ? "nav-link menu-expanded" : "nav-link"
                }
                onClick={
                  !isVerified
                    ? undefined
                    : () => handleMenuClick("asoSettingsMenuOpen")
                }
                data-toggle="collapse"
              >
                <span className="p-2">
                  <IoShareSocialSharp
                    style={{
                      color: "#A5B68D",
                      marginRight: "1rem",
                      fontSize: "1.2rem",
                    }}
                  />
                </span>
                <span style={{ color: "#FFF" }} className="menu-title">
                  SMO
                </span>
                &nbsp; &nbsp; &nbsp;
                {!isVerified && (
                  <FaLock
                    style={{
                      color: "#fff",
                      marginRight: "0.5rem",
                      fontSize: "1rem",
                    }}
                  />
                )}
                <i className="menu-arrow" style={{ color: "pink" }}></i>
              </div>
              <Collapse in={asoSettingsMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li
                    style={{
                      ...(isPathActive("/Go_seo/SMO_Dashboard")
                        ? activeStyle
                        : style),
                      marginTop: "10px",
                    }}
                    className={
                      isPathActive("/Go_seo/SMO_Dashboard")
                        ? "nav-item menu-items active"
                        : "nav-item menu-items"
                    }
                  >
                    <Link className="nav-link" to="/Go_seo/SMO_Dashboard">
                      <span className="p-2">
                        <TbReport
                          style={{
                            color: "#E8BCB9",
                            marginRight: "1rem",
                            fontSize: "1.2rem",
                          }}
                        />
                      </span>
                      <span style={{ color: "#FFF" }} className="menu-title">
                        Dashboard
                      </span>
                    </Link>
                  </li>
                  <li
                    className={
                      isPathActive("/Go_seo/Youtube")
                        ? "nav-item menu-items active"
                        : "nav-item menu-items"
                    }
                    style={{
                      ...(isPathActive("/Go_seo/Youtube")
                        ? activeStyle
                        : style),
                      marginTop: "px",
                    }}
                  >
                    <Link className="nav-link" to="/Go_seo/Youtube">
                      <span className="p-2">
                        <FaYoutube
                          style={{
                            color: "red",
                            marginRight: "1rem",
                            fontSize: "1.2rem",
                          }}
                        />
                      </span>
                      <span style={{ color: "#FFF" }} className="menu-title">
                        Youtube
                      </span>
                    </Link>
                  </li>
                  <li
                    className={
                      isPathActive("/Go_seo/Facebook")
                        ? "nav-item menu-items active"
                        : "nav-item menu-items"
                    }
                    style={{
                      ...(isPathActive("/Go_seo/Facebook")
                        ? activeStyle
                        : style),
                      marginTop: "",
                    }}
                  >
                    <Link className="nav-link" to="/Go_seo/Facebook">
                      <span className="p-2">
                        <FaFacebook
                          style={{
                            color: "#0D92F4",
                            marginRight: "1rem",
                            fontSize: "1.2rem",
                          }}
                        />
                      </span>
                      <span style={{ color: "#FFF" }} className="menu-title">
                        Facebook
                      </span>
                    </Link>
                  </li>
                  <li
                    className={
                      isPathActive("/Go_seo/Instagram")
                        ? "nav-item menu-items active"
                        : "nav-item menu-items"
                    }
                    style={{
                      ...(isPathActive("/Go_seo/Instagram")
                        ? activeStyle
                        : style),
                      marginTop: "",
                    }}
                  >
                    <Link className="nav-link" to="/Go_seo/Instagram">
                      <span className="p-2">
                        <FaSquareInstagram
                          style={{
                            color: "#FF77B7",
                            marginRight: "1rem",
                            fontSize: "1.2rem",
                          }}
                        />
                      </span>
                      <span style={{ color: "#FFF" }} className="menu-title">
                        Instagram
                      </span>
                    </Link>
                  </li>
                  <li
                    className={
                      isPathActive("/Go_seo/Linkedin")
                        ? "nav-item menu-items active"
                        : "nav-item menu-items"
                    }
                    style={{
                      ...(isPathActive("/Go_seo/Linkedin")
                        ? activeStyle
                        : style),
                      marginTop: "",
                    }}
                  >
                    <Link className="nav-link" to="/Go_seo/Linkedin">
                      <span className="p-2">
                        <FaLinkedin
                          style={{
                            color: "#0D92F4",
                            marginRight: "1rem",
                            fontSize: "1.2rem",
                          }}
                        />
                      </span>
                      <span style={{ color: "#FFF" }} className="menu-title">
                        Linkedin
                      </span>
                    </Link>
                  </li>
                  <li
                    style={
                      isPathActive("/Go_seo/Reporting") ? activeStyle : style
                    }
                    className={
                      isPathActive("/Go_seo/Reporting")
                        ? "nav-item menu-items active"
                        : "nav-item menu-items"
                    }
                  >
                    <Link className="nav-link" to="/Go_seo/Reporting">
                      <span className="p-2">
                        <FaLink
                          style={{
                            color: "#A5B68D",
                            marginRight: "1rem",
                            fontSize: "1rem",
                          }}
                        />
                      </span>
                      <span style={{ color: "#FFF" }} className="menu-title">
                        Reports
                      </span>
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li> */}
            <li
              className={
                asoMenuOpen
                  ? "nav-item menu-items active"
                  : "nav-item menu-items"
              }
              style={{
                // marginTop: ".8rem",
                ...(asoMenuOpen ? activeStyle : style),
                ...(!isVerified && { pointerEvents: "none", opacity: "0.5" }),
              }}
            >
              <div
                className={asoMenuOpen ? "nav-link menu-expanded" : "nav-link"}
                onClick={
                  !isVerified ? undefined : () => handleMenuClick("asoMenuOpen")
                } // Pass "asoMenuOpen" as the argument here
                data-toggle="collapse"
              >
                <span className="p-2">
                  <FaMobileAlt
                    style={{
                      color: "#E5D9F2",
                      marginRight: "1rem",
                      fontSize: "1.2rem",
                    }}
                  />
                </span>
                <span style={{ color: "#FFF" }} className="menu-title">
                  ASO
                </span>
                &nbsp; &nbsp; &nbsp;
                {!isVerified && (
                  <FaLock
                    style={{
                      color: "#fff",
                      marginRight: "0.5rem",
                      fontSize: "1rem",
                    }}
                  />
                )}
                <i className="menu-arrow" style={{ color: "pink" }}></i>
              </div>
              <Collapse in={asoMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li
                    className={
                      isPathActive("/Go_aso/Dashboard")
                        ? "nav-item menu-items active"
                        : "nav-item menu-items"
                    }
                    style={
                      isPathActive("/Go_aso/Dashboard") ? activeStyle : style
                    }
                  >
                    <Link className="nav-link" to="/Go_aso/Dashboard">
                      <span className="p-2">
                        <FaChartLine
                          style={{
                            color: "#CDC1FF",
                            marginRight: "1rem",
                            fontSize: "1.2rem",
                          }}
                        />
                      </span>
                      <span style={{ color: "#FFF" }} className="menu-title">
                        ASO Dashboard
                      </span>
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li>

            <li
              style={{
                ...(isPathActive("/Go_seo/Help_Guide") ? activeStyle : style),
                // marginTop: ".5rem",
                // ...(!isVerified && { pointerEvents: "none", opacity: "0.5" }),
              }}
              className={
                isPathActive("/Go_seo/Help_Guide")
                  ? "nav-item menu-items active"
                  : "nav-item menu-items"
              }
            >
              <Link
                className="nav-link"
                to="/Go_seo/Help_Guide"
                style={{ color: "#FFF" }}
              >
                <span className="p-2">
                  <TbHelpOctagonFilled
                    style={{
                      color: "#009990",
                      marginRight: "1rem",
                      fontSize: "1.2rem",
                    }}
                  />
                </span>
                <span className="menu-title">Guide </span>
                &nbsp; &nbsp; &nbsp;
              </Link>
            </li>
          </>
        )}

        {/* All Menus - Only for user_type "S" */}
        {canViewMenu("S") && (
          <>
            {/* <ul
              className="nav"
              style={{ overflowY: "scroll", height: "100vh" }}
            > */}
            <li
              style={{
                ...(isPathActive("/Go_seo/Dashboard") ? activeStyle : style),
                marginTop: "12px",
              }}
              className={
                isPathActive("/Go_seo/Dashboard")
                  ? "nav-item menu-items active"
                  : "nav-item menu-items"
              }
            >
              <Link
                className="nav-link"
                to="/Go_seo/Dashboard"
                onClick={(e) => {
                  if (isPathActive("/Go_seo/Dashboard")) {
                    e.preventDefault();
                  }
                }}
              >
                <span className="p-2">
                  <FaChartPie
                    style={{
                      color: "#CDC1FF",
                      marginRight: "1rem",
                      fontSize: "1.2rem",
                    }}
                  />
                </span>
                <span style={{ color: "#FFF" }} className="menu-title">
                  <Trans>Dashboard</Trans>
                </span>
              </Link>
            </li>
            {/* <li
              style={{
                ...(isPathActive("/Go_seo/Domain_page") ? activeStyle : style),
                // marginTop: ".5rem",
                // ...(!isVerified && { pointerEvents: "none", opacity: "0.5" }),
              }}
              className={
                isPathActive("/Go_seo/Domain_page")
                  ? "nav-item menu-items active"
                  : "nav-item menu-items"
              }
            >
              <Link className="nav-link" to="/Go_seo/Domain_page">
                <span className="p-2">
                  <FaCheckDouble
                    style={{
                      color: !isVerified ? "#AAA" : "#009990",
                      marginRight: "1rem",
                      fontSize: "1.2rem",
                    }}
                  />
                </span>
                <span className="menu-title" style={{ color: "#FFF" }}>
                  {" "}
                  DA/PA
                </span>
              </Link>
            </li> */}
            <li
              style={{
                ...(isPathActive("/Go_seo/Seo_analyzer") ? activeStyle : style),
                // marginTop: "10px",
              }}
              className={
                isPathActive("/Go_seo/Seo_analyzer")
                  ? "nav-item menu-items active"
                  : "nav-item menu-items"
              }
            >
              <Link className="nav-link" to="/Go_seo/Seo_analyzer">
                <span className="p-2">
                  <BiSolidReport
                    style={{
                      color: "#FADFA1",
                      marginRight: "1rem",
                      fontSize: "1.2rem",
                    }}
                  />
                </span>
                <span style={{ color: "#FFF" }} className="menu-title">
                  SEO Audit
                </span>
              </Link>
            </li>
            <li
              style={{
                ...(isPathActive("/Go_seo/Project") ? activeStyle : style),
                // marginTop: ".5rem",
                // ...(!isVerified && { pointerEvents: "none", opacity: "0.5" }),
              }}
              className={
                isPathActive("/Go_seo/Project")
                  ? "nav-item menu-items active"
                  : "nav-item menu-items"
              }
            >
              <Link className="nav-link" to="/Go_seo/Project">
                <span className="p-2">
                  <IoMdAddCircle
                    style={{
                      color: "#C0EBA6",
                      marginRight: "1rem",
                      fontSize: "1.2rem",
                    }}
                  />
                </span>
                <span className="menu-title" style={{ color: "#FFF" }}>
                  Add Website
                </span>
                &nbsp; &nbsp; &nbsp;
              </Link>
            </li>
            <li
              style={{
                ...(isPathActive("/Go_seo/Productblog") ? activeStyle : style),
                // marginTop: ".5rem",
                // ...(!isVerified && { pointerEvents: "none", opacity: "0.5" }),
              }}
              className={
                isPathActive("/Go_seo/Productblog")
                  ? "nav-item menu-items active"
                  : "nav-item menu-items"
              }
            >
              <Link className="nav-link" to="/Go_seo/Productblog">
                <span className="p-2">
                  <FaRegNewspaper
                    style={{
                      color: "#C0EBA6",
                      marginRight: "1rem",
                      fontSize: "1.2rem",
                    }}
                  />
                </span>
                <span className="menu-title" style={{ color: "#FFF" }}>
                  Site Blog
                </span>
                &nbsp; &nbsp; &nbsp;
              </Link>
            </li>
            <li
              style={{
                ...(isPathActive("/Go_seo/Campaign")
                  ? activeStyle
                  : style),marginBottom:"5px"
              }}
              className={
                isPathActive("/Go_seo/Campaign")
                  ? "nav-item menu-items active"
                  : "nav-item menu-items"
              }
            >
              <Link className="nav-link" to="/Go_seo/Campaign">
                <span className="p-2">
                  <MdCampaign
                    style={{
                      color: "#98DED9",
                      marginRight: "1rem",
                      fontSize: "1.5rem",
                    }}
                  />
                </span>
                <span style={{ color: "#FFF" }} className="menu-title">
                  Campaign
                </span>
              </Link>
            </li>
            {/* <li
              className={
                guestPostingMenuOpen
                  ? "nav-item menu-items active"
                  : "nav-item menu-items"
              }
              style={{
                ...(guestPostingMenuOpen ? activeStyle : style),
                // marginTop: "10px",
                // ...(!isVerified && { pointerEvents: "none", opacity: "0.5" }),
              }}
            >
              <div
                className={
                  guestPostingMenuOpen ? "nav-link menu-expanded" : "nav-link"
                }
                onClick={() => handleMenuClick("guestPostingMenuOpen")}
                // onClick={
                //   !isVerified
                //     ? undefined
                //     : () => handleMenuClick("guestPostingMenuOpen")
                // }
                data-toggle="collapse"
              >
                <span className="p-2">
                  <IoShareSocialSharp
                    style={{
                      color: "#A5B68D",
                      fontSize: "1.2rem",
                      marginRight: "1rem",
                    }}
                  />
                </span>
                <span style={{ color: "#FFF" }} className="menu-title">
                  Guest Post
                </span>

                <i className="menu-arrow" style={{ color: "pink" }}></i>
              </div>
              <Collapse in={guestPostingMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li
                    style={{
                      ...(isPathActive("/Go_seo/Guestpost")
                        ? activeStyle
                        : style),
                      // marginTop: "10px",
                    }}
                    className={
                      isPathActive("/Go_seo/Guestpost")
                        ? "nav-item menu-items active"
                        : "nav-item menu-items"
                    }
                  >
                    <Link className="nav-link" to="/Go_seo/Guestpost">
                      <span className="p-2">
                        <MdOutlineArticle
                          style={{ color: "#A5B68D", fontSize: "1rem" }}
                        />
                      </span>
                      <span style={{ color: "#FFF" }} className="menu-title">
                        Guest Post
                      </span>
                    </Link>
                  </li>
                  <li
                    style={{
                      ...(isPathActive("/Go_seo/Guest_post_request")
                        ? activeStyle
                        : style),
                    }}
                    className={
                      isPathActive("/Go_seo/Guest_post_request")
                        ? "nav-item menu-items active"
                        : "nav-item menu-items"
                    }
                  >
                    <Link className="nav-link" to="/Go_seo/Guest_post_request">
                      <span className="p-2">
                        <MdOutlineArticle
                          style={{
                            color: "#A5B68D",
                            marginRight: "1rem",
                            fontSize: "1rem",
                          }}
                        />
                      </span>
                      <span style={{ color: "#FFF" }} className="menu-title">
                        Guest Post
                        <br /> Request
                      </span>
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li> */}
            <li
              className="nav-item menu-items"
              style={{
                // marginTop: ".5rem",
                ...(seoMenuOpen ? { backgroundColor: "" } : {}),
                ...(!isVerified && { pointerEvents: "none", opacity: "0.5" }),
              }}
            >
              <div
                className={seoMenuOpen ? "nav-link menu-expanded" : "nav-link"}
                onClick={() => handleMenuClick("seoMenuOpen")}
                data-toggle="collapse"
              >
                <span className="p-2">
                  <RiEdgeNewFill
                    style={{
                      color: "#E5D9F2",
                      marginRight: "1rem",
                      fontSize: "1.2rem",
                    }}
                  />
                </span>
                <span style={{ color: "#FFF" }} className="menu-title">
                  <Trans>SEO</Trans>
                </span>
                &nbsp; &nbsp; &nbsp;
                {!isVerified && (
                  <FaLock
                    style={{
                      color: "#fff",
                      marginRight: "0.5rem",
                      fontSize: "1rem",
                    }}
                  />
                )}
                <i className="menu-arrow" style={{ color: "pink" }}></i>
              </div>
              <Collapse in={seoMenuOpen}>
                <div>
                  <ul className="nav flex-column sub-menu">
                    {/* SEO Audit */}
                    <li
                      style={
                        isPathActive("/Go_seo/Pages") ? activeStyle : style
                      }
                      className={
                        isPathActive("/Go_seo/Pages")
                          ? "nav-item menu-items active"
                          : "nav-item menu-items"
                      }
                    >
                      <Link className="nav-link" to="/Go_seo/Pages">
                        <span className="p-2">
                          <RiPagesLine
                            style={{
                              color: "#F6E96B",
                              marginRight: "1rem",
                              fontSize: "1.2rem",
                            }}
                          />
                        </span>
                        <span style={{ color: "#FFF" }} className="menu-title">
                          Pages
                        </span>
                      </Link>
                    </li>
                    <li
                      style={{
                        ...(isPathActive("/Go_seo/Structure_data")
                          ? activeStyle
                          : style),
                      }}
                      className={
                        isPathActive("/Go_seo/Structure_data")
                          ? "nav-item menu-items active"
                          : "nav-item menu-items"
                      }
                    >
                      <Link className="nav-link" to="/Go_seo/Structure_data">
                        <span className="p-2">
                          <PiTreeStructureFill
                            style={{
                              color: "#009990",
                              marginRight: "1rem",
                              fontSize: "1.2rem",
                            }}
                          />
                        </span>
                        <span style={{ color: "#FFF" }} className="menu-title">
                          Structure
                          <br /> Data
                        </span>
                      </Link>
                    </li>
                    <li
                      style={
                        isPathActive("/Go_seo/Backlinks_Sites")
                          ? activeStyle
                          : style
                      }
                      className={
                        isPathActive("/Go_seo/Backlinks_Sites")
                          ? "nav-item menu-items active"
                          : "nav-item menu-items"
                      }
                    >
                      <Link className="nav-link" to="/Go_seo/Backlinks_Sites">
                        <span className="p-2">
                          <FaLink
                            style={{
                              color: "#A5B68D",
                              marginRight: "1rem",
                              fontSize: "1rem",
                            }}
                          />
                        </span>
                        <span style={{ color: "#FFF" }} className="menu-title">
                          Backlinks
                        </span>
                      </Link>
                    </li>
                    <li
                      style={{
                        ...(isPathActive("/Go_seo/Internal_links")
                          ? activeStyle
                          : style),
                      }}
                      className={
                        isPathActive("/Go_seo/Internal_links")
                          ? "nav-item menu-items active"
                          : "nav-item menu-items"
                      }
                    >
                      <Link className="nav-link" to="/Go_seo/Internal_links">
                        <span className="p-2">
                          <CgInternal
                            style={{
                              color: "#88D66C",
                              marginRight: "1rem",
                              fontSize: "1.2rem",
                            }}
                          />
                        </span>
                        <span style={{ color: "#FFF" }} className="menu-title">
                          Internal
                          <br /> Links
                        </span>
                      </Link>
                    </li>
                    <li
                      style={{
                        ...(isPathActive("/Go_seo/Sitemap")
                          ? activeStyle
                          : style),
                        // marginTop: "12px",
                      }}
                      className={
                        isPathActive("/Go_seo/Sitemap")
                          ? "nav-item menu-items active"
                          : "nav-item menu-items"
                      }
                    >
                      <Link className="nav-link" to="/Go_seo/Sitemap">
                        <span className="p-2">
                          <FaSitemap
                            style={{
                              color: "#F05A7E",
                              marginRight: "1rem",
                              fontSize: "1.2rem",
                            }}
                          />
                        </span>
                        <span style={{ color: "#FFF" }} className="menu-title">
                          <Trans>Sitemap</Trans>
                        </span>
                      </Link>
                    </li>

                    <li
                      style={
                        isPathActive("/Go_seo/Social_links")
                          ? activeStyle
                          : style
                      }
                      className={
                        isPathActive("/Go_seo/Social_links")
                          ? "nav-item menu-items active"
                          : "nav-item menu-items"
                      }
                    >
                      <Link className="nav-link" to="/Go_seo/Social_links">
                        <span className="p-2">
                          <IoShareSocialSharp
                            style={{
                              color: "#6EACDA",
                              marginRight: "1rem",
                              fontSize: "1.2rem",
                            }}
                          />
                        </span>
                        <span style={{ color: "#FFF" }} className="menu-title">
                          Social <br /> Links
                        </span>
                      </Link>
                    </li>

                    <li
                      style={{
                        ...(isPathActive("/Go_seo/Robot_txt")
                          ? activeStyle
                          : style),marginBottom:"5px"
                      }}
                      className={
                        isPathActive("/Go_seo/Robot_txt")
                          ? "nav-item menu-items active"
                          : "nav-item menu-items"
                      }
                    >
                      <Link className="nav-link" to="/Go_seo/Robot_txt">
                        <span className="p-2">
                          <GrDocumentTxt
                            style={{
                              color: "Plum",
                              marginRight: "1rem",
                              fontSize: "1.2rem",
                            }}
                          />
                        </span>
                        <span style={{ color: "#FFF" }} className="menu-title">
                          Robots.txt
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </Collapse>
            </li>
            {/* <li
              className={
                asoSettingsMenuOpen
                  ? "nav-item menu-items active"
                  : "nav-item menu-items"
              }
              style={{
                ...(asoSettingsMenuOpen ? activeStyle : style),
                ...(!isVerified && { pointerEvents: "none", opacity: "0.5" }),
              }}
            >
              <div
                className={
                  asoSettingsMenuOpen ? "nav-link menu-expanded" : "nav-link"
                }
                onClick={
                  !isVerified
                    ? undefined
                    : () => handleMenuClick("asoSettingsMenuOpen")
                }
                data-toggle="collapse"
              >
                <span className="p-2">
                  <IoShareSocialSharp
                    style={{
                      color: "#A5B68D",
                      marginRight: "1rem",
                      fontSize: "1.2rem",
                    }}
                  />
                </span>
                <span style={{ color: "#FFF" }} className="menu-title">
                  SMO
                </span>
                &nbsp; &nbsp; &nbsp;
                {!isVerified && (
                  <FaLock
                    style={{
                      color: "#fff",
                      marginRight: "0.5rem",
                      fontSize: "1rem",
                    }}
                  />
                )}
                <i className="menu-arrow" style={{ color: "pink" }}></i>
              </div>
              <Collapse in={asoSettingsMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li
                    style={{
                      ...(isPathActive("/Go_seo/SMO_Dashboard")
                        ? activeStyle
                        : style),
                      marginTop: "5px",
                    }}
                    className={
                      isPathActive("/Go_seo/SMO_Dashboard")
                        ? "nav-item menu-items active"
                        : "nav-item menu-items"
                    }
                  >
                    <Link className="nav-link" to="/Go_seo/SMO_Dashboard">
                      <span className="p-2">
                        <TbReport
                          style={{
                            color: "#E8BCB9",
                            marginRight: "1rem",
                            fontSize: "1.2rem",
                          }}
                        />
                      </span>
                      <span style={{ color: "#FFF" }} className="menu-title">
                        Dashboard
                      </span>
                    </Link>
                  </li>
                  <li
                    className={
                      isPathActive("/Go_seo/Youtube")
                        ? "nav-item menu-items active"
                        : "nav-item menu-items"
                    }
                    style={{
                      ...(isPathActive("/Go_seo/Youtube")
                        ? activeStyle
                        : style),
                      marginTop: "px",
                    }}
                  >
                    <Link className="nav-link" to="/Go_seo/Youtube">
                      <span className="p-2">
                        <FaYoutube
                          style={{
                            color: "red",
                            marginRight: "1rem",
                            fontSize: "1.2rem",
                          }}
                        />
                      </span>
                      <span style={{ color: "#FFF" }} className="menu-title">
                        Youtube
                      </span>
                    </Link>
                  </li>
                  <li
                    className={
                      isPathActive("/Go_seo/Facebook")
                        ? "nav-item menu-items active"
                        : "nav-item menu-items"
                    }
                    style={{
                      ...(isPathActive("/Go_seo/Facebook")
                        ? activeStyle
                        : style),
                      marginTop: "",
                    }}
                  >
                    <Link className="nav-link" to="/Go_seo/Facebook">
                      <span className="p-2">
                        <FaFacebook
                          style={{
                            color: "#0D92F4",
                            marginRight: "1rem",
                            fontSize: "1.2rem",
                          }}
                        />
                      </span>
                      <span style={{ color: "#FFF" }} className="menu-title">
                        Facebook
                      </span>
                    </Link>
                  </li>
                  <li
                    className={
                      isPathActive("/Go_seo/Instagram")
                        ? "nav-item menu-items active"
                        : "nav-item menu-items"
                    }
                    style={{
                      ...(isPathActive("/Go_seo/Instagram")
                        ? activeStyle
                        : style),
                      marginTop: "",
                    }}
                  >
                    <Link className="nav-link" to="/Go_seo/Instagram">
                      <span className="p-2">
                        <FaSquareInstagram
                          style={{
                            color: "#FF77B7",
                            marginRight: "1rem",
                            fontSize: "1.2rem",
                          }}
                        />
                      </span>
                      <span style={{ color: "#FFF" }} className="menu-title">
                        Instagram
                      </span>
                    </Link>
                  </li>
                  <li
                    className={
                      isPathActive("/Go_seo/Linkedin")
                        ? "nav-item menu-items active"
                        : "nav-item menu-items"
                    }
                    style={{
                      ...(isPathActive("/Go_seo/Linkedin")
                        ? activeStyle
                        : style),
                      marginTop: "",
                    }}
                  >
                    <Link className="nav-link" to="/Go_seo/Linkedin">
                      <span className="p-2">
                        <FaLinkedin
                          style={{
                            color: "#0D92F4",
                            marginRight: "1rem",
                            fontSize: "1.2rem",
                          }}
                        />
                      </span>
                      <span style={{ color: "#FFF" }} className="menu-title">
                        Linkedin
                      </span>
                    </Link>
                  </li>
                  <li
                    style={
                      isPathActive("/Go_seo/Reporting") ? activeStyle : style
                    }
                    className={
                      isPathActive("/Go_seo/Reporting")
                        ? "nav-item menu-items active"
                        : "nav-item menu-items"
                    }
                  >
                    <Link className="nav-link" to="/Go_seo/Reporting">
                      <span className="p-2">
                        <FaLink
                          style={{
                            color: "#A5B68D",
                            marginRight: "1rem",
                            fontSize: "1rem",
                          }}
                        />
                      </span>
                      <span style={{ color: "#FFF" }} className="menu-title">
                        Reports
                      </span>
                    </Link>
                  </li>
                  
                </ul>
              </Collapse>
            </li> */}
            {/* <li
              className={
                asoMenuOpen
                  ? "nav-item menu-items active"
                  : "nav-item menu-items"
              }
              style={{
                // marginTop: ".8rem",
                ...(asoMenuOpen ? activeStyle : style),
                ...(!isVerified && { pointerEvents: "none", opacity: "0.5" }),
              }}
            >
              <div
                className={asoMenuOpen ? "nav-link menu-expanded" : "nav-link"}
                onClick={
                  !isVerified ? undefined : () => handleMenuClick("asoMenuOpen")
                } // Pass "asoMenuOpen" as the argument here
                data-toggle="collapse"
              >
                <span className="p-2">
                  <FaMobileAlt
                    style={{
                      color: "#E5D9F2",
                      marginRight: "1rem",
                      fontSize: "1.2rem",
                    }}
                  />
                </span>
                <span style={{ color: "#FFF" }} className="menu-title">
                  ASO
                </span>
                &nbsp; &nbsp; &nbsp;
                {!isVerified && (
                  <FaLock
                    style={{
                      color: "#fff",
                      marginRight: "0.5rem",
                      fontSize: "1rem",
                    }}
                  />
                )}
                <i className="menu-arrow" style={{ color: "pink" }}></i>
              </div>
              <Collapse in={asoMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li
                    className={
                      isPathActive("/Go_aso/Dashboard")
                        ? "nav-item menu-items active"
                        : "nav-item menu-items"
                    }
                    style={
                      isPathActive("/Go_aso/Dashboard") ? activeStyle : style
                    }
                  >
                    <Link className="nav-link" to="/Go_aso/Dashboard">
                      <span className="p-2">
                        <FaChartLine
                          style={{
                            color: "#CDC1FF",
                            marginRight: "1rem",
                            fontSize: "1.2rem",
                          }}
                        />
                      </span>
                      <span style={{ color: "#FFF" }} className="menu-title">
                        ASO Dashboard
                      </span>
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li> */}
            <li
              style={{
                ...(isPathActive("/Go_seo/Comapny_onboard")
                  ? activeStyle
                  : style),
                // marginTop: ".5rem",
                ...(!isVerified && { pointerEvents: "none", opacity: "0.5" }),
              }}
              className={
                isPathActive("/Go_seo/Comapny_onboard")
                  ? "nav-item menu-items active"
                  : "nav-item menu-items"
              }
            >
              <Link
                className="nav-link"
                to={!isVerified ? "#" : "/Go_seo/Comapny_onboard"}
                style={{ color: !isVerified ? "#AAA" : "#FFF" }}
              >
                <span className="p-2">
                  <TiNews
                    style={{
                      color: !isVerified ? "#AAA" : "#FCCD2A",
                      marginRight: "1rem",
                      fontSize: "1.2rem",
                    }}
                  />
                </span>
                <span className="menu-title">Onboard </span>
                &nbsp; &nbsp; &nbsp;
                {!isVerified && (
                  <FaLock
                    style={{
                      color: "#fff",
                      marginRight: "0.5rem",
                      fontSize: "1rem",
                    }}
                  />
                )}
              </Link>
            </li>

            <li
              style={{
                ...(isPathActive("/Go_seo/Access") ? activeStyle : style),
                marginTop: ".5rem",
                ...(!isVerified && { pointerEvents: "none", opacity: "0.5" }),
              }}
              className={
                isPathActive("/Go_seo/Access")
                  ? "nav-item menu-items active"
                  : "nav-item menu-items"
              }
            >
              <Link
                className="nav-link"
                to={!isVerified ? "#" : "/Go_seo/Access"}
                style={{ color: !isVerified ? "#AAA" : "#FFF" }}
              >
                <span className="p-2">
                  <MdDesktopAccessDisabled
                    style={{
                      color: !isVerified ? "#AAA" : "#15F5BA",
                      marginRight: "1rem",
                      fontSize: "1.2rem",
                    }}
                  />
                </span>
                <span className="menu-title">Access </span>
                &nbsp; &nbsp; &nbsp;
                {!isVerified && (
                  <FaLock
                    style={{
                      color: "#fff",
                      marginRight: "0.5rem",
                      fontSize: "1rem",
                    }}
                  />
                )}
              </Link>
            </li>
            <li
              style={{
                ...(isPathActive("/Go_seo/Help_Guide") ? activeStyle : style),
                // marginTop: ".5rem",
                // ...(!isVerified && { pointerEvents: "none", opacity: "0.5" }),
              }}
              className={
                isPathActive("/Go_seo/Help_Guide")
                  ? "nav-item menu-items active"
                  : "nav-item menu-items"
              }
            >
              <Link
                className="nav-link"
                to="/Go_seo/Help_Guide"
                style={{ color: "#FFF" }}
              >
                <span className="p-2">
                  <TbHelpOctagonFilled
                    style={{
                      color: "#009990",
                      marginRight: "1rem",
                      fontSize: "1.2rem",
                    }}
                  />
                </span>
                <span className="menu-title">Guide </span>
                &nbsp; &nbsp; &nbsp;
              </Link>
            </li>
            {/* </ul> */}
          </>
        )}
      </ul>
    </nav>
  );
};

export default Sidebar;
