import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Nav, Tooltip, OverlayTrigger } from "react-bootstrap"; 
import { GiNewspaper } from "react-icons/gi";
import { RiPagesLine } from "react-icons/ri";
import { FaUserPen } from "react-icons/fa6";
import Smodashboard from "./SMOdashboard";
import Youtube from "./Youtube";
import Facebook from "./Facebook";

const Smosetting = () => {
    const [stimapdata, setstimapdata] = useState([]);
    const [key, setKey] = useState("first");
    const [selectedLocation, setSelectedLocation] = useState(""); 
    const handleNavClick = (location) => {
        setSelectedLocation(location);
      };
    
  return (
    <Row>
      <Col xs="auto" className="sitemap-tabs-container m-0 p-0" style={{borderRight:"1px solid grey", background:"#F1F0E8", height:"90vh"}}>
        <Nav
          variant="pills" 
          className="flex-column" 
          activeKey={key} 
          onSelect={(k) => setKey(k)} 
          style={{border:"none"}}
        >
          <Nav.Item>
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>Pages</Tooltip>}
            >
              <Nav.Link 
                eventKey="first" 
                onClick={() => {
                  const pageLocation = stimapdata.find(sitemap => sitemap.location.includes("sitemap_pages.xml"))?.location;
                  if (pageLocation) {
                    handleNavClick(pageLocation);
                  }
                }}
              >
                <RiPagesLine style={{fontSize:"1.5rem"}} />
              </Nav.Link>
            </OverlayTrigger>
          </Nav.Item>

          {/* Author Tab */}
          <Nav.Item>
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>Author</Tooltip>}
            >
              <Nav.Link 
                eventKey="second" 
                onClick={() => {
                  const authorLocation = stimapdata.find(sitemap => sitemap.location.includes("sitemap_author.xml"))?.location;
                  if (authorLocation) {
                    handleNavClick(authorLocation);
                  }
                }} 
              >
                <FaUserPen style={{fontSize:"1.5rem"}} />
              </Nav.Link>
            </OverlayTrigger>
          </Nav.Item>

          {/* Blog Tab */}
          <Nav.Item>
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>Blog</Tooltip>}
            >
              <Nav.Link 
                eventKey="third" 
                onClick={() => {
                  const blogLocation = stimapdata.find(sitemap => sitemap.location.includes("sitemap_blogs.xml"))?.location;
                  if (blogLocation) {
                    handleNavClick(blogLocation);
                  }
                }} 
              >
                <GiNewspaper style={{fontSize:"1.5rem"}} />
              </Nav.Link>
            </OverlayTrigger>
          </Nav.Item>
        </Nav>
      </Col>

      <Col sm={11} className="sitemap-content-container">
        {key === "first" && <Smodashboard  />} 
        {key === "second" && <Youtube  />} 
        {key === "third" && <Facebook  />} 
      </Col>
    </Row>
  );
};

export default Smosetting;
