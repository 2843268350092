import React, { useContext, useEffect, useState } from "react";
import { Campaignform } from "./Forms";
import { Nav, Tab } from "react-bootstrap";
import { Campaigntable } from "./Table";
import { GlobalContext } from "../Context/GlobalState";
import { BASE_URL_API_TRANXT_UAT_API } from "../Utils/Constant";
const Campaign = () => {
  const {user, setTransactionLoaderState,siteid,siteurl,Productname } = useContext(GlobalContext);
  const [campaign, setcampaign] = useState([]);
  const Getcampaigndata = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          userid:siteid
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetCampaignByUser`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setcampaign(
          data?.data?.map((item, index) => ({
            id: `${index + 1}`,
            campaign_id: item?.campaign_id,
            utm_source: item?.utm_source,
            utm_medium: item?.utm_medium,
            utm_campaign: item?.utm_campaign,
            utm_term: item?.utm_term,
            utm_content: item?.utm_content,
            generated_link: item?.generated_link,
          }))
        );
      } 
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
  
    }
  };

  useEffect(() => {
    if (siteid) {
      Getcampaigndata();
    }

  }, [siteid]);
  return (
    <>
      <Tab.Container defaultActiveKey="Campaignform">
        <div className="tab-buttons mt-2">
          <Nav variant="tabs">
          <Nav.Item>
              <Nav.Link
                className="p-3"
                eventKey="Campaignform"
                style={{ fontSize: "1rem", fontWeight: "500" }}
              >
                Create Campaign
              </Nav.Link>
            </Nav.Item>
          <Nav.Item>
              <Nav.Link
                className="p-3"
                eventKey="Mycampaign"
                style={{ fontSize: "1rem", fontWeight: "500" }}
              >
                My Campaign
              </Nav.Link>
            </Nav.Item>
          
         
          </Nav>
        </div>
        <Tab.Content>
          <Tab.Pane eventKey="Campaignform">
           <div className="row d-flex justify-content-center">
           <div className="col-9 ">
              <Campaignform  Getcampaigndata={Getcampaigndata}/>
            </div>
           </div>
          </Tab.Pane>
          <Tab.Pane eventKey="Mycampaign">
            <Campaigntable campaign={campaign}/>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </>
  );
};

export default Campaign;

