import React from "react";
import { Row, Col, Nav, Tooltip, OverlayTrigger } from "react-bootstrap";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import { GiNewspaper } from "react-icons/gi";
import { RiPagesLine } from "react-icons/ri";
import { FaSitemap, FaUserPen } from "react-icons/fa6";
import Sitemap from "./Sitemap";
import Backlinksite from "./Backlinkssite";
import Guestpost from "./Guestpost";

const Workspace = () => {
  return (
    <Router>
      <Row className="">
        <Col
          xs="auto"
          className="sitemap-tabs-container m-0 p-0"
          style={{
            borderRight: "1px solid grey",
            background: "#F1F0E8",
            height: "90vh",
          }}
        >
          <Nav
            variant="pills"
            className="flex-column"
            style={{ border: "none" }}
          >
            <Nav.Item>
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip>Sitemap</Tooltip>}
              >
                <Link to="/Go_seo/Sitemap" className="nav-link">
                  <FaSitemap style={{ fontSize: "1.5rem" }} />
                </Link>
              </OverlayTrigger>
            </Nav.Item>
            <Nav.Item>
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip>Author</Tooltip>}
              >
                <Link to="/Go_seo/Backlinks_Sites" className="nav-link">
                  <FaUserPen style={{ fontSize: "1.5rem" }} />
                </Link>
              </OverlayTrigger>
            </Nav.Item>
            <Nav.Item>
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip>Blog</Tooltip>}
              >
                <Link to="/Go_seo/Guestpost" className="nav-link">
                  <GiNewspaper style={{ fontSize: "1.5rem" }} />
                </Link>
              </OverlayTrigger>
            </Nav.Item>
          </Nav>
        </Col>

        <Col sm={11} className="sitemap-content-container">
          <Switch>
            <Route path="/Go_seo/Sitemap" component={Sitemap} />
            <Route path="/Go_seo/Backlinks_Sites" component={Backlinksite} />
            <Route path="/Go_seo/Guestpost" component={Guestpost} />
          </Switch>
        </Col>
      </Row>
    </Router>
  );
};

export default Workspace;
