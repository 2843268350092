import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const Steper = ({ id, selectedURL, verifyDomain }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedOption, setSelectedOption] = useState("");
  const url = sessionStorage.getItem("selectedURLLabel");
  const steps = ["Step 1", "Step 2", "Step 3", "Step 4", "Step 5", "Step 6"];
  const handleNext = () => {
    if (currentStep < steps.length) {
      setCurrentStep((prev) => prev + 1);
    }
  };

  const handleBack = () => {
    if (currentStep > 1) {
      setCurrentStep((prev) => prev - 1);
    }
  };
  const handleConnectClick = (e) => {
    e.preventDefault();
    window.open(
      `https://api.goseo.in/api/GoogleAnalytics/auth?webid=${id}`,
      "_blank"
    );
    setCurrentStep(4);
  };
  return (
    <div
      className="container "
      style={{
        height: "70vh",
        overflowY: "scroll",
        scrollbarWidth: "none",
        msOverflowStyle: "none",
      }}
    >
      <style>
        {`
      div::-webkit-scrollbar {
        display: none; /* Hide scrollbar for Webkit browsers (Chrome, Safari, etc.) */
      }
    `}
      </style>

      {/* Stepper Header */}
      <div className="d-flex justify-content-between align-items-center">
        {steps.map((step, index) => (
          <div key={index} className="text-center">
            <div
              className={`rounded-circle ${
                currentStep > index + 1
                  ? "bg-success text-white"
                  : currentStep === index + 1
                  ? "bg-primary text-white"
                  : "bg-light text-muted"
              }`}
              style={{
                width: "40px",
                height: "40px",
                lineHeight: "40px",
                margin: "0 auto",
                border: "2px solid #ddd",
              }}
            >
              {currentStep > index + 1 ? "✔" : index + 1}
            </div>
            <small>{step}</small>
          </div>
        ))}
      </div>

      {/* Progress Bar */}
      <div
        className="progress mt-3"
        style={{ height: "5px", backgroundColor: "#f1f1f1" }}
      >
        <div
          className="progress-bar"
          role="progressbar"
          style={{
            width: `${((currentStep - 1) / (steps.length - 1)) * 100}%`,
          }}
          aria-valuenow={currentStep}
          aria-valuemin="0"
          aria-valuemax={steps.length}
        />
      </div>

      {/* Step Content */}
      <div className="mt-3">
        {currentStep === 1 && (
          <div>
            <h4>Select Your Web Hosting Server</h4>
            <div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  id="linux"
                  name="os"
                  value="Linux"
                  onChange={(e) => setSelectedOption(e.target.value)}
                />
                <label className="form-check-label" htmlFor="linux">
                  Linux
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  id="windows"
                  name="os"
                  value="Windows"
                  onChange={(e) => setSelectedOption(e.target.value)}
                />
                <label className="form-check-label" htmlFor="windows">
                  Windows
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  id="wordpress"
                  name="os"
                  value="WordPress"
                  onChange={(e) => setSelectedOption(e.target.value)}
                />
                <label className="form-check-label" htmlFor="wordpress">
                  WordPress
                </label>
              </div>
            </div>
          </div>
        )}
        {currentStep === 2 && (
          <div>
            <div className="container mt-2 text-center">
              <h3>Please verify the selected URL first.</h3>
              <div className="mt-4">
                <h5>Steps to verify your URL:</h5>
                <ol className="text-left mx-auto" style={{ maxWidth: "500px" }}>
                  <li>
                    <span>Step 1: </span>
                    Download the verification XML file by clicking the button
                    below.
                    <div className="mt-2">
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          const webid = id;
                          const url = `https://goseo.in/verification/generate_verification.php?webid=${webid}`;
                          window.location.href = url;
                        }}
                      >
                        Download Verification XML
                      </button>
                    </div>
                  </li>
                  <li>
                    <span>Step 2: </span>
                    Download the verification XML file by clicking the button
                    below.
                    <div className="mt-2">
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          const webid = id;
                          const url = `https://goseo.in/seo_manager_download.php`;
                          window.location.href = url;
                        }}
                      >
                        Download Seo Manager
                      </button>
                    </div>
                  </li>
                  <li className="mt-3">
                    <span>Step 4: </span>
                    Place the downloaded <code>verification.xml</code>{" "}
                    <code>seo_manager.php</code> file in the root folder of your
                    website.
                  </li>
                  <li className="mt-3">
                    <span>Step 3: </span>
                    Ensure the file is accessible by visiting the link:
                    <br />
                    <code>{`${selectedURL.value}`}</code>
                  </li>
                  <li className="mt-3">
                    <span>Step 5: </span>
                    Once the file is accessible, click the "Verify" button below
                    to complete the verification process.
                    <div className="mt-2">
                      <button
                        className="btn btn-success"
                        onClick={() => verifyDomain()}
                      >
                        Verify
                      </button>
                    </div>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        )}
        {currentStep === 3 && (
          <div>
            <div
              className="d-flex justify-content-center align-items-center vh-50 vw-75"
              style={{ backgroundColor: "#fff" }}
            >
              <div style={containerStyle}>
                <h3 className="mb-3 text-center">Connect Google Analytics</h3>
                <form>
                  <label htmlFor="website" className="mb-0">
                    Enter Website Link:
                  </label>
                  <input
                    className="mb-3"
                    type="url"
                    id="website"
                    name="website"
                    value={url}
                    placeholder="https://example.com"
                    style={inputStyle}
                  />
                  <button
                    type="submit"
                    style={buttonStyle}
                    className="p-2 btn btn-success"
                    onClick={handleConnectClick}
                  >
                   <b> Connect to Google Analytics</b>
                  </button>
                </form>
              </div>
            </div>
            {/* <iframe
    src="https://goseo.in/googleAnalytics/googleanalytics.html"
    title="Google Analytics"
    style={{
      width: "100%",
      height: "50vh",
      border: "none",
    }}
  ></iframe> */}
          </div>
        )}

        {currentStep === 4 && (
          <div>
            <h4>Connect Your Sitemap</h4>
            <p>
              Ensure your website's sitemap is properly configured to enhance
              search engine optimization.
            </p>
            <div className="mt-3">
              <p>Is your sitemap connected?</p>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  id="sitemapConnectedYes"
                  name="sitemapConnected"
                  value="yes"
                  onChange={(e) => console.log("Sitemap connected: Yes")}
                />
                <label
                  className="form-check-label"
                  htmlFor="sitemapConnectedYes"
                >
                  Yes
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  id="sitemapConnectedNo"
                  name="sitemapConnected"
                  value="no"
                  onChange={(e) => console.log("Sitemap connected: No")}
                />
                <label
                  className="form-check-label"
                  htmlFor="sitemapConnectedNo"
                >
                  No
                </label>
              </div>
            </div>

            <div className="mt-3">
              <h5>Upload Sitemap</h5>
              <p>
                If your sitemap is not connected, please upload the required
                sitemap files to the root folder of your website.
              </p>
              <ol>
                <li>
                  <strong>Sitemap for Pages:</strong>{" "}
                  <code>https://example.com/sitemap_pages.xml</code>
                </li>
                <li>
                  <strong>Sitemap for Authors:</strong>{" "}
                  <code>https://example.com/sitemap_author.xml</code>
                </li>
                <li>
                  <strong>Sitemap for Blogs:</strong>{" "}
                  <code>https://example.com/sitemap_blogs.xml</code>
                </li>
              </ol>
              <div className="mt-3">
                <p>
                  After uploading, ensure the files are accessible via the links
                  mentioned above.
                </p>
              </div>
            </div>
            <h4>Verify Sitemap Index</h4>
            <p>
              Once your sitemaps are uploaded, all of them should be listed in
              the main sitemap index file at:{" "}
              <code>https://example.com/sitemap.xml</code>. Here's an example of
              the expected format:
            </p>
            <pre className="bg-light p-3">
              {`<sitemapindex xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
  <sitemap>
    <loc>https://example.com/sitemap_pages.xml</loc>
    <lastmod>2024-12-01</lastmod>
  </sitemap>
  <sitemap>
    <loc>https://example.com/sitemap_author.xml</loc>
    <lastmod>2024-12-01</lastmod>
  </sitemap>
  <sitemap>
    <loc>https://example.com/sitemap_blogs.xml</loc>
    <lastmod>2024-12-01</lastmod>
  </sitemap>
</sitemapindex>`}
            </pre>
            <p>
              Ensure all individual sitemap files are accessible and properly
              linked here.
            </p>

            <div className="mt-3">
              <h5>Examples of Individual Sitemap Files</h5>

              <h6>Sitemap for Blogs</h6>
              <pre className="bg-light p-3">
                {`<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9 http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd">
  <url>
    <loc>https://example.com/blog/your-health-matters-tips-and-strategies-for-a-vibrant-life</loc>
    <priority>0.8</priority>
    <changefreq>monthly</changefreq>
    <lastmod>2024-07-01</lastmod>
  </url>
  <url>
    <loc>https://example.com/blog/choosing-the-perfect-quartz-countertop-a-comprehensive-guide-to-samples</loc>
    <priority>0.8</priority>
    <changefreq>monthly</changefreq>
    <lastmod>2024-07-01</lastmod>
  </url>
</urlset>`}
              </pre>

              <h6>Sitemap for Pages</h6>
              <pre className="bg-light p-3">
                {`<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9 http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd">
  <url>
    <loc>https://example.com/contact_us</loc>
    <priority>0.8</priority>
    <changefreq>monthly</changefreq>
    <lastmod>2024-07-01</lastmod>
  </url>
  <url>
    <loc>https://example.com/faq</loc>
    <priority>0.8</priority>
    <changefreq>monthly</changefreq>
    <lastmod>2024-07-01</lastmod>
  </url>
</urlset>`}
              </pre>

              <h6>Sitemap for Authors</h6>
              <pre className="bg-light p-3">
                {`<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
  <url>
    <loc>https://example.com/author/John-Doe</loc>
    <lastmod>2024-12-01</lastmod>
    <priority>0.9</priority>
  </url>
  <url>
    <loc>https://example.com/author/Bill-Gates</loc>
    <lastmod>2024-12-01</lastmod>
    <priority>0.9</priority>
  </url>
</urlset>`}
              </pre>
            </div>
          </div>
        )}
        {currentStep === 5 && (
          <div>
            <h4>Configure Your Robots.txt</h4>
            <p>
              The <code>robots.txt</code> file is essential for managing how
              search engines crawl your website. Ensure it is properly
              configured to optimize your SEO.
            </p>
            <div className="mt-3">
              <p>Is your robots.txt file configured?</p>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  id="robotsConfiguredYes"
                  name="robotsConfigured"
                  value="yes"
                  onChange={(e) => console.log("Robots.txt configured: Yes")}
                />
                <label
                  className="form-check-label"
                  htmlFor="robotsConfiguredYes"
                >
                  Yes
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  id="robotsConfiguredNo"
                  name="robotsConfigured"
                  value="no"
                  onChange={(e) => console.log("Robots.txt configured: No")}
                />
                <label
                  className="form-check-label"
                  htmlFor="robotsConfiguredNo"
                >
                  No
                </label>
              </div>
            </div>

            <div className="mt-3">
              <h5>Generate Robots.txt</h5>
              <p>
                If you don't have a <code>robots.txt</code> file, you can
                generate one from the <strong>Robots.txt Menu</strong> in the
                SEO Workspace.
              </p>
              <div className="mt-3">
                <p>
                  After generating the file, upload it to the root folder of
                  your website and ensure it is accessible at:
                </p>
                <code>https://example.com/robots.txt</code>
              </div>
            </div>
          </div>
        )}
        {currentStep === 6 && (
          <div className="text-center">
            <h3 className="text-success">
              Congratulations! Connectivity Established
            </h3>
            <p className="mt-3">
              Your website is now successfully connected to our platform. With
              this integration, you can effectively manage and monitor your
              website's SEO, performance, and health.
            </p>

            <div className="mt-4">
              <h4>What's Next?</h4>
              <p>
                Now that connectivity is established, you can take advantage of
                our tools to improve your website's visibility and performance.
                Here's what you can do:
              </p>
              <ul className="text-left mx-auto" style={{ maxWidth: "600px" }}>
                <li>
                  <strong>Check Site Health:</strong> Analyze your website's
                  current SEO health, including speed, mobile usability, and
                  more.
                </li>
                <li>
                  <strong>Monitor Traffic:</strong> Gain insights into your
                  website traffic, including sources, visitor behavior, and
                  trends.
                </li>
                <li>
                  <strong>Optimize Content:</strong> Use our content
                  optimization tools to enhance your blog posts, pages, and
                  author information.
                </li>
                <li>
                  <strong>Track Issues:</strong> Identify and resolve any issues
                  that may hinder your website's performance or search engine
                  ranking.
                </li>
              </ul>
            </div>

            <div className="mt-4">
              <h4>Explore Your Dashboard</h4>
              <p>
                Head over to your dashboard to start exploring all the features
                and insights available to you. Stay on top of your SEO and
                website health with real-time updates and actionable insights.
              </p>
              <button
                className="btn btn-primary"
                onClick={() => {
                  console.log("Redirecting to the dashboard...");
                }}
              >
                Go to Dashboard
              </button>
            </div>
          </div>
        )}
      </div>

      <div className="mt-4 d-flex justify-content-between">
        <button
          className="btn btn-secondary"
          onClick={handleBack}
          disabled={currentStep === 1}
        >
          Back
        </button>
        <button
          className="btn btn-primary"
          onClick={handleNext}
          disabled={currentStep === steps.length}
        >
          {currentStep === steps.length ? "Completed" : "Next"}
        </button>
      </div>
    </div>
  );
};
const containerStyle = {
  backgroundColor: "#fff",
  padding: "20px",
  borderRadius: "8px",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
  // maxWidth: "400px",
  margin: "auto",
};

const inputStyle = {
  width: "100%",
  padding: "5px",
  margin: "10px 0",
  border: "1px solid #ccc",
  borderRadius: "4px",
};

const buttonStyle = {
  // padding: "10px 20px",
  // backgroundColor: "#4285f4",
  color: "#fff",
  border: "none",
  borderRadius: "4px",
  cursor: "pointer",
  width: "100%",
};

export default Steper;
