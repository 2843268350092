import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../Context/GlobalState";
import { BASE_URL_API_TRANXT_UAT_API } from "../Utils/Constant";
import Select from "react-select";
import { Button, Col, Form, Row, Card, Spinner } from "react-bootstrap";
import facebookIcon from "../../assets/images/seoimages/facebook.png";
import instagramIcon from "../../assets/images/seoimages/insta.png";
import linkedinIcon from "../../assets/images/seoimages/linkdin.png";
import youtubeIcon from "../../assets/images/seoimages/youtube.png";

const Reportingdashboard = () => {
  const productname = sessionStorage.getItem("selectedURLProjectName");
  const [users, setUsers] = useState([]);
  const [backlindata, setbacklindata] = useState([]);
  const [dashboardV1Data, setdashboardV1Data] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [selectedDate, setSelectedDate] = useState("");
  const [socialData, setSocialData] = useState({});
  const [blogdashboard, setblogdashboard] = useState({});
  const [blogCount, setBlogCount] = useState(0);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const handleFromDateChange = (e) => setFromDate(e.target.value);
  const handleToDateChange = (e) => setToDate(e.target.value);

  const socialPlatforms = [
    { name: "facebook", icon: facebookIcon },
    { name: "instagram", icon: instagramIcon },
    { name: "linkedin", icon: linkedinIcon },
    { name: "youtube", icon: youtubeIcon },
  ];
  const GetGoseoUsers = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({}),
        redirect: "follow",
      };

      const response = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetGoseoUsers`,
        requestOptions
      );
      const data = await response.json();
      if (data.status === "0") {
        const userOptions = data.data.map((user) => ({
          value: user.id,
          label: user.user_name,
        }));
        setUsers(userOptions);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.error("Error fetching users:", error);
      setTransactionLoaderState(false);
    }
  };
  const getCurrentFormattedDate = () => {
    const currentDate = new Date();
    return `${currentDate.getFullYear()}-${String(
      currentDate.getMonth() + 1
    ).padStart(2, "0")}-${String(currentDate.getDate()).padStart(2, "0")}`;
  };
  const GetBacklinkDashbord = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          userid: selectedUser.value,
          fromdate: fromDate || getCurrentFormattedDate(),
          todate: toDate || getCurrentFormattedDate(),
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetBacklinkDashbord`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0" && data.data) {
        setbacklindata(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.error("Error:", error);
      setTransactionLoaderState(false);
    }
  };
  const GetBacklinkDashbordV1 = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          userid: selectedUser.value,
          fromdate: fromDate || getCurrentFormattedDate(),
          todate: toDate || getCurrentFormattedDate(),
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetBacklinkDashbordV1`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0" && data.data) {
        setdashboardV1Data(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.error("Error:", error);
      setTransactionLoaderState(false);
    }
  };
  const GetBlogDashboard = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          userid: selectedUser.value,
          fromdate: fromDate || getCurrentFormattedDate(),
          todate: toDate || getCurrentFormattedDate(),
          product_name: productname,
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetBlogDashboard`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0" && data.data && data.data.length > 0) {
        setBlogCount(data.data[0].totalBlogPosted);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.error("Error:", error);
      setTransactionLoaderState(false);
    }
  };
  const GetBlogDashboardV1 = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          userid: selectedUser.value,
          fromdate: fromDate || getCurrentFormattedDate(),
          todate: toDate || getCurrentFormattedDate(),
          product_name: productname,
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetBlogDashboardV1`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0" && data.data) {
        setblogdashboard(data.data);
      } else {
        setblogdashboard("");
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.error("Error:", error);
      setTransactionLoaderState(false);
    }
  };
  const fetchSocialData = async (platform) => {
    try {
      const response = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetBacklinkDashbordV2`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            userid: selectedUser?.value,
            fromdate: fromDate || getCurrentFormattedDate(),
            todate: toDate || getCurrentFormattedDate(),
            social: platform,
          }),
        }
      );
      const data = await response.json();
      if (data.status === "0") {
        setSocialData((prev) => ({ ...prev, [platform]: data.data }));
      }
    } catch (error) {
      console.error(`Error fetching data for ${platform}:`, error);
    }
  };
  const handleSearch = () => {
    socialPlatforms.forEach((platform) => {
      fetchSocialData(platform.name);
    });
  };

  useEffect(() => {
    GetGoseoUsers();
  }, []);
  const customStyles = {
    control: (base) => ({
      ...base,
      backgroundColor: "white",
      color: "black",
      borderColor: "#ced4da",
      boxShadow: "none",
      "&:hover": {
        borderColor: "#86b7fe",
      },
    }),
    singleValue: (base) => ({
      ...base,
      color: "black",
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: "white",
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isFocused ? "#e9ecef" : "white",
      color: "black",
      "&:active": {
        backgroundColor: "#dee2e6",
      },
    }),
  };
  return (
    <>
      <div className="mt-4 mx-2">
        <Row className="mx-md-1 p-0">
          <Col md={3}>
            <Form.Group className="mb-3">
              <Form.Label>Select User</Form.Label>
              <Select
                options={users}
                value={selectedUser}
                onChange={setSelectedUser}
                placeholder="Select User"
                styles={customStyles}
              />
            </Form.Group>
          </Col>

          <Col md={3}>
            <Form.Group controlId="fromDate">
              <Form.Label>From Date</Form.Label>
              <Form.Control
                type="date"
                value={fromDate}
                onChange={handleFromDateChange}
              />
            </Form.Group>
          </Col>

          <Col md={3}>
            <Form.Group controlId="toDate">
              <Form.Label>To Date</Form.Label>
              <Form.Control
                type="date"
                value={toDate}
                onChange={handleToDateChange}
              />
            </Form.Group>
          </Col>

          <Col md={3} className="d-flex align-items-center mt-2">
            <div>
              {" "}
              <Button
                style={{
                  backgroundColor: "#2ca01c",
                  border: "none",
                }}
                onClick={() => {
                  GetBacklinkDashbord();
                  GetBacklinkDashbordV1();
                  handleSearch();
                  GetBlogDashboardV1();
                  GetBlogDashboard();
                }}
              >
                Search
              </Button>
            </div>
          </Col>
        </Row>
        <hr className="m-1" />
        <div className="mt-3">
          <Row>
            <Col md={4} className="">
              <Card style={{ height: "36vh" }} className="shadow-sm p-3">
                <Card.Body className="p-0">
                  <Card.Title
                    className="text-center"
                    style={{ color: "green" }}
                  >
                    Backlink Information
                  </Card.Title>
                  {backlindata.length > 0 ? (
                    backlindata.map((item, index) => (
                      <div key={index}>
                        <Card.Text>
                          <Row className="m-0 p-0">
                            <p
                              className="mb-0 col-8"
                              style={{ fontSize: "1rem" }}
                            >
                              Backlink Credential:
                            </p>{" "}
                            <p className="col mb-0">
                              {" "}
                              {item.backlinkCredential}
                            </p>
                          </Row>
                        </Card.Text>

                        <Card.Text>
                          <Row className="m-0 p-0">
                            <p
                              className="mb-0 col-8"
                              style={{ fontSize: "1rem" }}
                            >
                              Backlinks Created:
                            </p>{" "}
                            <p className="col mb-0"> {item.backlinksCreated}</p>
                          </Row>
                        </Card.Text>
                      </div>
                    ))
                  ) : (
                    <div className="text-center">
                      <p>No data found</p>
                    </div>
                  )}
                </Card.Body>
              </Card>
            </Col>
            {/* <Col md={4} className="">
              <Card style={{ height: "" }} className="shadow-sm p-3">
                <Card.Body className="p-0">
                  <Card.Title
                    className="text-center"
                    style={{ color: "green" }}
                  >
                    Social Site Information
                  </Card.Title>
                  {["facebook", "instagram", "linkedin", "youtube"].map(
                    (platform, index) => {
                      const platformData = dashboardV1Data.find(
                        (item) => item.social.toLowerCase() === platform
                      );
                      const platformIcon = {
                        facebook: facebookIcon,
                        instagram: instagramIcon,
                        linkedin: linkedinIcon,
                        youtube: youtubeIcon,
                      };

                      return (
                        <Row key={index}>
                          <Col md={6}>
                            <p className="mb-0" style={{ fontSize: "1rem" }}>
                              <img
                                src={platformIcon[platform]}
                                alt={platform}
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  marginRight: "8px",
                                }}
                              />
                              {platform.charAt(0).toUpperCase() +
                                platform.slice(1)}
                            </p>
                          </Col>
                          <Col md={6} className="text-end">
                            {platformData ? platformData.total : 0}
                          </Col>
                        </Row>
                      );
                    }
                  )}
                </Card.Body>
              </Card>
            </Col> */}
            <Col md={4} className="">
              <Card style={{ height: "36vh" }} className="shadow-sm p-3">
                <Card.Body className="p-0">
                  <Card.Title
                    className="text-center mb-0"
                    style={{ color: "green" }}
                  >
                    Blogs Information
                  </Card.Title>
                  <div className="d-flex justify-content-center">
                    Total Blogs Post : <strong>{blogCount || "0"}</strong>
                  </div>
                  {blogCount === 0 ? (
                    <p className="text-center">No data found</p>
                  ) : blogdashboard && blogdashboard.length > 0 ? (
                    <ul
                      style={{
                        padding: "10px",
                        overflowY: "auto",
                        maxHeight: "30vh",
                      }}
                    >
                      {blogdashboard.map((blog) => (
                        <li key={blog.post_id} style={{ marginBottom: "10px" }}>
                          <a
                            href={blog.full_url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {blog.full_url}
                          </a>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p className="text-center">No blog data available</p>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
          {/* <Row className="gx-2 gy-3 mx-1 px-0">
            {socialPlatforms.map((platform, index) => (
              <Col md={3} className="p-1" key={index}>
                <Card className="shadow-sm">
                  <Card.Body className="p-2">
                    <Card.Title
                      className="text-center"
                      style={{ color: "green", marginBottom: "10px" }}
                    >
                      <img
                        src={platform.icon}
                        alt={platform.name}
                        style={{ width: "30px", marginRight: "10px" }}
                      />
                      {platform.name.charAt(0).toUpperCase() +
                        platform.name.slice(1)}
                    </Card.Title>
                    {socialData[platform.name]?.length > 0 ? (
                      <ul className="mb-0">
                        {socialData[platform.name].map((item) => (
                          <li key={item.id} className="mb-1">
                            <p className="mb-0">
                              <strong>URL:</strong> {item.url}
                            </p>
                            <p className="mb-0">
                              <strong>Date:</strong> {item.date}
                            </p>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p className="text-center">No data found</p>
                    )}
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row> */}
        </div>
      </div>
    </>
  );
};

export default Reportingdashboard;
