import React, { useContext, useEffect, useState } from "react";
import projectImage from "../../assets/images/seoimages/selectproject.png";
import visitorDashboardImage from "../../assets/images/seoimages/visitor_dashboard.png";
import auditImage1 from "../../assets/images/seoimages/Audit1.png";
import auditImage2 from "../../assets/images/seoimages/Audit2.png";
import campaignImage1 from "../../assets/images/seoimages/Campaign1.png";
import campaignImage2 from "../../assets/images/seoimages/Campaign2.png";
import seoDataImage from "../../assets/images/seoimages/Workspace.png";
import socialMediaImage from "../../assets/images/seoimages/Social.png";
import { Button, Col, Image, Row } from "react-bootstrap";
const Help = () => {
  const [step, setStep] = useState(0);

  const steps = [
    {
      title: "Step 1: Select Your Project",
      description: "From here, you can select your project.",
      image: [projectImage],
    },
    {
      title: "Step 2: Visitor Dashboard",
      description: "This is the visitor dashboard of your project.",
      image: [visitorDashboardImage],
    },
    {
      title: "Step 3: Audit Your Website Health",
      description: "Audit your website health.",
      image: [auditImage1, auditImage2],
    },
    {
      title: "Step 4: Run Your Campaigns",
      description: "Run your campaigns seamlessly from here.",
      image: [campaignImage1, campaignImage2],
    },
    {
      title: "Step 5: Manage Workspace or SEO Data",
      description: "Manage the SEO data of your website effectively.",
      image: [seoDataImage],
    },
    {
      title: "Step 6: Manage Your Social Media",
      description: "Handle your social media efficiently.",
      image: [socialMediaImage],
    },
  ];

  const handleNext = () => {
    if (step < steps.length - 1) setStep(step + 1);
  };

  const handlePrev = () => {
    if (step > 0) setStep(step - 1);
  };

  return (
    <div className="d-flex flex-column justify-content-center align-items-center mt-4">
    <div className="col-9 text-center">
      <h5>{steps[step]?.description || "Welcome to the application!"}</h5>
      {Array.isArray(steps[step]?.image) && steps[step]?.image.length ? (
        steps[step].image.length === 1 ? (
          <Image src={steps[step].image[0]} alt={steps[step]?.title} fluid />
        ) : (
          <Row>
            {steps[step].image.map((img, index) => (
              <Col key={index} xs={12} md={6}>
                <Image
                  src={img}
                  alt={`${steps[step]?.title} ${index + 1}`}
                  fluid
                />
              </Col>
            ))}
          </Row>
        )
      ) : (
        <p>No images available for this step.</p>
      )}
    </div>
    <div className="w-100 text-end pe-5">
      <Button size="sm" variant="secondary" onClick={handlePrev} disabled={step === 0} className="mr-1 ml-2">
        Previous
      </Button>
      {step < steps.length - 1 && (
        <Button size="sm" variant="primary" onClick={handleNext}>
          Next
        </Button>
      )}
    </div>
  </div>
  
  
  );
};

export default Help;
