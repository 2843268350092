import React, { useContext, useEffect, useState } from "react";
import { BASE_URL_API_TRANXT_UAT_API } from "../Utils/Constant";
import { GlobalContext } from "../Context/GlobalState";
import {
  Button,
  Col,
  Row,
  Form,
  Nav,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { GuestpostTable } from "./Table";
import "./Guestpost.css";
import swal from "sweetalert";
import { ImCross } from "react-icons/im";
import { Link } from "react-router-dom";
import Select from "react-select";
import { SiBasicattentiontoken } from "react-icons/si";
import { FaCrown, FaEye } from "react-icons/fa";
import { GrClear } from "react-icons/gr";
import { MdClose, MdVerified } from "react-icons/md";
import { RiPagesLine } from "react-icons/ri";
import { FaUserPen } from "react-icons/fa6";
import {
  DocumentUploadModal,
  InvoiceModal,
  ResubmitModal,
  ViewdocsModal,
} from "./Modal";
import { IoMdDocument } from "react-icons/io";
import { IoDocumentTextOutline, IoLink, IoSend } from "react-icons/io5";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const Guestpost = () => {
  const [tableData, setTableData] = useState([]);
  const [key, setKey] = useState("first");
  const { setTransactionLoaderState } = useContext(GlobalContext);
  const [categories, setCategories] = useState([]);
  const [registerModalOpen, setRegisterModalOpen] = useState(false);
  const [subcategories, setSubcategories] = useState({});
  const [formData, setFormData] = useState({
    website_name: "",
    p_da: "",
    p_pa: "",
    p_define_price: "",
    p_email: "",
    p_phone: "",
    p_person_name: "",
    category: null,
    subcategory: null,
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleCategoryChange = (selectedOption) => {
    setFormData({
      ...formData,
      category: selectedOption,
      subcategory: null,
    });
    GetGPSubCategories(selectedOption.value);
  };

  const handleSubcategoryChange = (selectedOption) => {
    setFormData({
      ...formData,
      subcategory: selectedOption,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        website_name: formData.website_name,
        p_da: formData.p_da,
        p_pa: formData.p_pa,
        p_define_price: formData.p_define_price,
        p_email: formData.p_email,
        p_phone: formData.p_phone,
        p_person_name: formData.p_person_name,
        p_categoryid: formData.category?.value,
        p_sub_categoryid: formData.subcategory?.value,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/RegisterYourDomain`,
        requestOptions
      );

      const data = await res.json();
      if (data.status === "1") {
        swal("Error", data.msg, "error");
      } else {
        swal("Success", data.result[0].message, "success");

        setFormData({
          website_name: "",
          p_da: "",
          p_pa: "",
          p_define_price: "",
          p_email: "",
          p_phone: "",
          p_person_name: "",
          category: null,
          subcategory: null,
        });
        GetGPAllDomains();
        setRegisterModalOpen(false);
      }
      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
    }
  };
  const GetGPAllDomains = async () => {
    setTransactionLoaderState(true);
    try {
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetGPAllDomains`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({}),
        }
      );
      const data = await res.json();
      if (data.status === "0") {
        setTableData(data.data);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };
  const customStyles = {
    control: (base) => ({
      ...base,
      backgroundColor: "white",
      color: "black",
      border: "1px solid #ced4da",
      boxShadow: "none",
      "&:hover": {
        borderColor: "#86b7fe",
      },
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: "white",
    }),
    singleValue: (base) => ({
      ...base,
      color: "black",
    }),
    option: (base, { isFocused }) => ({
      ...base,
      backgroundColor: isFocused ? "#e9ecef" : "white",
      color: "black",
    }),
  };
  const GetGPCategories = async () => {
    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({}),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetGPCategories`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        const formattedCategories = data.data.map((category) => ({
          value: category.category_id,
          label: category.category_name,
        }));
        setCategories(formattedCategories);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };
  const GetGPSubCategories = async (categoryId) => {
    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          categoryid: categoryId,
        }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetGPSubCategories`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        const formattedSubcategories = data.data.map((subcategory) => ({
          value: subcategory.subcategory_id,
          label: subcategory.subcategory_name,
        }));
        setSubcategories((prev) => ({
          ...prev,
          [categoryId]: formattedSubcategories,
        }));
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };
  useEffect(() => {
    GetGPCategories();
    GetGPAllDomains();
  }, []);
  return (
    <>
    <Row>
      <Col
       md={1}
        className="sitemap-tabs-container m-0 p-0"
        style={{
          borderRight: "1px solid grey",
          background: "#F1F0E8",
          height: "90vh",
        }}
      >
        <Nav
          variant="pills"
          className="flex-column"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          style={{ border: "none" }}
        >
          {/* Page Tab */}
          <Nav.Item>
             <Nav.Link eventKey="first">
               <h6> Guest Post</h6> 
              </Nav.Link>
          </Nav.Item>

          {/* Author Tab */}
          <Nav.Item>
              <Nav.Link eventKey="second">
              <h6>Your Order</h6> 
              </Nav.Link>
          </Nav.Item>
          <Nav.Item>
              <Nav.Link eventKey="third">
              <h6   onClick={() => setRegisterModalOpen(true)}>Register</h6> 
              </Nav.Link>
          </Nav.Item>
        </Nav>
      </Col>

      <Col sm={11} className="sitemap-content-container">
        {key === "first" && <Addguestpost />}
        {key === "second" && <Guestpostorders />}
        {key === "third" && <Addguestpost />}
      </Col>
    </Row>
    <div
        className={`guest-post-side-panel ${
          registerModalOpen ? "open" : "closed"
        }`}
      >
        <div className="guest-post-side-panel-header">
          <h5>Register Your Domain</h5>

          <ImCross
            style={{ color: "red" }}
            onClick={() => setRegisterModalOpen(false)}
          />
        </div>
        <div
          style={{
            overflow: "auto",
            height: "85vh",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
          }}
        >
          <Form className="guest-post-form" onSubmit={handleSubmit}>
            <Form.Group className="row mt-2 mb-0">
              <Form.Label className="col-4 d-flex align-items-center">
                Website Name
              </Form.Label>
              <Form.Control
                className="col"
                type="text"
                name="website_name"
                value={formData.website_name}
                onChange={handleChange}
                placeholder="Enter website name"
              />
            </Form.Group>
            <Form.Group className="row mt-2 mb-0">
              <Form.Label className="col-4 d-flex align-items-center">
                DA
              </Form.Label>
              <Form.Control
                className="col"
                type="text"
                name="p_da"
                value={formData.p_da}
                onChange={handleChange}
                placeholder="Enter DA"
              />
            </Form.Group>
            <Form.Group className="row mt-2 mb-0">
              <Form.Label className="col-4 d-flex align-items-center">
                PA
              </Form.Label>
              <Form.Control
                className="col"
                type="text"
                name="p_pa"
                value={formData.p_pa}
                onChange={handleChange}
                placeholder="Enter PA"
              />
            </Form.Group>
            <Form.Group className="row mt-2 mb-0">
              <Form.Label className="col-4 d-flex align-items-center">
                Category
              </Form.Label>
              <Select
                className="col p-0"
                options={categories}
                value={formData.category}
                onChange={handleCategoryChange}
                styles={customStyles}
              />
            </Form.Group>
            <Form.Group className="row mt-2 mb-0">
              <Form.Label className="col-4 d-flex align-items-center">
                Subcategory
              </Form.Label>
              <Select
                className="col p-0"
                options={subcategories[formData.category?.value] || []}
                value={formData.subcategory}
                onChange={handleSubcategoryChange}
                styles={customStyles}
              />
            </Form.Group>
            <Form.Group className="row mt-2 mb-0">
              <Form.Label className="col-4 d-flex align-items-center">
                Define Price
              </Form.Label>
              <Form.Control
                className="col"
                type="text"
                name="p_define_price"
                value={formData.p_define_price}
                onChange={handleChange}
                placeholder="Enter price"
              />
            </Form.Group>
            <Form.Group className="row mt-2 mb-0">
              <Form.Label className="col-4 d-flex align-items-center">
                Email
              </Form.Label>
              <Form.Control
                className="col"
                type="email"
                name="p_email"
                value={formData.p_email}
                onChange={handleChange}
                placeholder="Enter email"
              />
            </Form.Group>
            <Form.Group className="row mt-2 mb-0">
              <Form.Label className="col-4 d-flex align-items-center">
                Person Name
              </Form.Label>
              <Form.Control
                className="col"
                type="text"
                name="p_person_name"
                value={formData.p_person_name}
                onChange={handleChange}
                placeholder="Enter person's name"
              />
            </Form.Group>
            <Form.Group className="row mt-2 mb-0">
              <Form.Label className="col-4 d-flex align-items-center">
                Phone
              </Form.Label>
              <Form.Control
                className="col"
                type="text"
                name="p_phone"
                value={formData.p_phone}
                onChange={handleChange}
                placeholder="Enter phone number"
              />
            </Form.Group>
            <div className="d-flex  m-2 justify-content-start">
              {" "}
              <Button
                type="submit"
                size="sm"
                onClick={handleSubmit}
                className="guest-post-form-submit-btn "
                style={{ width: "7vw" }}
              >
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

const Addguestpost = () => {
  const history = useHistory();
  const [tableData, setTableData] = useState([]);
  const { setTransactionLoaderState } = useContext(GlobalContext);
  const [categories, setCategories] = useState([]);
  const [registerModalOpen, setRegisterModalOpen] = useState(false);
  const [subcategories, setSubcategories] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [verificationFilter, setVerificationFilter] = useState(null);
  const plans = [
    {
      value: "Premium",
      label: "Premium",
      icon: <FaCrown style={{ color: "#FFB200" }} />,
    },
    {
      value: "Standard",
      label: "Standard",
      icon: <SiBasicattentiontoken style={{ color: "blue" }} />,
    },
    { value: "", label: "Clear", icon: <GrClear style={{ color: "" }} /> },
  ];
  const verificationOptions = [
    {
      value: "Y",
      label: (
        <>
          <MdVerified style={{ color: "green" }} /> Verified
        </>
      ),
    },
    {
      value: "N",
      label: (
        <>
          <MdClose style={{ color: "red" }} /> Non-Verified
        </>
      ),
    },
    {
      value: "",
      label: (
        <>
          <GrClear style={{ color: "" }} /> Clear
        </>
      ),
    },
  ];
  const customSingleValue = ({ data }) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      {data.icon && <span style={{ marginRight: 8 }}>{data.icon}</span>}
      {data.label}
    </div>
  );
  const customOption = (props) => {
    const { data, innerRef, innerProps } = props;
    return (
      <div
        ref={innerRef}
        {...innerProps}
        style={{
          display: "flex",
          alignItems: "center",
          padding: "10px",
          backgroundColor: props.isFocused ? "#e9ecef" : "white",
          cursor: "pointer",
        }}
      >
        {data.icon && <span style={{ marginRight: 8 }}>{data.icon}</span>}
        {data.label}
      </div>
    );
  };
  const handlePlanChange = (selectedOption) => {
    if (selectedOption.value === "") {
      setSelectedPlan(null);
    } else {
      setSelectedPlan(selectedOption);
    }
  };
  const handleVerificationChange = (selectedOption) => {
    if (selectedOption.value === "") {
      setVerificationFilter(null);
    } else {
      setVerificationFilter(selectedOption.value);
    }
  };
  const filteredData = tableData
  .filter((item) =>
    selectedPlan ? item.plan_name === selectedPlan : true
  )
  .filter((item) =>
    verificationFilter ? item.is_verified === verificationFilter : true
  )
  .filter((item) =>
    searchQuery
      ? Object.values(item)
          .join(" ")
          .toLowerCase()
          .includes(searchQuery)
      : true
  );


  const handleInputChange = (id, field, value) => {
    const updatedData = tableData.map((row) =>
      row.id === id ? { ...row, [field]: value } : row
    );
    setTableData(updatedData);
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      backgroundColor: "white",
      color: "black",
      border: "1px solid #ced4da",
      boxShadow: "none",
      "&:hover": {
        borderColor: "#86b7fe",
      },
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: "white",
    }),
    singleValue: (base) => ({
      ...base,
      color: "black",
    }),
    option: (base, { isFocused }) => ({
      ...base,
      backgroundColor: isFocused ? "#e9ecef" : "white",
      color: "black",
    }),
  };

  const GetGPCategories = async () => {
    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({}),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetGPCategories`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        const formattedCategories = data.data.map((category) => ({
          value: category.category_id,
          label: category.category_name,
        }));
        setCategories(formattedCategories);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };
  const GetGPSubCategories = async (categoryId) => {
    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          categoryid: categoryId,
        }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetGPSubCategories`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        const formattedSubcategories = data.data.map((subcategory) => ({
          value: subcategory.subcategory_id,
          label: subcategory.subcategory_name,
        }));
        setSubcategories((prev) => ({
          ...prev,
          [categoryId]: formattedSubcategories,
        }));
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };
  const GetGPAllDomains = async () => {
    setTransactionLoaderState(true);
    try {
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetGPAllDomains`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({}),
        }
      );
      const data = await res.json();
      if (data.status === "0") {
        setTableData(data.data);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };
  const [formData, setFormData] = useState({
    website_name: "",
    p_da: "",
    p_pa: "",
    p_define_price: "",
    p_email: "",
    p_phone: "",
    p_person_name: "",
    category: null,
    subcategory: null,
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleCategoryChange = (selectedOption) => {
    setFormData({
      ...formData,
      category: selectedOption,
      subcategory: null,
    });
    GetGPSubCategories(selectedOption.value);
  };

  const handleSubcategoryChange = (selectedOption) => {
    setFormData({
      ...formData,
      subcategory: selectedOption,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        website_name: formData.website_name,
        p_da: formData.p_da,
        p_pa: formData.p_pa,
        p_define_price: formData.p_define_price,
        p_email: formData.p_email,
        p_phone: formData.p_phone,
        p_person_name: formData.p_person_name,
        p_categoryid: formData.category?.value,
        p_sub_categoryid: formData.subcategory?.value,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/RegisterYourDomain`,
        requestOptions
      );

      const data = await res.json();
      if (data.status === "1") {
        swal("Error", data.msg, "error");
      } else {
        swal("Success", data.result[0].message, "success");

        setFormData({
          website_name: "",
          p_da: "",
          p_pa: "",
          p_define_price: "",
          p_email: "",
          p_phone: "",
          p_person_name: "",
          category: null,
          subcategory: null,
        });
        GetGPAllDomains();
        setRegisterModalOpen(false);
      }
      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
    }
  };
  useEffect(() => {
    GetGPCategories();
    GetGPAllDomains();
  }, []);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  return (
    <div className="mt-2 ">
      <Row>
        <Col md={6} className="d-flex align-items-center p-1">
          <h5 className="guest-post-header mr-3">Guest Post</h5>
        </Col>

        <Col className="d-flex justify-content-end">
          <div>
          </div>
        </Col>
      </Row>
      <Row className="pt-0">
        <Col md={7} className="d-flex align-items-center p-1">
          <Select
            className="col p-0 mr-2"
            options={plans}
            value={selectedPlan}
            onChange={handlePlanChange}
            styles={customStyles}
            components={{
              SingleValue: customSingleValue,
              Option: customOption,
            }}
          />
          <Select
            className="col p-0"
            options={verificationOptions}
            value={
              verificationFilter
                ? {
                    value: verificationFilter,
                    label:
                      verificationFilter === "Y" ? (
                        <>
                          <MdVerified style={{ color: "green" }} /> Verified
                        </>
                      ) : (
                        <>
                          <MdClose style={{ color: "red" }} /> Non-Verified
                        </>
                      ),
                  }
                : null
            }
            onChange={handleVerificationChange}
            styles={customStyles}
          />
              <div className="col">
               <input
            type="text"
            className="form-control"
            placeholder="Search"
            value={searchQuery}
            onChange={handleSearchChange}
          />
               </div>

        </Col>
      </Row>
      <GuestpostTable
        tableData={filteredData}
        handleInputChange={handleInputChange}
      />
      <div
        className={`guest-post-side-panel ${
          registerModalOpen ? "open" : "closed"
        }`}
      >
        <div className="guest-post-side-panel-header">
          <h5>Register Your Domain</h5>

          <ImCross
            style={{ color: "red" }}
            onClick={() => setRegisterModalOpen(false)}
          />
        </div>
        <div
          style={{
            overflow: "auto",
            height: "85vh",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
          }}
        >
          <Form className="guest-post-form" onSubmit={handleSubmit}>
            <Form.Group className="row mt-2 mb-0">
              <Form.Label className="col-4 d-flex align-items-center">
                Website Name
              </Form.Label>
              <Form.Control
                className="col"
                type="text"
                name="website_name"
                value={formData.website_name}
                onChange={handleChange}
                placeholder="Enter website name"
              />
            </Form.Group>
            <Form.Group className="row mt-2 mb-0">
              <Form.Label className="col-4 d-flex align-items-center">
                DA
              </Form.Label>
              <Form.Control
                className="col"
                type="text"
                name="p_da"
                value={formData.p_da}
                onChange={handleChange}
                placeholder="Enter DA"
              />
            </Form.Group>
            <Form.Group className="row mt-2 mb-0">
              <Form.Label className="col-4 d-flex align-items-center">
                PA
              </Form.Label>
              <Form.Control
                className="col"
                type="text"
                name="p_pa"
                value={formData.p_pa}
                onChange={handleChange}
                placeholder="Enter PA"
              />
            </Form.Group>
            <Form.Group className="row mt-2 mb-0">
              <Form.Label className="col-4 d-flex align-items-center">
                Category
              </Form.Label>
              <Select
                className="col p-0"
                options={categories}
                value={formData.category}
                onChange={handleCategoryChange}
                styles={customStyles}
              />
            </Form.Group>
            <Form.Group className="row mt-2 mb-0">
              <Form.Label className="col-4 d-flex align-items-center">
                Subcategory
              </Form.Label>
              <Select
                className="col p-0"
                options={subcategories[formData.category?.value] || []}
                value={formData.subcategory}
                onChange={handleSubcategoryChange}
                styles={customStyles}
              />
            </Form.Group>
            <Form.Group className="row mt-2 mb-0">
              <Form.Label className="col-4 d-flex align-items-center">
                Define Price
              </Form.Label>
              <Form.Control
                className="col"
                type="text"
                name="p_define_price"
                value={formData.p_define_price}
                onChange={handleChange}
                placeholder="Enter price"
              />
            </Form.Group>
            <Form.Group className="row mt-2 mb-0">
              <Form.Label className="col-4 d-flex align-items-center">
                Email
              </Form.Label>
              <Form.Control
                className="col"
                type="email"
                name="p_email"
                value={formData.p_email}
                onChange={handleChange}
                placeholder="Enter email"
              />
            </Form.Group>
            <Form.Group className="row mt-2 mb-0">
              <Form.Label className="col-4 d-flex align-items-center">
                Person Name
              </Form.Label>
              <Form.Control
                className="col"
                type="text"
                name="p_person_name"
                value={formData.p_person_name}
                onChange={handleChange}
                placeholder="Enter person's name"
              />
            </Form.Group>
            <Form.Group className="row mt-2 mb-0">
              <Form.Label className="col-4 d-flex align-items-center">
                Phone
              </Form.Label>
              <Form.Control
                className="col"
                type="text"
                name="p_phone"
                value={formData.p_phone}
                onChange={handleChange}
                placeholder="Enter phone number"
              />
            </Form.Group>
            <div className="d-flex  m-2 justify-content-start">
              {" "}
              <Button
                type="submit"
                size="sm"
                onClick={handleSubmit}
                className="guest-post-form-submit-btn "
                style={{ width: "7vw" }}
              >
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};
const Guestpostorders = () => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [message, setMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedDocs, setSelectedDocs] = useState([]);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [docsData, setDocsData] = useState({});
  const [isResubmitModalOpen, setIsResubmitModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [Docmodal, setDocmodal] = useState(false);
  const [showinvoiceModal, setShowinvoiceModal] = useState(false);
  const handleOpeninvoiceModal = () => setShowinvoiceModal(true);
  const handleCloseinvoiceModal = () => setShowinvoiceModal(false);

  const [filterDate, setFilterDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    if (query.trim() === "") {
      setFilteredData(data); 
    } else {
      const filtered = data.filter(
        (row) =>
          row.webisie_name.toLowerCase().includes(query) ||
          row.category_name.toLowerCase().includes(query) ||
          row.subcategory_name.toLowerCase().includes(query)
      );
      setFilteredData(filtered);
    }
  };

  const handleResubmitClick = (row) => {
    setSelectedRow(row);
    setIsResubmitModalOpen(true);
  };
  const fetchData = async (date) => {
    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          userid: user.user_id,
          date: date || filterDate,
        }),
      };

      const response = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetAllGpRequests`,
        requestOptions
      );

      const data = await response.json();
      if (data.status === "0" && data.data && data.data.length > 0) {
        setData(data.data);
        setFilteredData(data.data);
      } else {
        console.error(
          "No data found or failed to fetch:",
          data.message || "Unknown error"
        );
        setData([]);
        setFilteredData([]);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setTransactionLoaderState(false);
    }
  };

  const handleRowClick = (row) => {
    setSelectedRow(row);
    setSidebarVisible(true);
  };

  const closeSidebar = () => {
    setSidebarVisible(false);
  };
  const handleopenDocmodal = (row) => {
    setSelectedRow(row);
    setDocmodal(true);
  };
  const handleSendMessage = async () => {
    if (!message.trim()) return;

    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.user_id,
        requestid: selectedRow.id,
        remark: message,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      console.log("sending data to the server");

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GpConversation`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        setSelectedRow((prev) => ({
          ...prev,
          response: [
            ...(prev.response || []),
            {
              userid: user.user_id,
              remark: message,
              in_date: new Date().toISOString(),
            },
          ],
        }));
        setMessage("");
        fetchData();
      } else {
      }
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };
  const handleViewDocs = async (requestId) => {
    try {
      setTransactionLoaderState(true);
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ requestId }),
      };

      const response = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetGpDocs`,
        requestOptions
      );
      const data = await response.json();

      if (data.status === "0" && data.data) {
        setSelectedDocs(data.data);
      } else {
        console.error(
          "Failed to fetch documents:",
          data.msg || "Unknown error"
        );
      }
      setShowModal(true);
      setTransactionLoaderState(false);
    } catch (error) {
      console.error("Error fetching documents:", error);
      setTransactionLoaderState(false);
    }
  };

  const handleFilterDateChange = (e) => {
    setFilterDate(e.target.value);
    fetchData(e.target.value);
  };

  const handleClearMessage = () => {
    setMessage("");
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="">
      <Row className="p-0 mb-2 mt-3">
        <Col className="d-flex align-items-center">
          <h5 className="d-flex align-items-center">Guest Post Request Order</h5>
        </Col>
        <Col className="d-flex justify-content-end align-items-center">
        <div>  <input
        type="text"
        placeholder="Search..."
        value={searchQuery}
        onChange={handleSearch}
        className="form-control my-3"
      /></div>
          <div className="ml-2 mr-3">
            <input
              type="date"
              id="filterDate"
              className="form-control"
              value={filterDate}
              onChange={handleFilterDateChange}
            />
          </div>
          <div>
            <Link to="/Go_seo/Guestpost">
              <Button size="sm" variant="info">
                Back
              </Button>
            </Link>
          </div>
        </Col>
      </Row>
      <div className="table-responsive guestpost-container p-0">
        <table className="guestpost-table">
          <thead>
            <tr>
              <th>Website</th>
              <th>DA / PA</th>
              <th>Price</th>
              <th>Bid Price</th>
              <th>Do Follow</th>
              <th>
                Category/
                <br />
                Subcategory
              </th>
              <th>Articles Count</th>
              <th>In Date / Updated</th>
              <th>Total Bid</th>
              <th>Status</th>
              <th>Remark</th>
              <th>Action</th>
              <th>Doc</th>
              <th>View Docs</th>
              <th>Url</th>
              <th>Invoice</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.length > 0 ? (
              filteredData.map((row) => (
                <tr key={row.id}>
                  <td className="text-center">{row.webisie_name}</td>
                  <td className="text-center">
                    {row.da} / {row.pa}
                  </td>
                  <td className="text-center">{row.define_price}</td>
                  <td className="text-center">{row.bid_price}</td>
                  <td className="text-center">{row.do_follow}</td>
                  <td className="text-center">
                    {row.category_name}/<br />
                    {row.subcategory_name}
                  </td>
                  <td className="text-center">{row.articles_count}</td>
                  <td className="text-center">
                    {new Date(row.in_date).toLocaleDateString()}/<br />
                    {row.update
                      ? new Date(row.update).toLocaleDateString()
                      : "-"}
                  </td>
                  <td className="text-center">{row.total_bid}</td>
                  <td className="text-center">
                    {row.status === "A" && (
                      <span className="badge bg-success">Approved</span>
                    )}
                    {row.status === "P" && (
                      <span className="badge bg-warning">Pending</span>
                    )}
                    {row.status === "R" && (
                      <span className="badge bg-danger">Rejected</span>
                    )}
                  </td>
                  <td className="text-center">
                    {row.response && row.response.length > 0 ? (
                      <span className="badge bg-warning">
                        {
                          [...row.response].sort(
                            (a, b) => new Date(b.in_date) - new Date(a.in_date)
                          )[0].remark
                        }
                      </span>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td className="text-center">
                    <Button
                      size="sm"
                      variant="success"
                      onClick={() => handleResubmitClick(row)}
                    >
                      Rebid
                    </Button>
                  </td>
                  <td className="text-center">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-open-docs`}>Upload Doc</Tooltip>
                      }
                    >
                      <IoMdDocument
                        onClick={() => handleopenDocmodal(row)}
                        style={{ fontSize: "1.5rem", cursor: "pointer" }}
                      />
                    </OverlayTrigger>
                  </td>
                  <td className="text-center">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-view-docs`}>View Doc</Tooltip>
                      }
                    >
                      <FaEye
                        onClick={() => handleViewDocs(row.id)}
                        style={{ cursor: "pointer" }}
                      />
                    </OverlayTrigger>
                  </td>

                  <td className="text-center">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-link-docs`}>Open Link </Tooltip>
                      }
                    >
                      <IoLink
                        style={{ fontSize: "1.2rem", cursor: "pointer" }}
                      />
                    </OverlayTrigger>
                  </td>
                  <td className="text-center">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-doc-text`}>Invoice</Tooltip>
                      }
                    >
                      <IoDocumentTextOutline
                        style={{ fontSize: "1.2rem", cursor: "pointer" }}
                        onClick={handleOpeninvoiceModal}
                      />
                    </OverlayTrigger>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="16" className="text-center">
                  No Data Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <InvoiceModal
        showModal={showinvoiceModal}
        handleCloseModal={handleCloseinvoiceModal}
        row={selectedRow}
      />
      <ResubmitModal
        show={isResubmitModalOpen}
        onHide={() => setIsResubmitModalOpen(false)}
        rowData={selectedRow}
        fetchData={fetchData}
      />
      <ViewdocsModal
        show={showModal}
        onHide={() => setShowModal(false)}
        docs={selectedDocs}
      />
      {sidebarVisible && selectedRow && (
        <div className="guestpost-sidebar">
          <button className="guestpost-close-btn" onClick={closeSidebar}>
            ✖
          </button>
          <h5>Messages for {selectedRow.webisie_name}</h5>
          <div className="guestpost-messages">
            {selectedRow.response ? (
              selectedRow.response
                .sort((a, b) => new Date(a.in_date) - new Date(b.in_date))
                .map((message, index) => (
                  <div
                    key={index}
                    className={
                      message.userid === user.user_id
                        ? "message-row user-message"
                        : "message-row other-message"
                    }
                  >
                    <p>{message.remark}</p>
                    <span className="message-timestamp">
                      {new Date(message.in_date).toLocaleString()}
                    </span>
                  </div>
                ))
            ) : (
              <p>No messages available</p>
            )}
          </div>

          <div className="message-input-container">
            <input
              type="text"
              className="message-input"
              placeholder="Type your message..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <IoSend onClick={handleSendMessage} />
            <ImCross onClick={handleClearMessage} />
          </div>
        </div>
      )}
      {selectedRow && (
        <DocumentUploadModal
          show={Docmodal}
          handleClose={() => setDocmodal(false)}
          articlesCount={selectedRow.articles_count}
          rowData={selectedRow}
        />
      )}
    </div>
  );
};

export default Guestpost;
