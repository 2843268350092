import React, { useContext, useEffect, useState } from "react";
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  Table,
  Tabs,
  Tab,
  Accordion,
  Card,
} from "react-bootstrap";
import { SiTicktick } from "react-icons/si";
import { IoMdTimer } from "react-icons/io";
import swal from "sweetalert";
import { BASE_URL_API_TRANXT_UAT_API } from "../Utils/Constant";
import Select from "react-select";
import { IoIosAddCircle, IoIosRemoveCircle } from "react-icons/io";
import { GlobalContext } from "../Context/GlobalState";
import { AiOutlineClose } from "react-icons/ai";
import { RiArrowDropDownLine } from "react-icons/ri";
import { IoCheckmarkDoneCircle } from "react-icons/io5";
import { MdAdsClick } from "react-icons/md";
import { Sparklines, SparklinesLine } from "react-sparklines";
import { PiCurrencyDollarFill } from "react-icons/pi";
import {
  FaCheckCircle,
  FaFolderOpen,
  FaGlobe,
  FaLink,
  FaSmile,
} from "react-icons/fa";
import { Doughnut, Line } from "react-chartjs-2";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Link } from "react-router-dom";
export const Oboardform = ({ Getonboarddata }) => {
  const [formData, setFormData] = useState({
    companyName: "",
    ownerName: "",
    mobile: "",
    regNumber: "",
    gst: "",
    pan: "",
    domain: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    // Clear specific field error when user starts typing
    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: null,
      });
    }
  };

  const handleSubmit = async () => {
    try {
      const updatedDomain = formData.domain.startsWith("https://")
        ? formData.domain
        : `https://${formData.domain}`;

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        company_name: formData.companyName,
        domain: updatedDomain,
        reg: formData.regNumber,
        gst: formData.gst,
        company_pan: formData.pan,
        founder: formData.ownerName,
        mobile: formData.mobile,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      console.log("sending data to the server");
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/OnboardCompany`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "1") {
        swal("Error", data.result, "error");
      } else {
        swal("Success", data.result, "success");
        setFormData({
          companyName: "",
          ownerName: "",
          mobile: "",
          regNumber: "",
          gst: "",
          pan: "",
          domain: "",
        });
        Getonboarddata();
      }
      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const validate = () => {
    let valid = true;
    let errors = {};

    if (formData.companyName.trim() === "") {
      errors.companyName = "*Please enter company name";
      valid = false;
    }

    if (formData.ownerName.trim() === "") {
      errors.ownerName = "*Please enter owner name";
      valid = false;
    }

    if (formData.mobile.trim() === "") {
      errors.mobile = "*Please enter mobile number";
      valid = false;
    }

    if (formData.regNumber.trim() === "") {
      errors.regNumber = "*Please enter registration number";
      valid = false;
    }

    if (formData.gst.trim() === "") {
      errors.gst = "*Please enter GST";
      valid = false;
    }

    if (formData.pan.trim() === "") {
      errors.pan = "*Please enter PAN";
      valid = false;
    }

    if (formData.domain.trim() === "") {
      errors.domain = "*Please enter domain";
      valid = false;
    }

    setErrors(errors);
    return valid;
  };

  const handleSubmitWithValidation = (e) => {
    e.preventDefault();
    if (validate()) {
      handleSubmit();
    }
  };

  return (
    <Form onSubmit={handleSubmitWithValidation}>
      <Row className="m-0 p-0">
        <Col md={4}>
          <Form.Group controlId="formCompanyName">
            <Form.Label>Company Name</Form.Label>
            <Form.Control
              type="text"
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
              placeholder="Enter company name"
              isInvalid={!!errors.companyName}
            />
            <Form.Control.Feedback type="invalid">
              {errors.companyName}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group controlId="formOwnerName">
            <Form.Label>Owner Name</Form.Label>
            <Form.Control
              type="text"
              name="ownerName"
              value={formData.ownerName}
              onChange={handleChange}
              placeholder="Enter owner name"
              isInvalid={!!errors.ownerName}
            />
            <Form.Control.Feedback type="invalid">
              {errors.ownerName}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group controlId="formMobile">
            <Form.Label>Mobile</Form.Label>
            <Form.Control
              type="text"
              name="mobile"
              value={formData.mobile}
              onChange={handleChange}
              placeholder="Enter mobile number"
              isInvalid={!!errors.mobile}
            />
            <Form.Control.Feedback type="invalid">
              {errors.mobile}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row className="m-0 p-0">
        <Col md={4}>
          <Form.Group controlId="formRegNumber">
            <Form.Label>Registration Number</Form.Label>
            <Form.Control
              type="text"
              name="regNumber"
              value={formData.regNumber}
              onChange={handleChange}
              placeholder="Enter registration number"
              isInvalid={!!errors.regNumber}
            />
            <Form.Control.Feedback type="invalid">
              {errors.regNumber}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group controlId="formGst">
            <Form.Label>GST</Form.Label>
            <Form.Control
              type="text"
              name="gst"
              value={formData.gst}
              onChange={handleChange}
              placeholder="Enter GST"
              isInvalid={!!errors.gst}
            />
            <Form.Control.Feedback type="invalid">
              {errors.gst}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group controlId="formPan">
            <Form.Label>PAN</Form.Label>
            <Form.Control
              type="text"
              name="pan"
              value={formData.pan}
              onChange={handleChange}
              placeholder="Enter PAN"
              isInvalid={!!errors.pan}
            />
            <Form.Control.Feedback type="invalid">
              {errors.pan}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row className="m-0 p-0">
        <Col md={4}>
          <Form.Group controlId="formDomain">
            <Form.Label>Domain</Form.Label>
            <Form.Control
              type="text"
              name="domain"
              value={formData.domain}
              onChange={handleChange}
              placeholder="Enter domain"
              isInvalid={!!errors.domain}
            />
            <Form.Control.Feedback type="invalid">
              {errors.domain}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col md={4} className="d-flex align-items-center">
          <Button variant="primary" size="sm" type="submit" className="mt-2">
            Submit
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
export const GuestOboardform = ({ Getonboarddata }) => {
  const [formData, setFormData] = useState({
    Name: "",
    mobile: "",
    email: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: null,
      });
    }
  };

  const handleSubmit = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        name: formData.Name,
        mobile: formData.mobile,
        email: formData.email,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      console.log("Sending data to the server...");

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/CreateGuestPostUser`,
        requestOptions
      );

      const data = await res.json();
      if (data.status === "0") {
        const message = data.result?.[0]?.message || "Operation successful";
        swal("Success", message, "success");
        setFormData({
          Name: "",
          mobile: "",
          email: "",
        });
        Getonboarddata();
      } else {
        const message = data.msg || "Unknown error";
        swal("Error", message, "error");
      }

      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
      swal("Error", "An unexpected error occurred", "error");
    }
  };

  const validate = () => {
    let valid = true;
    let errors = {};

    if (formData.Name.trim() === "") {
      errors.Name = "*Please enter your name";
      valid = false;
    }

    if (formData.email.trim() === "") {
      errors.email = "*Please enter your email";
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "*Please enter a valid email address";
      valid = false;
    }

    if (formData.mobile.trim() === "") {
      errors.mobile = "*Please enter your mobile number";
      valid = false;
    } else if (!/^\d{10}$/.test(formData.mobile)) {
      errors.mobile = "*Please enter a valid 10-digit mobile number";
      valid = false;
    }

    setErrors(errors);
    return valid;
  };

  const handleSubmitWithValidation = (e) => {
    e.preventDefault();
    if (validate()) {
      handleSubmit();
    }
  };

  return (
    <Container className="m-0 p-0">
      <h5 className="mt-4 mb-4 text-center">Guest Post Onboarding Form</h5>
      <Form onSubmit={handleSubmitWithValidation}>
        <Row className="m-0 p-0">
          <Col md={4}>
            <Form.Group controlId="Name">
              <Form.Label> Name</Form.Label>
              <Form.Control
                type="text"
                name="Name"
                value={formData.Name}
                onChange={handleChange}
                placeholder="Enter name"
                isInvalid={!!errors.Name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.Name}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="text"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Enter email"
                isInvalid={!!errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="formMobile">
              <Form.Label>Mobile</Form.Label>
              <Form.Control
                type="text"
                name="mobile"
                value={formData.mobile}
                onChange={handleChange}
                placeholder="Enter mobile number"
                isInvalid={!!errors.mobile}
              />
              <Form.Control.Feedback type="invalid">
                {errors.mobile}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <Row className="m-0 p-0">
          <Col md={4} className="d-flex align-items-center">
            <Button
              variant="primary"
              size="sm"
              type="submit"
              className="mt-2"
              onClick={handleSubmitWithValidation}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};
export const Internallinksform = () => {
  const [formData, setFormData] = useState([{ href: "", pagename: "" }]);
  const { setTransactionLoaderState,siteid,siteurl,Productname } = useContext(GlobalContext);

  const handleInputChange = (index, event) => {
    const values = [...formData];
    values[index][event.target.name] = event.target.value;
    setFormData(values);
  };

  const handleAddField = () => {
    setFormData([...formData, { href: "", pagename: "" }]);
  };

  const handleRemoveField = (index) => {
    const values = [...formData];
    values.splice(index, 1);
    setFormData(values);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const requests = formData.map((data) => ({
      webid: siteid,
      href: data.href,
      name: data.pagename,
    }));

    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ requests }),
      };

      console.log("Sending data to the server");
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/AddInternalLinks`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        swal("Success", data.result, "success");
        Getinternallinksdata(); // Refresh data after successful save
      } else {
        swal("Error", data.result, "error");
      }
      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
    }
  };
  const Getinternallinksdata = async () => {
    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ webid: siteid }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetAllInternalLinks`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0" && data.data.length > 0) {
        setFormData(
          data.data.map((item) => ({
            href: item.href || "",
            pagename: item.name || "",
          }))
        );
      }
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };
  useEffect(() => {
    Getinternallinksdata();
  }, [siteid]);
  return (
    <Container className="m-2">
      <h5 className="mt-4 mb-4 text-center">
        Manage Internal Links <u>{siteurl}</u>
      </h5>
      <form className="" onSubmit={handleSubmit}>
        <Table bordered hover responsive>
          <thead>
            <tr>
              <th>Href</th>
              <th>Page Name</th>
              <th
                className="d-flex justify-content-center"
                style={{ border: "none" }}
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {formData.map((data, index) => (
              <tr key={index}>
                <td>
                  <input
                    type="text"
                    name="href"
                    value={data.href}
                    onChange={(event) => handleInputChange(index, event)}
                    placeholder="Enter Href"
                    className="form-control"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    name="pagename"
                    value={data.pagename}
                    onChange={(event) => handleInputChange(index, event)}
                    placeholder="Enter Page Name"
                    className="form-control"
                  />
                </td>
                <td
                  className="d-flex"
                  style={{ border: "none", justifyContent: "center" }}
                >
                  {formData.length > 1 && (
                    <IoIosRemoveCircle
                      style={{
                        fontSize: "1.5rem",
                        color: "red",
                        cursor: "pointer",
                      }}
                      onClick={() => handleRemoveField(index)}
                    />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="d-flex mt-2">
          <IoIosAddCircle
            className="mr-2"
            style={{ color: "green", fontSize: "1.5rem", cursor: "pointer" }}
            onClick={handleAddField}
          />
          <Button size="sm" type="submit" variant="primary">
            Save
          </Button>
        </div>
      </form>
    </Container>
  );
};
export const Keyword = () => {
  const pageid = JSON.parse(sessionStorage.getItem("pageid"));

  const { user, setTransactionLoaderState ,siteid} = useContext(GlobalContext);

  const [keyword, setKeyword] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [suggestedKeywords, setSuggestedKeywords] = useState([]);

  const handleAddKeyword = (kw = keyword) => {
    if (kw) {
      const newKeywords = kw
        .split(",")
        .map((kw) => kw.trim())
        .filter(
          (kw) => kw && !keywords.some((existing) => existing.keyword === kw)
        );
      setKeywords([
        ...keywords,
        ...newKeywords.map((kw) => ({
          keyword: kw,
          id: Date.now() + Math.random(),
        })),
      ]);
      setKeyword("");
      setSuggestedKeywords([]);
    }
  };

  const handleRemoveKeyword = async (keyid) => {
    console.log(keyid);
    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          webid: siteid,
          pageid: pageid.id,
          keyid: keyid,
        }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/DeleteKeywords`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        swal("Success", data.data[0].message, "success");
        setKeywords(keywords.filter((kw) => kw.id !== keyid));
      } else {
        swal("Error", data.msg, "error");
      }
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  const handleSubmitKeywords = async () => {
    const requests = keywords.map((kw) => ({
      webid: siteid,
      pageid: pageid.id,
      keyword: kw.keyword,
    }));

    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ requests }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/AddKeyword`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        swal("Success", data.result, "success");
      } else {
        swal("Error", data.result, "error");
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const Getkeyworddata = async () => {
    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ webid: siteid, pageid: pageid.id }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetAllKeywords`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        setKeywords(
          data.data.map((item) => ({
            keyword: item.keyword,
            id: item.id,
          }))
        );
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  const GetSuggestedKeywordData = async (input) => {
    if (input.trim() === "") {
      setSuggestedKeywords([]);
      return;
    }
    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          userid: user.user_id,
          url: "https://www.billpunch.com/",
        }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetAllKeywordsSuggestion`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        const allKeywords = data.data[0].keywords.split(",");
        setSuggestedKeywords(allKeywords);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    Getkeyworddata();
  }, [siteid]);

  return (
    <Card className="p-0">
      <Accordion.Toggle
        as={Card.Header}
        eventKey="4"
        className="m-0 p-2"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderRadius: ".2rem",
          border: "1px solid rgb(170, 215, 189)",
          background: "rgb(236, 246, 240)",
        }}
      >
        <div>Keywords</div>
        <div>
          <RiArrowDropDownLine style={{ fontSize: "1.5rem" }} />
        </div>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="4">
        <Card.Body
          className="p-2"
          style={{
            background: "#FEFAF6",
            border: "1px solid rgb(170, 215, 189)",
          }}
        >
          <Form inline className="m-2">
            <Form.Control
              type="text"
              value={keyword}
              onChange={(e) => {
                setKeyword(e.target.value);
                GetSuggestedKeywordData(e.target.value);
              }}
              placeholder="Enter keywords separated by commas"
              className="mr-2"
              style={{ width: "70%" }}
            />
            <IoCheckmarkDoneCircle
              style={{
                color: "green",
                fontSize: "1.5rem",
                cursor: "pointer",
              }}
              onClick={() => handleAddKeyword()}
            />
          </Form>
          {/* Render suggested keywords below the input field */}
          {suggestedKeywords.length > 0 && (
            <div
              className="suggestion-dropdown"
              style={{
                maxHeight: "150px",
                overflowY: "auto",
                border: "1px solid #ddd",
                marginTop: "10px",
              }}
            >
              {suggestedKeywords.map((suggestion, index) => (
                <div
                  key={index}
                  className="suggestion-item"
                  style={{
                    cursor: "pointer",
                    padding: "5px",
                    borderBottom: "1px solid #ddd",
                  }}
                  onClick={() => handleAddKeyword(suggestion)}
                >
                  {suggestion}
                </div>
              ))}
            </div>
          )}
          <Tab.Container id="keyword-tabs" defaultActiveKey="keywords">
            <Tab.Content>
              <Tab.Pane eventKey="keywords">
                <div className="d-flex flex-wrap">
                  {keywords.length > 0 ? (
                    <>
                      {keywords.map((kw) => (
                        <div
                          key={kw.id}
                          className="d-flex align-items-center m-1 p-2 border rounded bg-light"
                          style={{
                            position: "relative",
                            margin: "5px",
                            padding: "5px 10px",
                          }}
                        >
                          {kw.keyword} &nbsp; &nbsp;
                          <AiOutlineClose
                            style={{
                              position: "absolute",
                              right: "5px",
                              top: "50%",
                              transform: "translateY(-50%)",
                              cursor: "pointer",
                            }}
                            onClick={() => handleRemoveKeyword(kw.id)} // Use kw.id to remove
                          />
                        </div>
                      ))}
                      <Button
                        type="button" // Use type="button" to prevent form submission
                        size="sm"
                        variant="primary"
                        className="ml-2 mt-2"
                        style={{ height: "2rem" }}
                        onClick={handleSubmitKeywords}
                      >
                        Save
                      </Button>
                    </>
                  ) : (
                    <div className="text-center mt-4">No keywords found</div>
                  )}
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

export const Header = () => {
  const { setTransactionLoaderState,siteid } = useContext(GlobalContext);
  const pageid = JSON.parse(sessionStorage.getItem("pageid"));

  const initialHeadersState = {
    H1: [{ text: "" }],
    H2: [{ text: "" }],
    H3: [{ text: "" }],
    H4: [{ text: "" }],
    H5: [{ text: "" }],
    H6: [{ text: "" }],
  };

  const [headers, setHeaders] = useState(initialHeadersState);
  const [tabKey, setTabKey] = useState("H1");
  const [fetchedData, setFetchedData] = useState([]);

  const handleHeaderChange = (type, index, event) => {
    const newHeaders = { ...headers };
    newHeaders[type][index].text = event.target.value;
    setHeaders(newHeaders);
  };

  const addHeader = (type) => {
    setHeaders({ ...headers, [type]: [...headers[type], { text: "" }] });
  };

  const removeHeader = (type, index) => {
    const newHeaders = { ...headers };
    newHeaders[type] = newHeaders[type].filter((_, i) => i !== index);
    setHeaders(newHeaders);
  };

  const handleSubmitheaders = async (e) => {
    e.preventDefault();

    const requests = [];

    Object.keys(headers).forEach((type) => {
      headers[type].forEach((header) => {
        if (header.text.trim() !== "") {
          requests.push({
            webid: siteid,
            pageid: pageid.id,
            header_type: type,
            header_content: header.text,
          });
        }
      });
    });

    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ requests }),
      };

      console.log("Sending data to the server");
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/AddHeaders`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        swal("Success", data.result, "success");
        Getheaderdata();
      } else {
        swal("Error", data.result, "error");
      }
      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
      swal(
        "Error",
        "An unexpected error occurred. Please try again later.",
        "error"
      );
    }
  };

  const Getheaderdata = async () => {
    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ webid: siteid, pageid: pageid.id }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetAllHeaders`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        setFetchedData(data.data);
      }
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    Getheaderdata();
  }, [siteid]);

  const handleRemoveHeader = async (headerId) => {
    if (!headerId) {
      console.error("Header ID is missing.");
      return;
    }

    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          webid: siteid,
          pageid: pageid.id,
          headerid: headerId,
        }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/DeleteHeaders`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        swal("Success", data.result[0].message, "success");

        Getheaderdata();
      } else {
        swal("Error", data.msg, "error");
      }
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  return (
    <Card className="p-0">
      <Accordion.Toggle
        as={Card.Header}
        eventKey="2"
        className="m-0 p-2"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderRadius: ".2rem",
          border: "1px solid rgb(170, 215, 189)",
          background: "rgb(236, 246, 240)",
        }}
      >
        <div>Header</div>
        <div>
          <RiArrowDropDownLine style={{ fontSize: "1.5rem" }} />
        </div>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="2">
        <Card.Body
          className="p-2"
          style={{
            background: "#FEFAF6",
            border: "1px solid rgb(170, 215, 189)",
          }}
        >
          <Tabs
            id="header-tabs"
            activeKey={tabKey}
            onSelect={(k) => setTabKey(k)}
            className="m-2"
          >
            {Object.keys(headers).map((type) => (
              <Tab
                key={type}
                eventKey={type}
                title={
                  <span style={{ fontSize: "1rem", padding: "0 10px" }}>
                    {type}
                  </span>
                }
              >
                <div className="p-2">
                  {/* Form for adding headers */}
                  <Form onSubmit={(e) => handleSubmitheaders(e)}>
                    {headers[type].map((header, index) => (
                      <div key={index} className="row m-0 p-0 mb-2">
                        <Form.Group
                          className="col-10 m-0 p-0"
                          controlId={`form${type}${index}`}
                        >
                          <Form.Control
                            type="text"
                            placeholder={`Enter ${type} text`}
                            value={header.text}
                            onChange={(e) => handleHeaderChange(type, index, e)}
                          />
                        </Form.Group>
                        <div className="col-2 d-flex align-items-center justify-content-end">
                          <IoIosRemoveCircle
                            onClick={() => removeHeader(type, index)}
                            style={{ color: "red", fontSize: "2rem" }}
                          />
                        </div>
                      </div>
                    ))}
                    <IoIosAddCircle
                      onClick={() => addHeader(type)}
                      style={{ color: "green", fontSize: "2rem" }}
                    />
                    <Button size="sm" variant="primary" type="submit">
                      Save
                    </Button>
                  </Form>

                  {/* Display fetched data */}
                  <div className="mt-4">
                    <h6>Existing {type} Headers</h6>
                    <div
                      style={{
                        border: "1px solid #ddd",
                        padding: "1rem",
                        borderRadius: ".2rem",
                        backgroundColor: "#f9f9f9",
                      }}
                    >
                      {fetchedData.filter((item) => item.header_type === type)
                        .length === 0 ? (
                        <p>No headers available</p>
                      ) : (
                        <ul>
                          {fetchedData
                            .filter((item) => item.header_type === type)
                            .map((item, index) => (
                              <li key={index}>
                                {item.header_content} &nbsp; &nbsp;
                                <AiOutlineClose
                                  style={{
                                    cursor: "pointer",
                                    color: "red",
                                  }}
                                  onClick={() => handleRemoveHeader(item.id)}
                                />
                              </li>
                            ))}
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              </Tab>
            ))}
          </Tabs>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

export const Ogsetting = () => {

  const pageid = JSON.parse(sessionStorage.getItem("pageid"));

  const [ogSetting, setOgSetting] = useState({
    ogTitle: "",
    ogDescription: "",
    ogUrl: "",
    ogImage: "",
    ogType: "",
    ogSiteName: "",
    ogLocale: "",
    ogAudio: "",
    ogVideo: "",
    ogVideoSecureUrl: "",
    ogVideoType: "",
    ogVideoWidth: "",
    ogVideoHeight: "",
    ogDeterminer: "",
    twitterCard: "",
    twitterSite: "",
    twitterTitle: "",
    twitterDescription: "",
    twitterImage: "",
    twitterUrl: "",
    twitterCreator: "",
    ogArticleAuthor: "",
    ogArticlePublishedTime: "",
    ogArticleModifiedTime: "",
    fbAppId: "",
  });
  const { setTransactionLoaderState ,siteid} = useContext(GlobalContext);

  const handleOGSubmit = async (e) => {
    e.preventDefault();

    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          webId: siteid,
          pageId: pageid.id,
          ogTitle: ogSetting.ogTitle,
          ogDesc: ogSetting.ogDescription,
          ogImage: ogSetting.ogImage,
          ogUrl: ogSetting.ogUrl,
          ogType: ogSetting.ogType,
          ogLocale: ogSetting.ogLocale,
          ogSitename: ogSetting.ogSiteName,
          ogAudio: ogSetting.ogAudio,
          ogVideo: ogSetting.ogVideo,
          ogVideoSecureUrl: ogSetting.ogVideoSecureUrl,
          ogVideoType: ogSetting.ogVideoType,
          ogVideoWidth: ogSetting.ogVideoWidth,
          ogVideoHeight: ogSetting.ogVideoHeight,
          ogDeterminer: ogSetting.ogDeterminer,
          twittercard: ogSetting.twitterCard,
          twitterSite: ogSetting.twitterSite,
          twitterTitle: ogSetting.twitterTitle,
          twitterDesc: ogSetting.twitterDescription,
          twitterImage: ogSetting.twitterImage,
          twitterUrl: ogSetting.twitterUrl,
          twitterCreator: ogSetting.twitterCreator,
          fbAppId: ogSetting.fbAppId,
          fbOgArticleAuthor: ogSetting.ogArticleAuthor,
          fbOgArticlePublishedTime: ogSetting.ogArticlePublishedTime,
          fbOgArticleModifiedTime: ogSetting.ogArticleModifiedTime,
        }),
      };

      console.log("Sending data to the server");
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/AddOgSettingsSeo`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        swal("Success", data.msg, "success");
      } else {
        swal("Error", data.msg, "error");
      }
      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOgSetting((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const Getogsettingdata = async () => {
    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ webid: siteid, pageid: pageid.id }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetAllOgSettings`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0" && data.data.length > 0) {
        const fetchedData = data.data[0];
        setOgSetting({
          ogTitle: fetchedData.og_title || "",
          ogDescription: fetchedData.og_desc || "",
          ogUrl: fetchedData.og_url || "",
          ogImage: fetchedData.og_image || "",
          ogType: fetchedData.og_type || "",
          ogSiteName: fetchedData.og_sitename || "",
          ogLocale: fetchedData.og_locale || "",
          ogAudio: fetchedData.og_audio || "",
          ogVideo: fetchedData.og_video || "",
          ogVideoSecureUrl: fetchedData.og_video_secure_url || "",
          ogVideoType: fetchedData.og_video_type || "",
          ogVideoWidth: fetchedData.og_video_width || "",
          ogVideoHeight: fetchedData.og_video_height || "",
          ogDeterminer: fetchedData.og_determiner || "",
          twitterCard: fetchedData.twitter_card || "",
          twitterSite: fetchedData.twitter_site || "",
          twitterTitle: fetchedData.twitter_title || "",
          twitterDescription: fetchedData.twitter_desc || "",
          twitterImage: fetchedData.twitter_image || "",
          twitterUrl: fetchedData.twitter_url || "",
          twitterCreator: fetchedData.twitter_creator || "",
          ogArticleAuthor: fetchedData.fb_og_article_author || "",
          ogArticlePublishedTime:
            fetchedData.fb_og_article_published_time || "",
          ogArticleModifiedTime: fetchedData.fb_og_article_modified_time || "",
          fbAppId: fetchedData.fb_app_id || "",
        });
      }
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    Getogsettingdata();
  }, [siteid]);

  return (
    <Card className="p-0">
      <Accordion.Toggle
        as={Card.Header}
        eventKey="1"
        className="m-0 p-2"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderRadius: ".2rem",
          border: "1px solid rgb(170, 215, 189)",
          background: "rgb(236, 246, 240)",
        }}
      >
        <div>OG Setting</div>
        <div>
          <RiArrowDropDownLine style={{ fontSize: "1.5rem" }} />
        </div>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="1">
        <Card.Body
          className="p-2"
          style={{
            background: "#FEFAF6",
            border: "1px solid rgb(170, 215, 189)",
          }}
        >
          <Form className="p-2 mt-2" onSubmit={handleOGSubmit}>
            <Form.Group controlId="formOgTitle">
              <Form.Label>OG Title</Form.Label>
              <Form.Control
                type="text"
                name="ogTitle"
                placeholder="Enter OG title"
                value={ogSetting.ogTitle}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formOgDescription">
              <Form.Label>OG Description</Form.Label>
              <Form.Control
                type="text"
                name="ogDescription"
                placeholder="Enter OG description"
                value={ogSetting.ogDescription}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formOgUrl">
              <Form.Label>OG URL</Form.Label>
              <Form.Control
                type="text"
                name="ogUrl"
                placeholder="Enter OG URL"
                value={ogSetting.ogUrl}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formOgImage">
              <Form.Label>OG Image</Form.Label>
              <Form.Control
                type="text"
                name="ogImage"
                placeholder="Enter OG image URL"
                value={ogSetting.ogImage}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formOgType">
              <Form.Label>OG Type</Form.Label>
              <Form.Control
                type="text"
                name="ogType"
                placeholder="Enter OG type"
                value={ogSetting.ogType}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formOgSiteName">
              <Form.Label>OG Site Name</Form.Label>
              <Form.Control
                type="text"
                name="ogSiteName"
                placeholder="Enter OG site name"
                value={ogSetting.ogSiteName}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formOgLocale">
              <Form.Label>OG Locale</Form.Label>
              <Form.Control
                type="text"
                name="ogLocale"
                placeholder="Enter OG locale"
                value={ogSetting.ogLocale}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formOgAudio">
              <Form.Label>OG Audio</Form.Label>
              <Form.Control
                type="text"
                name="ogAudio"
                placeholder="Enter OG audio URL"
                value={ogSetting.ogAudio}
                onChange={handleChange}
              />
            </Form.Group>
            <h6>Video Setting</h6>
            <Form.Group controlId="formOgVideo">
              <Form.Label>OG Video</Form.Label>
              <Form.Control
                type="text"
                name="ogVideo"
                placeholder="Enter OG video URL"
                value={ogSetting.ogVideo}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formOgVideoSecureUrl">
              <Form.Label>OG Video Secure URL</Form.Label>
              <Form.Control
                type="text"
                name="ogVideoSecureUrl"
                placeholder="Enter OG video secure URL"
                value={ogSetting.ogVideoSecureUrl}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formOgVideoType">
              <Form.Label>OG Video Type</Form.Label>
              <Form.Control
                type="text"
                name="ogVideoType"
                placeholder="Enter OG video type"
                value={ogSetting.ogVideoType}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formOgVideoWidth">
              <Form.Label>OG Video Width</Form.Label>
              <Form.Control
                type="text"
                name="ogVideoWidth"
                placeholder="Enter OG video width"
                value={ogSetting.ogVideoWidth}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formOgVideoHeight">
              <Form.Label>OG Video Height</Form.Label>
              <Form.Control
                type="text"
                name="ogVideoHeight"
                placeholder="Enter OG video height"
                value={ogSetting.ogVideoHeight}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formOgDeterminer">
              <Form.Label>OG Determiner</Form.Label>
              <Form.Control
                type="text"
                name="ogDeterminer"
                placeholder="Enter OG determiner"
                value={ogSetting.ogDeterminer}
                onChange={handleChange}
              />
            </Form.Group>
            <h6>Twitter Setting</h6>
            <Form.Group controlId="formTwitterCard">
              <Form.Label>Twitter Card</Form.Label>
              <Form.Control
                type="text"
                name="twitterCard"
                placeholder="Enter Twitter card"
                value={ogSetting.twitterCard}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formTwitterSite">
              <Form.Label>Twitter Site</Form.Label>
              <Form.Control
                type="text"
                name="twitterSite"
                placeholder="Enter Twitter site"
                value={ogSetting.twitterSite}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formTwitterTitle">
              <Form.Label>Twitter Title</Form.Label>
              <Form.Control
                type="text"
                name="twitterTitle"
                placeholder="Enter Twitter title"
                value={ogSetting.twitterTitle}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formTwitterDescription">
              <Form.Label>Twitter Description</Form.Label>
              <Form.Control
                type="text"
                name="twitterDescription"
                placeholder="Enter Twitter description"
                value={ogSetting.twitterDescription}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formTwitterImage">
              <Form.Label>Twitter Image</Form.Label>
              <Form.Control
                type="text"
                name="twitterImage"
                placeholder="Enter Twitter image URL"
                value={ogSetting.twitterImage}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formTwitterUrl">
              <Form.Label>Twitter URL</Form.Label>
              <Form.Control
                type="text"
                name="twitterUrl"
                placeholder="Enter Twitter URL"
                value={ogSetting.twitterUrl}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formTwitterCreator">
              <Form.Label>Twitter Creator</Form.Label>
              <Form.Control
                type="text"
                name="twitterCreator"
                placeholder="Enter Twitter creator handle"
                value={ogSetting.twitterCreator}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="fbAppId">
              <Form.Label>Fb App Id</Form.Label>
              <Form.Control
                type="text"
                name="fbAppId"
                placeholder="Enter fbAppId"
                value={ogSetting.fbAppId}
                onChange={handleChange}
              />
            </Form.Group>
            <h6>Facebook Setting</h6>
            <Form.Group controlId="formOgArticleAuthor">
              <Form.Label>OG Article Author</Form.Label>
              <Form.Control
                type="text"
                name="ogArticleAuthor"
                placeholder="Enter OG article author"
                value={ogSetting.ogArticleAuthor}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formOgArticlePublishedTime">
              <Form.Label>OG Article Published Time</Form.Label>
              <Form.Control
                type="text"
                name="ogArticlePublishedTime"
                placeholder="Enter OG article published time"
                value={ogSetting.ogArticlePublishedTime}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formOgArticleModifiedTime">
              <Form.Label>OG Article Modified Time</Form.Label>
              <Form.Control
                type="text"
                name="ogArticleModifiedTime"
                placeholder="Enter OG article modified time"
                value={ogSetting.ogArticleModifiedTime}
                onChange={handleChange}
              />
            </Form.Group>
            <Button variant="primary" size="sm" type="submit">
              Save
            </Button>
          </Form>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

export const Paragraph = () => {
  const pageid = JSON.parse(sessionStorage.getItem("pageid"));
  const { setTransactionLoaderState ,siteid} = useContext(GlobalContext);

  const initialParagraphs = [{ text: "" }];
  const [paragraphs, setParagraphs] = useState(initialParagraphs);
  const [fetchedData, setFetchedData] = useState([]);

  const handleAccordionClick = (section) => {};

  const handleParagraphChange = (index, event) => {
    const newParagraphs = [...paragraphs];
    newParagraphs[index].text = event.target.value;
    setParagraphs(newParagraphs);
  };

  const addParagraph = () => {
    setParagraphs([...paragraphs, { text: "" }]);
  };

  const removeParagraph = (index) => {
    const newParagraphs = paragraphs.filter((_, i) => i !== index);
    setParagraphs(newParagraphs);
  };

  const handleSubmitpara = async (e) => {
    e.preventDefault();

    const requests = paragraphs.map((paragraph, index) => ({
      webid: siteid,
      page_id: pageid.id,
      para_type: `P${index + 1}`,
      para_content: paragraph.text,
    }));

    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ requests }),
      };

      console.log("Sending data to the server:", requests);
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/AddParagraph`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        swal("Success", data.msg, "success");
        Getparadata();
        setParagraphs(initialParagraphs); // Reset paragraphs state to clear the form fields
      } else {
        swal("Error", data.msg, "error");
      }
      console.log("Form submission completed:", data);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const Getparadata = async () => {
    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ webid: siteid, pageid: pageid.id }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetAllParagraphs`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        setFetchedData(data.data);
        console.log("Fetched data:", data.data); // Log the fetched data to ensure para_id is present
      } else {
        console.error("Error fetching paragraphs:", data.msg);
      }
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  const handleRemoveFetchedData = async (paraId) => {
    if (!paraId) {
      console.error("Paragraph ID is missing.");
      return;
    }

    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          webid: siteid,
          pageid: pageid.id,
          paraid: paraId,
        }),
      };

      console.log("Sending delete request:", {
        webid: siteid,
        pageid: pageid.id,
        para_id: paraId,
      });
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/DeleteParagraphs`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        swal("Success", data.data[0].message, "success");
        setFetchedData(fetchedData.filter((item) => item.id !== paraId));
      } else {
        swal("Error", data.msg, "error");
      }
      console.log("Delete request completed:", data);
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    Getparadata();
  }, []);

  return (
    <Card className="p-0">
      <Accordion.Toggle
        as={Card.Header}
        eventKey="3"
        onClick={() => handleAccordionClick("Paragraphs")}
        className="m-0 p-2"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderRadius: ".2rem",
          border: "1px solid rgb(170, 215, 189)",
          background: "rgb(236, 246, 240)",
        }}
      >
        <div>Paragraphs</div>
        <div>
          <RiArrowDropDownLine style={{ fontSize: "1.5rem" }} />
        </div>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="3">
        <Card.Body
          className="p-2"
          style={{
            background: "#FEFAF6",
            border: "1px solid rgb(170, 215, 189)",
          }}
        >
          <Form className="p-2 mt-2" onSubmit={handleSubmitpara}>
            <h6>Paragraphs</h6>
            {paragraphs.map((paragraph, index) => (
              <div key={index} className="row m-0 p-0 mb-2">
                <Form.Group
                  className="col-10 m-0 p-0"
                  controlId={`formParagraph${index}`}
                >
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Enter paragraph text"
                    value={paragraph.text}
                    onChange={(e) => handleParagraphChange(index, e)}
                  />
                </Form.Group>
                <div className="col-2 d-flex align-items-center justify-content-end">
                  <IoIosRemoveCircle
                    onClick={() => removeParagraph(index)}
                    style={{ color: "red", fontSize: "2rem" }}
                  />
                </div>
              </div>
            ))}
            <IoIosAddCircle
              className="mb-3"
              onClick={addParagraph}
              style={{ color: "green", fontSize: "2rem" }}
            />
            <br />
            <Button size="sm" variant="primary" type="submit">
              Save
            </Button>
          </Form>
          <div className="mt-3">
            {fetchedData.map((item) => (
              <div
                key={item.id} // Assuming `id` is the unique identifier
                className="d-flex align-items-center m-1 p-2 border rounded bg-light"
                style={{
                  position: "relative",
                  margin: "5px",
                  padding: "5px 10px",
                }}
              >
                {item.para_content} &nbsp; &nbsp;
                <AiOutlineClose
                  style={{
                    position: "absolute",
                    right: "5px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }}
                  onClick={() => handleRemoveFetchedData(item.id)}
                />
              </div>
            ))}
          </div>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

export const Basicsetting = () => {
  const { setTransactionLoaderState,siteid } = useContext(GlobalContext);
  const pageid = JSON.parse(sessionStorage.getItem("pageid"));

  const [basicSetting, setBasicSetting] = useState({
    title: "",
    metaDescription: "",
    canonicalLink: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          web_id: siteid,
          page_id: pageid.id,
          page_name: pageid.url_pages,
          tittle: basicSetting.title,
          description: basicSetting.metaDescription,
          canonical: basicSetting.canonicalLink,
        }),
      };

      console.log("Sending data to the server");
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/AddBasicSettingsSeo`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        swal("Success", data.result, "success");
      } else {
        swal("Error", data.msg, "error");
      }
      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handleBasicSettingChange = (e) => {
    const { id, value } = e.target;
    setBasicSetting((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleAccordionClick = (section) => {};

  const Getbasicsetting = async () => {
    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ webid: siteid, pageid: pageid.id }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetAlBasicSettings`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0" && data.data.length > 0) {
        const fetchedData = data.data[0];
        setBasicSetting({
          title: fetchedData.tittle,
          metaDescription: fetchedData.meta_desc,
          canonicalLink: fetchedData.canonical_tag,
        });
      }
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    Getbasicsetting();
  }, []);

  return (
    <Card className="p-0">
      <Accordion.Toggle
        as={Card.Header}
        eventKey="0"
        onClick={() => handleAccordionClick("Basic Setting")}
        className="m-0 p-2"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderRadius: ".2rem",
          border: "1px solid rgb(170, 215, 189)",
          background: "rgb(236, 246, 240)",
        }}
      >
        <div>Basic Setting</div>
        <div>
          <RiArrowDropDownLine style={{ fontSize: "1.5rem" }} />
        </div>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="0">
        <Card.Body
          className="p-2"
          style={{
            background: "#FEFAF6",
            border: "1px solid rgb(170, 215, 189)",
          }}
        >
          <Form className="p-2 mt-2" onSubmit={handleSubmit}>
            <Form.Group controlId="title">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter title"
                value={basicSetting.title}
                onChange={handleBasicSettingChange}
              />
            </Form.Group>
            <Form.Group controlId="metaDescription">
              <Form.Label>Meta Description</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter meta description"
                value={basicSetting.metaDescription}
                onChange={handleBasicSettingChange}
              />
            </Form.Group>
            <Form.Group controlId="canonicalLink">
              <Form.Label>Canonical Link</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter canonical link"
                value={basicSetting.canonicalLink}
                onChange={handleBasicSettingChange}
              />
            </Form.Group>
            <Button variant="primary" size="sm" type="submit">
              Save
            </Button>
          </Form>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

export const Projectform = ({ Geprojectdata }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({
    sitename: "",
    site_url: "",
    site_category: "",
    comp_urls: ["", "", "", "", ""],
  });

  const [errors, setErrors] = useState({});
  const [appreciationMessage, setAppreciationMessage] = useState(
    "Great! Let's start with the Site Name."
  );
  const { user } = useContext(GlobalContext);
  const appreciationIcons = [
    <FaCheckCircle />,
    <FaGlobe />,
    <FaFolderOpen />,
    <FaLink />,
    <FaSmile />,
  ];
  const appreciationMessages = [
    "Great! Let's start with the Site Name.",
    "Nice job! Now, please provide the Site URL.",
    "Awesome! Select the category that best fits your site.",
    "You're doing well! Now, please enter the competitive URLs.",
    "Almost there! Just one more step to complete.",
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name.startsWith("comp_url")) {
      const index = parseInt(name.split("_")[2]);
      const newCompUrls = [...formData.comp_urls];
      newCompUrls[index] = value;
      setFormData({
        ...formData,
        comp_urls: newCompUrls,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }

    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: null,
      });
    }
  };

  const handleSelectChange = (selectedOption) => {
    setFormData({
      ...formData,
      site_category: selectedOption,
    });

    if (errors.site_category) {
      setErrors({
        ...errors,
        site_category: null,
      });
    }
  };

  const validateStep = () => {
    let stepErrors = {};
    switch (currentStep) {
      case 0:
        if (!formData.sitename) {
          stepErrors.sitename = "Site Name is required.";
        }
        break;
      case 1:
        if (!formData.site_url) {
          stepErrors.site_url = "Site URL is required.";
        }
        break;
      case 2:
        if (!formData.site_category) {
          stepErrors.site_category = "Site Category is required.";
        }
        break;
      case 3:
        for (let i = 0; i < formData.comp_urls.length; i++) {
          if (!formData.comp_urls[i]) {
            stepErrors[`comp_url${i + 1}`] = `Competitive URL ${
              i + 1
            } is required.`;
          }
        }
        break;
      default:
        break;
    }

    if (Object.keys(stepErrors).length > 0) {
      setErrors(stepErrors);
      const errorFields = Object.keys(stepErrors)
        .map((key) => stepErrors[key])
        .join(", ");
      swal(
        "Error",
        `Please fill in the following fields: ${errorFields}`,
        "error"
      );
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateStep()) {
      return;
    }

    if (currentStep === 4) {
      try {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
          userid: user.user_id,
          site_url: formData.site_url,
          site_category: formData.site_category?.label,
          comp_url1: formData.comp_urls[0],
          comp_url2: formData.comp_urls[1],
          comp_url3: formData.comp_urls[2],
          comp_url4: formData.comp_urls[3],
          comp_url5: formData.comp_urls[4],
          keywords: "string",
          project_name: formData.sitename,
          type: "0",
          full_name: "string",
          mobile: "string",
          email: "string",
          website: "string",
          company_name: "string",
          password: "string",
          da: "string",
          pa: "string",
        });

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        console.log("sending data to the server");
        const res = await fetch(
          `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/AddYourUrl`,
          requestOptions
        );
        const data = await res.json();

        if (data.status === "1") {
          swal("Error", data.msg, "error");
        } else if (data.status === "0") {
          const message = data.result[0].message;
          swal("Success", message, "success");
          setFormData({
            site_url: "",
            site_category: "",
            comp_urls: ["", "", "", "", ""],
            sitename: "",
          });
          Geprojectdata();
        }

        console.log("Form submission completed.");
      } catch (error) {
        console.log("Error:", error);
      }
    }
  };

  const [categories, setCategories] = useState([]);
  const fetchCategories = async () => {
    try {
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetBlogCategories`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({}),
        }
      );
      const data = await res.json();
      if (data.status === "0") {
        setCategories(
          data.data.map((category) => ({
            label: category.category_name,
            value: category.id,
          }))
        );
      } else {
        console.error("Failed to fetch categories:", data.msg);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const nextStep = (e) => {
    e.preventDefault();
    if (validateStep()) {
      setCurrentStep((prevStep) => {
        const newStep = Math.min(prevStep + 1, 4);
        setAppreciationMessage(appreciationMessages[newStep]);
        return newStep;
      });
    }
  };

  const prevStep = () => {
    setCurrentStep((prevStep) => {
      const newStep = Math.max(prevStep - 1, 0);
      setAppreciationMessage(appreciationMessages[newStep]);
      return newStep;
    });
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case 0:
        return (
          <>
            <Form.Group controlId="formSiteName">
              <Form.Label>Site Name</Form.Label>
              <Form.Control
                type="text"
                name="sitename"
                value={formData.sitename}
                onChange={handleChange}
                placeholder="Enter site name"
                isInvalid={!!errors.sitename}
              />
            </Form.Group>
          </>
        );
      case 1:
        return (
          <>
            <Form.Group controlId="formSiteUrl">
              <Form.Label>Site URL</Form.Label>
              <Form.Control
                type="text"
                name="site_url"
                value={formData.site_url}
                onChange={handleChange}
                placeholder="Enter site URL"
                isInvalid={!!errors.site_url}
              />
            </Form.Group>
          </>
        );
      case 2:
        return (
          <>
            <Form.Group controlId="formSiteCategory">
              <Form.Label>Site Category</Form.Label>
              <Select
                value={formData.site_category}
                onChange={handleSelectChange}
                options={categories}
                placeholder="Select site category"
                isInvalid={!!errors.site_category}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    backgroundColor: "white",
                  }),
                  menu: (provided) => ({
                    ...provided,
                    backgroundColor: "white",
                  }),
                }}
              />
            </Form.Group>
          </>
        );
      case 3:
        return (
          <>
            <Form.Label>Competitive URLs</Form.Label>
            <Row>
              {formData.comp_urls.map((url, index) => (
                <Col key={index} md={6} className="mb-3">
                  <Form.Group controlId={`formCompUrl${index + 1}`}>
                    <Form.Control
                      type="text"
                      name={`comp_url_${index}`}
                      value={url}
                      onChange={handleChange}
                      placeholder={`Enter Competitive URL ${index + 1}`}
                      isInvalid={!!errors[`comp_url${index + 1}`]}
                    />
                  </Form.Group>
                </Col>
              ))}
            </Row>
          </>
        );

      default:
        return null;
    }
  };

  return (
    <div className="mx-3">
      <Row className="justify-content-center">
        <Col md={10} >
          <div className="card p-3" style={{background:"rgba(234,229,244)"}}>
            <h4 className=" mb-4 text-center">Add Website Form</h4>
            {appreciationMessage && (
              <div className="alert  d-flex align-items-center" style={{background:"#441752", color:"#fff"}}>
                <span className="me-2">{appreciationIcons[currentStep]}</span> &nbsp; &nbsp;
                {appreciationMessage}
              </div>
            )}

            <Form onSubmit={handleSubmit}>
              {renderStepContent()}
              <Row className="m-0 p-0">
                <Col className="d-flex justify-content-between">
                  {currentStep > 0 && (
                    <Button variant="secondary" onClick={prevStep}>
                      Back
                    </Button>
                  )}

                  {currentStep === 4 ? (
                    <Button variant="primary" type="submit">
                      Submit
                    </Button>
                  ) : (
                    <Button variant="primary" onClick={nextStep}>
                      Next
                    </Button>
                  )}
                </Col>
              </Row>
            </Form>
          </div>
          <div className="card p-2 mt-3" style={{background:"rgba(234,229,244)"}}>

          </div>
        </Col>
      </Row>
    </div>
  );
};

export const Campaignform = ({ Getcampaigndata }) => {
  const [formData, setFormData] = useState({
    link: "",
    source: "",
    medium: "",
    name: "",
    term: "",
    content: "",
  });
  const [showForm, setShowForm] = useState(false);
  const [steps, setSteps] = useState([]);
  const [dynamicFields, setDynamicFields] = useState([{ field: "", step: "" }]);
  const [iframeSrc, setIframeSrc] = useState("");
  const { user, setTransactionLoaderState,siteid } = useContext(GlobalContext);
  const [shortUrl, setShortUrl] = useState("");
  const [utmLink, setUtmLink] = useState("");
  const [qr, setqr] = useState("");
  const [campaignId, setCampaignId] = useState("");
  const [uuid, setUuid] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        p_base_url: formData.link,
        p_utm_source: formData.source,
        p_utm_medium: formData.medium,
        p_utm_campaign: formData.name,
        p_utm_term: formData.term,
        p_utm_content: formData.content,
        p_userid: siteid,
        _uuid: uuid,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      console.log("Sending data to the server...");
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GenerateUtmLinks`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "1") {
        swal({
          title: "Error",
          text: data.result[0].message,
          icon: "error",
        });
      } else {
        swal({
          title: "Success",
          text: data.result[0].message,
          icon: "success",
        });
        Getcampaigndata();
        setUtmLink(data.result[0].utm_link);
      }
      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
      swal({
        title: "Error",
        text: "Something went wrong!",
        icon: "error",
      });
    }
  };
  const Shortlinkurl = async (e) => {
    e.preventDefault();
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        url: utmLink,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      console.log("Sending data to the server...");
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/ShortLinkURL`,
        requestOptions
      );
      const data = await res.json();

      if (data.responseCode === "00") {
        setShortUrl(data.data.shortUrl);
        GenerateShortLinkQrCode();
        Getcampaigndata();
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };
  const GenerateShortLinkQrCode = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        type: "link",
        data: utmLink,
        background: "string",
        foreground: "string",
        logo: "https://www.billpunch.com/uploads/billpunch_logo.png",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      console.log("Sending data to the server...");
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GenerateShortLinkQrCode`,
        requestOptions
      );
      const data = await res.json();

      if (data.responseCode === "00") {
        setqr(data.data.link);
        setIframeSrc(data.data.link);
        Getcampaigndata();
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };
  const [copySuccess, setCopySuccess] = useState("");

  const handleCopy = () => {
    navigator.clipboard
      .writeText(shortUrl)
      .then(() => {
        setCopySuccess("Copied!");
        setTimeout(() => setCopySuccess(""), 2000);
      })
      .catch((err) => {
        setCopySuccess("Failed to copy!");
      });
  };
  const handleAddField = () => {
    setDynamicFields([...dynamicFields, { field: "", step: "" }]);
  };
  const handleRemoveField = (index) => {
    const fields = [...dynamicFields];
    fields.splice(index, 1);
    setDynamicFields(fields);
  };
  const handleDynamicChange = (index, event) => {
    const values = [...dynamicFields];
    values[index][event.target.name] = event.target.value;
    setDynamicFields(values);
  };
  const Getproductdata = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          productid: siteid,
        }),
        redirect: "follow",
      };
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetProductSteps`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        console.log("Success:", data.data);
        setSteps(
          data.data.map((step) => ({ value: step.id, label: step.stepname }))
        );
      } else {
        console.log("API returned an error:", data.msg);
      }
    } catch (error) {
      console.log("Error fetching product steps:", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    Getproductdata("");
  }, []);
  const generateUUID = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
      const r = (Math.random() * 16) | 0;
      const v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  };
  const handleSubmitproduct = async (e) => {
    e.preventDefault();
    const generatedUuid = generateUUID();
    setUuid(generatedUuid);
    const requests = dynamicFields.map((dynamicField, index) => ({
      stepname: dynamicField.step,
      step_order: index + 1,
      is_final: dynamicField.field === "yes" ? 1 : 0,
      uuid: generatedUuid,
    }));

    const requestBody = { requests };

    try {
      const response = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/InsertCampaignSteps`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      if (data.result && data.result.length > 0) {
        setCampaignId(data.result[0].campaign_Id);
        console.log("Campaign ID:", data.result[0].campaign_Id);
      }

      setShowForm(true);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="card p-3" style={{ borderRadius: "1rem" }}>
      <h3 className="text-center">
        Before creating a link, fill the fields below
      </h3>
      {!showForm && (
        <>
          {dynamicFields.map((dynamicField, index) => (
            <div key={index} className="mt-3">
              <div className="row p-0">
                <Form.Group className="col-5 mb-0">
                  <Form.Label>Choose Step</Form.Label>
                  <Form.Control
                    type="text"
                    name="step"
                    value={dynamicField.step || ""}
                    onChange={(e) =>
                      handleDynamicChange(index, {
                        target: { name: "step", value: e.target.value },
                      })
                    }
                    placeholder="Enter Step"
                    style={{ backgroundColor: "white", color: "black" }}
                  />
                </Form.Group>

                <Form.Group className="col-3 mb-0">
                  <Form.Label>Is Final Step</Form.Label>
                  <div className="d-flex flex-row">
                    <Form.Check
                      inline
                      type="radio"
                      label="Yes"
                      name={`field-${index}`}
                      value="yes"
                      checked={dynamicField.field === "yes"}
                      onChange={(event) =>
                        handleDynamicChange(index, {
                          target: { name: "field", value: event.target.value },
                        })
                      }
                    />
                    <Form.Check
                      inline
                      type="radio"
                      label="No"
                      name={`field-${index}`}
                      value="no"
                      checked={dynamicField.field === "no"}
                      onChange={(event) =>
                        handleDynamicChange(index, {
                          target: { name: "field", value: event.target.value },
                        })
                      }
                    />
                  </div>
                </Form.Group>

                <div className="col d-flex align-items-center">
                  <Button
                    variant="link"
                    onClick={() => handleRemoveField(index)}
                  >
                    <IoIosRemoveCircle
                      style={{ color: "red", fontSize: "1.5rem" }}
                    />
                  </Button>
                </div>
              </div>
            </div>
          ))}

          <div className="row d-flex align-items-center">
            <IoIosAddCircle
              className="m-3"
              style={{ color: "green", fontSize: "1.5rem" }}
              onClick={handleAddField}
            />
            <Button
              className=""
              variant="success"
              size="sm"
              style={{ width: "4rem" }}
              onClick={handleSubmitproduct}
            >
              Submit
            </Button>
          </div>
        </>
      )}

      {showForm && (
        <>
          <div className=" mb-2 d-flex justify-content-end">
            <Button
              className=""
              size="sm"
              variant="secondary"
              onClick={() => setShowForm(false)}
            >
              Back
            </Button>
          </div>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formlink">
              <Form.Label>Campaign Link</Form.Label>
              <Form.Control
                type="text"
                name="link"
                placeholder="For example: www.google.com"
                value={formData.link}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formSource">
              <Form.Label>Campaign Source</Form.Label>
              <Form.Control
                type="text"
                name="source"
                placeholder="For example: Google"
                value={formData.source}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formMedium" className="mt-3">
              <Form.Label>Campaign Medium</Form.Label>
              <Form.Control
                type="text"
                name="medium"
                placeholder="For example: CPC, Banner"
                value={formData.medium}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formName" className="mt-3">
              <Form.Label>Campaign Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                placeholder="Name to identify your campaign"
                value={formData.name}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formTerm" className="mt-3">
              <Form.Label>Campaign Term (Optional)</Form.Label>
              <Form.Control
                type="text"
                name="term"
                placeholder="Enter if there is a specific keyword"
                value={formData.term}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formContent" className="mt-3">
              <Form.Label>Campaign Content (Optional)</Form.Label>
              <Form.Control
                type="text"
                name="content"
                placeholder="Enter if there is specific content"
                value={formData.content}
                onChange={handleChange}
              />
            </Form.Group>
            <div className="d-flex justify-content-center">
              <Button
                className=""
                variant="primary"
                type="submit"
                style={{
                  color: "black",
                  background: "rgb(255, 215, 0)",
                  border: "1px solid rgb(255, 215, 0)",
                }}
                onClick={handleSubmit}
              >
                Create Link
              </Button>
            </div>
            {utmLink && (
              <div className="mt-4">
                <h5 className="text-center">Here is Your Link / URL:</h5>
                <p className="text-center">{utmLink}</p>
                <Row className="align-items-center">
                  <Col className="col-10" xs="auto">
                    <Form.Control
                      value={utmLink}
                      type="text"
                      placeholder="Enter something"
                    />
                  </Col>
                  <Col xs="auto">
                    <Button variant="info" onClick={Shortlinkurl}>
                      Shorten
                    </Button>
                  </Col>
                </Row>
              </div>
            )}
            {shortUrl && (
              <div className="mt-4">
                <div className="card p-2">
                  <Row className="">
                    <Col className="col-md-2 col-6" xs="auto">
                      <img
                        src={qr}
                        alt="QR Code Preview"
                        style={{ width: "100px", height: "100px" }}
                      />
                    </Col>
                    <Col xs="auto">
                      <h6 className="text-center">{shortUrl}</h6>
                      <Button size="sm" variant="info" onClick={handleCopy}>
                        Copy
                      </Button>
                      {copySuccess && (
                        <p className="text-success mt-2">{copySuccess}</p>
                      )}
                    </Col>
                  </Row>
                </div>
              </div>
            )}
          </Form>
        </>
      )}
    </div>
  );
};

export const Campaigndashboard = () => {
  const cardData = [
    {
      icon: <MdAdsClick />,
      title: "Click",
      value: "1.2M",
      sparkData: [5, 10, 5, 20, 8, 15],
    },
    {
      icon: <PiCurrencyDollarFill />,
      title: "Cost",
      value: "345K",
      sparkData: [2, 4, 2, 5, 8, 4],
    },
    {
      icon: <FaLink />,
      title: "Backlinks",
      value: "1.5K",
      sparkData: [1, 2, 3, 4, 5, 6],
    },
    {
      icon: <IoMdTimer />,
      title: "Time",
      value: "$0.45",
      sparkData: [5, 6, 7, 9, 3, 5],
    },
    {
      icon: <SiTicktick />,
      title: "Open Rate",
      value: "$15,000",
      sparkData: [3, 4, 6, 8, 7, 5],
    },
    {
      icon: <MdAdsClick />,
      title: "ROI",
      value: "300%",
      sparkData: [4, 7, 5, 10, 5, 6],
    },
  ];
  const SocialcardData = [
    {
      icon: require("../../assets/images/seoimages/insta.png"),
      title: "Instagram",
      value: "1.2M",
      sparkData: [5, 10, 5, 20, 8, 15],
    },
    {
      icon: require("../../assets/images/seoimages/facebook.png"),
      title: "Facebook",
      value: "345K",
      sparkData: [2, 4, 2, 5, 8, 4],
    },
    {
      icon: require("../../assets/images/seoimages/youtube.png"),
      title: "Youtube",
      value: "1.5K",
      sparkData: [1, 2, 3, 4, 5, 6],
    },
    {
      icon: require("../../assets/images/seoimages/linkdin.png"),
      title: "Linkedin",
      value: "5K",
      sparkData: [5, 6, 7, 9, 3, 5],
    },
    {
      icon: require("../../assets/images/seoimages/Google.png"),
      title: "Google",
      value: "1K",
      sparkData: [3, 4, 6, 8, 7, 5],
    },
  ];
  const doughnutOptions = {
    responsive: true,
    maintainAspectRatio: false,
  };
  const doughnutData1 = {
    // labels: ["Label 1", "Label 2", "Label 3"],
    datasets: [
      {
        data: [300, 50, 100],
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
        hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
      },
    ],
  };
  const doughnutData2 = {
    // labels: ["Red", "Green", "Yellow"],
    datasets: [
      {
        data: [200, 150, 50],
        backgroundColor: ["#FF6384", "#4BC0C0", "#FFCE56"],
        hoverBackgroundColor: ["#FF6384", "#4BC0C0", "#FFCE56"],
      },
    ],
  };
  const lineData = {
    labels: ["1s", "2s", "3s", "4s", "5s", "6s"],
    datasets: [
      {
        label: "",
        data: [10, 25, 15, 30, 20, 25],
        borderColor: "rgba(75,192,192,1)",
        fill: false,
      },
    ],
  };

  const lineOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: "Time (s)",
        },
      },
      y: {
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: "Amplitude",
        },
      },
    },
  };

  return (
    <div className="m-4">
      <Row className="pb-0">
        {cardData.map((data, index) => (
          <Col key={index} md={2} className="mb-4">
            <Card className="h-100 p-1">
              <Card.Body className=" p-2">
                <Card.Title style={{ color: "black" }}>
                  {data.icon}{" "}
                  <span style={{ marginLeft: "8px" }}>{data.title}</span>
                </Card.Title>

                <Card.Text style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                  {data.value}
                </Card.Text>
                <Sparklines data={data.sparkData}>
                  <SparklinesLine color="blue" />
                </Sparklines>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      <Row className="pt-0 pb-0">
        {/* ECG-like Graph */}
        <Col md={4} className="mb-4">
          <Card className="h-100 p-0">
            <Card.Body className="p-2">
              <Line data={lineData} options={lineOptions} />
            </Card.Body>
          </Card>
        </Col>

        {/* Doughnut Chart 1 with labels beside */}
        <Col md={4} className="mb-4">
          <Card className="h-100 p-0">
            <Card.Body className="d-flex align-items-center p-2">
              <div style={{ width: "50%", height: "200px" }}>
                <Doughnut data={doughnutData1} options={doughnutOptions} />
              </div>

              <div style={{ marginLeft: "16px" }}>
                <div style={{ marginBottom: "10px" }}>
                  <strong>Label 1:</strong> 300
                </div>
                <div style={{ marginBottom: "10px" }}>
                  <strong>Label 2:</strong> 50
                </div>
                <div>
                  <strong>Label 3:</strong> 100
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>

        {/* Doughnut Chart 2 with labels beside */}
        <Col md={4} className="mb-4">
          <Card className="h-100 p-0">
            <Card.Body className="d-flex align-items-center p-2">
              <div style={{ width: "50%", height: "200px" }}>
                <Doughnut data={doughnutData2} options={doughnutOptions} />
              </div>
              <div style={{ marginLeft: "16px" }}>
                <div style={{ marginBottom: "10px" }}>
                  <strong>Red:</strong> 200
                </div>
                <div style={{ marginBottom: "10px" }}>
                  <strong>Green:</strong> 150
                </div>
                <div>
                  <strong>Yellow:</strong> 50
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="pb-0 pt-0">
        {SocialcardData.map((data, index) => (
          <Col key={index} className="mb-4">
            <Card className="h-100 p-1">
              <Card.Body className=" p-2">
                <Card.Title style={{ color: "black" }}>
                  {" "}
                  <img
                    className="card-img-top"
                    src={data.icon}
                    style={{ width: "24px", height: "24px" }}
                  />
                  <span style={{ marginLeft: "8px" }}>{data.title}</span>
                </Card.Title>

                <Card.Text style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                  {data.value}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};
export const Blogbasicsetting = ({
  postdata,
  BasicNext,
  formData,
  handleChange,
}) => {
  console.log(postdata);
  const handleSubmit = async (e) => {
    e.preventDefault();
    BasicNext();
  };
  return (
    <Form className=" mt-2" onSubmit={handleSubmit}>
      <Form.Group controlId="title">
        <Form.Label>Meta Title</Form.Label>
        <Form.Control
          type="text"
          name="title"
          placeholder="Enter title"
          value={formData.title || ""}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group controlId="metaDescription">
        <Form.Label>Meta Description</Form.Label>
        <Form.Control
          type="text"
          name="metaDescription"
          placeholder="Enter meta description"
          value={formData.metaDescription || ""}
          onChange={handleChange}
        />
      </Form.Group>

      <Button variant="primary" size="sm" type="submit" onClick={handleSubmit}>
        Next
      </Button>
    </Form>
  );
};
export const Blogogsetting = ({ formData, handleChange, OgNext }) => {
  const handleOGSubmit = async (e) => {
    e.preventDefault();
    OgNext();
  };

  return (
    <Form className=" mt-2" onSubmit={handleOGSubmit}>
      <Form.Group controlId="formOgTitle">
        <Form.Label>OG Title</Form.Label>
        <Form.Control
          type="text"
          name="ogTitle"
          placeholder="Enter OG title"
          value={formData.ogTitle || ""}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group controlId="formOgDescription">
        <Form.Label>OG Description</Form.Label>
        <Form.Control
          type="text"
          name="ogDescription"
          placeholder="Enter OG description"
          value={formData.ogDescription || ""}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group controlId="formOgUrl">
        <Form.Label>OG URL</Form.Label>
        <Form.Control
          type="text"
          name="ogUrl"
          placeholder="Enter OG URL"
          value={formData.ogUrl || ""}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group controlId="formOgImage">
        <Form.Label>OG Image</Form.Label>
        <Form.Control
          type="text"
          name="ogImage"
          placeholder="Enter OG image URL"
          value={formData.ogImage || ""}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group controlId="formOgType">
        <Form.Label>OG Type</Form.Label>
        <Form.Control
          type="text"
          name="ogType"
          placeholder="Enter OG type"
          value={formData.ogType || ""}
          onChange={handleChange}
        />
      </Form.Group>
      <h6>Twitter Setting</h6>
      <Form.Group controlId="formTwitterCard">
        <Form.Label>Twitter Card</Form.Label>
        <Form.Control
          type="text"
          name="twitterCard"
          placeholder="Enter Twitter card"
          value={formData.twitterCard || ""}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group controlId="formTwitterTitle">
        <Form.Label>Twitter Title</Form.Label>
        <Form.Control
          type="text"
          name="twitterTitle"
          placeholder="Enter Twitter title"
          value={formData.twitterTitle || ""}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group controlId="formTwitterImage">
        <Form.Label>Twitter Image</Form.Label>
        <Form.Control
          type="text"
          name="twitterImage"
          placeholder="Enter Twitter image URL"
          value={formData.twitterImage || ""}
          onChange={handleChange}
        />
      </Form.Group>
      <Button variant="primary" size="sm" type="submit">
        Next
      </Button>
    </Form>
  );
};
export const Blogkeyword = ({ postdata, KeywordNext }) => {
  const pageid = JSON.parse(sessionStorage.getItem("pageid"));

  const { user, setTransactionLoaderState,siteid } = useContext(GlobalContext);

  const [keyword, setKeyword] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [suggestedKeywords, setSuggestedKeywords] = useState([]);
  useEffect(() => {
    if (postdata?.post_keywords) {
      const prefilledKeywords = postdata.post_keywords.split(",").map((kw) => ({
        keyword: kw.trim(),
        id: Date.now() + Math.random(),
      }));
      setKeywords(prefilledKeywords);
    }
  }, [postdata]);
  const handleAddKeyword = (kw = keyword) => {
    if (kw) {
      const newKeywords = kw
        .split(",")
        .map((kw) => kw.trim())
        .filter(
          (kw) => kw && !keywords.some((existing) => existing.keyword === kw)
        );
      setKeywords([
        ...keywords,
        ...newKeywords.map((kw) => ({
          keyword: kw,
          id: Date.now() + Math.random(),
        })),
      ]);
      setKeyword("");
      setSuggestedKeywords([]);
    }
  };

  const handleRemoveKeyword = async (keyid) => {
    console.log(keyid);
    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          webid: siteid,
          pageid: pageid.id,
          keyid: keyid,
        }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/DeleteKeywords`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        swal("Success", data.data[0].message, "success");
        setKeywords(keywords.filter((kw) => kw.id !== keyid));
      } else {
        swal("Error", data.msg, "error");
      }
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  const Getkeyworddata = async () => {
    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ webid: siteid, pageid: pageid.id }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetAllKeywords`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        setKeywords(
          data.data.map((item) => ({
            keyword: item.keyword,
            id: item.id,
          }))
        );
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };
  const url = sessionStorage.getItem("selectedURLLabel");
  const GetSuggestedKeywordData = async (input) => {
    if (input.trim() === "") {
      setSuggestedKeywords([]);
      return;
    }
    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          userid: user.user_id,
          url: url,
        }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetAllKeywordsSuggestion`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        const allKeywords = data.data[0].keywords.split(",");
        setSuggestedKeywords(allKeywords);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    Getkeyworddata();
  }, []);
  console.log(postdata.post_keywords);
  return (
    <>
      <Form inline className="">
        <Form.Control
          type="text"
          value={keyword}
          onChange={(e) => {
            setKeyword(e.target.value);
            GetSuggestedKeywordData(e.target.value);
          }}
          placeholder="Enter keywords separated by commas"
          className="mr-2"
          style={{ width: "50%" }}
        />
        <IoCheckmarkDoneCircle
          style={{
            color: "green",
            fontSize: "1.5rem",
            cursor: "pointer",
          }}
          onClick={() => handleAddKeyword()}
        />
      </Form>
      {/* Render suggested keywords below the input field */}
      {suggestedKeywords.length > 0 && (
        <div
          className="suggestion-dropdown"
          style={{
            maxHeight: "150px",
            overflowY: "auto",
            border: "1px solid #ddd",
            marginTop: "10px",
          }}
        >
          {suggestedKeywords.map((suggestion, index) => (
            <div
              key={index}
              className="suggestion-item"
              style={{
                cursor: "pointer",
                padding: "5px",
                borderBottom: "1px solid #ddd",
              }}
              onClick={() => handleAddKeyword(suggestion)}
            >
              {suggestion}
            </div>
          ))}
        </div>
      )}
      <Tab.Container id="keyword-tabs" defaultActiveKey="keywords">
        <Tab.Content>
          <Tab.Pane eventKey="keywords">
            <div className="d-flex flex-wrap">
              {keywords.length > 0 ? (
                <>
                  {keywords.map((kw) => (
                    <div
                      key={kw.id}
                      className="d-flex align-items-center m-1 p-2 border rounded bg-light"
                      style={{
                        position: "relative",
                        margin: "5px",
                        padding: "5px 10px",
                      }}
                    >
                      {kw.keyword} &nbsp; &nbsp;
                      <AiOutlineClose
                        style={{
                          position: "absolute",
                          right: "5px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                        }}
                        onClick={() => handleRemoveKeyword(kw.id)}
                      />
                    </div>
                  ))}
                  <Button
                    type="button"
                    size="sm"
                    variant="primary"
                    className="ml-2 mt-2"
                    style={{ height: "2rem" }}
                    onClick={() => {
                      KeywordNext();
                    }}
                  >
                    Save & Next
                  </Button>
                </>
              ) : (
                <div className="text-center mt-4">No keywords found</div>
              )}
            </div>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </>
  );
};
export const Authorsetting = ({
  postdata,
  formData,
  handleChange,
  handleSubmit,
}) => {
  console.log(postdata);
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  // };
  return (
    <Form className=" mt-2" onSubmit={handleSubmit}>
      <Form.Group controlId="title">
        <Form.Label>Author Name</Form.Label>
        <Form.Control
          type="text"
          name="Authorname"
          placeholder="Enter Author name"
          value={formData.Authorname}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group controlId="Authorurl">
        <Form.Label>Author URL</Form.Label>
        <Form.Control
          type="text"
          name="Authorurl"
          placeholder="Enter Author url"
          value={formData.Authorurl}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group controlId="Publishername">
        <Form.Label>Publisher Name </Form.Label>
        <Form.Control
          type="text"
          name="Publishername"
          placeholder="Enter Publisher name"
          value={formData.Publishername}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group controlId="Publisherlogo">
        <Form.Label>Publisher Logo</Form.Label>
        <Form.Control
          type="text"
          name="Publisherlogo"
          placeholder="Enter Publisher logo"
          value={formData.Publisherlogo}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group controlId="Pageurl">
        <Form.Label>Page URL</Form.Label>
        <Form.Control
          type="text"
          name="Pageurl"
          placeholder="Enter Page url"
          value={formData.Pageurl}
          onChange={handleChange}
        />
      </Form.Group>
      <Button variant="primary" size="sm" type="submit" onClick={handleSubmit}>
        Next
      </Button>
    </Form>
  );
};

export const BlogSeoSettingForm = () => {
  const pageid = JSON.parse(sessionStorage.getItem("pageid"));
  const { setTransactionLoaderState, siteid, } = useContext(GlobalContext);

  const [formData, setFormData] = useState({
    meta_title: "",
    meta_description: "",
    og_title: "",
    og_description: "",
    og_image: "",
    og_url: "",
    og_type: "Blog",
    twitter_card: "summary_large_image",
    twitter_title: "",
    twitter_image: "",
    schema_org: "",
    created_at: "",
    updated_at: "",
    author_name: "",
    author_url: "",
    publisher_name: "",
    publisher_logo: "",
    keywords: "",
    pageurl: "",
  });

  const GetBlogSeoData = async () => {
    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          webid: siteid,
          pageid: pageid.id,
        }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetBlogSeoData`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0" && data.data.length > 0) {
        const apiData = data.data[0];
        setFormData({
          meta_title: apiData.meta_title || "",
          meta_description: apiData.meta_description || "",
          og_title: apiData.og_title || "",
          og_description: apiData.og_description || "",
          og_image: apiData.og_image || "",
          og_url: apiData.og_url || "",
          og_type: apiData.og_type || "Blog",
          twitter_card: apiData.twitter_card || "summary_large_image",
          twitter_title: apiData.twitter_title || "",
          twitter_image: apiData.twitter_image || "",
          schema_org: apiData.schema_org || "",
          created_at: apiData.created_at || "",
          updated_at: apiData.updated_at || "",
          author_name: apiData.author_name || "",
          author_url: apiData.author_url || "",
          publisher_name: apiData.publisher_name || "",
          publisher_logo: apiData.publisher_logo || "",
          keywords: apiData.keywords || "",
          pageurl: apiData.pageurl || "",
        });
      }
    } catch (error) {
      console.error("Error fetching blog SEO data:", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    GetBlogSeoData();
  }, [siteid]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          post_id: pageid.id,
          meta_title: formData.meta_title,
          meta_description: formData.meta_description,
          og_title: formData.og_title,
          og_description: formData.og_description,
          og_image: formData.og_image,
          og_url: formData.og_url,
          og_type: formData.og_type,
          twitter_card: formData.twitter_card,
          twitter_title: formData.twitter_title,
          twitter_image: formData.twitter_image,
          author_name: formData.author_name,
          author_Url: formData.author_url,
          publisher_name: formData.publisher_name,
          publisher_logo: formData.publisher_logo,
          keywords: formData.keywords,
          page_url: formData.pageurl,
        }),
      };

      console.log("Sending data to the server");
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/InsertBlogSeo`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        const message = data.result[0].message;
        swal("Success", message, "success");
      } else {
        swal("Error", data.msg, "error");
      }
      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
    }
  };

  return (
    <>
      <h6 className="mb-3">Seo Setting of {pageid.url_pages}</h6>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="metaTitle">
          <Form.Label>Meta Title</Form.Label>
          <Form.Control
            type="text"
            name="meta_title"
            value={formData.meta_title}
            onChange={handleChange}
            placeholder="Enter Meta Title"
            required
          />
        </Form.Group>

        <Form.Group controlId="metaDescription">
          <Form.Label>Meta Description</Form.Label>
          <Form.Control
            as="textarea"
            name="meta_description"
            value={formData.meta_description}
            onChange={handleChange}
            placeholder="Enter Meta Description"
            rows={3}
            required
          />
        </Form.Group>
        <Form.Group controlId="ogTitle">
          <Form.Label>OG Title</Form.Label>
          <Form.Control
            type="text"
            name="og_title"
            value={formData.og_title}
            onChange={handleChange}
            placeholder="Enter OG Title"
            required
          />
        </Form.Group>
        <Form.Group controlId="ogDescription">
          <Form.Label>OG Description</Form.Label>
          <Form.Control
            as="textarea"
            name="og_description"
            value={formData.og_description}
            onChange={handleChange}
            placeholder="Enter OG Description"
            rows={3}
            required
          />
        </Form.Group>
        <Form.Group controlId="ogImage">
          <Form.Label>OG Image URL</Form.Label>
          <Form.Control
            type="url"
            name="og_image"
            value={formData.og_image}
            onChange={handleChange}
            placeholder="Enter OG Image URL"
            required
          />
        </Form.Group>
        <Form.Group controlId="ogUrl">
          <Form.Label>OG URL</Form.Label>
          <Form.Control
            type="url"
            name="og_url"
            value={formData.og_url}
            onChange={handleChange}
            placeholder="Enter OG URL"
            required
          />
        </Form.Group>
        <Form.Group controlId="twitterCard">
          <Form.Label>Twitter Card Type</Form.Label>
          <Form.Control
            type="text"
            name="twitter_card"
            value={formData.twitter_card}
            onChange={handleChange}
            placeholder="Enter Twitter Card Type"
            required
          />
        </Form.Group>
        <Form.Group controlId="twitterTitle">
          <Form.Label>Twitter Title</Form.Label>
          <Form.Control
            type="text"
            name="twitter_title"
            value={formData.twitter_title}
            onChange={handleChange}
            placeholder="Enter Twitter Title"
            required
          />
        </Form.Group>
        <Form.Group controlId="twitterImage">
          <Form.Label>Twitter Image URL</Form.Label>
          <Form.Control
            type="url"
            name="twitter_image"
            value={formData.twitter_image}
            onChange={handleChange}
            placeholder="Enter Twitter Image URL"
            required
          />
        </Form.Group>
        <Form.Group controlId="keywords">
          <Form.Label>Keywords</Form.Label>
          <Form.Control
            type="text"
            name="keywords"
            value={formData.keywords}
            onChange={handleChange}
            placeholder="Enter Keywords"
            required
          />
        </Form.Group>
        <Form.Group controlId="schemaOrg">
          <Form.Label>Schema.org Data</Form.Label>
          <Form.Control
            as="textarea"
            name="schema_org"
            value={formData.schema_org}
            onChange={handleChange}
            placeholder="Enter Schema.org Data"
            rows={5}
            required
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>{" "}
    </>
  );
};
